/***
 * handle display date
 */
export const displayDate = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    const date = timestamp.toDate(); // Convert to JavaScript Date
    return `${date.toDateString()} : ${date.toLocaleTimeString()}`; // Format date and time as a string
  }
  return ""; // Return an empty string or a default value if the timestamp is invalid
};

/***
 * handle display date
 */
export const handleDisplayDate = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    const date = timestamp.toDate(); // Convert to JavaScript Date
    return `${date.toDateString()} : ${date.toLocaleTimeString()}`; // Format date and time as a string
  }
  return ""; // Return an empty string or a default value if the timestamp is invalid
};
