export default function Divider({ color = "white", height = "0.5px" }) {
  return (
    <hr
      style={{
        height: height,
        opacity: 0.25,
        backgroundColor: color,
        border: "none",
      }}
      className="my-2"
    />
  );
}
