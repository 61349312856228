import React, { useEffect, useState, useRef } from "react";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import Project from "../../models/Project";
import "./Projects.css";
import { db, timestamp } from "../../firebase/config";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BasicDialog from "../../components/dialogs/BasicDialog";
import InboxRow from "./components/InboxRow";

import AddProject from "./form/AddProject";
import ProjectTile from "./ProjectTile";
import TextField from "../../fields/TextField";
import MuiToggleButton from "@mui/material/ToggleButton";
import ProjectCollections from "./ProjectCollections";
import { toast } from "react-toastify";
import CollectionButton from "./CollectonButton";
import AddCollectionForm from "./form/AddCollectionForm";
import ProjectListRow from "./ProjectListRow";
import Enums from "../../models/Enums";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { useCollection } from "../../hooks/useCollection";
import { useAppContext } from "../../hooks/useAppContext";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Dialog } from "@mui/material";
import HexUser from "../../models/HexUser";
import { useCanvasContext } from "../../hooks/useCanvasContext";
import UserInbox from "./components/UserInbox";
import PendingFeedback from "./components/PendingFeedback";

import { SELECT_ROLES } from "../../models/Enums";

export default function Projects() {
  const { activeUser, userIs } = useAuthContext();
  const { users } = useCollectionContext();
  const { document: user } = useDocument("users", activeUser.id);
  const { height, width } = useWindowDimensions();
  const [showAddForm, setShowAddForm] = useState(false);
  const divRef = useRef(null);

  const {
    projects: projs,
    projectsLoading,
    collections,
  } = useCollectionContext();

  const { resetCanavs, setShowNav } = useCanvasContext();

  const [projects, setProjects] = useState([]);
  const [drawings, setDrawings] = useState([]);

  useEffect(() => {
    resetCanavs();
    setShowNav(true);
  }, []);

  useEffect(() => {
    if (projs && user) {
      const p = projs.filter((proj) => proj.entityId == user.entityId);
      setProjects([...p]);
    }
  }, [projs, user]);

  useEffect(() => {
    let results = [];
    if (projects) {
      projects.forEach((project) => {
        // results = results.concat(project.drawings); // Concatenate the drawings arrays
        if (project.drawings && project.drawings.length > 0) {
          results.push(...project.drawings);
        }
      });
      setDrawings([...results]);
    }
  }, [projects]);

  // entity
  const [openEntDialog, setOpenEntDialog] = useState(false);

  // open add menues
  const [popen, setpopen] = useState(false);
  const [copen, setcopen] = useState(false);

  //const [selected, setSelected] = useState(0);
  // Load the initial value from localStorage or set to 0 if not present
  const [selected, setSelected] = useState(() => {
    const savedSelected = localStorage.getItem("selected");
    return savedSelected !== null ? JSON.parse(savedSelected) : 0;
  });
  // Save `selected` to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selected", JSON.stringify(selected));
  }, [selected]);

  const handleOnListChnage = (value) => {
    setSelected(value);
  };

  const dismiss = () => {
    setShowAddForm(false);
    setShowCollectionForm(false);
  };

  const handleShowForm = () => {
    setpopen(true);
    // setShowAddForm(true);
  };

  const [showCollectionForm, setShowCollectionForm] = useState(false);
  const handleShowCollectionForm = () => {
    setcopen(true);
    // setShowCollectionForm(true);
  };

  const [state, setstate] = useState({
    query: "",
    list: [],
  });

  const [activeList, setActiveList] = useState([]);

  // update the filter
  const handleChange = (e) => {
    console.log(activeList);
    const results = activeList.filter((item) => {
      if (e.target.value === "") return activeList;
      return (
        item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.number.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setstate({
      query: e.target.value,
      list: results,
    });
  };

  // set initial list
  useEffect(() => {
    // I need to include project name as a feild
    if (projects) {
      // set initial values to project list
      setActiveList([...projects]);
      setstate({ ...state, list: [...projects] });
    }
  }, [projects]);

  const [inboxHeight, setInboxHeight] = useState("100");

  // switching between lists
  useEffect(() => {
    if (divRef.current) {
      const topOffset = divRef.current.getBoundingClientRect().top;
      setInboxHeight(`calc(100vh - ${topOffset}px)`);
    }

    // upon setting list
    switch (selected) {
      case 0:
        setActiveList(projects);
        setstate({ ...state, list: projects });
        return;
      default:
        setActiveList(collections);
        setstate({ ...state, list: collections });
        return;
    }
  }, [selected]);

  /***
   * handle click pinned
   */
  const handleClickPinned = async (e, pin) => {
    // propagation
    e.stopPropagation();

    // pinned
    const pinned = user.pinned.filter((p) => p != pin);
    const d = { pinned: pinned };
    const docRef = doc(db, "users", user.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      toast.success("pin removed");
    });
  };

  const nameForProjectId = (id) => {
    // Check if projects exist and is an array before filtering
    const project = projects?.find((p) => p.id === id);
    return project ? project.name : "Unknown Project";
  };

  const navigate = useNavigate();
  const goToProject = (id) => {
    navigate(`/project/${id}`);
  };

  const handleStartNewEntity = () => {
    setOpenEntDialog(true);
  };

  const handleAction = async (agree) => {
    if (agree) {
      // agree
      if (data.name == "") {
        toast.error("you must provide an entity name");
        return;
      }
      const entityName = data.name;
      const d = { name: entityName };
      const colRef = collection(db, "entities");
      await addDoc(colRef, d).then(async (ref) => {
        // update user account
        const entityId = ref.id;
        const d = { entityId: entityId };
        const docRef = doc(db, "users", user.id);
        await setDoc(docRef, d, { merge: true }).then((then) => {
          toast.success("account updated with new entity");
        });
      });
      setOpenEntDialog(false);
    } else {
      // disagree
      setOpenEntDialog(false);
    }
  };

  const [data, setData] = useState({ name: "" });
  const handleInputChange = (e, key) => {
    setData({ ...data, [key]: e.target.value });
  };

  const [unread, setUnread] = useState(0);
  useEffect(() => {
    if (user) {
      // check unread on user
      const hexUser = HexUser.fromData(user);
      const u = hexUser.numUnread();
      setUnread(u);
    }
  }, [user]);

  const [pendingFB, setPendingFB] = useState(0);

  return activeUser ? (
    <div
      style={{ minHeight: height - 50, overflowY: "scroll" }}
      className="w-[1200px] mx-auto hide-scrollbar"
    >
      {/* Dialog */}
      <BasicDialog
        title={"Add Entity"}
        open={openEntDialog}
        handleAction={handleAction}
      >
        {/* input */}

        <span style={{ display: "block" }} className="w-[500px]">
          <span style={{ display: "block" }} className="mt-4">
            <label
              htmlFor="sessionDescription"
              className="block text-sm font-medium text-gray-700"
            >
              Add session description
            </label>
            <input
              id="entityName"
              name="entity name"
              placeholder={"entity name"}
              value={data["name"]}
              className="mt-2 block w-full rounded-md border-gray-200 border-2 focus:border-gray-300 focus:ring-0 sm:text-sm h-12 pl-4 resize-none"
              onChange={(e) => handleInputChange(e, "name")}
            />
          </span>
        </span>
      </BasicDialog>

      {/* if user has an entity */}
      {activeUser.entityId && (
        <div className="">
          <div className="flex h-[50px] items-center gap-1 py-10">
            <div className="flex gap-1 items-center">
              <ProjectCollections
                selected={selected}
                onChange={handleOnListChnage}
              >
                <CollectionButton
                  index={0}
                  title={"projects"}
                  selected={selected}
                />
                <CollectionButton
                  index={1}
                  title={"collection"}
                  selected={selected}
                />
                <CollectionButton
                  index={2}
                  title={"inbox"}
                  selected={selected}
                  badge={unread}
                />
                <CollectionButton
                  index={3}
                  title={"Feedback"}
                  selected={selected}
                  badge={pendingFB}
                />
              </ProjectCollections>
              {/* <div className="flex items-center justify-center rounded w-[100px] h-[50px] font-bold text-lg bg-orange-300 cursor-pointer">
                <div className="">Inbox</div>
              </div> */}
            </div>

            {/* project search filed */}
            <div className="flex ml-auto">
              <input
                className="flex w-[400px] h-[50px] px-4 bg-gray-300 rounded-lg"
                placeholder="search for scope"
                onChange={handleChange}
                value={state.query}
                type="search"
              />
            </div>

            <div className="flex items-center justify-center">
              <div
                onClick={handleShowForm}
                className="flex items-center justify-center w-[200px] h-[50px] cursor-pointer bg-gray-500 px-4 py-2 rounded"
              >
                [add project]
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div
                onClick={handleShowCollectionForm}
                className="flex items-center justify-center w-[200px] h-[50px] cursor-pointer bg-gray-500 px-4 py-2 rounded"
              >
                [add collection]
              </div>
            </div>
          </div>
          {showCollectionForm && (
            <div>
              <AddCollectionForm dismiss={dismiss} />
            </div>
          )}
          {showAddForm && (
            <div className="flex w-full items-center justify-center mt-4">
              <AddProject dismiss={dismiss} />
            </div>
          )}

          <ProjectMenu popen={popen} setpopen={setpopen} />
          <CollectionMenu copen={copen} setcopen={setcopen} />

          {/* no projects */}
          {projectsLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="flex flex-col gap-2 items-center justify-center bg-black/50 rounded-lg w-[100px] h-[100px]">
                <div className="text-white font-thin">loading</div>
                <CircularProgress size={20} sx={{ color: "white" }} />
              </div>
            </div>
          )}

          {!projectsLoading && projects.length == 0 && (
            <div className="flex h-full">
              <div className="flex items-center justify-center w-full min-h-[calc(100vh-142px)]">
                <div
                  onClick={() => setpopen(true)}
                  className="text-lg cursor-pointer border-gray-200 border-2 rounded-xl px-16 py-4 hover:bg-gray-100 hover:border-gray-300"
                >
                  add a project
                </div>
              </div>
            </div>
          )}

          {/* project list */}
          {projects.length > 0 && (
            <div className="flex flex-col justify-center gap-1">
              {selected == 0 &&
                user?.pinned.length > 0 &&
                state.query.length == 0 && (
                  <div className="flex flex-col gap-1 mb-4">
                    <h1>Pinned Projects</h1>
                    {user?.pinned.map((pin) => (
                      <div key={pin}>
                        <h2
                          onClick={() => goToProject(pin)}
                          className="flex items-center bg-orange-300 cursor-pointer p-4 font-bold min-h-[80px]"
                        >
                          <div className="w-[80px]">100</div>
                          <div>{nameForProjectId(pin)}</div>
                          <div
                            onClick={(e) => handleClickPinned(e, pin)}
                            className="ml-auto"
                          >
                            unpin
                          </div>
                        </h2>
                      </div>
                    ))}
                  </div>
                )}

              {selected == 3 && (
                <div
                  ref={divRef}
                  style={{ minHeight: inboxHeight }}
                  className="relative w-full"
                >
                  <PendingFeedback
                    inboxHeight={inboxHeight}
                    drawings={drawings}
                  />
                </div>
              )}

              {selected == 2 && (
                <div
                  ref={divRef}
                  style={{ minHeight: inboxHeight }}
                  className="relative w-full"
                >
                  <UserInbox inboxHeight={inboxHeight} drawings={drawings} />
                </div>
              )}

              {selected == 0 &&
                state.list
                  .sort(
                    (itemA, itemB) =>
                      Number(itemA.number) - Number(itemB.number)
                  )
                  ?.map((projectData) => Project.fromData(projectData))
                  .map((project, index) => (
                    <div className="flex" key={index}>
                      <ProjectListRow
                        type={Enums.ListItemType.project}
                        hexpoData={project}
                      />
                    </div>
                  ))}

              {selected == 1 &&
                state.list?.map((colData, index) => (
                  <div className="flex" key={index}>
                    <ProjectListRow
                      type={Enums.ListItemType.collection}
                      hexpoData={colData}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      )}

      {/* if user has no entity */}

      {!activeUser.entityId && !userIs(SELECT_ROLES.STUDENT) && (
        <div className="flex items-center justify-center w-full min-h-[calc(100vh-50px)]">
          <div className="flex flex-col gap-1 text-center">
            <div className="text-3xl">user has no entity</div>
            <div
              onClick={handleStartNewEntity}
              className="bg-gray-200 w-[400px] py-2 px-6 rounded mt-2 cursor-pointer"
            >
              start new entity
            </div>
            <div className="bg-gray-200 w-[400px] py-2 px-6 rounded cursor-pointer">
              request joining to entity
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="flex flex-col gap-2 items-center justify-center bg-black/50 rounded-lg w-[100px] h-[100px]">
        <div className="text-white font-thin">loading</div>
        <CircularProgress size={20} sx={{ color: "white" }} />
      </div>
    </div>
  );
}

const ProjectMenu = ({ popen, setpopen, collection }) => {
  const dismiss = () => {
    setpopen(false);
  };
  return (
    <div className="flex">
      <div
        style={{ height: popen ? "100%" : "0%", width: "100%" }}
        className={`fixed flex flex-col overflow-hidden duration-300 items-center bottom-0 right-0 bg-yellow-400 h-full z-10`}
      >
        <div
          onClick={() => setpopen(false)}
          className="cfursor-pointer mt-10 h-[40px]"
        >
          <h1 className="cursor-pointer">add project</h1>
        </div>
        <div>
          <AddProject dismiss={dismiss} />
        </div>
        {/* <div className="w-[600px] h-48 overflow-y-scroll scroll-box bg-transparent p-4">
          <div>
            <h2 className="text-lg font-semibold mb-2">Title 1</h2>
            <p className="mb-4">
              This is some text inside the scroll box. You can add as much
              content as you like.
            </p>

            <h2 className="text-lg font-semibold mb-2">Title 2</h2>
            <p className="mb-4">
              Here's another paragraph. Notice how the scroll bar appears as you
              add more content.
            </p>

            <h2 className="text-lg font-semibold mb-2">Title 3</h2>
            <p className="mb-4">
              More content here. You can also add images, links, or any other
              HTML elements.
            </p>

            <h2 className="text-lg font-semibold mb-2">Title 4</h2>
            <p className="mb-4">
              The scroll box will expand to fit all the content until it reaches
              the defined height.
            </p>

            <h2 className="text-lg font-semibold mb-2">Title 5</h2>
            <p className="mb-4">
              Feel free to customize the width, height, and other styles to fit
              your design needs.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

// collection menu
const CollectionMenu = ({ copen, setcopen, collection }) => {
  const dismiss = () => {
    setcopen(false);
  };

  return (
    <div>
      <div
        style={{ height: copen ? "100%" : "0%", width: "100%" }}
        className={`flex flex-col overflow-hidden duration-300 items-center absolute bottom-0 right-0 bg-orange-600 h-full z-10`}
      >
        <div
          onClick={() => setcopen(false)}
          className="cfursor-pointer mt-10 h-[40px]"
        >
          <h1 className="cursor-pointer">add collection</h1>
        </div>
        <div className="">
          <AddCollectionForm dismiss={dismiss} />
        </div>
      </div>
    </div>
  );
};
