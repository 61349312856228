import { Dialog } from "@mui/material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import InboxRow from "./InboxRow";
import { handleSendDrawing_Group } from "../../../utilities/InboxManager";
import RecipientSelector from "../../hex/components/RecipientSelector";

export default function UserInbox({ inboxHeight, drawings }) {
  const { activeUser } = useAuthContext();
  const { users } = useCollectionContext();
  const [user, setUser] = useState(null);
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [currentDrawing, setCurrentDrawing] = useState(null);

  // drawing groups
  const [todayItems, setTodayItems] = useState([]);
  const [prevItems, setPrevItems] = useState([]);

  useEffect(() => {
    if (users) {
      const u = users.find((u) => u.id == activeUser.id);
      setUser(u);
      const items = u.inbox.items;

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset to the start of the day

      const todayGroup = [];
      const otherGroup = [];

      items.forEach((item) => {
        const itemDate = item.date.toDate();

        // Check if the item's date is today
        if (itemDate >= today) {
          todayGroup.push(item);
        } else {
          otherGroup.push(item);
        }
      });

      setTodayItems(todayGroup);
      setPrevItems(otherGroup);
    }
  }, [users]);

  const handleOpenDialog = (drawing) => {
    setCurrentDrawing(drawing);
    setOpenSendDialog(true);
  };

  /***
   * handle send to inboxs
   */
  const handleSend = (e) => {
    e.stopPropagation();
    if (recipients.length < 1) {
      toast.error("must add recipients to send drawing");
      return;
    }
    const ids = recipients.map((r) => r.id);
    console.log(ids);

    handleSendDrawing_Group(
      e,
      users,
      currentDrawing,
      activeUser.id,
      ids,
      setOpenSendDialog
    );
  };

  const handleClose = () => {
    setCurrentDrawing(null);
    setOpenSendDialog(false);
  };

  const handleCapture = (e) => {
    e.stopPropagation();
  };

  if (!user) return <div>loading</div>;

  return (
    <div
      style={{ minHeight: inboxHeight }}
      className="flex flex-col gap-1 w-full h-full"
    >
      {/* forward dialog */}
      <div onClick={(e) => handleCapture(e)}>
        <Dialog
          open={openSendDialog}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "600px",
              maxWidth: "600px",
              padding: "20px",
              textAlign: "center",
            },
          }}
          BackdropProps={{
            style: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Darker backdrop
          }}
        >
          <RecipientSelector
            users={users}
            recipients={recipients}
            setRecipients={setRecipients}
          />
          <div className="flex gap-1 mx-auto mt-4">
            <button
              onClick={handleClose}
              className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
            >
              Close
            </button>
            <button
              onClick={handleSend}
              className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
            >
              send
            </button>
          </div>
        </Dialog>
      </div>

      {todayItems.length > 0 && <div className="text-4xl my-6">Today</div>}

      {todayItems
        .sort((a, b) => b.date.toMillis() - a.date.toMillis())
        .map((item) => (
          <div key={item.id}>
            <InboxRow
              user={user}
              item={item}
              drawings={drawings}
              handleOpenDialog={handleOpenDialog}
            />
          </div>
        ))}

      {prevItems.length > 0 && <div className="text-4xl my-6">Previous</div>}
      {prevItems
        .sort((a, b) => b.date.toMillis() - a.date.toMillis())
        .map((item) => (
          <div key={item.id}>
            <InboxRow
              user={user}
              item={item}
              drawings={drawings}
              handleOpenDialog={handleOpenDialog}
            />
          </div>
        ))}
    </div>
  );
}
