import { useEffect, useState } from "react";

export const useIsWindows = () => {
  const [isWindows, setIsWindows] = useState(() => {
    if (typeof navigator !== "undefined") {
      return navigator.platform.indexOf("Win") > -1;
    }
    return false;
  });

  useEffect(() => {
    if (typeof navigator !== "undefined") {
      setIsWindows(navigator.platform.indexOf("Win") > -1);
    }
  }, []);

  return isWindows;
};
