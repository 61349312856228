import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../firebase/config";

class MisDocument {
  id = ""; // generated by firebase on adding object to firestore
  name = "";
  number = "";
  date = new Date();
  url = "";
  serial = "";
  drafts = [];

  constructor(_id = uuid()) {
    this.id = _id;
  }

  blankId() {
    delete this.id; // remove the id when first initiating so we can allow firebase to install its document id
    return this;
  }

  static fromData = (data) => {
    const p = new MisDocument(data["id"]);
    p.name = data["name"];
    p.number = data["number"];
    p.date = data["date"];
    p.drafts = data["drafts"];
    p.url = data["url"];
    p.serial = data["serial"];
    return p;
  };

  data = () => {
    return {
      ...(this.id !== undefined && { id: this.id }),
      number: this.number,
      name: this.name,
      date: this.date,
      drafts: this.drafts,
      url: this.url,
      serial: this.serial,
    };
  };

  addDraft = (draft) => {
    this.drafts.push(draft);
  };

  removeDraft = (draftId) => {
    this.drafts = this.drafts.filter((draft) => draft.id != draftId);
  };
}
export default MisDocument;
