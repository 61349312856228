import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import HexDialog from "../studio/forms/HexDialog";
import { COLLECTION } from "../../models/Enums";
import MisLawsuit from "../../models/MisLawsuit";
import MisClient from "../../models/MisClient";
import { toast } from "react-toastify";
import { uuidv4 as uuid } from "@firebase/util";

export default function LawsuitPage() {
  const { clientId, lawsuitId } = useParams();
  const { document: client } = useDocument(COLLECTION.MisClients, clientId);
  const [data, setData] = useState({ name: "", serial: "" });
  const [lawsuit, setLawsuit] = useState(null);

  /***
   * set lawsuit
   */
  useEffect(() => {
    if (client) {
      const lawsuit = client.lawsuits.find(
        (lawsuit) => lawsuit.id == lawsuitId
      );
      setLawsuit(lawsuit);
    }
  }, [client]);

  /***
   * handle add draft
   */
  const handleAddDraft = () => {
    toast.success("add drafts");
    const d = { id: uuid(), name: "document", date: new Date(), drafts: [] };
    const misClient = MisClient.fromData(client);
    misClient.addDocumentToLawsuit(d, lawsuitId);
    misClient.commit().then((ref) => {
      toast.success("document added");
    });
  };

  const [showEditDialog, setShowEditDialog] = useState(false);
  const handleShowDialog = () => {
    setShowEditDialog(true);
  };

  const handleSetName = (agree) => {
    if (agree) {
      const misClient = MisClient.fromData(client);
      const hexLawsuit = misClient.getLawsuitWithId(lawsuitId);
      hexLawsuit.name = data.name;
      misClient.commit().then((ref) => {
        toast.success("name updated");

        setShowEditDialog(false);
      });
    } else {
      setShowEditDialog(false);
    }
  };

  return (
    <div className="flex w-[1000px] bg-red-400 mx-auto">
      {/* dialog */}
      <HexDialog
        open={showEditDialog}
        data={data}
        setData={setData}
        handleDialogButton={handleSetName}
      />

      <div className="w-full">
        <div className="flex items-center">
          <h2>Lawsuit</h2>
          <div
            onClick={handleShowDialog}
            className="ml-auto mr-2 opacity-50 hover:opacity-100 cursor-pointer"
          >
            [edit]
          </div>
        </div>
        <div>name {lawsuit?.name}</div>
        <div>clientId: {client?.id}</div>
        <div>clientId: {client?.name}</div>
        <div>lawsuitId: {lawsuitId}</div>
        <div className="flex items-center">
          <h2>documents</h2>
          <div onClick={handleAddDraft} className="ml-auto mr-2 cursor-pointer">
            [add document]
          </div>
        </div>
        <div className="flex flex-col gap-1">
          {lawsuit &&
            lawsuit.documents.map((document) => (
              <div key={document.id}>
                <LawsuitDocumentRow
                  clientId={clientId}
                  lawsuitId={lawsuitId}
                  document={document}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const LawsuitDocumentRow = ({ clientId, lawsuitId, document }) => {
  const navigate = useNavigate();
  const goToDocument = (e) => {
    navigate(`/lawsuitDocument/${clientId}/${lawsuitId}/${document.id}`);
  };

  return (
    <div
      onClick={(e) => goToDocument(e)}
      className="flex items-center bg-gray-600 min-h-[60px] p-2 cursor-pointer"
    >
      <div className="">{document.id}</div>
    </div>
  );
};
