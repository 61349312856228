import React, { useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CanvasDraw from "react-canvas-draw";
import ImageA from "../../assets/black/image_a.jpg";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js";

const PdfSketch = ({ pdfFile }) => {
  const canvasRef = useRef(null);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const { width, height } = useWindowDimensions();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleSave = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const pdfCanvas = document.querySelector(".react-pdf__Page__canvas");
    const drawingCanvas = canvasRef.current.canvasContainer.children[1];

    canvas.width = pdfCanvas.width;
    canvas.height = pdfCanvas.height;

    ctx.drawImage(pdfCanvas, 0, 0);
    ctx.drawImage(drawingCanvas, 0, 0);

    const link = document.createElement("a");
    link.download = "sketch.pdf";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  return (
    <div>
      {/* <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document> */}
      <img src={ImageA} />
      <div className="bg-red-400 p-4">
        <CanvasDraw
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "transparent",
          }}
          canvasWidth={width} // Match PDF page width
          canvasHeight={height} // Match PDF page height
        />
      </div>

      {/* <button onClick={handleSave}>Save Sketch</button>
      <div>
        <button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Previous
        </button>
        <button
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default PdfSketch;
