import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { DrawingType } from "./Enums";

class HexInboxItem {
  id = ""; // generated by firebase on adding object to firestore
  projectId = "";
  drawingId = "";
  senderId = "";
  date = new Date();
  isRead = false;

  constructor(_id = uuid()) {
    this.id = _id;
    this.date = new Date();
  }

  static fromData = (data) => {
    const p = new HexInboxItem(data["id"]);
    p.projectId = data["projectId"];
    p.drawingId = data["drwaingId"];
    p.date = data["date"];
    p.isRead = data["isRead"];
    p.senderId = data["senderId"];

    return p;
  };

  data = () => {
    return {
      id: this.id,
      projectId: this.projectId,
      drawingId: this.drawingId,
      date: this.date,
      isRead: this.isRead,
      senderId: this.senderId,
    };
  };

  dateString = () => {
    return new Date(this.date.seconds * 1000).toDateString();
  };

  /***
   * handle display date
   */
  dateTimeString = () => {
    const d = this.date.toDate(); // Convert to JavaScript Date
    return `${d.toDateString()} : ${d.toLocaleTimeString()}`; // Format date and time as a string

    return ""; // Return an empty string or a default value if the timestamp is invalid
  };
}

export default HexInboxItem;
