import PlanA from "../assets/plans/planA.jpg";
import PlanC from "../assets/plans/PlanC.jpg";
import PlanD from "../assets/plans/PlanD.jpg";

import { createContext, useContext, useEffect, useState, useRef } from "react";
import { useCollection } from "../hooks/useCollection";
import * as pdfjs from "pdfjs-dist/webpack";
import { toast } from "react-toastify";
import { HexpoTools, Colors, DrawingType } from "../models/Enums";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { jsPDF } from "jspdf";
import { PointOfSale } from "@mui/icons-material";
import useDeviceType from "../hooks/useDeviceType";
import { uuidv4 as uuid } from "@firebase/util";

import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { db } from "../firebase/config";
import HexDrawing from "../models/HexDrawing";
import Project from "../models/Project";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUploadDocument } from "../hooks/useUploadDocument";
import { useIsWindows } from "../hooks/useIsWindows";
import {
  resolveFeedBackSerial,
  resolveStage,
} from "../utilities/SerialManager";
import HexStudioProject from "../models/HexStudioProject";
import { useStudioContext } from "../hooks/useStudioContext";
import { useNavigate } from "react-router-dom";

// create context
export const CanvasContext = createContext();

// context provider
export const CanvasContextProvider = ({ children }) => {
  const { width, height } = useWindowDimensions();
  const { activeUser } = useAuthContext();
  const { currentStudentId, resolveSerial, rating } = useStudioContext();
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
  const [showQuickBrush, setShowQuickBrush] = useState(false);
  const [hexSession, setHexSession] = useState(true);

  const { uploadImage } = useUploadDocument();
  const isWindows = useIsWindows();
  const deviceType = useDeviceType();

  // refs
  const renderTaskRef = useRef(null); // Ref to track the ongoing render task
  const canvasRef = useRef(null);
  const imageCanvasRef = useRef(null);
  const image = useRef(new Image());
  const storage = getStorage();
  const audioRef = useRef(null);
  const audioClickRef = useRef(null);

  const resetCanavs = () => {
    clearLines();
    resetScale();
    resetCanvasOffset();
    activateViewMode();
  };

  useEffect(() => {
    if (audioRef.current) audioRef.current.volume = 0.25;
    if (audioClickRef.current) audioClickRef.current.volume = 0.25;
  }, []);

  // scale
  const scaleFactor = 0.15;

  const [loading, setLoading] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [currentDrawing, setCurrentDrawing] = useState(null);
  const [showNav, setShowNav] = useState(true);

  // pdf resolution
  const viewPortDefaultScale = 4;
  const [viewportScale, setViewportScale] = useState(viewPortDefaultScale);
  const [isHighRes, setIsHighRes] = useState(false);
  // const [isHighRes, setIsHighRes] = useState(() => {
  //   const savedIsHighRes = localStorage.getItem("isHighRes");
  //   return savedIsHighRes ? JSON.parse(savedIsHighRes) : true;
  // });

  // useEffect(() => {
  //   // high res
  //   localStorage.setItem("isHighRes", JSON.stringify(isHighRes));
  // }, [isHighRes]);

  // state
  const [pdf, setPdf] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pdfCanvasRef = useRef(null);
  const [numPages, setNumPages] = useState(1);
  const [scale, setScale] = useState(1);
  const [lastScale, setLastScale] = useState(1);
  const [activeTool, setActiveTool] = useState(HexpoTools.Draw);
  const [isDrawing, setIsDrawing] = useState(false);

  // debug
  const [dImageHeight, setDImageHeight] = useState(0);
  const [dA3Height, setDA3Height] = useState(0);
  const [dOffsetY, setDOffsetY] = useState(0);

  // imaegs + lines
  const [imagesArray, setImagesArray] = useState([]); // To store images of each page
  const [linesArray, setLinesArray] = useState([]);

  // undo + redo
  const [history, setHistory] = useState([]); // Undo history
  const [redoStack, setRedoStack] = useState([]); // Redo stack

  // position
  const [dragStartOffset, setDragStartOffset] = useState({ x: 0, y: 0 });
  const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });
  const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });
  const [canvasOffset, setCanvasOffset] = useState({ x: 0, y: 0 });
  const [lastCanvasOffset, setLastCanvasOffset] = useState({ x: 0, y: 0 });
  const [lastPos, setLastPos] = useState({ x: 0, y: 0 });
  const [canvasPosition, setCanvasPosition] = useState({
    x: 0,
    y: 0,
  });

  const resetCanvasOffset = () => {
    setCanvasOffset({ x: 0, y: 0 });
  };

  // touches and guestures
  const [touches, setTouches] = useState([]);
  const [isPinching, setIsPinching] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startDistance, setStartDistance] = useState(0);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  // tools
  const [viewModeIsActive, setViewModeIsActive] = useState(true);
  const [dLock, setDLock] = useState(false);
  const [selected, setSelected] = useState(0);
  const [brushSize, setBrushSize] = useState(20);
  const [isErasing, setIsErasing] = useState(false);
  const [isPainting, setIsPainting] = useState(false);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [sessionColor, setSessionColor] = useState("bg-black");
  const [activeQuickBrush, setActiveQuickBrush] = useState(0);

  const [showCanvasPages, setShowCanvasPages] = useState(true);

  // const [opacity, setOpacity] = useState(100);

  // load opacity
  const [opacity, setOpacity] = useState(() => {
    const savedOpacity = localStorage.getItem("opacity");
    return savedOpacity ? JSON.parse(savedOpacity) : 100;
  });
  // save opacity
  useEffect(() => {
    // high res
    localStorage.setItem("opacity", JSON.stringify(opacity));
  }, [opacity]);

  const [activePdf, setActivePdf] = useState(() => {
    const savedActivePdf = localStorage.getItem("activePDF");
    return savedActivePdf ? JSON.parse(savedActivePdf) : "";
  });

  const drawImageCanvas = (
    canvas,
    context,
    viewport,
    scale = 1,
    canvasOffset = { x: 0, y: 0 }
  ) => {
    // Clear the canvas before drawing
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Save the current context state
    context.save();

    // Apply scaling and translation
    context.setTransform(scale, 0, 0, scale, canvasOffset.x, canvasOffset.y);

    // Call the method to draw the image with object-fit cover behavior
    const renderContext = drawImageWithObjectFitCover(context, viewport);

    // Restore the context to its original state
    context.restore();

    // return render context
    return renderContext;
  };

  const loadPdfDocument = async (url) => {
    const loadingTask = pdfjs.getDocument(url);
    const pdfDocument = await loadingTask.promise; // promise, when loading is complete, assign it to pdfDocument
    return pdfDocument;
  };

  // ---------------------------------------------------------------------------------

  const drawImageWithObjectFitCover = (canvas, context, viewport) => {
    const imgWidth = viewport.width;
    const imgHeight = viewport.height;

    const imgAspectRatio = imgWidth / imgHeight;
    const canvasAspectRatio = canvas.width / canvas.height;

    let drawWidth, drawHeight, offsetX, offsetY;

    if (imgAspectRatio > canvasAspectRatio) {
      // Image is wider than canvas
      drawHeight = canvas.height;
      drawWidth = imgWidth * (canvas.height / imgHeight);
      offsetX = -(drawWidth - canvas.width) / 2; // Center horizontally
      offsetY = 0;
    } else {
      // Image is taller than canvas
      drawWidth = canvas.width;
      drawHeight = imgHeight * (canvas.width / imgWidth);
      offsetX = 0;
      offsetY = -(drawHeight - canvas.height) / 2; // Center vertically
    }

    let scale = drawHeight / imgHeight;

    // Clear the canvas before drawing
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Set the transform to scale and translate the content
    context.setTransform(scale, 0, 0, scale, offsetX, offsetY);

    // Render the PDF page using the current transform settings
    return { canvasContext: context, viewport };
  };

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  const renderPage = async (width, height) => {
    if (!pdf) return; // Wait for the PDF to load before rendering pages

    try {
      // Get the page and its original viewport
      const page = await pdf.getPage(currentPage || 1);

      // const originalViewport = page.getViewport({ scale: 1 });

      // Get the screen dimensions (or the container's dimensions where the canvas is placed)
      // const screenWidth = window.innerWidth;
      // const screenHeight = window.innerHeight;

      // Calculate the scaling factor for object-fit: cover
      // const scaleWidth = screenWidth / originalViewport.width;
      // const scaleHeight = screenHeight / originalViewport.height;
      // const scale = Math.max(scaleWidth, scaleHeight); // To cover the whole screen, use the larger scale

      // Apply the new scale to the viewport
      const viewport = page.getViewport({ scale });

      // Get the canvas and context
      const canvas = pdfCanvasRef.current;
      const context = canvas.getContext("2d");

      // Set the canvas size to match the screen dimensions
      canvas.width = width; // screenWidth;
      canvas.height = height; // screenHeight;

      // Cancel the previous render task if still running
      if (renderTaskRef.current) {
        toast.success("will cancel render task");
        renderTaskRef.current.cancel();
      }

      // Call the method to draw the image with object-fit cover behavior
      const renderContext = drawImageWithObjectFitCover(
        canvas,
        context,
        viewport
      );

      // Render the page on the canvas with the new viewport
      const task = page.render(renderContext);
      renderTaskRef.current = task; // Track the ongoing render task

      // not sure what this is doing
      await task.promise;

      renderTaskRef.current = null; // Clear the task when rendering is complete
    } catch (e) {
      if (e.name === "RenderingCancelledException") {
        console.log("Rendering cancelled:", e.message);
      } else {
        toast.error(`Error rendering page ${currentPage}: ${e.message}`);
      }
    }
  };

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  const cancelRenderTask = () => {
    if (renderTaskRef.current) {
      renderTaskRef.current.cancel();
    }
  };

  const loadPdf = async () => {
    try {
      const url = activePdf != "" ? activePdf : "";
      const pdfDocument = await loadPdfDocument(url);

      // set state
      setCurrentPage(1);
      setPdf(pdfDocument); // Store the loaded PDF document
      setNumPages(pdfDocument.numPages); // Set the total number of pages
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  };

  /***
   * method to set brush size
   */
  const handleBrush = (value) => {
    switch (value) {
      case -1:
        setBrushSize(1);
        break;
      case 0:
        setBrushSize(2);
        break;
      case 1:
        setBrushSize(6);
        break;
      case 2:
        setBrushSize(10);
        break;
      case 3:
        setBrushSize(14);
        break;
      case 4:
        setBrushSize(18);
        break;
    }
    // update ui
    setSelected(value);
  };

  /***
   * call back on tool selection
   */
  const handleToolClick = (toolKey) => {
    switch (toolKey) {
      case HexpoTools.Draw:
        setIsErasing(false);
        setIsPainting(false);
        setActiveTool(HexpoTools.Draw);
        break;
      case HexpoTools.Erase:
        setIsErasing(true);
        setIsPainting(false);
        setActiveTool(HexpoTools.Erase);
        break;
      case HexpoTools.Paint:
        setIsErasing(false);
        setIsPainting(true);
        setDLock(false);
        setActiveTool(HexpoTools.Paint);
        break;
    }
  };

  const handleZoomExtent = () => {
    restCanvasPosition();
  };

  const restCanvasPosition = () => {
    setScale(1);
    setCanvasPosition({ x: 0, y: 0 });
    setCanvasOffset({ x: 0, y: 0 });
  };

  // Undo the last action
  const undoLastAction = () => {
    if (lines.length > 0) {
      // Create a shallow copy of lines to avoid direct mutation
      const newLines = [...lines];

      // Pop the last line (undo the most recent action)
      const lastLine = newLines.pop();

      // Log state changes for debugging
      console.log("Before undo:", lines);
      console.log("Last line removed:", lastLine);
      console.log("New lines after undo:", newLines);

      // Add the removed line to history (for redo functionality)
      setHistory([...history, lastLine]);

      // Update the lines with the new array, which excludes the last line
      setLines((prev) => [...newLines]);

      // Clear the redo stack when a new action is taken
      setRedoStack([]);
      // audioRef.current.volume = 0.02;
      // audioRef.current.play();
    } else {
      // audioClickRef.current.volume = 0.2;
      // audioClickRef.current.play();
    }
  };

  // Redo the last undone action
  const redoLastAction = () => {
    if (history.length > 0) {
      const newHistory = [...history];
      const lastUndoneLine = newHistory.pop(); // Get last undone line
      setRedoStack([...redoStack, lastUndoneLine]); // Add to redo stack
      setLines([...lines, lastUndoneLine]); // Re-add the undone line to the canvas
      setHistory(newHistory); // Update the history
      // audioRef.current.valume = 0.02;
      // audioRef.current.play();
    } else {
      // audioClickRef.current.valume = 0.2;
      // audioClickRef.current.play();
    }
  };

  // lines
  const lineRef = useRef([]);
  const [lines, setLines] = useState(() => {
    const savedLines = localStorage.getItem("canvas-drawings");
    return savedLines ? JSON.parse(savedLines) : [];
  });

  const clearCanvas = (bypass = false) => {
    if (bypass || window.confirm("clear canvas")) {
      lineRef.current = { points: [] };

      const context = canvasRef.current.getContext("2d");
      context.clearRect(0, 0, canvasSize.width, canvasSize.height);
      clearLines();
    }
  };

  const clearLines = () => {
    // empty lineRef
    setLines([]);
    setHistory([]);
    localStorage.setItem("canvas-drawings", JSON.stringify([]));
  };

  const clearLocalStorage = () => {};

  const baseLineWidth = 10; // Set a base line width that remains constant
  const canvasScaleFactor = 4;
  const [canvasSize, setCanvasSize] = useState({
    width: width * canvasScaleFactor,
    height: height * canvasScaleFactor,
  });

  const handleColorPicker = () => {
    setIsSavingPDF(false);
    setOpenColorPicker(!openColorPicker);
  };

  const closeColorPicker = () => {
    setOpenColorPicker(false);
  };

  const handleDLock = () => {
    setDLock(!dLock);
  };

  /***
   * save canvas
   */
  const saveCanvas = (page = undefined) => {
    const canvas = canvasRef.current;
    let localLines = [];
    let img = new Image();

    if (page == undefined) {
      img = image.current;
      localLines = lines;
    } else {
      img.src = imagesArray[currentPage - 1]; // image.current;
      localLines = linesArray[currentPage - 1];
    }

    console.log(image.current);
    console.log(imagesArray[0]);

    toast.success("start to save image");

    const sacelFactor = 4;
    if (canvas && img) {
      // Create an offscreen canvas with the same resolution as the natural image
      const offCanvas = document.createElement("canvas");
      offCanvas.width = canvas.width * sacelFactor; // img.naturalWidth; // Use the high-res image width
      offCanvas.height = canvas.height * sacelFactor; // img.naturalHeight; // Use the high-res image height
      const offContext = offCanvas.getContext("2d");

      // Calculate aspect ratios for both the image and the canvas
      const imgAspectRatio = img.naturalWidth / img.naturalHeight;
      const canvasAspectRatio = canvas.width / canvas.height;

      // params
      let drawWidth, drawHeight, offsetX, offsetY;

      // Object-fit: cover logic: scale and crop the image correctly
      if (imgAspectRatio > canvasAspectRatio) {
        // Image is wider than canvas: scale by height and crop horizontally
        drawHeight = img.naturalHeight;
        drawWidth = drawHeight * canvasAspectRatio; // Calculate the width based on the canvas aspect ratio
        offsetX = (img.naturalWidth - drawWidth) / 2; // Center horizontally by cropping sides
        offsetY = 0; // No vertical offset
      } else {
        // Image is taller than canvas: scale by width and crop vertically
        drawWidth = img.naturalWidth;
        drawHeight = drawWidth / canvasAspectRatio; // Calculate the height based on the canvas aspect ratio
        offsetX = 0; // No horizontal offset
        offsetY = (img.naturalHeight - drawHeight) / 2; // Center vertically by cropping top/bottom
      }

      // Draw the image on the offscreen canvas, cropping excess parts
      offContext.drawImage(
        img,
        offsetX, // Crop and center horizontally
        offsetY, // Crop and center vertically
        drawWidth, // Scaled width of the image
        drawHeight, // Scaled height of the image
        0, // Draw at top-left corner of the offscreen canvas
        0,
        offCanvas.width, // Draw to the full size of the offscreen canvas
        offCanvas.height
      );

      // Calculate scaling factors to map the lines canvas to the high-res canvas
      const scaleFactorX = offCanvas.width / canvas.width;
      const scaleFactorY = offCanvas.height / canvas.height;

      // Draw the lines on the offscreen canvas, scaling the points to match the high-res canvas
      offContext.strokeStyle = "red"; // Set line color
      offContext.lineWidth = 10 * scaleFactorX; // Adjust line width for high resolution
      offContext.lineCap = "round";

      // draw lines
      localLines.forEach((line) => {
        offContext.beginPath();
        line.points.forEach((point, index) => {
          // Scale the line points to match the high-resolution image
          const scaledX = point.x * scaleFactorX;
          const scaledY = point.y * scaleFactorY;
          if (index === 0) {
            offContext.moveTo(scaledX, scaledY);
          } else {
            offContext.lineTo(scaledX, scaledY);
          }
        });
        offContext.stroke();
      });

      // Save the offscreen canvas as a high-resolution PNG
      const dataURL = offCanvas.toDataURL(
        "image/jpeg",
        0.8,
        drawWidth,
        drawHeight,
        undefined,
        "FAST"
      );

      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "high_res-image";
      link.click(); // Trigger the download
    }
  };

  // ------------------------------------------------------------------------------------------------

  //   const a3Width = 4960; // A3 landscape width in pixels at 300 DPI
  //   const a3Height = 3508; // A3 landscape height in pixels at 300 DPI

  /***
   * create pdf
   */
  const [isSavingPDF, setIsSavingPDF] = useState(false);
  const [pdfUploadProgress, setPDFUploadProgress] = useState(0);
  const [isCreatingPDF, setIsCreatingPDF] = useState(false);
  const [pagesCompleted, setPagesCompleted] = useState(0);

  const createStudioPDF = async (project, description = "", onCloud = true) => {
    // Initialize jsPDF instance with landscape orientation and A3 page size
    const pdf = new jsPDF("landscape", "mm", "a3");
    const pageWidth = 420;
    const pageHeight = 297;

    setIsSavingPDF(true);
    setIsCreatingPDF(true);
    setPagesCompleted("");
    setPDFUploadProgress(0);

    // go through the creation to see if its causing problems
    const imagesPayload = await saveCanvasArrays_fixed_upload_broken_lines();

    // console.log(imagesPayload);

    // Loop through all the image URLs and add them to the PDF
    imagesPayload.forEach(async (imageUrl, index) => {
      pdf.addImage(imageUrl, "JPEG", 0, 0, pageWidth, pageHeight);
      // toast.success("added image");
      if (index < imagesPayload.length - 1) {
        pdf.addPage();
        // toast.success("added pdf page");
      }
    });

    // toast.success("finished building new pdf document");

    // Convert PDF to Blob
    const pdfBlob = pdf.output("blob");
    // Get the size in bytes
    const sizeInBytes = pdfBlob.size;

    if (imagesPayload.length == imagesArray.length) {
      // finished creating pdf
      setIsCreatingPDF(false);
    }

    if (!onCloud) {
      if (deviceType == "desktop") {
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob); // Create a URL for the Blob
        link.download = "document.pdf"; // Set the desired file name with .pdf extension
        // Append the link to the body (iOS Safari needs this)
        document.body.appendChild(link);
        link.click(); // Trigger the download
        // Cleanup after a short delay to ensure the download completes
        setTimeout(() => {
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link); // Remove the link from the DOM
        }, 100);
      } else {
        const file = new File([pdfBlob], "document.pdf", {
          type: "application/pdf",
        });
        try {
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            await navigator.share({
              files: [file],
              title: "Save PDF",
              text: "Please save this PDF to your device.",
            });
          } else {
            alert("Sharing not supported on this browser.");
          }
        } catch (error) {
          console.error("Error sharing PDF:", error);
          toast.error(`error sharing pdf: ${error}`);
        }
      }
      // finished saving locally
      setIsSavingPDF(false);
    } else {
      // Upload PDF to Firebase Storage
      const fileName = `${currentDrawing.id}`;
      // set storage path
      const storagePath = `studio/pdfs/${fileName}.pdf`;

      await uploadImage(pdfBlob, storagePath, setPDFUploadProgress).then(
        async (downloadURL) => {
          // add drawing with url
          addStudioDrawingWithDownloadUrl(
            project,
            downloadURL,
            fileName,
            sizeInBytes,
            description
          );
        }
      );
    }
  };

  /***
   * add drawing with url
   */
  const addStudioDrawingWithDownloadUrl = async (
    project,
    downloadURL,
    fileName,
    sizeInBytes,
    description
  ) => {
    // fix the url to point to imageKit
    const url = downloadURL.replace(
      "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
      "ik.imagekit.io/habiatuq/hexpo"
    );

    const hexStudioProject = HexStudioProject.fromData(project);

    // drawing
    //currentStudent,
    let serial = resolveSerial(project);

    const d = {
      id: uuid(),
      studioId: activeUser.studioId,
      projectId: project.id,
      studentId: currentDrawing.studentId,
      sender: activeUser.isInstructor
        ? activeUser.id
        : currentDrawing.studentId,
      serial: serial,
      date: new Date(),
      type: "feedback",
      url: url,
      isRemoved: false,
    };
    // add drawing
    hexStudioProject.addDrawing(d);

    // set ration
    hexStudioProject.setDrawingRating(currentDrawing.id, rating);

    // commit
    hexStudioProject.commit().then((ref) => {
      setIsSavingPDF(false);
      setPDFUploadProgress(0);
      toast.success("drawing uploaded");
    });
  };

  // -----------------------------------------------------------------------
  // -----------------------------------------------------------------------

  const createPDF = async (project, description = "", onCloud = true) => {
    // Initialize jsPDF instance with landscape orientation and A3 page size
    const pdf = new jsPDF("landscape", "mm", "a3");
    const pageWidth = 420;
    const pageHeight = 297;

    setIsSavingPDF(true);
    setIsCreatingPDF(true);
    setPagesCompleted("");
    setPDFUploadProgress(0);

    // go through the creation to see if its causing problems
    const imagesPayload = await saveCanvasArrays_fixed_upload_broken_lines();

    // console.log(imagesPayload);

    // Loop through all the image URLs and add them to the PDF
    imagesPayload.forEach(async (imageUrl, index) => {
      pdf.addImage(imageUrl, "JPEG", 0, 0, pageWidth, pageHeight);
      // toast.success("added image");
      if (index < imagesPayload.length - 1) {
        pdf.addPage();
        // toast.success("added pdf page");
      }
    });

    // toast.success("finished building new pdf document");

    // Convert PDF to Blob
    const pdfBlob = pdf.output("blob");
    // Get the size in bytes
    const sizeInBytes = pdfBlob.size;

    if (imagesPayload.length == imagesArray.length) {
      // finished creating pdf
      setIsCreatingPDF(false);
    }

    if (!onCloud) {
      if (deviceType == "desktop") {
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob); // Create a URL for the Blob
        link.download = "document.pdf"; // Set the desired file name with .pdf extension
        // Append the link to the body (iOS Safari needs this)
        document.body.appendChild(link);
        link.click(); // Trigger the download
        // Cleanup after a short delay to ensure the download completes
        setTimeout(() => {
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link); // Remove the link from the DOM
        }, 100);
      } else {
        const file = new File([pdfBlob], "document.pdf", {
          type: "application/pdf",
        });
        try {
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            await navigator.share({
              files: [file],
              title: "Save PDF",
              text: "Please save this PDF to your device.",
            });
          } else {
            alert("Sharing not supported on this browser.");
          }
        } catch (error) {
          console.error("Error sharing PDF:", error);
          toast.error(`error sharing pdf: ${error}`);
        }
      }
      // finished saving locally
      setIsSavingPDF(false);
    } else {
      // Upload PDF to Firebase Storage
      const fileName = `feedback_${currentDrawing.name}`;
      // set storage path
      const storagePath = `pdfs/${fileName}.pdf`;

      // set storage ref
      // const storageRef = ref(storage, storagePath);
      // upload to storage
      // await uploadBytes(storageRef, pdfBlob);
      // Get the download URL of the uploaded PDF
      // const downloadURL = await getDownloadURL(storageRef);

      await uploadImage(pdfBlob, storagePath, setPDFUploadProgress).then(
        async (downloadURL) => {
          // add drawing with url
          addDrawingWithDownloadUrl(
            project,
            downloadURL,
            fileName,
            sizeInBytes,
            description
          );
        }
      );
    }
  };

  /***
   * fallback if share not available
   */
  // const downloadPDF = (file) => {
  //   const url = URL.createObjectURL(file);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "document.pdf";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };

  /***
   * add drawing with url
   */
  const addDrawingWithDownloadUrl = async (
    project,
    downloadURL,
    fileName,
    sizeInBytes,
    description
  ) => {
    // fix the url to point to imageKit
    const url = downloadURL.replace(
      "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
      "ik.imagekit.io/habiatuq/hexpo"
    );

    // 3. create new drawing object
    const drawing = new HexDrawing();
    drawing.projectId = project.id;
    drawing.url = url;
    drawing.name = fileName;
    drawing.size = sizeInBytes;
    drawing.drawingType = DrawingType.Feedback;
    drawing.description = description;
    drawing.entityId = activeUser.entityId;
    drawing.pushedBy = activeUser.id;

    // serial + stage
    const parentStage = currentDrawing.stage;
    drawing.serial = resolveFeedBackSerial(project, parentStage);
    drawing.stage = parentStage;

    // drawing data
    const drawingData = drawing.data();
    // add to payload
    const d = { drawings: [...(project.drawings || []), drawingData] };
    // // lets add to the projects urls
    const docRef = doc(db, "projects", project.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      setIsSavingPDF(false);
      setPDFUploadProgress(0);
      toast.success("added to project");
    });
  };

  /***
   * save canvas Arrays
   */
  const saveCanvasArrays_fixed = async () => {
    // 1. canvas + images
    const canvas = canvasRef.current;
    let imageUrls = imagesArray;

    // 2. A3 size
    const a3Width = 4960 * 0.8; // A3 landscape width in pixels at 300 DPI
    const a3Height = 3508 * 0.8; // A3 landscape height in pixels at 300 DPI

    // 3. promises
    const imagePromises = imageUrls.map((imageSrc, index) => {
      return new Promise(async (resolve, reject) => {
        try {
          // 4. new img + lines
          let img = new Image();
          let localLines = structuredClone(linesArray[index]); // Get corresponding lines for the image
          // 5. assign img src
          img.src = imageSrc
            ? imageSrc
            : await getImageForPage_tiling(pdfDocument, index + 1);

          // 6. when the img loads
          img.onload = () => {
            if (canvas && img) {
              // 7. Create offscreen canvas for the image
              const offCanvas = document.createElement("canvas");
              offCanvas.width = a3Width;
              offCanvas.height = a3Height;
              const offContext = offCanvas.getContext("2d");
              offContext.imageSmoothingEnabled = true; // Enable anti-aliasing
              offContext.imageSmoothingQuality = "high"; // Use high-quality smoothing

              // 7.1 Create offscreen canvas for the lines
              const offCanvasLines = document.createElement("canvas");
              offCanvasLines.width = a3Width;
              offCanvasLines.height = a3Height;
              const offContextLines = offCanvasLines.getContext("2d");
              offContextLines.imageSmoothingEnabled = true; // Enable anti-aliasing
              offContextLines.imageSmoothingQuality = "high"; // Use high-quality smoothing

              // 7.2 Create a combined canvas to merge image and lines
              const combinedCanvas = document.createElement("canvas");
              combinedCanvas.width = a3Width;
              combinedCanvas.height = a3Height;
              const combinedContext = combinedCanvas.getContext("2d");
              combinedContext.imageSmoothingEnabled = true; // Enable anti-aliasing
              combinedContext.imageSmoothingQuality = "high"; // Use high-quality smoothing

              // 8. Determine scaling factors for the image
              const imgAspectRatio = img.naturalWidth / img.naturalHeight;
              const canvasAspectRatio = canvas.width / canvas.height;

              // 9. declarations
              let drawWidth,
                drawHeight,
                offsetX = 0,
                offsetY = 0;
              let scaleFactorX, scaleFactorY;

              // 10 Object-fit: cover logic to cover the entire canvas
              if (imgAspectRatio > canvasAspectRatio) {
                // Image is wider than canvas, so crop horizontally
                drawHeight = canvas.height;
                drawWidth = drawHeight * imgAspectRatio;
                offsetX = (canvas.width - drawWidth) / 2; // Center horizontally
              } else {
                // Image is taller than canvas, so crop vertically
                drawWidth = canvas.width;
                drawHeight = drawWidth / imgAspectRatio;
                offsetY = (canvas.height - drawHeight) / 2; // Center vertically
              }

              // 11. Undo the object-fit cover effect on the points
              const undoScaleFactorX = img.naturalWidth / drawWidth;
              const undoScaleFactorY = img.naturalHeight / drawHeight;

              // 12. Adjust the points back to their "uncropped" state before scaling them to A3 size
              localLines?.forEach((line) => {
                line.points = line.points.map((point) => {
                  // Undo the object-fit cover scaling
                  const originalX = (point.x - offsetX) * undoScaleFactorX;
                  const originalY = (point.y - offsetY) * undoScaleFactorY;
                  // fix the points ... don't forget the rest of the fields
                  return { ...point, x: originalX, y: originalY };
                });
              });

              // 13. Draw the image on the A3 canvas
              const imgAspectRatioForA3 = img.naturalWidth / img.naturalHeight;
              const a3AspectRatio = a3Width / a3Height;

              // 14. calculate offsets
              if (imgAspectRatioForA3 > a3AspectRatio) {
                // Image is wider than A3, so adjust horizontally
                drawHeight = a3Height;
                drawWidth = drawHeight * imgAspectRatioForA3;
                offsetX = (a3Width - drawWidth) / 2;
                offsetY = 0; // No vertical offset needed
              } else {
                // Image is taller than A3, so adjust vertically
                drawWidth = a3Width;
                drawHeight = drawWidth / imgAspectRatioForA3;
                offsetY = (a3Height - drawHeight) / 2; // Adjust vertical offset
              }

              // 15. Draw the image on the image canvas
              offContext.drawImage(img, 0, 0, a3Width, a3Height);

              // lines -----------------------------------------------------

              // 16. Apply scaling for A3 dimensions after undoing object-fit cover
              scaleFactorX = drawWidth / img.naturalWidth;
              scaleFactorY = drawHeight / img.naturalHeight;

              // 17. Draw the lines on the lines canvas
              localLines?.forEach((line) => {
                if (line.isErasing) {
                  // Erasing logic
                  offContextLines.globalCompositeOperation = "destination-out";
                  offContextLines.beginPath();
                  line.points.forEach((point, index) => {
                    const scaledX = point.x * scaleFactorX + offsetX;
                    const scaledY = point.y * scaleFactorY + offsetY;

                    if (index === 0) {
                      offContextLines.moveTo(scaledX, scaledY);
                    } else {
                      offContextLines.lineTo(scaledX, scaledY);
                    }
                  });

                  // Set brush size for erasing
                  const bSize = line.points[0].brushSize * 2;
                  offContextLines.lineWidth = bSize;
                  offContextLines.lineCap = "round";
                  offContextLines.lineJoin = "round";

                  // Apply the erasing stroke
                  offContextLines.stroke();

                  // Reset globalCompositeOperation back to default after erasing
                  offContextLines.globalCompositeOperation = "source-over";
                } else {
                  // Drawing logic for non-erasing strokes
                  offContextLines.beginPath();
                  line.points.forEach((point, index) => {
                    const scaledX = point.x * scaleFactorX + offsetX;
                    const scaledY = point.y * scaleFactorY + offsetY;

                    if (index === 0) {
                      offContextLines.moveTo(scaledX, scaledY);
                    } else {
                      offContextLines.lineTo(scaledX, scaledY);
                    }
                  });

                  // Set brush size for drawing
                  const lineScalingConstant = 2;
                  offContextLines.lineWidth =
                    line.points[0].brushSize * lineScalingConstant;
                  offContextLines.lineCap = "round";
                  offContextLines.lineJoin = "round";
                  offContextLines.strokeStyle = `rgba(${line.sessionColor.r},${line.sessionColor.g},${line.sessionColor.b},1.0)`;
                  offContextLines.fillStyle = `rgba(${line.sessionColor.r},${line.sessionColor.g},${line.sessionColor.b},0.65)`;

                  // Apply the stroke or fill
                  if (line.isPainting) {
                    offContextLines.closePath();
                    offContextLines.fill();
                  } else {
                    offContextLines.stroke();
                  }
                }
              });

              // 18. Combine the image and lines canvases onto the combined canvas
              combinedContext.drawImage(offCanvas, 0, 0);
              combinedContext.drawImage(offCanvasLines, 0, 0);

              // 19. Save the combined canvas as a high-resolution image
              const dataURL = combinedCanvas.toDataURL("image/jpeg", 0.8);
              resolve(dataURL); // Resolve the promise with the data URL
            }
          };

          img.onerror = (err) => {
            console.error(`Error loading image at index ${index}:`, err);
            toast.error(`Error loading image at index ${index}: ${err}`, {
              autoClose: false,
            });
            reject(new Error(`Failed to load image at index ${index}`));
          };
        } catch (promiseError) {
          console.error(`Error in promise at index ${index}:`, promiseError);
          toast.error(`Error in promise at index ${index}:`, {
            autoClose: false,
          });
          reject(promiseError); // Reject the promise if any unexpected error occurs
        }
      });
    });

    try {
      imageUrls = await Promise.all(imagePromises);
      return imageUrls;
    } catch (error) {
      console.error("Error generating image URLs:", error);
      toast.error(`Error in promise all: ${error}`);
      throw new Error("Failed to generate image URLs.");
    }
  };

  /***
   * save canvas array
   */
  const saveCanvasArrays_fixed_upload_broken_lines = async () => {
    // 1. canvas + images
    const canvas = canvasRef.current;
    let imageUrls = imagesArray;

    // 2. A3 size
    const a3Width = 4960 * 0.8; // A3 landscape width in pixels at 300 DPI
    const a3Height = 3508 * 0.8; // A3 landscape height in pixels at 300 DPI

    // 3. promises
    const imagePromises = imageUrls.map((imageSrc, index) => {
      return new Promise(async (resolve, reject) => {
        let img = new Image();
        let localLines = structuredClone(linesArray[index]);

        // Assign the image source or load a fallback if needed
        img.src =
          imageSrc || (await getImageForPage_tiling(pdfDocument, index + 1));

        img.onload = () => {
          try {
            // 7. Create offscreen canvas for the image
            const offCanvas = document.createElement("canvas");
            offCanvas.width = a3Width;
            offCanvas.height = a3Height;
            const offContext = offCanvas.getContext("2d");

            // 7.1 Create offscreen canvas for the lines
            const offCanvasLines = document.createElement("canvas");
            offCanvasLines.width = a3Width;
            offCanvasLines.height = a3Height;
            const offContextLines = offCanvasLines.getContext("2d");

            // 7.2 Create a combined canvas to merge image and lines
            const combinedCanvas = document.createElement("canvas");
            combinedCanvas.width = a3Width;
            combinedCanvas.height = a3Height;
            const combinedContext = combinedCanvas.getContext("2d");

            // 8. Determine scaling factors for the image
            const imgAspectRatio = img.naturalWidth / img.naturalHeight;
            const canvasAspectRatio = canvas.width / canvas.height;

            // 9. declarations
            let drawWidth,
              drawHeight,
              offsetX = 0,
              offsetY = 0;
            let scaleFactorX, scaleFactorY;

            // 10 Object-fit: cover logic to cover the entire canvas
            if (imgAspectRatio > canvasAspectRatio) {
              // Image is wider than canvas, so crop horizontally
              drawHeight = canvas.height;
              drawWidth = drawHeight * imgAspectRatio;
              offsetX = (canvas.width - drawWidth) / 2; // Center horizontally
            } else {
              // Image is taller than canvas, so crop vertically
              drawWidth = canvas.width;
              drawHeight = drawWidth / imgAspectRatio;
              offsetY = (canvas.height - drawHeight) / 2; // Center vertically
            }

            // 11. Undo the object-fit cover effect on the points
            const undoScaleFactorX = img.naturalWidth / drawWidth;
            const undoScaleFactorY = img.naturalHeight / drawHeight;

            // 12. Adjust the points back to their "uncropped" state before scaling them to A3 size
            localLines?.forEach((line) => {
              line.points = line.points.map((point) => {
                // Undo the object-fit cover scaling
                const originalX = (point.x - offsetX) * undoScaleFactorX;
                const originalY = (point.y - offsetY) * undoScaleFactorY;
                // fix the points ... don't forget the rest of the fields
                return { ...point, x: originalX, y: originalY };
              });
            });

            // 13. Draw the image on the A3 canvas
            const imgAspectRatioForA3 = img.naturalWidth / img.naturalHeight;
            const a3AspectRatio = a3Width / a3Height;

            // 14. calculate offsets
            if (imgAspectRatioForA3 > a3AspectRatio) {
              // Image is wider than A3, so adjust horizontally
              drawHeight = a3Height;
              drawWidth = drawHeight * imgAspectRatioForA3;
              offsetX = (a3Width - drawWidth) / 2;
              offsetY = 0; // No vertical offset needed
            } else {
              // Image is taller than A3, so adjust vertically
              drawWidth = a3Width;
              drawHeight = drawWidth / imgAspectRatioForA3;
              offsetY = (a3Height - drawHeight) / 2; // Adjust vertical offset
            }

            // 15. Draw the image on the image canvas
            offContext.drawImage(img, 0, 0, a3Width, a3Height);

            // 15. Draw the image on the image canvas
            offContext.drawImage(img, 0, 0, a3Width, a3Height);

            // lines -----------------------------------------------------

            // 16. Apply scaling for A3 dimensions after undoing object-fit cover
            scaleFactorX = drawWidth / img.naturalWidth;
            scaleFactorY = drawHeight / img.naturalHeight;

            // 17. Draw the lines on the lines canvas
            localLines?.forEach((line) => {
              if (line.isErasing) {
                // Erasing logic
                offContextLines.globalCompositeOperation = "destination-out";
                offContextLines.beginPath();
                line.points.forEach((point, index) => {
                  const scaledX = point.x * scaleFactorX + offsetX;
                  const scaledY = point.y * scaleFactorY + offsetY;

                  if (index === 0) {
                    offContextLines.moveTo(scaledX, scaledY);
                  } else {
                    offContextLines.lineTo(scaledX, scaledY);
                  }
                });

                // Set brush size for erasing
                const bSize = line.points[0].brushSize * 2;
                offContextLines.lineWidth = bSize;
                offContextLines.lineCap = "round";
                offContextLines.lineJoin = "round";

                // Apply the erasing stroke
                offContextLines.stroke();

                // Reset globalCompositeOperation back to default after erasing
                offContextLines.globalCompositeOperation = "source-over";
              } else {
                // Drawing logic for non-erasing strokes
                offContextLines.beginPath();
                line.points.forEach((point, index) => {
                  const scaledX = point.x * scaleFactorX + offsetX;
                  const scaledY = point.y * scaleFactorY + offsetY;

                  if (index === 0) {
                    offContextLines.moveTo(scaledX, scaledY);
                  } else {
                    offContextLines.lineTo(scaledX, scaledY);
                  }
                });

                // color
                const { r, g, b } = line.sessionColor ?? {
                  r: 0,
                  g: 0,
                  b: 0,
                };
                const opacity = line.opacityVal ? line.opacityVal / 100 : 1;
                const color = `rgba(${r},${g},${b},${opacity})`;

                // Set brush size for drawing
                const lineScalingConstant = 2;
                offContextLines.lineWidth =
                  line.brushSize * lineScalingConstant;
                // line cap + join
                offContextLines.lineCap = "round";
                offContextLines.lineJoin = "round";
                offContextLines.strokeStyle = color; // `rgba(${line.sessionColor.r},${line.sessionColor.g},${line.sessionColor.b},1.0)`;
                offContextLines.fillStyle = color; // `rgba(${line.sessionColor.r},${line.sessionColor.g},${line.sessionColor.b},0.65)`;

                // Apply the stroke or fill
                if (line.isPainting) {
                  offContextLines.closePath();
                  offContextLines.fill();
                } else {
                  offContextLines.stroke();
                }
              }
            });

            // 18. Combine the image and lines canvases onto the combined canvas
            combinedContext.drawImage(offCanvas, 0, 0);
            combinedContext.drawImage(offCanvasLines, 0, 0);

            // 19. Save the combined canvas as a high-resolution image
            const dataURL = combinedCanvas.toDataURL("image/jpeg", 0.8);
            resolve(dataURL); // Resolve the promise with the data URL
          } catch (err) {
            console.error(
              `Error processing canvas for image at index ${index}:`,
              err
            );
            toast.error(
              `Error processing canvas for image at index ${index}:${err}`,
              { autoClose: false }
            );
            resolve(null); // Resolve with null on error to continue processing
          }
        };

        img.onerror = (err) => {
          console.error(`Error loading image at index ${index}:`, err);
          toast.error(`Error loading image at index ${index}:${err}`, {
            autoClose: false,
          });
          resolve(null); // Resolve with null to avoid blocking the process
        };
      });
    });

    try {
      imageUrls = await Promise.all(imagePromises);
      return imageUrls.filter((url) => url !== null); // Filter out failed images
    } catch (error) {
      console.error("Error generating images:", error);
      toast.error(`Error generating images: ${error}`, { autoClose: false });
      throw new Error("Image generation failed.");
    }
  };

  // -------------------------------------------------------------------------------------------

  /***
   * get image with tiling
   */
  const getImageForPage_tiling = async (pdf, pageNum) => {
    setLoading(true);
    try {
      const page = await pdf.getPage(pageNum);

      // Use a higher scale since we are rendering in smaller tiles
      const viewport = page.getViewport({ scale: viewportScale }); // Higher scale for better clarity

      // Tile configuration
      const tileCount = 1; // Split into a 2x2 grid for smaller tiles
      const tileWidth = viewport.width / tileCount;
      const tileHeight = viewport.height / tileCount;

      // Create a canvas for stitching the tiles together
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Set the full canvas size
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      for (let row = 0; row < tileCount; row++) {
        for (let col = 0; col < tileCount; col++) {
          // Calculate the viewport transformation for the current tile
          const xOffset = col * tileWidth;
          const yOffset = row * tileHeight;
          const tileViewport = page.getViewport({
            scale: viewportScale,
            offsetX: -xOffset, // Move the viewport to the current tile
            offsetY: -yOffset,
          });

          // Create a tile canvas
          const tileCanvas = document.createElement("canvas");
          tileCanvas.width = tileWidth;
          tileCanvas.height = tileHeight;

          const tileContext = tileCanvas.getContext("2d");

          // Render the visible part of the page into the tile canvas
          await page.render({
            canvasContext: tileContext,
            viewport: tileViewport,
          }).promise;

          // Draw the tile onto the main canvas at the correct position
          context.drawImage(tileCanvas, xOffset, yOffset);
        }
      }

      // Convert the stitched canvas to an image data URL
      const img = canvas.toDataURL("image/png");
      setLoading(false);

      return img;
    } catch (e) {
      console.error("Error rendering page:", e.message);
      setLoading(false);
    }
  };

  const reloadImagesArray = async () => {
    setCurrentPage(1);
    const tempImagesArray = Array(pdfDocument.numPages).fill(null);
    const img = await getImageForPage_tiling(pdfDocument, 1);
    tempImagesArray[0] = img;
    // Once all pages are processed, update state with the images array
    setImagesArray(tempImagesArray);
  };

  const flushPdfDocument = () => {
    setPdfDocument(null);
    setImagesArray([]);
  };

  const resetScale = () => {
    setScale(1);
  };

  /***
   * low res
   */
  const setLowRes = () => {
    // reduce resolution
    setIsHighRes(false);
    setViewportScale(4);
  };

  /***
   *high res
   */
  const setHighRes = () => {
    // increase resolution
    setIsHighRes(true);
    setViewportScale(8);
  };

  const activateViewMode = () => {
    setViewModeIsActive(true);
    setShowTools(false);
  };

  const goToHexCanvas = (drawingId, projectId) => {
    // navigate(`/canvas/${drawingId}/${projectId}/${0}`);
  };

  return (
    <CanvasContext.Provider
      value={{
        activateViewMode,
        setLowRes,
        setHighRes,
        resetScale,
        showNav,
        getImageForPage_tiling,
        setShowNav,
        loading,
        setLoading,
        loadingDocument,
        setLoadingDocument,
        // getImageForPage,
        createPDF,
        image,
        handleDLock,
        canvasSize,
        setCanvasSize,
        canvasScaleFactor,
        baseLineWidth,
        redoStack,
        setRedoStack,
        lines,
        setLines,
        renderTaskRef,
        pdfCanvasRef,
        pdf,
        setPdf,
        currentPage,
        renderPage,
        setCurrentPage,
        drawImageCanvas,
        drawImageWithObjectFitCover,
        loadPdfDocument,
        numPages,
        setNumPages,
        activePdf,
        setActivePdf,
        loadPdf,
        scale,
        setScale,
        lastScale,
        setLastScale,
        cancelRenderTask,
        activeTool,
        setActiveTool,
        isPinching,
        setIsPinching,
        startDistance,
        setStartDistance,
        brushSize,
        setBrushSize,
        touches,
        setTouches,
        lastTapTime,
        setLastTapTime,
        isDragging,
        setIsDragging,
        isErasing,
        setIsErasing,
        isPainting,
        setIsPainting,
        canvasRef,
        imageCanvasRef,
        mouseOffset,
        setMouseOffset,
        canvasPosition,
        setCanvasPosition,
        canvasOffset,
        setCanvasOffset,
        lastCanvasOffset,
        setLastCanvasOffset,
        lastPos,
        setLastPos,
        dragStartPos,
        setDragStartPos,
        dragStartOffset,
        setDragStartOffset,
        selected,
        setSelected,
        scaleFactor,
        handleBrush,
        handleToolClick,
        dLock,
        setDLock,
        openColorPicker,
        setOpenColorPicker,
        showTools,
        setShowTools,
        restCanvasPosition,
        handleZoomExtent,
        sessionColor,
        setSessionColor,
        undoLastAction,
        redoLastAction,
        clearCanvas,
        handleColorPicker,
        setImageLoaded,
        isDrawing,
        setIsDrawing,
        setPointerPosition,
        saveCanvas,
        imagesArray,
        setImagesArray,
        linesArray,
        setLinesArray,
        pdfDocument,
        setPdfDocument,
        dImageHeight,
        setDImageHeight,
        dA3Height,
        setDA3Height,
        setDOffsetY,
        dOffsetY,
        viewportScale,
        setViewportScale,
        isHighRes,
        setIsHighRes,
        reloadImagesArray,
        viewModeIsActive,
        setViewModeIsActive,
        opacity,
        setOpacity,
        closeColorPicker,
        lineRef,
        showQuickBrush,
        setShowQuickBrush,
        activeQuickBrush,
        setActiveQuickBrush,
        showCanvasPages,
        setShowCanvasPages,
        flushPdfDocument,
        audioRef,
        audioClickRef,
        clearLines,
        resetCanvasOffset,
        currentDrawing,
        setCurrentDrawing,
        isCreatingPDF,
        setIsCreatingPDF,
        setPDFUploadProgress,
        pdfUploadProgress,
        isSavingPDF,
        setIsSavingPDF,
        pagesCompleted,
        setPagesCompleted,
        resetCanavs,
        createStudioPDF,
        goToHexCanvas,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
