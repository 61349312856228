// collection menu

import { doc, setDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCanvasContext } from "../../../hooks/useCanvasContext";
import { useCollection } from "../../../hooks/useCollection";
import { useUploadDocument } from "../../../hooks/useUploadDocument";
import { DrawingType } from "../../../models/Enums";
import HexDrawing from "../../../models/HexDrawing";
import DrawingRevisionStack from "./DrawingRevisionStack";
import CircularProgressWithLabel from "../../../components/progress/CircularProgressWithLabel";
import { stageForStack, resolveStage } from "../../../models/Enums";
import RevisionSheetDashPanel from "./RevisionSheetDashPanel";
import StackSegmentedButton from "./StackSegmentedButton";

// icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  resolveSubmissionSerial,
  resolveFeedBackSerial,
} from "../../../utilities/SerialManager";

export default function DrawingRevisionSheet({
  project,
  openMenu,
  stackDismiss,
}) {
  const { activeUser } = useAuthContext();
  // const { documents: users } = useCollection("users");
  const inputfileRef = useRef();
  const { uploadImage } = useUploadDocument();
  const [currentStack, setCurrentStack] = useState("studio");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState({
    name: "",
    file: null,
  });
  const {
    isHighRes,
    // setIsHighRes,
    // viewportScale,
    // setViewportScale,
    setHighRes,
    setLowRes,
  } = useCanvasContext();

  const resoveStackDismiss = () => {
    stackDismiss(true);
  };

  /***
   * resolve add drawing
   */
  const resolveAddDrawing = () => {
    toast.success("add drawings");
  };

  // ------------------------------------------------

  /***
   * handle add click
   */
  const handleAddSubmissionClick = () => {
    if (validateAddSubmission()) {
      inputfileRef.current.click();
    }
  };

  /***
   * handle upload pdf
   */
  const handleUploadSubmissionPdf = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name || "hamadah drawings";
    setData({ ...data, file: file });
    if (!project) return;

    // Get the file size in bytes
    const sizeInBytes = file.size;

    // loading spinner
    setUploading(true);
    setProgress(0);

    // 1. I must look into uploadImage and see how I might get the progress
    // 2. look at the file:HexRevisionRow.jsx where I have successfully reported progrress
    // use this component to show progress
    // <CircularProgressWithLabel value={progress} />;

    // 2. upload pdf
    await uploadImage(file, "pdf", setProgress).then(async (pdfUrl) => {
      const url = pdfUrl.replace(
        "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
        "ik.imagekit.io/habiatuq/hexpo"
      );

      // 3. create new drawing object
      const drawing = new HexDrawing();
      drawing.projectId = project.id;
      drawing.url = url;
      drawing.name = file.name;
      drawing.entityId = activeUser.entityId;
      drawing.pushedBy = activeUser.id;
      drawing.drawingType = DrawingType.Submission;
      drawing.size = sizeInBytes;

      // drawing serial + stage
      drawing.serial = resolveSubmissionSerial(project, currentStack);
      drawing.stage = resolveStage(currentStack);

      // drawing data
      const drawingData = drawing.data();
      // add to payload
      const d = { drawings: [...(project.drawings || []), drawingData] };

      // // lets add to the projects urls
      const docRef = doc(db, "projects", project.id);
      await setDoc(docRef, d, { merge: true }).then((ref) => {
        e.target.value = null;
        setUploading(false);
        toast.success("added to project");
      });
    });
  };

  // --------------------------------------------------------

  /***
   * Handle Add Submission
   */
  const handleAddSubmission_temp = async () => {
    if (!validateAddSubmission()) {
      return;
    }

    setUploading(true);
    setProgress(1);
    // add to firestore directly
    // 3. create new drawing object
    const drawing = new HexDrawing();
    drawing.projectId = project.id;
    drawing.url = "";
    drawing.name = "new file";
    drawing.entityId = activeUser.entityId;
    drawing.pushedBy = activeUser.id;
    drawing.drawingType = DrawingType.Submission;
    drawing.size = "";

    // resolve serial
    drawing.serial = resolveSubmissionSerial(project, currentStack);
    drawing.stage = resolveStage(currentStack);

    // drawing data
    const drawingData = drawing.data();
    // add to payload
    const d = { drawings: [...(project.drawings || []), drawingData] };

    // // lets add to the projects urls
    const docRef = doc(db, "projects", project.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      setUploading(false);
      setProgress(0);
    });
  };

  /***
   * validate add submission
   */
  const validateAddSubmission = () => {
    if (currentStack == "client") {
      toast.error("you must submit on the studio or principle stacks only");
      return false;
    }
    if (project.drawings.length == 0 && currentStack == "principle") {
      toast.error("the first submission must be made on the studio stack");
      return false;
    }
    return true;
  };

  /***
   * handle Add Feedback
   */
  const handleAddFeedback_temp = async () => {
    if (currentStack == "client") {
      toast.error("you must submit on the studio or principle stacks only");
      return;
    }
    if (project.drawings.length == 0 && currentStack == "principle") {
      toast.error("the first submission must be made on the studio stack");
      return;
    }

    setUploading(true);
    setProgress(1);
    // add to firestore directly
    // 3. create new drawing object
    const drawing = new HexDrawing();
    drawing.projectId = project.id;
    drawing.url = "";
    drawing.name = "new file";
    drawing.entityId = activeUser.entityId;
    drawing.pushedBy = activeUser.id;
    drawing.drawingType = DrawingType.Feedback;
    drawing.size = "";

    // resolve serial
    drawing.serial = resolveFeedBackSerial(project, currentStack);
    drawing.stage = resolveStage(currentStack);

    // drawing data
    const drawingData = drawing.data();
    // add to payload
    const d = { drawings: [...(project.drawings || []), drawingData] };

    // // lets add to the projects urls
    const docRef = doc(db, "projects", project.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      setUploading(false);
      setProgress(0);
    });
  };

  /***
   * handle highres
   */
  const highRes = 8;
  const lowRes = 4;
  const handleHighRes = () => {
    const check = isHighRes;
    if (check) {
      setLowRes();
    } else {
      // increase resolution
      setHighRes();
    }
  };

  /***
   * resolve sheet color
   */
  const resolveSheetColor = () => {
    return isHighRes ? "bg-orange-900" : "bg-orange-600";
  };

  return (
    <div>
      <div
        style={{ height: openMenu ? "100%" : "0%", width: "100%" }}
        className={`flex flex-col overflow-hidden duration-300 items-center absolute bottom-0 right-0 ${resolveSheetColor()} h-full z-10`}
      >
        <div className="cfursor-pointer mt-10 h-[40px]">
          <div className="flex items-center gap-2">
            {/* title */}
            <h1
              className={`cursor-default ${
                isHighRes ? "text-white" : "text-black"
              }`}
            >
              {project.name}
            </h1>
            {/* add buttons */}
            <div
              onClick={handleAddSubmissionClick}
              className="ml-auto mr-2 cursor-pointer"
            >
              <div className="ml-2 pointer-events-none">
                <input
                  ref={inputfileRef}
                  type="file"
                  accept="application/pdf" // This limits the file type to PDF
                  id="file"
                  onChange={handleUploadSubmissionPdf}
                  className="hidden"
                />
                <div
                  className={`flex gap-2 ${
                    isHighRes ? "text-white" : "text-black"
                  }  h-full cursor-pointer`}
                >
                  <div className="flex items-center">
                    <AddCircleOutlineIcon fontSize="large" />
                    <div className="font-thin text-xs ml-1 opacity-50">
                      drawings
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              onClick={handleAddFeedback_temp}
              className="font-bold cursor-pointer text-2xl"
            >
              [feedback]
            </div> */}
          </div>
        </div>

        {/* close */}
        <div
          onClick={resoveStackDismiss}
          className={`absolute top-[30px] right-[50px] cursor-pointer ${
            isHighRes
              ? "text-white/40 hover:text-white"
              : "text-black/40 hover:text-black"
          } `}
        >
          <h1>close</h1>
        </div>
        <div className="flex justify-center relative mt-6 w-full">
          {/* dash panel */}
          <RevisionSheetDashPanel
            project={project}
            handleHighRes={handleHighRes}
            isHighRes={isHighRes}
          />
          {/* drwing stack */}
          <div className="w-[700px]">
            {/* stack filter */}
            <StackSegmentedButton
              currentStack={currentStack}
              setCurrentStack={setCurrentStack}
            />
            {/* stack list */}
            <DrawingRevisionStack
              currentStack={currentStack}
              project={project}
            />
          </div>
        </div>
        {/* Progress spinner */}
        {uploading && (
          <div className="flex items-center gap-1 absolute top-1/2 left-1/2 bg-black/75 p-10 rounded-lg text-white transform -translate-x-1/2 -translate-y-1/2">
            uploading drawing
            <CircularProgressWithLabel value={progress} />
          </div>
        )}
      </div>
    </div>
  );
}
