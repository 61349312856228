import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { PreviewTwoTone } from "@mui/icons-material";

export default function HexCrumb({
  size,
  titles,
  resolveCurrentIndex,
  sentinals = [],
  currentIndex = 0,
}) {
  const [needsAnimate, setNeedsAnimate] = useState(false);
  const [list, setList] = useState([]);

  //
  useEffect(() => {
    const r = [];
    for (var i = 0; i < size; i++) {
      r.push(i);
    }
    setList(r);
    setCrumbTitles(titles);

    if (titles) {
      if (titles.length > 0) {
        setCurrentTitle(titles[0]?.title);
      }
    }
  }, [size, titles]);

  const [sent, setSent] = useState([]);
  const [crumbTitles, setCrumbTitles] = useState([]);
  const needSep = (index) => {
    return sentinals.includes(index);
  };

  const titleForIndex = (ind) => {
    let projTitle = "project name";
    let c;
    for (var i = 0; i < crumbTitles?.length; i++) {
      let c = crumbTitles[i];
      if (c.brackets(ind)) {
        return c.title;
      }
    }
    return "";
  };

  const handleAnimationComplete = () => {
    setNeedsAnimate(false);
  };

  const [currentTitle, setCurrentTitle] = useState("");
  const [prevIitle, setPrevTitle] = useState("");

  const resolveCurrentIndexProxy = (index) => {
    setPrevTitle(currentTitle);
    const _title = titleForIndex(index);
    setCurrentTitle(_title);
    resolveCurrentIndex(index);
  };

  const didChangeProj = (index) => {
    if (currentTitle == prevIitle) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {sent.length < 1 && (
        <motion.div
          key={currentIndex}
          initial={{ x: didChangeProj(currentIndex) ? 50 : 0, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "-100%", opacity: 0 }}
          transition={{ duration: 0.5 }}
          // style={{ position: "absolute" }}
          className="font-bold text-white ml-2 text-xl"
        >
          <div>{titleForIndex(currentIndex)}</div>
        </motion.div>
      )}
      <div className="flex items-center justify-start gap-2 bg-black/60 p-2 rounded-md">
        {list.map((i, index) => {
          return (
            <div key={index} className="flex items-center justify-center gap-2">
              {needSep(index) && (
                <div className="size-3 bg-white/50 rounded-full"></div>
              )}
              <div onClick={() => resolveCurrentIndexProxy(index)}>
                <Tile active={currentIndex == i ? true : false} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const Tile = ({ active = false }) => {
  if (active) {
    return <div className="opacity-50 bg-white size-[24px] rounded-sm"></div>;
  }
  return (
    <div className="opacity-50 border border-white size-[24px] rounded-sm"></div>
  );
};
