import React, { useRef, useState, useEffect } from "react";

const LassoCanvas = () => {
  const canvasRef = useRef(null);
  const [isCutting, setIsCutting] = useState(false); // Cutting mode toggle
  const [knifePath, setKnifePath] = useState([]); // Path for lasso (knife)
  const [shapes, setShapes] = useState([]); // List of shapes
  const [undoStack, setUndoStack] = useState([]); // Undo stack
  const [redoStack, setRedoStack] = useState([]); // Redo stack
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(null); // Index of selected shape for dragging
  const [isDragging, setIsDragging] = useState(false); // Dragging state toggle
  const [dragStartPos, setDragStartPos] = useState(null); // Starting position for dragging

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    drawShapes(ctx);
  }, [shapes, knifePath]);

  const saveState = () => {
    setUndoStack([...undoStack, JSON.parse(JSON.stringify(shapes))]);
    setRedoStack([]); // Clear the redo stack on new action
  };

  const getLineIntersection = (line1, line2) => {
    const [x1, y1, x2, y2] = line1;
    const [x3, y3, x4, y4] = line2;

    const denom = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
    if (denom === 0) return null; // Parallel lines

    const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denom;
    const u = ((x1 - x3) * (y1 - y2) - (y1 - y3) * (x1 - x2)) / denom;

    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
      return [x1 + t * (x2 - x1), y1 + t * (y2 - y1)];
    }
    return null;
  };

  const drawShapes = (ctx) => {
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    shapes.forEach((shape, index) => {
      if (shape.type === "stroke") {
        ctx.beginPath();
        ctx.moveTo(shape.path[0][0], shape.path[0][1]);
        shape.path.forEach((point) => ctx.lineTo(point[0], point[1]));
        ctx.strokeStyle = shape.color;
        ctx.lineWidth = shape.width;
        ctx.stroke();
      } else if (shape.type === "fill") {
        ctx.beginPath();
        ctx.moveTo(shape.path[0][0], shape.path[0][1]);
        shape.path.forEach((point) => ctx.lineTo(point[0], point[1]));
        ctx.closePath();
        ctx.fillStyle = shape.color;
        ctx.fill();
      }

      // Highlight selected shape
      if (index === selectedShapeIndex) {
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 2;
        ctx.strokeRect(shape.path[0][0] - 5, shape.path[0][1] - 5, 10, 10); // Simple indicator for selection
      }
    });

    // Draw knife path (lasso) in cutting mode
    if (isCutting && knifePath.length > 1) {
      ctx.beginPath();
      ctx.moveTo(knifePath[0][0], knifePath[0][1]);
      knifePath.forEach((point) => ctx.lineTo(point[0], point[1]));
      ctx.strokeStyle = "blue";
      ctx.lineWidth = 1;
      ctx.stroke();
    }
  };

  // Handle mouse down event for both cutting and dragging modes
  const handleMouseDown = (e) => {
    const mousePos = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };

    if (isCutting) {
      // Start a new knife path
      setKnifePath([[mousePos.x, mousePos.y]]);
    } else {
      // Try to select and drag a shape
      handleShapeClick(e);
    }
  };

  // Handle mouse move for dragging shapes or extending knife path
  const handleMouseMove = (e) => {
    const mousePos = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };

    if (isCutting && knifePath.length > 0) {
      // Extend knife path while in cutting mode
      setKnifePath([...knifePath, [mousePos.x, mousePos.y]]);
    } else if (isDragging && selectedShapeIndex !== null) {
      // Calculate how far the mouse has moved
      const dx = mousePos.x - dragStartPos.x;
      const dy = mousePos.y - dragStartPos.y;

      // Move the selected shape by applying the difference to each point in the path
      const updatedShapes = shapes.map((shape, index) => {
        if (index === selectedShapeIndex) {
          return {
            ...shape,
            path: shape.path.map((point) => [point[0] + dx, point[1] + dy]),
          };
        }
        return shape;
      });

      setShapes(updatedShapes);
      setDragStartPos(mousePos); // Update the starting position to the current mouse position
    }
  };

  // Handle mouse up event for both cutting and dragging modes
  const handleMouseUp = () => {
    if (isCutting) {
      // Perform the cut when mouse is released
      cutShapes();
      setKnifePath([]); // Reset the knife path
    } else if (isDragging) {
      setIsDragging(false);
      saveState(); // Save the new position in undo stack
    }
  };

  // Detect if a shape is clicked for selection and dragging
  const handleShapeClick = (e) => {
    const mousePos = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
    let clickedShapeIndex = null;

    // Check if the mouse position intersects with any shape
    shapes.forEach((shape, index) => {
      shape.path.forEach((point) => {
        const dx = mousePos.x - point[0];
        const dy = mousePos.y - point[1];
        if (Math.sqrt(dx * dx + dy * dy) < 5) {
          clickedShapeIndex = index;
        }
      });
    });

    if (clickedShapeIndex !== null) {
      setSelectedShapeIndex(clickedShapeIndex);
      setIsDragging(true);
      setDragStartPos(mousePos); // Set the initial drag position
    } else {
      setSelectedShapeIndex(null);
    }
  };

  const cutShapes = () => {
    saveState(); // Save the current shapes for undo

    const newShapes = [];

    shapes.forEach((shape) => {
      let cutOccurred = false;

      if (shape.type === "stroke") {
        let currentSegment = [shape.path[0]];
        let newSegments = [];
        let intersections = [];

        // Check for intersections between each stroke segment and the knife path
        for (let i = 1; i < shape.path.length; i++) {
          let segment = [shape.path[i - 1], shape.path[i]];

          for (let j = 1; j < knifePath.length; j++) {
            let knifeSegment = [knifePath[j - 1], knifePath[j]];
            const intersection = getLineIntersection(segment, knifeSegment);

            if (intersection) {
              // If intersection occurs, split the stroke at this point
              cutOccurred = true;
              intersections.push(intersection);

              // Add the intersection point and complete the current segment
              currentSegment.push(intersection);
              newSegments.push(currentSegment);

              // Start a new segment from the intersection point
              currentSegment = [intersection, shape.path[i]];
            }
          }
          currentSegment.push(shape.path[i]);
        }

        if (cutOccurred) {
          newSegments.push(currentSegment);

          // Each new segment becomes a new stroke shape
          newSegments.forEach((seg) => {
            newShapes.push({ ...shape, path: seg });
          });
        } else {
          // If no cutting happened, retain the original shape
          newShapes.push(shape);
        }
      } else if (shape.type === "fill") {
        // For fills, similar logic can be applied for cutting polygons
        newShapes.push(shape);
      }
    });

    setShapes(newShapes); // Set the new shapes array
  };

  const undo = () => {
    if (undoStack.length > 0) {
      const prevState = undoStack.pop();
      setRedoStack([...redoStack, shapes]);
      setShapes(prevState);
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack.pop();
      setUndoStack([...undoStack, shapes]);
      setShapes(nextState);
    }
  };

  // Toggle between cutting and moving mode
  const toggleCuttingMode = () => {
    setIsCutting(!isCutting);
    setSelectedShapeIndex(null); // Deselect shape when switching to cutting mode
  };

  // Initial shapes example
  useEffect(() => {
    setShapes([
      {
        type: "stroke",
        path: [
          [50, 50],
          [200, 200],
        ],
        color: "black",
        width: 2,
      },
      {
        type: "stroke",
        path: [
          [300, 100],
          [400, 400],
        ],
        color: "red",
        width: 4,
      },
      {
        type: "fill",
        path: [
          [500, 150],
          [550, 250],
          [450, 250],
        ],
        color: "green",
      },
    ]);
  }, []);

  return (
    <div>
      <h1>canvas</h1>
      <canvas
        ref={canvasRef}
        width={800}
        height={600}
        style={{ border: "1px solid black" }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      ></canvas>
      <div>
        <button onClick={toggleCuttingMode}>
          {isCutting ? "Switch to Move Mode" : "Switch to Cutting Mode"}
        </button>
        <button onClick={undo}>Undo</button>
        <button onClick={redo}>Redo</button>
      </div>
    </div>
  );
};

export default LassoCanvas;
