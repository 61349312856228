import React, { useRef, useEffect, useState } from "react";
import ImageA from "../../../assets/black/image_a.jpg";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const CanvasWithImage = () => {
  const { width, height } = useWindowDimensions();

  // canvas, image ref
  const canvasRef = useRef(null);
  const image = useRef(new Image()); // Use a persistent ref to store the image

  // canvas, image size
  const [canvasSize, setCanvasSize] = useState({
    width: width,
    height: height,
  });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  // drawing state
  const [imageLoaded, setImageLoaded] = useState(false); // Keep track of image loading state
  const [isDrawing, setIsDrawing] = useState(false);

  // lines
  const [lines, setLines] = useState(() => {
    // Retrieve previous drawing from localStorage on first load
    const savedLines = localStorage.getItem("canvas-drawings");
    return savedLines ? JSON.parse(savedLines) : [];
  });

  // context setup
  useEffect(() => {
    // context
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // image
    image.current.src = ImageA;

    // Once the image is loaded, update canvas size and draw the image
    image.current.onload = () => {
      setImageLoaded(true); // Set image as loaded
      const aspectRatio = image.current.width / image.current.height;
      const canvasHeight = canvasSize.width / aspectRatio;
      setCanvasSize({ width: canvasSize.width, height: canvasHeight });
      setImageSize({
        width: image.current.width,
        height: image.current.height,
      });

      // Draw the image on the canvas
      context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before drawing
      context.drawImage(image.current, 0, 0, canvasSize.width, canvasHeight);

      // Redraw previous lines after the image is loaded
      redrawLines(context);
    };

    // Handle image loading errors
    image.current.onerror = () => {
      console.error("Image failed to load.");
    };
  }, [canvasSize]);

  const handleMouseDown = (event) => {
    if (!imageLoaded) return; // Only allow drawing after the image has loaded
    setIsDrawing(true);
    const { x, y } = getPointerPosition(event);
    setLines((prev) => [...prev, [{ x, y }]]);
  };

  const handleMouseMove = (event) => {
    if (!isDrawing || !imageLoaded) return;
    const { x, y } = getPointerPosition(event);
    setLines((prev) => {
      const updatedLines = [...prev];
      updatedLines[updatedLines.length - 1].push({ x, y });
      return updatedLines;
    });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const getPointerPosition = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x =
      (event.clientX - rect.left) * (imageSize.width / canvasSize.width);
    const y =
      (event.clientY - rect.top) * (imageSize.height / canvasSize.height);
    return { x, y };
  };

  // Redraw the canvas, including the image and lines
  const redrawLines = (context) => {
    // Draw all lines
    context.strokeStyle = "red";
    context.lineWidth = 2;
    lines.forEach((line) => {
      context.beginPath();
      line.forEach((point, index) => {
        const scaledX = point.x * (canvasSize.width / imageSize.width);
        const scaledY = point.y * (canvasSize.height / imageSize.height);
        if (index === 0) {
          context.moveTo(scaledX, scaledY);
        } else {
          context.lineTo(scaledX, scaledY);
        }
      });
      context.stroke();
    });
  };

  // Save lines to localStorage whenever they are updated
  useEffect(() => {
    if (lines.length > 0) {
      localStorage.setItem("canvas-drawings", JSON.stringify(lines));
    }
  }, [lines]);

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ border: "1px solid black" }}
      />
    </div>
  );
};

export default CanvasWithImage;
