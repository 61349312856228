import { useNavigate } from "react-router-dom";
import { useCanvasContext } from "../../hooks/useCanvasContext";
import useLongPress from "../../hooks/useLongPress";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";

/***
 * canvas pages
 */
const CanvasPages = ({
  loading,
  loadingDocument,
  imagesArray,
  linesArray,
  moveToPage,
}) => {
  const {
    handleZoomExtent,
    currentPage,
    dImageHeight,
    dOffsetY,
    isHighRes,
    setIsHighRes,
    viewportScale,
    setViewportScale,
    reloadImagesArray,
    viewModeIsActive,
    setViewModeIsActive,
    showTools,
    setShowTools,
    showCanvasPages,
    setShowCanvasPages,
    flushPdfDocument,
  } = useCanvasContext();

  const resolveHasImage = (img) => {
    return img != null;
  };

  const handlePageClick = async (e, img, index) => {
    e.preventDefault();
    const pageNum = index + 1;
    await moveToPage(pageNum);
  };

  const resolveIsCurrent = (pageNum) => {
    return currentPage == pageNum;
  };

  const resolveHasLines = (lines) => {
    return false;
  };

  const navigate = useNavigate();

  const onLongPress = () => {};

  const longPressEvent = useLongPress(onLongPress, 1000); // 1000ms for long press duration

  const navigator = useNavigate();
  const handleClose = () => {
    if (window.confirm("are you sure you want to leave")) {
      flushPdfDocument();
      navigator(-1);
    }
  };

  const handleDisableViewModel = () => {
    if (viewModeIsActive) {
      setViewModeIsActive(false);
      setShowTools(true);
    } else {
      setViewModeIsActive(true);
      setShowTools(false);
    }
  };

  const resolveBottom = () => {
    if (!showCanvasPages) return 40;
    return viewModeIsActive ? 40 : 100;
  };

  const resolveOpacity = () => {
    if (!showCanvasPages) return 0;
    return 1;
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute left-[100px] top-[300px]">
          {/* <h1>imageHeight: {dImageHeight}</h1>
            <h1 className="mt-5">offsetY: {dOffsetY}</h1> */}
        </div>
      </div>
      <div
        // className="flex flex-col gap-1 pr-6"
        className={`flex flex-col gap-1 pr-6 ${
          showCanvasPages ? "" : "pointer-events-none"
        }`}
        style={{
          position: "absolute",
          bottom: resolveBottom(),
          opacity: resolveOpacity(),
          left: 25,
          minWidth: "200px",
          transition: "bottom 0.2s ease, opacity 0.2s ease", // Include opacity in transition
        }}
      >
        <div className="max-w-[180px]">
          {loadingDocument && <DocumentLoadingSpinner />}
          {loading && <LoadingSpinner />}
        </div>
        {/* current page */}
        {/* <h3 className="text-white bg-black py-2 px-4 rounded-lg">
            current page: {currentPage}
          </h3> */}

        <div className="flex items-center gap-1">
          {/* close button*/}
          <div
            onClick={handleClose}
            className="flex items-center justify-center bg-black rounded-lg text-white/50 hover:text-white w-[56px] h-[56px] cursor-pointer"
          >
            <CloseIcon fontSize="large" />
          </div>

          {/* pages */}
          <div
            className={`flex items-center gap-2 min-h-[56px] min-w-[100px] ${
              isHighRes ? "bg-black" : "bg-orange-300"
            }  py-2 px-4 rounded-lg`}
          >
            {imagesArray.map((img, index) => (
              <div key={index} onClick={(e) => handlePageClick(e, img, index)}>
                <PageImageDisk
                  hasImage={resolveHasImage(img)}
                  isCurrent={resolveIsCurrent(index + 1)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasPages;

const diskSize = 40;
const PageImageDisk = ({ hasImage = false, isCurrent = false }) => {
  return (
    <div>
      <div
        style={{
          width: diskSize,
          height: diskSize,
        }}
        className={`rounded-full ${hasImage ? "bg-red-500" : "bg-red-500/25"} ${
          isCurrent ? "border-[4px] border-white" : "border border-white/50"
        } cursor-pointer `}
      ></div>
    </div>
  );
};

const DocumentLoadingSpinner = () => {
  return (
    <div className="flex gap-2 items-center text-black bg-blue-500 py-2 px-4 rounded-lg font-bold ml-[56px]">
      <div>Loading</div>
      <CircularProgress sx={{ color: "black" }} size={16} />
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div className="flex gap-2 items-center text-black bg-orange-400 py-2 px-4 rounded-lg font-bold ml-[56px]">
      <div>Loading</div>
      <CircularProgress sx={{ color: "black" }} size={16} />
    </div>
  );
};
