export default function RevisionSheetDashPanel({
  project,
  handleHighRes,
  isHighRes,
}) {
  /***
   * resolve Number of drawing
   */
  const resolveNumberOfDrawings = () => {
    const drawings = project.drawings;
    return drawings.length;
  };

  /***
   * resolve current serial
   */
  const resolveCurrentSerial = () => {
    const drawings = project.drawings;
    return drawings[drawings.length - 1]?.serial || "0.0.0";
  };

  return (
    <div className={`absolute top-[65px] left-[30px] w-[250px] min-h-[100px]`}>
      <div className="flex flex-col bg-black/50 rounded-lg p-4">
        <div className="text-white text-5xl font-bold text-center cursor-default">
          {resolveNumberOfDrawings()}
        </div>
        <div className="text-white text-center mt-2 cursor-default">
          drawings
        </div>
      </div>
      <div className="bg-black/50 rounded-lg p-4 mt-1">
        <div className="text-white text-5xl font-bold text-center cursor-default">
          {resolveCurrentSerial()}
        </div>
        <div className="text-white text-center mt-2 cursor-default">serial</div>
      </div>

      <div className="bg-black/50 rounded-lg p-4 mt-1">
        {/* <h3 className="text-white">controls</h3> */}
        <div
          onClick={handleHighRes}
          className={`cursor-pointer text-center ${
            isHighRes ? "text-white" : "text-white/50"
          } font-thin`}
        >
          render with high res
        </div>
      </div>
    </div>
  );
}
