import { Dialog } from "@mui/material";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useStudioContext } from "../../hooks/useStudioContext";
import HexStudioProject from "../../models/HexStudioProject";

export default function StudioPage() {
  const { studioProjects, users, usersLoading } = useCollectionContext();
  const { activeUser } = useAuthContext();
  const [studioUsers, setStudioUsers] = useState([]);
  const { setCurrentStudentId, members, setMembers } = useStudioContext();

  /***
   * set studio users
   */
  useEffect(() => {
    if (activeUser && users) {
      const result = users.filter(
        (user) => user.studioId == activeUser.studioId
      );
      setStudioUsers(result);
    }
  }, [users]);

  /***
   * add studio project
   */
  const addStudioProject = async () => {
    toast.success("add project");

    const hexProject = new HexStudioProject().blankId();
    hexProject.name = "blank project";
    hexProject.studioId = activeUser.studioId;

    const d = hexProject.data();
    console.log(d);

    const colRef = collection(db, "studioProjects");
    await addDoc(colRef, d).then((ref) => {
      toast.success("project added");
    });
  };

  const addMemberToStudio = async () => {
    setDiaName("");
    setOpenAddMemberDialog(true);
  };

  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);

  const handleClose = (e) => {
    setOpenAddMemberDialog(false);
  };

  const handleAddMemberToStudio = async (e) => {
    const d = { studioId: activeUser.studioId };
    const docRef = doc(db, "users", diaName);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      toast.success("member added");
    });

    handleClose();
  };

  const [diaName, setDiaName] = useState("");
  const handleInputChange = (event) => {
    const inputValue = event.target.value; // Get the input string
    setDiaName(inputValue);
  };

  const navigate = useNavigate();
  const gotoStudioProject = (studioProjectId) => {
    // resolve members

    if (users && activeUser) {
      const results = users.filter(
        (user) => user.studioId == activeUser.studioId && !user.isInstructor
      );

      // sort results alphabatecally according to displayname
      const sortedResults = results.sort((a, b) =>
        a.displayname.localeCompare(b.displayname)
      );

      // set members
      setMembers(sortedResults);
      const curId = sortedResults[0].id;
      setCurrentStudentId(curId);
      toast.success(curId);
    }

    navigate(`/studioProject/${studioProjectId}`);
  };

  if (!activeUser) return <div>loading</div>;

  return (
    <div className="w-[1000px] mx-auto">
      {/* forward dialog */}
      <Dialog
        open={openAddMemberDialog}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "600px",
            maxWidth: "600px",
            padding: "20px",
            textAlign: "center",
          },
        }}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Darker backdrop
        }}
      >
        {/* input */}
        <div className="w-[500px]">
          <div className="mt-4">
            <label
              htmlFor="imageName"
              className="block text-sm font-medium text-gray-700"
            >
              Add member with id
            </label>
            <input
              type="text"
              id="memberId"
              name="memberId"
              placeholder={"member id"}
              value={diaName}
              className="mt-2 block w-full rounded-md border-gray-200 border-2 focus:border-gray-300 focus:ring-0 sm:text-sm h-12 pl-4"
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* buttons */}
        <div className="flex gap-1 mx-auto mt-4">
          <button
            onClick={handleClose}
            className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
          >
            Close
          </button>
          <button
            onClick={handleAddMemberToStudio}
            className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
          >
            add
          </button>
        </div>
      </Dialog>

      <h2 className="h-[50px] p-2">Studio</h2>
      {activeUser.isInstructor && (
        <div className="mb-4">{activeUser.studioId}</div>
      )}

      {/* controls */}
      {activeUser.isInstroctor && (
        <div>
          <div className="flex gap-1">
            <div
              onClick={addStudioProject}
              className="w-[300px] p-2 bg-gray-500 rounded cursor-pointer hover:bg-gray-400"
            >
              add project
            </div>
            <div
              onClick={addMemberToStudio}
              className="w-[300px] p-2 bg-gray-500 rounded cursor-pointer hover:bg-gray-400"
            >
              add member
            </div>
          </div>
        </div>
      )}

      {/* horizontal break */}
      <hr className="border-gray-800 border-[1px] my-1" />

      <div className="flex flex-col gap-1">
        {studioProjects?.map((sproj) => (
          <div
            key={sproj.id}
            onClick={() => gotoStudioProject(sproj.id)}
            className="flex items-center bg-gray-400 cursor-pointer min-h-[60px] p-2 hover:bg-gray-500"
          >
            <div className="font-bold text-2xl">{sproj.name}</div>
          </div>
        ))}
      </div>
      {activeUser.isInstructor && (
        <div>
          <div className="flex items-center gap-1 my-4">
            <div className="text-xl font-bold">members</div>
            <div
              onClick={addMemberToStudio}
              className="cursor-pointer opacity-50 hover:opacity-100 ml-2"
            >
              [add member]
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {studioUsers
              .sort((a, b) => a.displayname.localeCompare(b.displayname))
              .map((user) => (
                <div>
                  <div className="flex items-center bg-gray-300 h-[60px] p-2 hover:bg-gray-400">
                    <div>{user.displayname}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
