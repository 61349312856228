import { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import HexDialogButtons from "../../../components/dialogs/HexDialogButtons";
import CheckIcon from "@mui/icons-material/Check";

/***
 * Add Member Dialog
 */
export default function HexAddMemberDialog({
  open,
  data,
  setData,
  handleDialogButton,
  members,
  project,
}) {
  const resolveDialogButton = (flag) => {
    handleDialogButton(flag, memberState);
  };

  const [memberState, setMemberState] = useState([]);

  const handleCheckAll = () => {
    const updatedMembers = memberState.map((memberState) => ({
      ...memberState,
      checked: true,
    }));

    setMemberState(updatedMembers); // assuming you have a state setter function
  };

  const handleCheckNone = () => {
    const updatedMembers = memberState.map((memberState) => ({
      ...memberState,
      checked: false,
    }));

    setMemberState(updatedMembers); // assuming you have a state setter function
  };

  /***
   * set member state
   */
  useEffect(() => {
    const s = members.map((member) => ({ member: member, checked: true }));
    setMemberState(s);
  }, [members]);

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      PaperProps={{
        style: {
          width: "600px",
          maxWidth: "600px",
          padding: "20px",
        },
      }}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Darker backdrop
      }}
    >
      <div className="font-bold">add members to project: {project.name}</div>

      <div className="flex items-center gap-1 cursor-pointer">
        <div
          onClick={handleCheckAll}
          className="text-gray-500 hover:text-gray-800"
        >
          [check all]
        </div>
        <div
          onClick={handleCheckNone}
          className="text-gray-500 hover:text-gray-800"
        >
          [uncheck all]
        </div>
      </div>

      <div className="flex flex-col gap-1">
        {memberState
          .sort((a, b) =>
            a.member.displayname.localeCompare(b.member.displayname)
          )
          .map((m, index) => (
            <div key={index}>
              <MemberSelectRow project={project} memberState={m} />
            </div>
          ))}
      </div>

      <HexDialogButtons callBack={resolveDialogButton} />
    </Dialog>
  );
}

/***
 * Member Select Row
 * for select dialog
 */
const MemberSelectRow = ({ project, memberState }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(memberState.checked);
  }, [memberState]);

  const handleToggle = () => {
    // setMemberState({ ...memberState, checked: false });
    memberState.checked = false; // this will update the value but not the interfac
    setChecked(!checked);
  };
  return (
    <div
      onClick={handleToggle}
      className="flex items-center h-[40px] bg-gray-300 p-2 hover:bg-gray-400 cursor-pointer"
    >
      <div className={`${checked ? "visible" : "invisible"}`}>
        <CheckIcon />
      </div>
      <div className="ml-4">{memberState.member.displayname}</div>
    </div>
  );
};
