import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { db, timestamp } from "../firebase/config";

// firebase imports
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  addDoc,
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useCollection } from "./useCollection";
import { Navigate, useNavigate } from "react-router";

// import { toast } from "react-toastify";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const signup = async ({ email, password, displayname, contact }) => {
    setError(null);
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        const user = res.user;

        const resolveRoles = () => {
          return { value: "user", label: "User" };
        };

        const resolveInbox = () => {
          return { items: [] };
        };

        const addDocument = async () => {
          let d = {
            uid: user.uid,
            email: email,
            displayname: displayname,
            entityId: "",
            studioId: "",
            created: timestamp.now(),
            contact: contact,
            role: resolveRoles(),
            permissions: [{ value: "read", label: "Read" }],
            pinned: [],
            inbox: resolveInbox(),
            isInstructor: false,
          };

          const cRef = collection(db, "users");
          return await addDoc(cRef, d);
        };
        if (user) {
          navigate("/");
        }
        // add user to firestore
        addDocument();
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
      });
  };

  return { error, signup };
};
