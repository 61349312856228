import { Dialog } from "@mui/material";
import HexDialogButtons from "../../../components/dialogs/HexDialogButtons";

/***
 * Dialog
 */
export default function HexDialog({ open, data, setData, handleDialogButton }) {
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      PaperProps={{
        style: {
          width: "600px",
          maxWidth: "600px",
          padding: "20px",
        },
      }}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Darker backdrop
      }}
    >
      <div className="font-bold">member name</div>
      <HexTextfiled
        valKey={"name"}
        data={data}
        setData={setData}
        placeholder="enter project name"
      />

      <HexDialogButtons callBack={handleDialogButton} />
    </Dialog>
  );
}

/***
 * Textfield
 */
const HexTextfiled = ({
  valKey,
  data,
  setData,
  placeholder = "enter text",
  width = 500,
}) => {
  return (
    <div style={{ width: width }}>
      <div>
        <input
          type="text"
          id={valKey}
          name="memberId"
          placeholder={placeholder}
          value={data[valKey]}
          className="block w-full rounded-md border-gray-200 border-2 focus:border-gray-300 focus:ring-0 sm:text-sm h-12 pl-4"
          onChange={(e) => {
            setData({
              ...data,
              [valKey]: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
