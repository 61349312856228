import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Divider from "../../components/Divider";
import Enums from "../../models/Enums";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { data } from "autoprefixer";
import TextField from "../../fields/TextField";
import SelectField from "../../fields/SelectField";
import Button from "@mui/material/Button";
import HexUser from "../../models/HexUser";
import { useAuthContext } from "../../hooks/useAuthContext";
import { SELECT_ROLES } from "../../models/Enums";

export default function UserPage() {
  const { height, width } = useWindowDimensions();
  const { id } = useParams();
  const { document: user, error } = useDocument("users", id);
  const [showForm, setShowForm] = useState(false);
  const { is } = useAuthContext();

  const handleEdit = () => {
    setShowForm(true);
  };

  const dismiss = () => {
    setShowForm(false);
  };

  return (
    <div
      style={{ minHeight: height - 50 }}
      className="flex flex-col relative overflow-hidden w-[1000px] mx-auto mt-6"
    >
      <Link to="/users">
        <h3 className="font-thin">back</h3>
      </Link>

      <div className="flex items-center">
        <h1
          className={`cursor-default ${
            is(SELECT_ROLES.MASTER) ? "bg-blue-500 rounded px-2 py-1" : ""
          }`}
        >
          {user?.displayname}
        </h1>
        <div
          onClick={handleEdit}
          className="size-8 rounded-full border-[2px] border-black ml-auto mr-4 cursor-pointer"
        >
          <ModeEditIcon size={6} />
        </div>
      </div>
      <Divider color={"black"} height={"1px"} />

      {showForm && (
        <div>
          <EditUserForm user={user} dismiss={dismiss} />
        </div>
      )}

      {user && (
        <div>
          <div className="flex">
            <div className="w-[200px] font-bold">created:</div>
            <div>{user.created.toDate().toDateString()}</div>
          </div>
          <div className="flex">
            <div className="w-[200px] font-bold">email:</div>
            <div>{user.email}</div>
          </div>
          <div className="flex">
            <div className="w-[200px] font-bold">role:</div>
            <div>{user.role.value}</div>
          </div>

          <div className="flex">
            <div className="min-w-[200px] font-bold">role permissions:</div>

            <div className="flex flex-wrap gap-1">
              {Enums.RolePermissions[user.role.value]?.map((perm, index) => (
                <div key={index}>
                  <div className="bg-orange-300 rounded p-1 min-w-[120px] text-center cursor-default">
                    {perm}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex mt-1">
            <div className="w-[200px] font-bold">permissions:</div>
            <div className="flex gap-1">
              {user.permissions.map((perm, index) => (
                <div key={index}>
                  <div className="bg-gray-300 rounded p-1 min-w-[120px] text-center cursor-default">
                    {perm.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function EditUserForm({ user, dismiss }) {
  useEffect(() => {
    if (user) {
      console.log("from effect: @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
      console.log(user.role);
      console.log(user.permissions);

      setRoleOpt(user.role);
      setPermOpt(user.permissions);
    }
  }, [user]);

  const [roleOpt, setRoleOpt] = useState("");
  const resolveRoleOption = (option) => {
    setRoleOpt(option);
  };

  const [permOpt, setPermOpt] = useState(null);
  const resolvePermOption = (option) => {
    setPermOpt(option);
  };

  const handleupdate = () => {
    // save

    const u = HexUser.fromData(user);
    u.role = roleOpt;
    u.permissions = permOpt;
    u.commit().then((ref) => {
      dismiss();
    });
  };

  const handlecancel = () => {
    dismiss();
  };

  return (
    <div>
      <div className="flex flex-col gap-1">
        <SelectField
          label={"role"}
          fullWidth={true}
          options={Enums.UserRoles}
          data={roleOpt}
          setData={resolveRoleOption}
          isActive={true}
          showLabel={false}
        />
        <SelectField
          key={"permissions"}
          label={"permissions"}
          fullWidth={true}
          options={Enums.Permissions}
          data={permOpt}
          setData={resolvePermOption}
          isActive={true}
          showLabel={false}
          isMulti={true}
        />
      </div>
      <div className="flex gap-1">
        <Button variant="contained" sx={{ mt: 2 }} onClick={handleupdate}>
          update
        </Button>
        <Button variant="contained" sx={{ mt: 2 }} onClick={handlecancel}>
          cancel
        </Button>
      </div>
    </div>
  );
}
