// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8Mzm8rpsNidqSs6MQwYAH5FMu0VFsCHM",
  authDomain: "hexpo-94ce2.firebaseapp.com",
  projectId: "hexpo-94ce2",
  storageBucket: "hexpo-94ce2.appspot.com",
  messagingSenderId: "795033070224",
  appId: "1:795033070224:web:336102379816800bd30615",
  measurementId: "G-JGV3RQ7M4Z",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// init firestore
const db = getFirestore();

// init authentication
const auth = getAuth();

// time stamp
const timestamp = Timestamp;

export { db, auth, timestamp };
