import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useDocument } from "../../hooks/useDocument";
import { useUploadDocument } from "../../hooks/useUploadDocument";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// icons
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useCanvasContext } from "../../hooks/useCanvasContext";

/***
 * Revision Stack
 */
export default function RevStack() {
  const {
    loadPdf,
    numPages,
    setNumPages,
    activePdf,
    setActivePdf,
    renderTaskRef,
    pdfCanvasRef,
    pdf,
    setPdf,
    currentPage,
    setCurrentPage,
    renderPage,
    drawImageWithObjectFitCover,
    loadPdfDocument,
    scale,
    setScale,
  } = useCanvasContext();

  const { uploadImage } = useUploadDocument();
  const [data, setData] = useState({
    name: "",
    file: null,
  });

  const { document: project } = useDocument("reviews", "hamadah");

  // handle previous
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < numPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleDelete = async (e, url) => {
    e.stopPropagation();
    if (window.confirm("delete pdf")) {
      const urls = project.urls.filter((u) => u != url);
      const d = { urls: [...urls] };
      const docRef = doc(db, "reviews", "hamadah");
      await setDoc(docRef, d, { merge: true }).then((ref) => {
        toast.success("pdf deleted");
      });
    }
  };

  const handleClick = (url) => {
    // assign pdf
    setActivePdf(url);
    setCurrentPage(1);
  };

  const resolveRowBg = (url) => {
    if (url == activePdf) {
      return "bg-gray-400";
    } else {
      return "bg-gray-500";
    }
  };

  const handleDownload = async (e, url) => {
    // Use the URL to download the PDF or open it in a new tab

    // const url = pdfUrl.replace(
    //   "ik.imagekit.io/habiatuq/hexpo",
    //   "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com"
    // );

    // Fetch the file as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");

    // Create a URL for the Blob and set it as the href of the link
    const blobURL = window.URL.createObjectURL(blob);
    link.href = blobURL;

    // link.download = "your-filename.pdf";
    link.setAttribute("download", "your-filename.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const inputfileRef = useRef();

  const handleAddClick = () => {
    inputfileRef.current.click();
  };

  const handleUploadPdf = async (e) => {
    const file = e.target.files[0];
    setData({ ...data, file: file });

    if (!project) return;

    // setInProgress(true);

    // 2. upload pdf
    await uploadImage(file, "pdf").then(async (pdfUrl) => {
      const url = pdfUrl.replace(
        "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
        "ik.imagekit.io/habiatuq/hexpo"
      );

      // 3. prepare document for firestore
      const fileName = data.file?.name;
      const d = { urls: [...project.urls, url] };

      // // lets add to the projects urls
      const docRef = doc(db, "reviews", "hamadah");
      await setDoc(docRef, d, { merge: true }).then((ref) => {
        toast.success("added to project");
      });
    });
  };

  return (
    <div
      style={{ position: "absolute", right: 0, top: 50, zIndex: 4 }}
      className=" min-w-[350px] mr-10 p-2"
    >
      <div className="bg-black/80 rounded-lg">
        <div className="flex items-center p-2">
          <div className="text-white ">drawing list</div>
          <div onClick={handleAddClick} className="ml-auto mr-2">
            <div className="ml-2 font pointer-events-none">
              <input
                ref={inputfileRef}
                type="file"
                accept="application/pdf" // This limits the file type to PDF
                id="file"
                onChange={handleUploadPdf}
                className="hidden"
              />

              <div className="text-white cursor-pointer">
                <AddCircleOutlineIcon />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[2px] p-2">
          {project &&
            project.urls.map((url, index) => (
              <div key={index}>
                <div
                  // onClick={() => handleDelete(url)}
                  onClick={() => handleClick(url)}
                  className={`flex ${resolveRowBg(
                    url
                  )} p-1 rounded cursor-pointer`}
                >
                  <div className=" table">revisions-{index + 1}</div>
                  <div
                    className="ml-auto"
                    onClick={(e) => handleDelete(e, url)}
                  >
                    <DeleteOutlineIcon />
                  </div>
                  <div
                    className="ml-2 mr-2"
                    onClick={(e) => handleDownload(e, url)}
                  >
                    <SystemUpdateAltIcon />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="flex relative items-center gap-2 p-2 bg-black/80 rounded-lg mt-1">
        <button
          onClick={handlePrev}
          className="bg-gray-500 rounded text-white w-[120px] px-4 py-2 font-light"
        >
          [Previous]
        </button>
        <div className="bg-gray-500 rounded text-white w-[140px] px-4 py-2 font-light">
          Page: {currentPage} of {numPages}
        </div>
        <button
          onClick={handleNext}
          className="bg-gray-500 rounded text-white w-[120px] px-4 py-2 font-light"
        >
          [Next]
        </button>
      </div>
      <div className="mt-1">{pdf && <PDFStats pdf={pdf} />}</div>
    </div>
  );
}

/***
 * PDF Stats
 */
const PDFStats = ({ pdf, scale = 1 }) => {
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  useEffect(() => {
    // page

    const setup = async () => {
      const page = await pdf.getPage(1);
      // viewport
      const viewport = page.getViewport({ scale: scale });
      setWidth(viewport.width);
      setHeight(viewport.height);
    };

    setup();
  }, [pdf]);

  return (
    <div>
      <div className="bg-black/80 rounded-lg min-w-[300px] min-h-[100px] text-white p-2">
        <div className="text-xl">stats</div>
        <div>width: {width}</div>
        <div>height: {height}</div>
      </div>
    </div>
  );
};
