class FORMATER {
  static formatedDate = (date) => {
    return FORMATER.setInputDate(date);
  };

  static setInputDate(_date) {
    if (_date) {
      let d = _date.getDate();
      let m = _date.getMonth() + 1;
      let y = _date.getFullYear();

      if (d < 10) {
        d = "0" + d;
      }
      if (m < 10) {
        m = "0" + m;
      }

      const date = y + "-" + m + "-" + d;
      //   console.log(date);
      return date;
    }
    return "";
  }
}

export default FORMATER;
