import { useAuthContext } from "../../../hooks/useAuthContext";
import { DrawingType, SELECT_ROLES } from "../../../models/Enums";
import { Tooltip, IconButton } from "@mui/material";
import Project from "../../../models/Project";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

// icons
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import { useCollection } from "../../../hooks/useCollection";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import HexDrawing from "../../../models/HexDrawing";
import BasicDialog from "../../../components/dialogs/BasicDialog";
import BrushIcon from "@mui/icons-material/Brush";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TvIcon from "@mui/icons-material/Tv";
import SendIcon from "@mui/icons-material/Send";
import IosShareIcon from "@mui/icons-material/IosShare";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CropSquareIcon from "@mui/icons-material/CropSquare";

import { handleSendDrawing_Group } from "../../../utilities/InboxManager";

import {
  zeroCenter,
  zeroRight,
  incrementCenter,
  incrementLeft,
  incrementRight,
  resolveFeedbackSerial,
} from "../../../utilities/SerialManager";
import { useCanvasContext } from "../../../hooks/useCanvasContext";
import { Eject } from "@mui/icons-material";
import { db } from "../../../firebase/config";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import HexInboxItem from "../../../models/HexInboxItem";
import RecipientSelector from "./RecipientSelector";

export default function DrawingRevRow({
  index,
  drawing,
  handleClickRow,
  users,
  handleDownload,
  handleRemoveDrawing,
  handleDisplayDate,
}) {
  const { userIs, activeUser } = useAuthContext();
  const { projects } = useCollectionContext();
  const { currentDrawing } = useCanvasContext();

  /***
   * resolve name for id
   */
  const resolveNameForId = (id) => {
    const n = users?.filter((u) => u.id == id)[0];
    return n.displayname || "";
  };

  /***
   * get size in MB
   */
  const getSizeInMB = (sizeInBytes) => {
    const sizeInMB = sizeInBytes / (1024 * 1024); // 1 MB = 1024 * 1024 bytes
    return sizeInMB;
  };

  /***
   * resolve background
   */
  const resolveBg = (stage) => {
    switch (stage) {
      case "presentation":
        return "bg-orange-400";
        break;
      case "review":
        return "bg-blue-500";
        break;
      default:
        return "bg-gray-400";
    }
  };

  const resolveBg_drawingType = (drawingTypeValue) => {
    switch (drawingTypeValue) {
      case "feedback":
        return "bg-orange-400";
        break;
      default:
        return "bg-gray-400";
    }
  };

  const isSubmission = () => {
    if (
      drawing.drawingType?.value != DrawingType.Feedback.value &&
      drawing.drawingType != "undefined"
    )
      return true;
    const n =
      drawing.drawingType?.value == DrawingType.Submission.value ||
      drawing.drawingType?.value == DrawingType.Blank.value;
    return n;
  };

  const handleFixDrawingType = (e, drawing) => {
    e.stopPropagation();
    // drawing
    const projectObject = getProjectObject(drawing.projectId);
    const drawingObject = projectObject.drawingsWithId(drawing.id);
    drawingObject.setType(DrawingType.Submission);
    projectObject.commit();
  };

  /***
   * get project object
   */
  const getProjectObject = (projectId) => {
    const project = projects.filter((proj) => proj.id == projectId)[0];
    return Project.fromData(project);
  };

  /***
   * get project header
   */
  const getProjectHeader = (projectId) => {
    const projectObject = getProjectObject(projectId);
    const header = projectObject.getHeader();
    // add a new
  };

  const resolveDrawingType = () => {
    return drawing.drawingType?.value;
  };

  const handleSwitchStage = (e, id) => {
    e.stopPropagation();
    setShowSwitcher(true);
  };

  const [showSwitcher, setShowSwitcher] = useState(false);

  const setDrawingStage = (key) => {
    if (drawing.stage == key) {
      setShowSwitcher(false);
      return;
    }

    dismissSubmenu();
    switch (key) {
      case "presentation":
        switchDrawingStage(key);
        break;
      case "review":
        switchDrawingStage(key);
        break;
      default:
        switchDrawingStage(key);
        break;
    }
  };

  /***
   * switch drawing
   */
  const switchDrawingStage = async (key) => {
    const project = projects.find((p) => p.id == drawing.projectId);
    const xProj = Project.fromData(project);
    const xDrawing = xProj.drawingsWithId(drawing.id);
    xDrawing.setStage(key);
    await xProj.commit().then((ref) => {
      dismissSubmenu();
    });
  };

  const dismissSubmenu = () => {
    setShowSwitcher(false);
  };

  const [openSerialDialog, setOpenSerialDialog] = useState(false);
  const handleOnSerialClick = (e) => {
    e.stopPropagation();
    setOpenSerialDialog(true);
  };

  const handleDataChange = (e, key) => {
    setData({ ...data, [key]: e.target.value });
  };

  const [data, setData] = useState({ serial: drawing.serial });
  const handleAction = (agree) => {
    if (agree) {
      toast.success("agree");
      handleSaveSerial();
      setOpenSerialDialog(false);
    } else {
      toast.success("disagree");
      setOpenSerialDialog(false);
    }
  };

  const handleSaveSerial = () => {
    // save serial
    const projectId = drawing.projectId;
    const project = projects.filter((proj) => proj.id == projectId)[0];
    const projectObject = Project.fromData(project);
    const drawingObject = projectObject.drawingsWithId(drawing.id);

    // set serial
    drawingObject.setSerial(data.serial || "0.0.x");
    projectObject.commit();
  };

  const handleClientRowComponents = (e) => {
    if (!openSerialDialog) {
      handleClickRow(drawing.id);
    } else {
      e.stopPropagation();
    }
  };

  /***
   * resolve handle promote
   */
  const resolveHandlePromote = (e) => {
    e.stopPropagation();
    if (window.confirm("promote drawing?")) {
      // handlePromote(drawing.serial);
      let i = "";
      let newStage = "";
      if (drawing.stage == "design" || drawing.stage == "") {
        i = zeroRight(drawing.serial);
        i = incrementCenter(i);
        // If implementing lead: check is newSeial is head, if not, then leap
        newStage = "review";
      }
      if (drawing.stage == "review") {
        i = zeroRight(drawing.serial);
        i = zeroCenter(i);
        i = incrementLeft(i);
        // If implementing lead: check is newSeial is head, if not, then leap
        newStage = "presentation";
      }

      const newSerial = i;
      // drawing
      const projectId = drawing.projectId;
      const project = projects.filter((proj) => proj.id == projectId)[0];
      const projectObject = Project.fromData(project);
      const drawingObject = projectObject.drawingsWithId(drawing.id);
      drawingObject.setSerial(newSerial);
      drawingObject.setStage(newStage);
      projectObject.commit();
    }
  };

  // drawing.drawingType?.value || DrawingType.Blank.value;

  const resolvePromoteMessege = () => {
    if (drawing.stage == "design" || drawing.stage == "") {
      return "promote to review";
    }
    return "promote to presentation";
  };

  /***
   * is header
   */
  const isHeader = () => {
    const projectObject = getProjectObject(drawing.projectId);
    const drawingObjects = projectObject.drawings;

    if (drawingObjects.length == 0) {
      return true;
    }
    return drawing.id == drawingObjects[drawingObjects.length - 1].id;
  };

  /***
   * is stage header
   */
  const isStageHeader = () => {
    const projectObject = getProjectObject(drawing.projectId);
    const drawingObjects = projectObject.drawings;

    const stage = drawing.stage;
    const stageDrawings = drawingObjects.filter((d) => d.stage == stage);

    if (stageDrawings.length == 0) {
      return true;
    }
    return drawing.id == stageDrawings[stageDrawings.length - 1].id;
  };

  const handleLeap = (e, drawing) => {
    e.stopPropagation();
    // get header
    getProjectHeader(drawing.projectId);
  };

  const [open, setOpen] = useState(false);
  const handleSendDrwaing_update = (e, drawing) => {
    e.stopPropagation();
    setOpen(true); // Open the dialog
    setRecipients([]);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
    setRecipients([]);
  };

  const handleCapture = (e) => {
    e.stopPropagation();
  };

  const handleSend = (e) => {
    e.stopPropagation();
    if (recipients.length < 1) {
      toast.error("must add recipients to send drawing");
      return;
    }
    const ids = recipients.map((r) => r.id);
    handleSendDrawing_Group(e, users, drawing, activeUser.id, ids, setOpen);
  };

  const [recipients, setRecipients] = useState([]);

  return (
    <div
      onClick={(e) => handleClientRowComponents(e)}
      className={`relative flex items-center ${resolveBg_drawingType(
        drawing.drawingType.value
      )} rounded min-h-[50px] p-2 cursor-pointer`}
    >
      {/* Dialog */}

      <BasicDialog
        title={"edit serial"}
        open={openSerialDialog}
        handleAction={handleAction}
      >
        {/* input */}
        <span style={{ display: "block" }} className="w-[500px]">
          <span style={{ display: "block" }} className="mt-4">
            <label
              htmlFor="serial"
              className="block text-sm font-medium text-gray-700"
            >
              enter new serial
            </label>
            <input
              id="serial"
              name="serial"
              placeholder={"serial"}
              value={data["serial"]}
              className="mt-2 block w-full rounded-md border-gray-200 border-2 focus:border-gray-300 focus:ring-0 sm:text-sm h-12 pl-4 resize-none"
              onChange={(e) => handleDataChange(e, "serial")}
            />
          </span>
        </span>
      </BasicDialog>

      {/* test dialog */}
      <div onClick={(e) => handleCapture(e)}>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "600px",
              maxWidth: "600px",
              padding: "20px",
              textAlign: "center",
            },
          }}
          BackdropProps={{
            style: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Darker backdrop
          }}
        >
          <RecipientSelector
            users={users}
            recipients={recipients}
            setRecipients={setRecipients}
          />
          <div className="flex gap-1 mx-auto mt-4">
            <button
              onClick={handleClose}
              className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
            >
              Close
            </button>
            <button
              onClick={handleSend}
              className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
            >
              send
            </button>
          </div>
        </Dialog>
      </div>

      {/* {drawing.drawingType.value == DrawingType.Feedback.value && (
        // <div className="absolute top-0 left-0 h-full w-[65px] bg-black rounded-l z-0 opacity-75"></div>
        // <div className="absolute top-0 left-0 h-full w-[65px] bg-black rounded-l z-0 opacity-75"></div>
      )} */}

      <div className="relative flex gap-4 w-full">
        <div className="relative px-4 mt-3 min-w-[100px]">
          <AvatarComponent stage={drawing.stage} />
          {/* feedback avatar */}
          {drawing.drawingType?.value == DrawingType.Feedback.value && (
            <div className="absolute scale-[65%] top-[20px] left-[45px]">
              <FeedbackAvatar />
            </div>
          )}
          {/* submission avatar */}
          {isSubmission() && (
            <div className="absolute scale-[65%] top-[20px] left-[45px]">
              <SubmitAvatar />
            </div>
          )}
        </div>
        <div className="w-full">
          <h2 className="flex items-center h-[50px]">
            <SerialResolver drawingSerial={drawing.serial} />
            <div className="flex items-center font-thin ml-4 text-sm border border-black/50 px-4 py-1 rounded-full">
              <div>{getSizeInMB(drawing.size).toFixed(2)} MB</div>
            </div>
          </h2>
          <div className="cursor-default pointer-events-none">
            {handleDisplayDate(drawing.date)}
          </div>

          <div className="hidden">
            {drawing.name != "" && (
              <div className="flex items-center gap-2 font-thin">
                <div className="w-[80px]"> pdf name:</div>
                <div>{drawing.name}</div>
              </div>
            )}
            <div className="flex items-center gap-2 font-thin">
              <div className="w-[80px]"> pdf size:</div>
              <div>{getSizeInMB(drawing.size).toFixed(2)} MB</div>
            </div>
            {/* {drawing.description && drawing.description != "" && (
            <div className="flex items-center gap-2 font-thin my-4 max-w-[500px]">
              {drawing.description}
            </div>
          )} */}
          </div>

          <div className="flex items-center gap-2 font-thin w-full">
            <div className="w-[80px]"> pushed by:</div>
            {users && resolveNameForId(drawing?.pushedBy)}
          </div>

          {/* drawing type */}
          {userIs(SELECT_ROLES.MASTER) && (
            <div>
              {/* <div>
                stage:<span className="ml-2">{drawing.stage}</span>
              </div> */}
              {/* <div>
                drawingType:<span className="ml-2">{resolveDrawingType()}</span>
              </div> */}
            </div>
          )}

          {userIs(SELECT_ROLES.MASTER) &&
            (drawing.drawingType?.value === "blank" ||
              typeof drawing.drawingType === "undefined") && (
              <div className="bg-orange-400 rounded p-2 w-[300px] flex items-center">
                <span>debug drawing type:</span>
                <span className="ml-2 font-bold">
                  {drawing.drawingType?.value}
                </span>
                <div
                  onClick={(e) => handleFixDrawingType(e, drawing)}
                  className="cursor-pointer ml-auto px-2 py-1 rounded hover:bg-orange-600 transition-colors duration-300"
                >
                  [fix]
                </div>
              </div>
            )}

          {/* {userIs(SELECT_ROLES.MASTER) && (
            <div>
              <div className="flex items-center gap-2 font-thin mt-2">
                <div className="w-[80px]"> project:</div>
                {drawing.projectId || "no project id"}
              </div>
              <div className="flex items-center gap-2 font-thin">
                <div className="w-[80px]"> entity:</div>
                {drawing.entityId || "no entity id"}
              </div>
            </div>
          )} */}

          <div className="relative flex items-center gap-2">
            {/* {drawing.stage != "presentation" && !isHeader() && (
              <div>
                <div
                  onClick={(e) => handleLeap(e, drawing)}
                  className="font-bold text-4xl text-gray-600"
                >
                  leap
                </div>
              </div>
            )} */}
            {drawing.stage != "presentation" && isHeader() && (
              <div
                onClick={(e) => resolveHandlePromote(e)}
                className="flex items-center justify-center bg-red-400 border border-gray-600 rounded w-[200px] h-[40px] my-2"
              >
                <div>{resolvePromoteMessege()}</div>
              </div>
            )}

            {/* <div
              onClick={(e) => handleSwitchStage(e, drawing.id)}
              className="max-w-[200px] flex bg-gray-400 border border-gray-500 rounded-lg px-2 py-2"
            >
              <div>stage</div>
              <div className="ml-4">{drawing.stage}</div>
              {showSwitcher && (
                // switcher menu
                <div onMouseLeave={dismissSubmenu}>
                  <div
                    className={`absolute flex flex-col w-full gap-1 top-[-25px] left-0 bg-black/85 rounded p-2 text-white 
  transition-opacity duration-300 ease-in-out ${
    showSwitcher ? "opacity-100" : "opacity-0 pointer-events-none"
  }`}
                  >
                    <div
                      onClick={() => setDrawingStage("presentation")}
                      className="hover:bg-white/15 rounded p-2"
                    >
                      presentation
                    </div>
                    <div
                      onClick={() => setDrawingStage("review")}
                      className="hover:bg-white/15 rounded p-2"
                    >
                      review
                    </div>
                    <div
                      onClick={() => setDrawingStage("design")}
                      className="hover:bg-white/15 rounded p-2"
                    >
                      design
                    </div>
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>

        <div className="ml-auto flex">
          {/* info */}
          <div className="">
            <Tooltip title={drawing.name} arrow placement="top">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Customize the hover background color here
                  },
                }}
                className="cursor-pointer h-[35px] w-[35px]"
              >
                <div className="">
                  <CropSquareIcon />
                </div>
              </IconButton>
            </Tooltip>
          </div>
          {/* share */}
          <div className="">
            <Tooltip title="share" arrow placement="top">
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Customize the hover background color here
                  },
                }}
                onClick={(e) => handleSendDrwaing_update(e, drawing)}
                className="cursor-pointer h-[35px] w-[35px]"
              >
                <IosShareIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* download */}
          <div className="">
            <Tooltip title="Download" arrow placement="top">
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Customize the hover background color here
                  },
                }}
                onClick={(e) => handleDownload(e, drawing)}
                className="cursor-pointer h-[35px] w-[35px]"
              >
                <SystemUpdateAltIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* Delete row */}
          <div className="">
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Customize the hover background color here
                  },
                }}
                onClick={(e) => handleRemoveDrawing(e, drawing.id)}
                className="cursor-pointer w-[35px] h-[35px]"
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

/***
 * Feedback avatar
 */
const FeedbackAvatar = () => {
  return (
    <div
      style={{
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(50, 50, 50, 1)", // Transparent black
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid rgba(255, 255, 255, 0.75)", // Transparent white border
      }}
    >
      <ChatBubbleOutlineIcon style={{ color: "white", fontSize: "24px" }} />
    </div>
  );
};

/***
 * submit avatar
 */
const SubmitAvatar = () => {
  return (
    <div
      style={{
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(50, 50, 50, 1)", // Transparent black
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid rgba(255, 255, 255, 0.75)", // Transparent white border
      }}
    >
      <ArrowUpwardIcon style={{ color: "white", fontSize: "24px" }} />
    </div>
  );
};

const StudioAvatar = () => {
  return (
    <div
      style={{
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent black
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid rgba(255, 255, 255, 0.75)", // Transparent white border
      }}
    >
      <BrushIcon style={{ color: "white", fontSize: "24px" }} />
    </div>
  );
};

const PrincipleAvatar = () => {
  return (
    <div className="flex flex-col justify-center">
      <div
        style={{
          width: "50px",
          height: "50px",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent black
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid rgba(255, 255, 255, 0.75)", // Transparent white border
        }}
      >
        <VisibilityIcon style={{ color: "white", fontSize: "24px" }} />
      </div>
      {/* <div className="text-center text-xs opacity-50 mt-1">principle</div> */}
    </div>
  );
};

const ClientAvatar = () => {
  return (
    <div
      style={{
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent black
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid rgba(255, 255, 255, 0.75)", // Transparent white border
      }}
    >
      <TvIcon style={{ color: "white", fontSize: "24px" }} />
    </div>
  );
};

function AvatarComponent({ stage }) {
  return (
    <div className="scale-[120%]">
      {(stage === "design" || stage === "") && <StudioAvatar />}
      {stage === "review" && <PrincipleAvatar />}
      {stage === "presentation" && <ClientAvatar />}
    </div>
  );
}

const SerialResolver = ({ drawingSerial }) => {
  let serial = drawingSerial;
  return (
    <div>
      <div className="w-[100px]">{serial}</div>
    </div>
  );
};

// const RecipientSelector = ({ users, recipients, setRecipients }) => {
//   const [inputValue, setInputValue] = useState("");
//   const [filteredNames, setFilteredNames] = useState([]);

//   let allNames = [];
//   const [entityUsers, setEntityUsers] = useState([]);

//   // Update filtered list based on input
//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setInputValue(value);

//     allNames = users.map((r) => {
//       return { name: r.displayname, id: r.id };
//     });

//     if (value) {
//       const filtered = allNames.filter((user) =>
//         user.name.toLowerCase().includes(value.toLowerCase())
//       );
//       setFilteredNames(filtered);
//     } else {
//       setFilteredNames([]); // Clear suggestions if input is empty
//     }
//   };

//   // Add name to recipients list and clear input
//   const addRecipient = (name) => {
//     if (!recipients.includes(name)) {
//       setRecipients([...recipients, name]);
//     }
//     setInputValue("");
//     setFilteredNames([]);
//   };

//   /***
//    * handle remove
//    */
//   const handleRemove = (recipient) => {
//     const result = recipients.filter((r) => r.id != recipient.id);
//     setRecipients(result);
//   };

//   return (
//     <div className="relative w-full max-w-xs mx-auto">
//       <h2 className="">Select Recipients</h2>

//       {/* Input field */}
//       <input
//         type="text"
//         value={inputValue}
//         onChange={handleInputChange}
//         placeholder="Type a name..."
//         className="border border-gray-300 p-2 w-full"
//       />

//       {/* Filtered list as a dropdown */}
//       {filteredNames.length > 0 && (
//         <ul
//           className="fixed left-0 w-full border border-gray-300 rounded bg-white shadow-md mt-1 z-10"
//           style={{
//             top: `${
//               document.querySelector("input").getBoundingClientRect().bottom
//             }px`,
//           }}
//         >
//           {filteredNames.map((user, index) => (
//             <li
//               key={index}
//               onClick={() => addRecipient(user)}
//               className="cursor-pointer hover:bg-gray-200 p-2"
//             >
//               {user.name}
//             </li>
//           ))}
//         </ul>
//       )}

//       {/* Recipients list */}
//       {recipients.length > 0 && (
//         <div className="my-4 font-thin">Recipients:</div>
//       )}
//       <ul className="flex flex-col gap-1 cursor-default">
//         {recipients.map((recipient, index) => (
//           <li
//             key={index}
//             className="relative flex items-center h-[40px] w-full rounded bg-gray-300"
//           >
//             <div className="flex items-center justify-center w-full">
//               {recipient.name}
//             </div>
//             <div
//               onClick={() => handleRemove(recipient)}
//               className="absolute top-[8px] scale-[70%] right-0 ml-auto mr-2 cursor-pointer"
//             >
//               <CloseIcon />
//             </div>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };
