import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function About() {
  const { height, width } = useWindowDimensions();
  return (
    <div>
      <div
        style={{ height: height - 50 }}
        className="bg-orange-600 flex items-center justify-center"
      >
        <div className="">
          <div className="mx-auto cursor-default text-6xl font-bold">about</div>
        </div>
      </div>
      <div className="w-[1200px] mx-auto mb-4">
        <div className=" bg-blue-500 opacity-75 rounded-lg h-[400px] mt-4">
          panel 1
        </div>
        <div className=" bg-blue-400 opacity-75 rounded-lg h-[400px] mt-4">
          panel 2
        </div>
        <div className=" bg-blue-300 opacity-75 rounded-lg h-[400px] mt-4">
          panel 3
        </div>
      </div>
    </div>
  );
}
