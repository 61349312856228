import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useSignout } from "../../hooks/useSignout";
import { useAuthContext } from "../../hooks/useAuthContext";
import Divider from "../Divider";
import Enums, { SELECT_ROLES } from "../../models/Enums";
import { toast } from "react-toastify";
import useDeviceDetection from "../../hooks/useDeviceDetection";

export default function Navbar() {
  const { activeUser, userIs, userCan, getEntityId } = useAuthContext();
  const { signout } = useSignout();
  const deviceType = useDeviceDetection();

  const handleSignout = () => {
    // handle signout
    signout();
  };

  // useEffect(() => {
  //   if (activeUser) {
  //     console.log("active user @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  //     console.log(activeUser);
  //   }
  // }, [activeUser]);

  const [showAccountMenu, setShowAcountMenu] = useState(false);

  const handleCloseMenu = () => {
    dismiss();
  };

  const gotoMyAccount = () => {};

  const dismiss = () => {
    setShowAcountMenu(false);
  };

  return (
    <div className="bg-black h-[50px] text-white flex items-center">
      <div className="w-[1000px]  mx-auto">
        <div className="flex h-full items-center">
          <Link to="/">
            <div className="flex">
              {/* <div className="font-bold text-2xl">HAMADAH</div> */}
              <div className="font-bold text-2xl">Hexpo</div>
            </div>
          </Link>
          <div className="flex mt-[4px]">
            <div className="font-thin ml-4">{getEntityId()}</div>
            <div className="font-thin ml-4">{activeUser?.id}</div>
          </div>
          {/* <Link to="/about" className="ml-auto">
            About
          </Link>
          <Link to="/services" className="ml-4">
            Services
          </Link> */}
          <div className="ml-auto"></div>

          {activeUser && !userIs(SELECT_ROLES.STUDENT) && (
            <div>
              <Link to="/courses" className="">
                Courses
              </Link>
              {activeUser && userIs(SELECT_ROLES.MASTER) && (
                <Link to="/miswadah" className="ml-4">
                  Miswadah
                </Link>
              )}

              <Link to="/stories" className="ml-4">
                Stories
              </Link>
              <Link to="/projects" className="ml-4">
                Projects
              </Link>
            </div>
          )}
          {activeUser && activeUser.studioId != "" && (
            <Link to="/studio" className="ml-4">
              Studio
            </Link>
          )}
          {/* <Link to="/contacts" className="ml-4">
            Contacts
          </Link> */}
          <div className="ml-4">
            {/* {activeUser && (
              <div className="cursor-pointer" onClick={handleSignout}>
                Sign out
              </div>
            )} */}
            {activeUser && (
              <div className="relative">
                <div
                  onClick={() => setShowAcountMenu(true)}
                  className="cursor-pointer min-w-[100px] max-w-[120px] overflow-hidden "
                >
                  {activeUser.displayname}
                </div>
                <div
                  onMouseLeave={() => setShowAcountMenu(false)}
                  className={`absolute ${
                    showAccountMenu ? "" : "hidden"
                  } top-[38px] left-[-16px] bg-black-400 w-[200px] bg-orange-600 rounded backdrop-blur-md px-2 py-2 z-[20]`}
                >
                  {userIs(SELECT_ROLES.MASTER) && (
                    <div className="cursor-pointer hover:bg-white/10 rounded p-1">
                      <div className="flex">
                        role:
                        <div className="ml-2 font-thin">
                          {activeUser.role.value}
                        </div>
                      </div>
                    </div>
                  )}
                  {userIs(SELECT_ROLES.MASTER) && (
                    <Link to="/hexsettings">
                      <div
                        onClick={() => setShowAcountMenu(false)}
                        className="cursor-pointer hover:bg-white/10 rounded p-1"
                      >
                        settings
                      </div>
                    </Link>
                  )}
                  {userIs(SELECT_ROLES.MASTER) && (
                    <div
                      onClick={() => setShowAcountMenu(false)}
                      className="cursor-pointer hover:bg-white/10 rounded p-1"
                    >
                      <Link to="/users">Manage Users</Link>
                    </div>
                  )}
                  {/* <Divider />
                  <div className="cursor-pointer hover:bg-white/10 rounded p-1">
                    menu item
                  </div> */}

                  {!userIs(SELECT_ROLES.STUDENT) && (
                    <div>
                      <Link
                        to={`/myaccount/${activeUser.id}`}
                        onClick={dismiss}
                        className="flex flex-grow cursor-pointer hover:bg-white/10 rounded p-1"
                      >
                        my account
                      </Link>
                    </div>
                  )}

                  <Divider />
                  <div
                    onClick={handleSignout}
                    className="cursor-pointer hover:bg-white/10 rounded p-1"
                  >
                    sign out
                  </div>
                  {userIs(SELECT_ROLES.MASTER) && (
                    <div>
                      <div>{`you are on a ${deviceType}`}</div>
                      {deviceType == "tablet" && (
                        <div>
                          <Divider />
                          <div onClick={handleCloseMenu} className="">
                            <div className="cursor-pointer hover:bg-white/10 rounded p-1">
                              close menu
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {!activeUser && (
              <Link to="/login">
                <div className="min-w-[100px] max-w-[120px] overflow-hidden ">
                  Sign in
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
