import { CanvasContext } from "../context/CanvasContext";
import { useContext } from "react";

export const useCanvasContext = () => {
  const context = useContext(CanvasContext);

  if (!context) {
    throw Error(
      "useCanvasContext must be used inside an CanvasContextProvider"
    );
  }

  return context;
};
