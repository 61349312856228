import { createContext, useContext, useEffect, useState } from "react";
import { useCollection } from "../hooks/useCollection";

// create context
export const CollectionContext = createContext();

// context provider
export const CollectionContextProvider = ({ children }) => {
  const { documents: projects, loading: projectsLoading } =
    useCollection("projects");

  const { documents: studioProjects, loading: studioProjectsLoading } =
    useCollection("studioProjects");
  const { documents: collections } = useCollection("collections");
  const { documents: users, loading: usersLoading } = useCollection("users");
  const { documents: entities } = useCollection("entities");
  const { documents: misClients, loading: misClientLoading } =
    useCollection("misClients");

  return (
    <CollectionContext.Provider
      value={{
        projects,
        projectsLoading,
        collections,
        users,
        usersLoading,
        entities,
        studioProjects,
        studioProjectsLoading,
        misClients,
        misClientLoading,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
