import { MiswadahContext } from "../context/MiswadahContext";
import { useContext } from "react";

export const useMiswadahContext = () => {
  const context = useContext(MiswadahContext);

  if (!context) {
    throw Error(
      "useMiswadahContext must be used inside an MiswadahContextProvider"
    );
  }

  return context;
};
