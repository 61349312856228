import React, { useEffect, useState, useRef } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import ImageA from "../../assets/black/image_a.jpg";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import { toast } from "react-toastify";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Slider from "@mui/material/Slider";
import { motion } from "framer-motion";

import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const SketchOverImage = () => {
  const { height, width } = useWindowDimensions();
  const blackContainer = useRef(null);

  // -----------------------------------------------------------------
  const [history, setHistory] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const [eraseMode, setEraseMode] = useState(false);

  const [canvasStyle, setCanvasStyle] = useState({});
  const [proportionalHeight, setProportionalHeight] = useState(0);
  const [origWidth, setOrigWidth] = useState(0);
  const [origHeight, setOrigHeight] = useState(0);

  // refs
  const canvasContRef = useRef(null);
  const containerRef = useRef(null);
  const canvasRef = useRef(null); // Reference to the React Sketch Canvas
  const imageRef = useRef(null); // Reference to the background image

  const [scaleFactor, setScaleFactor] = useState(1); // Keep track of the scale factor

  // -----------------------------------------------------------------

  let [isFullScreen, setFullScreen] = useState(false);
  // Function to combine the sketch and image and save it
  //   const saveImage = async () => {
  //     const canvas = canvasRef.current;
  //     const image = imageRef.current;

  //     // Create an offscreen canvas to combine the background image and the sketch
  //     const offscreenCanvas = document.createElement("canvas");
  //     offscreenCanvas.width = image.width;
  //     offscreenCanvas.height = image.height;
  //     const ctx = offscreenCanvas.getContext("2d");

  //     // Draw the background image on the offscreen canvas
  //     ctx.drawImage(image, 0, 0, offscreenCanvas.width, offscreenCanvas.height);

  //     // Export the sketch as a PNG and draw it on top of the background image
  //     const sketchDataUrl = await canvas.exportImage("png");
  //     const sketchImage = new Image();
  //     sketchImage.src = sketchDataUrl;
  //     sketchImage.onload = () => {
  //       ctx.drawImage(
  //         sketchImage,
  //         0,
  //         0,
  //         offscreenCanvas.width,
  //         offscreenCanvas.height
  //       );

  //       // Convert the combined canvas to an image and download it
  //       const combinedImageDataUrl = offscreenCanvas.toDataURL("image/png");
  //       const link = document.createElement("a");
  //       link.href = combinedImageDataUrl;
  //       link.download = "sketch-over-image.png";
  //       link.click();
  //     };
  //   };

  const handleKeyDown = (e) => {
    // console.log(e);
    // console.log(e.keyCode);
    if (e.keyCode == "70") {
      setFullScreen(true);
    }
  };

  const [objectType, setObjectType] = useState("cover");

  // -----------------------------------------------------------------

  useEffect(() => {
    // Wait until the image is loaded to get its original width and height
    const image = imageRef.current;

    // // Listen to window resize and fullscreen changes
    // window.addEventListener("resize", handleResize);
    // document.addEventListener("fullscreenchange", handleFullScreenChnage);

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    //   document.removeEventListener("fullscreenchange", handleFullScreenChnage);
    // };
  }, []);

  // ----------------------------------------------------------------

  // ----------------------------------------------------------------

  const calculateProportionalHeight = (image) => {
    const originalWidth = image.naturalWidth;
    const originalHeight = image.naturalHeight;

    const newWidth = 500; // Set the width of the image/canvas as needed
    const proportionalHeight = (originalHeight / originalWidth) * newWidth;

    setProportionalHeight(proportionalHeight);
    setOrigWidth(originalWidth);
    setOrigHeight(originalHeight);
  };

  // -----------------------------------------------------------------

  // Function to save the combined image (background + sketch)
  const saveImage = async () => {
    const canvas = canvasRef.current;
    const image = imageRef.current;

    // Export the sketch as a data URL
    const sketchDataUrl = await canvas.exportImage("png");

    // Create an off-screen canvas to combine the background and the sketch
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = image.naturalWidth;
    offscreenCanvas.height = image.naturalHeight;
    const ctx = offscreenCanvas.getContext("2d");

    // step 1: Draw the background image on the off-screen canvas
    ctx.drawImage(image, 0, 0, offscreenCanvas.width, offscreenCanvas.height);

    // Step 2: Draw the semi-transparent layer
    ctx.fillStyle = `rgba(255, 255, 0, ${value / 100})`; // 50% opacity yellow layer
    ctx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

    // step 3: Draw the sketch on top of the background image
    const sketchImage = new Image();
    sketchImage.src = sketchDataUrl;
    sketchImage.onload = () => {
      ctx.drawImage(
        sketchImage,
        0,
        0,
        offscreenCanvas.width,
        offscreenCanvas.height
      );

      // Convert the combined canvas to a data URL and trigger download
      //   const combinedImageDataUrl = offscreenCanvas.toDataURL("image/png");
      //   const link = document.createElement("a");
      //   link.href = combinedImageDataUrl;
      //   link.download = "sketch-over-image.png";
      //   link.click();

      // Convert the combined canvas to Blob
      const storage = getStorage();
      offscreenCanvas.toBlob(async (blob) => {
        // Create a reference to the Firebase storage location
        const storageRef = ref(storage, `sketches/testing-${Date.now()}.png`);

        // Upload the Blob to Firebase Storage
        await uploadBytes(storageRef, blob).then((snapshot) => {
          console.log("Uploaded the sketch over the image successfully!");

          // Optionally, get the download URL
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            alert(downloadURL);
            console.log("Download URL:", downloadURL);
          });
        });
      }, "image/png");
    };
  };

  // -----------------------------------------------------------------

  // Calculate the canvas size to match the visible portion of the image
  const calculateCanvasSize = (image) => {
    // toast.success("calculating canvas size");
    const container = containerRef.current;
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const imageRatio = image.naturalWidth / image.naturalHeight;
    const containerRatio = containerWidth / containerHeight;

    let renderedWidth, renderedHeight, offsetX, offsetY;

    // Handle object-fit: cover behavior
    if (containerRatio > imageRatio) {
      renderedWidth = containerWidth;
      renderedHeight = containerWidth / imageRatio;
      offsetX = 0;
      offsetY = (containerHeight - renderedHeight) / 2; // Center the image vertically
    } else {
      renderedWidth = containerHeight * imageRatio;
      renderedHeight = containerHeight;
      offsetX = (containerWidth - renderedWidth) / 2; // Center the image horizontally
      offsetY = 0;
    }

    // Set canvas size and position to match the visible image
    setCanvasStyle({
      width: `${renderedWidth}px`,
      height: `${renderedHeight}px`,
      top: `${offsetY}px`,
      left: `${offsetX}px`,
    });
  };

  // -----------------------------------------------------------------

  const handleResize = () => {
    if (imageRef.current) {
      calculateCanvasSize(imageRef.current);
    }
  };

  const handleFullScreenChnage = (isFull) => {
    // handleResize();
    setFullScreen(isFull);
  };

  const [value, setValue] = React.useState(30);
  const handleTransChange = (e, newVal) => {
    setValue(newVal);
  };

  const handleSave = async () => {
    // const dataURL = await canvasRef.current.exportImage("png");
    // const updatedHistory = [...history.slice(0, currentStep + 1), dataURL];
    // setHistory(updatedHistory);
    // setCurrentStep(updatedHistory.length - 1);
  };

  const handleRedo = () => {
    canvasRef.current?.redo();
  };

  const handleClear = () => {
    canvasRef.current?.clearCanvas();
  };

  const handleEraserClick = () => {
    setEraseMode(true);
    canvasRef.current?.eraseMode(true);
  };

  const handlePenClick = () => {
    setEraseMode(false);
    canvasRef.current?.eraseMode(false);
  };

  const handleUndo = () => {
    canvasRef.current?.undo();
  };
  const [scale, setScale] = useState(1);
  const handleWheel = (event) => {
    // Update the scale based on the wheel direction
    setScale((prevScale) => {
      const newScale = prevScale + event.deltaY * -0.001; // Adjust scaling sensitivity
      return Math.max(0.5, Math.min(newScale, 3)); // Restrict scaling between 0.5 and 3
    });
    setScaleFactor(scale);
  };

  const getAdjustedCoords = (event) => {
    const boundingRect = canvasRef.current.getBoundingClientRect();
    // Adjust the coordinates based on the scaling factor
    const x = (event.clientX - boundingRect.left) / scaleFactor;
    const y = (event.clientY - boundingRect.top) / scaleFactor;
    return { x, y };
  };

  useEffect(() => {
    if (canvasContRef.current) {
      // Query for the canvas inside the wrapper
      const canvasElement = canvasContRef.current.querySelector("canvas");

      console.log("from effect ++++++++++++++++++++++++++++++++++++++");
      console.log(canvasElement);
      if (canvasElement) {
        const boundingRect = canvasElement.getBoundingClientRect();
        console.log("Bounding Rect:", boundingRect);
      }
    }
  }, [isFullScreen]);

  const handlePointerDown = (event) => {
    if (canvasRef.current) {
      console.log("down event ################### ");
      console.log(canvasContRef.current.getBoundingClientRect());
      console.log(canvasRef.current.getBoundingClientRect());

      const boundingRect = canvasContRef.current.getBoundingClientRect();
      const x = (event.clientX - boundingRect.left) / scaleFactor; // Adjust for scale
      const y = (event.clientY - boundingRect.top) / scaleFactor; // Adjust for scale
      canvasRef.current.x = x;
      canvasRef.current.y = y;
    }
  };

  const handlePointerMove = (event) => {
    if (canvasRef.current) {
      const boundingRect = canvasContRef.current.getBoundingClientRect();
      const x = (event.clientX - boundingRect.left) / scaleFactor; // Adjust for scale
      const y = (event.clientY - boundingRect.top) / scaleFactor; // Adjust for scale
      canvasRef.current.x = x;
      canvasRef.current.y = y;
    }
    // if (canvasRef.current) {
    //   console.log("move event");
    //   // Only when mouse is pressed
    //   const boundingRect = canvasRef.current.getBoundingClientRect();
    //   const x = (event.clientX - boundingRect.left) / scaleFactor; // Adjust for scale
    //   const y = (event.clientY - boundingRect.top) / scaleFactor; // Adjust for scale
    //   canvasRef.current.addPoint({ x, y });
    // }
  };

  const handleOnstroke = (str) => {};

  return (
    <div
      className="min-h-[600px] bg-red-300"
      style={{
        position: "relative",
        width: width,
        height: height,
      }}
    >
      {/* Sketch Canvas Overlay */}
      <FullScreen isFullScreen={isFullScreen} onChange={handleFullScreenChnage}>
        <motion.div
          onWheel={handleWheel}
          tabIndex="-1"
          ref={containerRef}
          onKeyDown={(e) => handleKeyDown(e)}
          style={{
            width: "100%",
            height: height,
          }}
          initial={{ scale: 1 }} // Initial scale
          animate={{ scale }}
          transition={{ duration: 0.5 }} // Add a smooth transition
        >
          {/* Background Image */}
          {!isFullScreen && (
            <img
              ref={imageRef}
              draggable="false"
              src={ImageA}
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                zIndex: 1,
                objectFit: "cover",
                pointerEvents: "none",
              }}
            />
          )}

          {isFullScreen && (
            <>
              <img
                ref={imageRef}
                draggable="false"
                src={ImageA}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: `rgba(255, 255, 0, ${value / 100})`, // 50% opacity white background
                  zIndex: 2,
                  pointerEvents: "none",
                }}
              />
              <div
                ref={canvasContRef}
                style={{ height: "100%" }}
                onPointerDown={handlePointerDown}
                onMouseMove={handlePointerMove}
              >
                <ReactSketchCanvas
                  ref={canvasRef}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 3,
                  }}
                  canvasColor="transparent"
                  strokeWidth={15}
                  strokeColor="black"
                />
              </div>
            </>
          )}
        </motion.div>

        {/* Controls Button */}
        {isFullScreen && (
          <div style={{ position: "absolute", top: 25, left: 25, zIndex: 4 }}>
            <button
              onClick={saveImage}
              className="bg-blue-600 px-4 py-2 rounded"
            >
              Save Image
            </button>
            <div className="flex gap-1 mt-26">
              <button
                onClick={handlePenClick}
                className="bg-blue-600 px-4 py-2 rounded"
              >
                draw
              </button>
              <button
                onClick={handleEraserClick}
                className="bg-blue-600 px-4 py-2 rounded"
              >
                erease
              </button>
              <button
                onClick={handleUndo}
                className="bg-blue-600 px-4 py-2 rounded"
              >
                undo
              </button>
              <button
                onClick={handleRedo}
                className="bg-blue-600 px-4 py-2 rounded"
              >
                redo
              </button>
              <button
                onClick={handleClear}
                className="bg-blue-600 px-4 py-2 rounded"
              >
                clear
              </button>
            </div>
            <Slider
              className="mt-2"
              aria-label="Volume"
              value={value}
              onChange={handleTransChange}
            />
          </div>
        )}
      </FullScreen>
    </div>
  );
};

export default SketchOverImage;
