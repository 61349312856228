export const start = `
<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.97 133.01">
  <defs>
    <style>
      .cls-1 {
        fill: #fbb040;
        stroke: #231f20;
        stroke-miterlimit: 10;
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-1" d="M77.04,5.99l12.62,38.85h40.85c7.69,0,10.89,9.85,4.67,14.37l-33.05,24.01,12.62,38.85c2.38,7.32-6,13.4-12.22,8.88l-33.05-24.01-33.05,24.01c-6.22,4.52-14.6-1.56-12.22-8.88l12.62-38.85L3.79,59.21c-6.22-4.52-3.03-14.37,4.67-14.37h40.85S61.93,5.99,61.93,5.99c2.38-7.32,12.73-7.32,15.11,0Z"/>
  </g>
</svg>
`;

export const StarSVG = ({ size }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: start }}
      style={{ width: size, height: size }} // Adjust size as needed
    />
  );
};
