import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";

class MisLawsuit {
  id = ""; // generated by firebase on adding object to firestore
  name = "";
  number = "";
  date = new Date();
  documents = [];

  constructor(_id = uuid()) {
    this.id = _id;
  }

  blankId() {
    delete this.id; // remove the id when first initiating so we can allow firebase to install its document id
    return this;
  }

  static fromData = (data) => {
    const p = new MisLawsuit(data["id"]);
    p.name = data["name"];
    p.number = data["number"];
    p.date = data["date"];
    p.documents = data["documents"];

    return p;
  };

  data = () => {
    return {
      ...(this.id !== undefined && { id: this.id }),
      number: this.number,
      name: this.name,
      date: this.date,
      documents: this.documents,
    };
  };

  addDocument = (document) => {
    this.documents.push(document);
  };

  getDocumentWithId = (docId) => {
    return this.documents.find((doc) => doc.id === docId);
  };
}
export default MisLawsuit;
