import React, { useEffect, useState } from "react";
import { Reorder } from "framer-motion";
import { uuidv4 as uuid } from "@firebase/util";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { toast } from "react-toastify";
import Collection from "../../models/Collection";

export default function ColProList({ collection, projects }) {
  const { projects: pros } = useCollectionContext();
  const [items, setItems] = useState([]);
  const [proList, setProList] = useState([]);

  const fetchProjectForId = (proId) => {
    return pros.filter((project) => project.id == proId)[0];
  };

  const projectForId = (proId) => {
    return proList.filter((pro) => pro.id == proId)[0];
  };

  const projectName = (proId) => {
    const p = projectForId(proId);
    return p.name;
  };

  useEffect(() => {
    if (projects) {
      const list = projects.map((pro) => {
        return {
          project: pro,
          id: uuid(),
        };
      });
      // pull the projects for ids
      const pl = projects.map((proId) => fetchProjectForId(proId));

      // set lists
      setProList(pl);
      setItems(list);
    }
  }, [projects, pros]);

  const handleDelete = async (proId) => {
    if (window.confirm("delete proejct")) {
      const xCol = Collection.fromData(collection);
      xCol.removeProjectId(proId);
      await xCol.commit().then((ref) => {
        toast.success("delete");
      });
    }
  };

  const handleUpdate = async () => {
    const mlist = items.map((item) => item.project);
    const xCol = Collection.fromData(collection);
    xCol.updateProjects(mlist);
    await xCol.commit().then((ref) => {
      toast.success("order updated");
    });
  };

  // ----------------------------------------------------
  // ----------------------------------------------------

  const [isDragging, setIsDragging] = useState(false);

  const resolveReorder = (newItems) => {
    setItems(newItems);
  };

  // on start drag
  const handleDragStart = (e) => {
    setIsDragging(true);
    // toast.success("dragging started");
  };

  // on end drag
  const handleDragEnd = (e) => {
    setIsDragging(false);
    // resolveListOrder(items);
    // toast.success("dragging ended");
  };

  // on mouse up
  const handleMouseUp = (e, url) => {
    // do not fire if image is dragging

    e.stopPropagation();

    if (isDragging) {
      // image is dragging don't fire the mouse up actions
      return;
    }

    if (window.confirm("delete image")) {
      handleDelete(url);
    }
  };

  // ----------------------------------------------------
  // ----------------------------------------------------

  return (
    <>
      <div onClick={handleUpdate} className="cursor-pointer">
        update
      </div>
      <Reorder.Group values={items} onReorder={resolveReorder}>
        {items.map((item) => (
          <Reorder.Item
            onDragEnd={(e) => handleDragEnd(e)}
            onDragStart={(e) => handleDragStart(e)}
            onMouseUpCapture={(e) => handleMouseUp(e, item)}
            key={item.id}
            value={item}
          >
            <div
              onMouseUp={() => handleDelete(item.project)}
              className="flex overflow-clip items-center justify-center bg-gray-300 w-[300px] h-[60px]
        rounded-md border-[1px] border-sky-900"
            >
              <div>{projectName(item.project)}</div>
            </div>
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </>
  );
}
