import React from "react";
import { type ReactNode } from "react";

type HeaderProps = {
  image: {
    src: string;
    alt: string;
  };
  children: ReactNode;
};

export default function GoalsHeader({ image, children }: HeaderProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={image.src} alt={image.alt} width={100} />
      {children}
    </div>
  );
}
