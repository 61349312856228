import React from "react";
import ButtonComp from "./ButtonComp";

export default function InputPro() {
  return (
    <div className="min-h-[400px]">
      <div className="flex flex-col">
        <ButtonComp el="button">A First Button</ButtonComp>
        <ButtonComp el="anchor" href="https://google.com">
          A Second Button
        </ButtonComp>
      </div>
    </div>
  );
}
