import React from "react";
import CanvasWithImage from "./archive/CanvasWithImage";
import CanvasWithObjectFitCover from "./archive/CanvasWithObjectFitCover";
import CanvasBase from "./archive/CanvasBase";
import CanvasScale from "./archive/CanvasScale";
import CanvasScaleWithFullScreen from "./archive/CanvasScaleWithFullScreen";
import CanvasScalingByTransform from "./archive/CanvasScalingByTransform";
import CanvasScalingByTransform_saving from "./archive/CanvasScalingByTransform_saving";
import CanvasScaling_Center_of_Mouse from "./archive/CanvasScaling_Center_of_Mouse";
import CanvasScaleWithSaveImage from "./HexCanvas";
import MultiCanvasBroken from "./archive/MultiCanvasBroken";
import HexCanvas from "./HexCanvas";

export default function CanvasPlayground() {
  return (
    <div>
      <HexCanvas />
    </div>
  );
}
