import { StudioContext } from "../context/StudioContext";
import { useContext } from "react";

export const useStudioContext = () => {
  const context = useContext(StudioContext);

  if (!context) {
    throw Error(
      "useStudioContext must be used inside an StudioContextProvider"
    );
  }

  return context;
};
