import React, { useEffect, useState } from "react";

import UploadImageButton from "../../../components/UploadImage";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { useUploadDocument } from "../../../hooks/useUploadDocument";
import ImageFormRaw from "./RevisionImageTile";
import HexRevisionRow from "./HexRevisionRow";
import Project from "../../../models/Project";
import HexRevList from "../HexRevList";

export default function HexManageRevisionForm({
  project,
  dismiss,
  activeRevId,
  resolveActiveRev,
}) {
  const { uploadImage } = useUploadDocument();
  const [activeRev, setActiveRev] = useState("");

  // handle upload
  const [data, setData] = useState({});
  // I rather call this handleFileSelection
  const handleUpload = (e) => {
    const file = e.target.files[0];
    const d = { ...data, file: file };
    setData(d);
  };

  const handleAddRevision = async () => {
    const p = Project.fromData(project);
    p.addNewRevision();
    const d = p.data();
    const docRef = doc(db, "projects", project.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      toast.success("revision add");
    });
  };

  // const handleAddImage = async () => {
  //   // validate image
  //   if (data.file == undefined || data.file == "") {
  //     toast.error("please select an image");
  //     return;
  //   }

  //   const file = data.file;
  //   await uploadImage(file, "hexpo").then(async (url) => {
  //     // add url to project images
  //     console.log(url);
  //     toast.success(url);

  //     const d = {
  //       ...project,
  //       images: [...(project.images ?? []), url],
  //     };

  //     // update doc
  //     const docRef = doc(db, "projects", project.id);
  //     await setDoc(docRef, d, { merge: true }).then((docRef) => {
  //       dismiss();
  //       toast.success("done");
  //     });
  //   });
  // };

  return (
    <div>
      <div className="flex flex-col gap-1">
        {project &&
          project.revisions.map((revision, index) => (
            <div key={index}>
              <HexRevisionRow
                project={project}
                activeRevId={activeRevId}
                resolveActiveRev={resolveActiveRev}
                revision={revision}
              />
            </div>
          ))}
      </div>
      {/* <UploadImageButton callBack={handleUpload} /> */}
      <div className="flex items-center bg-blue-300 h-[40px] cursor-pointer">
        <div onClick={handleAddRevision} className="ml-2">
          add revision
        </div>
      </div>
      {/* <div className="flex items-center bg-orange-300 h-[40px] cursor-pointer">
        <div onClick={handleAddImage} className="ml-2">
          save
        </div>
      </div> */}
      <div
        className="flex items-center bg-gray-400 h-[40px] cursor-pointer"
        onClick={() => dismiss()}
      >
        <div className="ml-2">close</div>
      </div>
    </div>
  );
}
