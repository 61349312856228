import React, { useState } from "react";
import { toast } from "react-toastify";
import CollectionButton from "./CollectonButton";

export default function ProjectCollections({ children, selected, onChange }) {
  // const [selected, setSelected] = useState(buttonData[0].value);

  return (
    <div className="bg-zinc-400">
      <div className="flex gap-1">
        {children.map((child, i) => {
          // child.props = { ...child.props, selected: selected };
          // child.props = { ...child.props, index: i };
          return (
            <div key={i} onClick={(e) => onChange(child.props.index)}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}

// const TButton = ({ title, value, onChange }) => {
//   return (
//     <div
//       onClick={() => onChange(value)}
//       className="bg-orange-300 px-4 py-1 rounded cursor-pointer"
//     >
//       <div>{title}</div>
//     </div>
//   );
// };
