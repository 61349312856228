import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  redirect,
} from "react-router-dom";

import "./pages/projects/Projects.css";

// adding a comment to test commit
import Navbar from "./components/navbar/Navbar";
import Login from "./pages/login/Login";

import TemCanvas from "./pages/nigta/components/canvas/TemCanvas";

import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import Contacts from "./pages/contacts/Contacts";
import Projects from "./pages/projects/Projects";
import Goals from "./pages/goals/Goals";

import HexRevList from "./pages/hex/HexRevList";
import Footer from "./components/navbar/Footer";
import Framer from "./pages/Framer";

import logo from "./assets/playground/logo-inverted.png";
import bgImage from "./assets/playground/hero.jpg";
import heroLogo from "./assets/playground/logo.png";
//tostify
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./pages/signup/Signup";
import ProjectHex from "./pages/hex/ProjectHex";
import { useEffect, useRef, useState } from "react";
import Landing from "./pages/playground/Landing";
import { keyframes } from "@emotion/react";
import InputPro from "./pages/inputpro/InputPro";
import CollectionHex from "./pages/hex/CollectionHex";
import UsersDash from "./pages/users/UsersDash";
import UserPage from "./pages/users/UserPage";
import SketchPage from "./pages/sketch/SketchPage";
import SketchOverImage_2 from "./pages/sketch/SketchOverImage_2";
import Canvas from "./pages/canvas/archive/Canvas";
import CanvasPlayground from "./pages/canvas/CanvasPlayground";
import Lassoplay from "./pages/lasso/Lassoplay";
import PDFCanvasplay from "./pages/pdf/PDFCanvasplay";
import { useCanvasContext } from "./hooks/useCanvasContext";
import HexCanvas from "./pages/canvas/HexCanvas";
import HexSettings from "./pages/settings/HexSettings";
import HexEntityPage from "./pages/settings/HexEntityPage";
import ProtectedRoute from "./utilities/ProtectedRout";
import Enums from "./models/Enums";
import { auth } from "./firebase/config";
import { signInWithCustomToken } from "firebase/auth";
import AuthenticaedRout from "./utilities/AuthenticaedRout";
import MyAccountPage from "./pages/account/MyAccountPage";
import { useAuthContext } from "./hooks/useAuthContext";
import DrawingsPage from "./pages/drawings/DrawingsPage";
import StudioPage from "./pages/studio/StudioPage";
import StudioProjectPage from "./pages/studio/StudioProjectPage";
import StoriesPage from "./pages/stories/StoriesPage";
import CoursesPage from "./pages/courses/CoursesPage";
import MiswadahPage from "./pages/miswadah/MiswadahPage";
import ClientPage from "./pages/client/ClientPage";
import LawsuitPage from "./pages/lawsuit/LawsuitPage";
import LawsuitDocumentPage from "./pages/lawsuit/LawsuitDocumentPage";
import NigtaPage from "./pages/nigta/NigtaPage";
import NigCanvas from "./pages/nigta/components/canvas/NigCanvas";
import BuildTheWordPage from "./pages/nigta/BuildTheWordPage";
import MemoBlockPage from "./pages/nigta/MemoBlockPage";

function App() {
  const { showNav, setShowNav } = useCanvasContext();
  const { authIsReady } = useAuthContext();

  // playgrounds
  const [playground, setPlayground] = useState(false);
  const [blue, setBlue] = useState(false);

  const [menuState, setMenuState] = useState(false);
  const [showTickets, setShowTickets] = useState(false);

  const handleMenuClick = () => {
    setMenuState(!menuState);
  };

  const HandleShowTickets = () => {
    setShowTickets(!showTickets);
  };

  if (playground) {
    return (
      <div>
        <nav className="bg-gradient-to-r flex from-rose-500 to-pink-500 sticky top-0 text-zinc-200">
          <div className="flex items-center p-2 gap-2">
            <img src={logo} width="50" />
            <div className="font-bold text-2xl">
              tw:<span className="text-sky-900">mf</span>
            </div>
          </div>
          <div className="block md:hidden ml-auto pr-4 my-auto cursor-pointer">
            <div
              onClick={handleMenuClick}
              className={`group peer ${menuState ? "open" : ""}`}
            >
              <div className="top-0 bg-zinc-200 rounded-full w-8 h-1 group-open:rotate-45 transition-all group-open:top-2 relative"></div>
              <div className="bg-zinc-200 opacity-100 rounded-full w-8 h-1 mt-1 group-open:opacity-0 transition-all"></div>
              <div className="top-0 bg-zinc-200 rounded-full w-8 h-1 mt-1 group-open:-rotate-45 transition-all group-open:-top-2 relative"></div>
            </div>
            <div className="absolute top-[62px] left-0 bg-gradient-to-r from-rose-500 to-pink-500 w-full peer-open:block hidden">
              <div className="realtive flex justify-center h-full cursor-point items-center p-2 font-bold text-pink-200 hover:bg-white/10 transition-colors ease-in-out">
                <span>home</span>
              </div>
              <div className="realtive flex justify-center h-full cursor-point items-center p-2 font-bold text-pink-200 hover:bg-white/10 transition-colors ease-in-out">
                <span>lineup</span>
              </div>

              <div className="group realtive h-full cursor-point text-pink-200 hover:bg-white/10 transition-colors ease-in-out">
                <div
                  onClick={HandleShowTickets}
                  className="p-2 text-center font-bold"
                >
                  tickets
                </div>

                <div className={`${!showTickets ? "hidden" : ""}`}>
                  <div className="text-center p-2 relative text-pink-200 hover:text-zinc-200 hover:bg-white/5 transition-color ease-in-out">
                    <span>single day ticket</span>
                  </div>
                  <div className="text-center p-2 relative text-pink-200 hover:text-zinc-200 hover:bg-white/5 transition-color ease-in-out">
                    <span>7 day ticket</span>
                  </div>
                </div>
              </div>

              <div className="realtive flex justify-center h-full cursor-point items-center p-2 font-bold text-pink-200 hover:bg-white/10 transition-colors ease-in-out">
                <span>support +</span>
              </div>
            </div>
          </div>

          <div className="hidden md:flex flex-1 items-center justify-end">
            <div className="menu-item">
              <span>home</span>
            </div>
            <div className="menu-item">
              <span>lineup</span>
            </div>
            <div className="menu-item group">
              <span>tickets</span>
              <div className="hidden group-hover:block absolute top-full bg-pink-500 left-0 whitespace-nowrap rounded-b min-w-[100px]">
                <div className="p-4 font-bold hover:bg-white/5 hover:text-zinc-200 transition-colors ease-in-out cursor-pointer text-pink-200">
                  price
                </div>
                <div className="p-4 font-bold hover:bg-white/5 hover:text-zinc-200 transition-colors ease-in-out cursor-pointer text-pink-200">
                  class
                </div>
              </div>
            </div>
            <div className="menu-item">
              <span>support</span>
            </div>
          </div>
        </nav>

        <div
          style={{ backgroundImage: `url(${bgImage})` }}
          className="bg-cover bg-center bg-fixed min-h-[400px] flex flex-col items-center justify-center h-[calc(100vh-200px)]"
        >
          <div
            className="bg-white/30 py-2 px-4 rounded-xl text-center 
          backdrop-blur-md"
          >
            <div className="relative">
              <img src={heroLogo} width="175" />
              <div className="flex items-center justify-center mt-[-70px] mb-5">
                <div
                  className="bg-pink-500 w-14 h-14 rounded-full flex items-center 
                justify-center gap-1"
                >
                  <div className="animation-delay-[50ms] h-2 w-1 bg-pink-300 rounded-full animate-wavey"></div>
                  <div className="animation-delay-200 h-3 w-1 bg-pink-200 rounded-full animate-wavey"></div>
                  <div className="animation-delay-300 h-4 w-1 bg-pink-100 rounded-full animate-wavey"></div>
                  <div className="animation-delay-[75ms] h-3 w-1 bg-pink-200 rounded-full animate-wavey"></div>
                  <div className="animation-delay-200 h-2 w-1 bg-pink-300 rounded-full animate-wavey"></div>
                </div>
              </div>
            </div>
            <div className="text-5xl font-bold">
              tw:<span className="text-sky-500">mf</span>
            </div>
          </div>
          <div className="font-bold mt-3 text-white">
            keep me updated with news and promos
          </div>
          <form className="mt-3 flex gap-2">
            <input
              type="email"
              placeholder="email"
              className="rounded-sm border border-white/40 bg-white/30 backdrop-blur-md p-2 font-bold text-sky-900 placeholder-zinc-500 caret-pink-500 outline-pink-500"
            />
            <button
              type="submit"
              className="cursor-pointer text-white rounded-sm bg-pink-500 py-2 px-4 font-bold 
            transition-colors hover:bg-sky-900 hover:shadow-lg hover:shadow-black/20
            "
            >
              subscribe
            </button>
          </form>
        </div>
        <div>
          <div className="max-w-screen-lg mx-auto mt-20">
            <h2>Headliners</h2>
            <p>
              This is a pharagraph highlighting the best concerts and how to
              make the reservation. It is the best way to reach the information
              about your favorit band and all their activities. For more
              information, you could reach the team on the following links.
            </p>

            {/* carrosel */}

            <div className="flex">
              <div>previous</div>
              <div
                id="carasel"
                className="mt-4 scroll-smooth flex flex-1 gap-4 w-full pb-5 snap-x overflow-x-auto"
              >
                <div className="min-w-[80%] md:min-w-[40%] snap-center transition-all ease-in-out">
                  <div
                    style={{ backgroundImage: `url(${bgImage})` }}
                    className="h-80 w-full rounded-xl bg-cover bg-center
                shadow-md shadow-black/20"
                  ></div>
                  <h3 className="">artist name</h3>
                  <h4 className="">a brief about the artist</h4>
                </div>
                <div className="min-w-[80%] md:min-w-[40%]">
                  <div
                    style={{ backgroundImage: `url(${bgImage})` }}
                    className="h-80 w-full snap-center rounded-xl bg-cover bg-center
                shadow-md shadow-black/20"
                  ></div>
                  <h3 className="">artist name</h3>
                  <h4 className="">a brief about the artist</h4>
                </div>
                <div className="min-w-[80%] md:min-w-[40%]">
                  <div
                    style={{ backgroundImage: `url(${bgImage})` }}
                    className="h-80 w-full snap-center rounded-xl bg-cover bg-center
                shadow-md shadow-black/20"
                  ></div>
                  <h3 className="">artist name</h3>
                  <h4 className="">a brief about the artist</h4>
                </div>
                <div className="min-w-[80%] md:min-w-[40%]">
                  <div
                    style={{ backgroundImage: `url(${bgImage})` }}
                    className="h-80 w-full snap-center rounded-xl bg-cover bg-center
                shadow-md shadow-black/20"
                  ></div>
                  <h3 className="">artist name</h3>
                  <h4 className="">a brief about the artist</h4>
                </div>
                <div className="min-w-[80%] md:min-w-[40%]">
                  <div
                    style={{ backgroundImage: `url(${bgImage})` }}
                    className="h-80 w-full snap-center rounded-xl bg-cover bg-center
                shadow-md shadow-black/20"
                  ></div>
                  <h3 className="">artist name</h3>
                  <h4 className="">a brief about the artist</h4>
                </div>
              </div>
              <div>next</div>
            </div>
          </div>
        </div>
        {/* <Landing /> */}
      </div>
    );
  }

  if (blue) {
    return (
      <div className="flex flex-col gap-2 items-center justify-center mt-[400px]">
        <div className="bg-blue-400 size-[300px] rounded-lg"></div>
        <div className="bg-blue-400 size-[300px] rounded-lg"></div>
        <div className="bg-blue-400 size-[300px] rounded-lg"></div>
        <div className="bg-blue-400 size-[300px] rounded-lg"></div>
        <div className="bg-blue-400 size-[300px] rounded-lg"></div>
        <div className="bg-blue-400 size-[300px] rounded-lg"></div>
        <div className="bg-blue-400 size-[300px] rounded-lg"></div>
      </div>
    );
  }
  return (
    <div className="App hide-scrollbar">
      <BrowserRouter>
        {showNav && (
          <div className="sticky top-0 z-[11]">
            <Navbar />
          </div>
        )}
        <Routes>
          <Route path="/" element={<Home />} />;
          <Route path="/about" element={<About />} />;
          <Route path="/services" element={<Services />} />;
          <Route path="/courses" element={<CoursesPage />} />;
          <Route path="/stories" element={<StoriesPage />} />;
          <Route
            path="/projects"
            element={
              <ProtectedRoute authIsReady={authIsReady}>
                <Projects />
              </ProtectedRoute>
            }
          />
          ;
          <Route
            path="/drawings"
            element={
              <ProtectedRoute requiredRole={Enums.MAStER}>
                <DrawingsPage />
              </ProtectedRoute>
            }
          />
          ;
          <Route path="/contacts" element={<Contacts />} />;
          <Route path="/framer" element={<Framer />} />;
          <Route path="/signup" element={<Signup />} />;
          <Route path="/login" element={<Login />} />
          <Route path="/goals" element={<Goals />} />
          <Route path="/inputpro" element={<InputPro />} />
          <Route path="/collection/:id" element={<CollectionHex />} />
          <Route path="/users" element={<UsersDash />} />
          <Route path="/sketch" element={<SketchPage />} />
          <Route path="/canvas" element={<Canvas />} />
          <Route path="/canvasplay" element={<CanvasPlayground />} />
          <Route path="/sketch2" element={<SketchOverImage_2 />} />
          <Route path="/pdfcanvasplay" element={<PDFCanvasplay />} />
          <Route path="/project/:id" element={<ProjectHex />} />
          <Route path="/canvas/:id/:proId/:studio" element={<HexCanvas />} />
          <Route path="/lassoplay" element={<Lassoplay />} />
          <Route path="/studio" element={<StudioPage />} />
          <Route path="/nigta" element={<NigtaPage />} />
          <Route path="/memoblock" element={<MemoBlockPage />} />
          <Route path="/nigCanvas/:id" element={<NigCanvas />} />
          <Route path="/tmpCanvas" element={<TemCanvas />} />
          <Route path="/buildTheWord" element={<BuildTheWordPage />} />
          <Route path="/user/:id" element={<UserPage />} />
          <Route path="/client/:id" element={<ClientPage />} />
          <Route
            path="/lawsuitDocument/:clientId/:lawsuitId/:docId"
            element={<LawsuitDocumentPage />}
          />
          <Route
            path="/lawsuit/:clientId/:lawsuitId"
            element={<LawsuitPage />}
          />
          <Route
            path="/miswadah"
            element={
              <AuthenticaedRout authIsReady={authIsReady}>
                <MiswadahPage />
              </AuthenticaedRout>
            }
          />
          <Route
            path="/studioProject/:id"
            element={
              <AuthenticaedRout authIsReady={authIsReady}>
                <StudioProjectPage />
              </AuthenticaedRout>
            }
          />
          <Route
            path="/myaccount/:id"
            element={
              <AuthenticaedRout authIsReady={authIsReady}>
                <MyAccountPage />
              </AuthenticaedRout>
            }
          />
          <Route
            path="/hexsettings"
            element={
              <ProtectedRoute requiredRole={Enums.MAStER}>
                <HexSettings />
              </ProtectedRoute>
            }
          />
          <Route path="/entity/:id" element={<HexEntityPage />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
}

export default App;
