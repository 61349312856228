import { uuidv4 as uuid } from "@firebase/util";
import Revision from "./Revision";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { useCollectionContext } from "../hooks/useCollectionContext";
import Project from "./Project";
import CrumbTitle from "../models/CrumbTitle";

class Collection {
  id = ""; // generated by firebase on adding object to firestore
  name = "";
  number = "";
  color = "";
  isReady = false;
  date = null;
  url = "";
  cover = "";
  projects = [];

  constructor(_id = uuid()) {
    this.id = _id;
  }

  static fromData = (data) => {
    const p = new Collection(data["id"]);
    p.number = data["number"];
    p.name = data["name"];

    p.color = data["color"];
    p.isReady = data["isReady"];
    p.date = data["date"];
    p.url = data["url"];
    p.cover = data["cover"];
    p.projects = data["projects"];
    return p;
  };

  data = () => {
    return {
      id: this.id,
      number: this.number,
      name: this.name,
      color: this.color,
      isReady: this.isReady,
      date: this.date,
      url: this.url,
      cover: this.cover,
      projects: this.projects,
    };
  };

  dateString = () => {
    return new Date(this.date.seconds * 1000).toDateString();
  };

  removeProjectId = (projectId) => {
    this.projects = this.projects.filter((pId) => pId != projectId);
  };

  updateProjects = (list) => {
    this.projects = [...list];
  };

  stageImages = (projectsData) => {
    console.log(projectsData);
    let images = [];
    let sentinals = [];
    let titles = [];
    let start = 0;
    this.projects?.forEach((proId, index) => {
      const proj = projectsData.filter((project) => project.id == proId)[0];
      const xPro = Project.fromData(proj);
      const imgs = xPro.stageImages();

      // images
      images = [...images, ...imgs];

      // sentinals
      sentinals.push(images.length);

      // titles param
      const end = images.length - 1;
      const crumbTitle = proj.name;
      const id = proj.id;
      // title
      const t = new CrumbTitle(start, end, crumbTitle, id);
      titles.push(t);
      start = images.length;
    });

    console.log("titles ___________________________________________");
    console.log(titles);

    return { imgs: images, sents: sentinals, titles: titles };
  };

  commit = () => {
    const d = this.data();

    const docRef = doc(db, "collections", this.id);
    return setDoc(docRef, d, { merge: true });
  };
}

export default Collection;
