import React, { useEffect, useState } from "react";
import * as pdfjs from "pdfjs-dist/webpack";
import { toast } from "react-toastify";

export default function PDFToImages({ pdfUrl }) {
  const [imagesArray, setImagesArray] = useState([]); // To store images of each page
  const [loading, setLoading] = useState(true); // To track loading state

  useEffect(() => {
    const loadPdfAndExtractImages = async () => {
      setLoading(true); // Start loading state

      try {
        // Load PDF document from URL
        const pdf = await pdfjs.getDocument(pdfUrl).promise;

        // Array to hold images of each page
        const tempImagesArray = [];

        // Loop through each page and render it as an image
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1.5 }); // Set scale to adjust resolution

          // Create a temporary canvas to render the page
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          // Render the page into the canvas context
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;

          // Convert canvas to an image data URL and store it in the array
          const image = canvas.toDataURL("image/png");
          tempImagesArray.push(image);
        }

        // Once all pages are processed, update state with the images array
        setImagesArray(tempImagesArray);
      } catch (error) {
        console.error("Error loading or rendering PDF:", error);
      }
      toast.success("loading complete");
      setLoading(false); // End loading state
    };

    if (pdfUrl) {
      loadPdfAndExtractImages();
    }
  }, [pdfUrl]);

  return (
    <div>
      {loading && <p>Loading PDF...</p>}

      {!loading && imagesArray.length > 0 && (
        <div>
          {imagesArray.map((image, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <img
                src={image}
                alt={`Page ${index + 1}`}
                style={{ width: "100%", height: "auto" }}
              />
              <p>Page {index + 1}</p>
            </div>
          ))}
        </div>
      )}

      {!loading && imagesArray.length === 0 && <p>No images available</p>}
    </div>
  );
}
