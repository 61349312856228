import { createContext, useReducer, useEffect, useState } from "react";
import { auth } from "../firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Enums, { Roles } from "../models/Enums";
import { useDocument } from "../hooks/useDocument";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "AUTH_IS_READY":
      return { ...state, user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

// process to generate accounts
// 1. create a basic account. this account will have no office id
// 2. ask to upgrade to office. it will assign an office id to admin
// 3. admin can generate new accounts with office id
// 4. users can add office idea to connect to office. this has to be approved by admin.

const roles = ["admin"]; // This could be dynamic based on user role

export const AuthContextProvider = ({ children }) => {
  console.log("mounting -----------------------------------");
  const [activeUser, setActiveUser] = useState(null);
  const [officeId, setOfficeId] = useState(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
      return unsub;
    });
  }, []);

  // load, on mount
  // useEffect(() => {
  //   console.log("mounting fromt effect -----------------------------------");
  //   const activeUser_json = window.sessionStorage.getItem("activeUser");
  //   const activeUserObject = JSON.parse(activeUser_json);
  //   setActiveUser(activeUserObject);
  // }, []);

  // save, on value change / update
  // useEffect(() => {
  //   window.sessionStorage.setItem("activeUser", JSON.stringify(activeUser));
  // }, [activeUser]);

  const [role, setRole] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [autIsReady, setAuthIsReady] = useState(true);

  // load, on mount
  useEffect(() => {
    setAuthIsReady(false);
    const activeUeer_json = window.sessionStorage.getItem("activeUserFromAuth");
    const activeUser_Object = JSON.parse(activeUeer_json);
    setActiveUser(activeUser_Object);
    // 2. role
    const role_json = window.sessionStorage.getItem("role");
    const role_Object = JSON.parse(role_json);
    setRole(role_Object);
    // 3. permissions
    const perm_json = window.sessionStorage.getItem("perm");
    const perm_Object = JSON.parse(perm_json);
    setPermissions(perm_Object);
    setAuthIsReady(true);
  }, []);

  // save activeUser
  useEffect(() => {
    window.sessionStorage.setItem(
      "activeUserFromAuth",
      JSON.stringify(activeUser)
    );
  }, [activeUser]);

  // save role
  useEffect(() => {
    window.sessionStorage.setItem("role", JSON.stringify(role));
  }, [role]);

  // save, on value change / update
  useEffect(() => {
    window.sessionStorage.setItem("perm", JSON.stringify(permissions));
  }, [permissions]);

  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  /***
   * user is
   */
  const userIs = (roleInQuestion) => {
    return is(roleInQuestion);
  };

  /***
   * is
   */
  const is = (roleInQuestion) => {
    if (!roleInQuestion || !role) return false;
    return roleInQuestion.value == role.value;
  };

  /***
   * use can
   */
  const userCan = (perm) => {
    return can(perm);
  };

  const can = (perm) => {
    if (!activeUser || !role || !permissions) {
      return false;
    }
    // 1. default permissions for role
    var permForUser = Enums.SelectRolePermissions[role.value];
    // 2. additional permissions
    permForUser = [...permForUser, ...permissions];

    return permForUser.some((p) => p.value == perm);
  };

  const flushActiveUser = () => {
    console.log("from flush active user @@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    console.log("from flush active user @@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    window.sessionStorage.setItem("role", JSON.stringify(null));
    window.sessionStorage.setItem("permissions", JSON.stringify(null));
    window.sessionStorage.setItem("activeUserFromAuth", JSON.stringify(null));
  };

  const getEntityId = () => {
    if (!activeUser) return "";
    return activeUser.entityId;
  };

  console.log("AuthContext state:", state);

  const value = {
    ...state,
    dispatch,
    activeUser,
    setActiveUser,
    permissions,
    setPermissions,
    role,
    setRole,
    can,
    is,
    userIs,
    userCan,
    flushActiveUser,
    getEntityId,
    autIsReady,
    setAuthIsReady,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
