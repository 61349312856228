import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export default function RecipientSelector({
  users,
  recipients,
  setRecipients,
}) {
  const [inputValue, setInputValue] = useState("");
  const [filteredNames, setFilteredNames] = useState([]);

  let allNames = [];
  const [entityUsers, setEntityUsers] = useState([]);

  // Update filtered list based on input
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    allNames = users.map((r) => {
      return { name: r.displayname, id: r.id };
    });

    if (value) {
      const filtered = allNames.filter((user) =>
        user.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNames(filtered);
    } else {
      setFilteredNames([]); // Clear suggestions if input is empty
    }
  };

  // Add name to recipients list and clear input
  const addRecipient = (name) => {
    if (!recipients.includes(name)) {
      setRecipients([...recipients, name]);
    }
    setInputValue("");
    setFilteredNames([]);
  };

  /***
   * handle remove
   */
  const handleRemove = (recipient) => {
    const result = recipients.filter((r) => r.id != recipient.id);
    setRecipients(result);
  };

  return (
    <div className="relative w-full max-w-xs mx-auto">
      <h2 className="">Select Recipients</h2>

      {/* Input field */}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type a name..."
        className="border border-gray-300 p-2 w-full"
      />

      {/* Filtered list as a dropdown */}
      {filteredNames.length > 0 && (
        <ul
          className="fixed left-0 w-full border border-gray-300 rounded bg-white shadow-md mt-1 z-10"
          style={{
            top: `${
              document.querySelector("input").getBoundingClientRect().bottom
            }px`,
          }}
        >
          {filteredNames.map((user, index) => (
            <li
              key={index}
              onClick={() => addRecipient(user)}
              className="cursor-pointer hover:bg-gray-200 p-2"
            >
              {user.name}
            </li>
          ))}
        </ul>
      )}

      {/* Recipients list */}
      {recipients.length > 0 && (
        <div className="my-4 font-thin">Recipients:</div>
      )}
      <ul className="flex flex-col gap-1 cursor-default">
        {recipients.map((recipient, index) => (
          <li
            key={index}
            className="relative flex items-center h-[40px] w-full rounded bg-gray-300"
          >
            <div className="flex items-center justify-center w-full">
              {recipient.name}
            </div>
            <div
              onClick={() => handleRemove(recipient)}
              className="absolute top-[8px] scale-[70%] right-0 ml-auto mr-2 cursor-pointer"
            >
              <CloseIcon />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
