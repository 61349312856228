import Apple from "./apple.mp3";
import Orange from "./orange.mp3";
import Banana from "./banana.mp3";

import Mango from "./mango.mp3";
import Pare from "./pare.mp3";
import Cherry from "./cherry.mp3";

export const wordAudio = {
  apple: Apple,
  orange: Orange,
  banana: Banana,
  mango: Mango,
  pare: Pare,
  cherry: Cherry,
};
