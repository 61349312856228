import React, { useState, useEffect } from "react";
import { HexpoTools, Colors } from "../../models/Enums";
import { useCanvasContext } from "../../hooks/useCanvasContext";

// icons
import ColorLensIcon from "@mui/icons-material/ColorLens";
import StraightenIcon from "@mui/icons-material/Straighten";
import CreateIcon from "@mui/icons-material/Create";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CropFreeIcon from "@mui/icons-material/CropFree";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { toast } from "react-toastify";
import BasicDialog from "../../components/dialogs/BasicDialog";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { useStudioContext } from "../../hooks/useStudioContext";

export default function CanvasControls({ project, studio }) {
  const {
    setActiveQuickBrush,
    closeColorPicker,
    viewModeIsActive,
    setViewModeIsActive,
    isSavingPDF,
    setIsSavingPDF,
    showTools,
    handleToolClick,
    activeTool,
    handleDLock,
    isPainting,
    dLock,
    handleBrush,
    selected,
    handleColorPicker,
    sessionColor,
    openColorPicker,
    clearCanvas,
    handleZoomExtent,
    lines,
    saveCanvas,
    undoLastAction,
    redoLastAction,
    setSessionColor,
    setOpenColorPicker,
    opacity,
    setOpacity,
    setShowTools,
    showQuickBrush,
    setShowQuickBrush,
    setShowCanvasPages,
    createPDF,
    createStudioPDF,
  } = useCanvasContext();
  const { rating } = useStudioContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dText, setDText] = useState("");

  const handleAction = (agree) => {
    if (agree) {
      if (studio == 1) {
        // toast.success("saving studio session", { autoClose: false });
        createStudioPDF(project, dText);
      } else {
        // toast.success("saving hex session", { autoClose: false });
        createPDF(project, dText);
      }
    }
    setDialogOpen(false);
    setDText("");
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    setDText(val);
  };

  const handleDidPickColor = (color) => {
    // color
    setSessionColor(color);
    // setOpenColorPicker(false);
  };

  const handleSavePdf = () => {
    setDialogOpen(true);
  };

  const handleChange = (event, newValue) => {
    setOpacity(newValue);
  };

  const handleCallQuickBrush = () => {
    setShowCanvasPages(false);
    setShowTools(false);
    setShowQuickBrush(true);
    // setViewModeIsActive(true);
  };

  return (
    <div className="">
      <div
        className={`flex items-center w-full absolute bottom-6 left-6 transform transition-all duration-300 ease-in-out ${
          showTools ? "opacity-100 translate-y-0" : "opacity-0 translate-y-16"
        }`}
        style={{
          position: "absolute",
          bottom: 25,
          left: 25,
          zIndex: 5,
        }}
      >
        <div
          onClick={handleCallQuickBrush}
          className="flex cursor-pointer items-center justify-center bg-black rounded-lg min-h-[60px] min-w-[56px] mr-1 text-white"
        >
          <ColorLensIcon fontSize="large" />
        </div>

        {/* tools */}
        <div className="flex items-center bg-black rounded-lg  min-w-[300px] min-h-[50px] px-4 py-2">
          <div
            onClick={() => handleToolClick(HexpoTools.Draw)}
            className={`${
              activeTool === HexpoTools.Draw
                ? "text-black bg-orange-400"
                : "text-white/50"
            } cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <CreateIcon />
          </div>
          <div
            onClick={() => handleToolClick(HexpoTools.Erase)}
            className={`${
              activeTool === HexpoTools.Erase
                ? "text-black bg-orange-400"
                : "text-white"
            } cursor-pointer ml-4 border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <CheckBoxOutlineBlankIcon />
          </div>

          <div
            onClick={() => handleToolClick(HexpoTools.Paint)}
            className={`${
              activeTool === HexpoTools.Paint
                ? "text-black bg-orange-400"
                : "text-white"
            } cursor-pointer ml-4 border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <ImagesearchRollerIcon />
          </div>

          <div
            onClick={handleDLock}
            className={`${isPainting ? "pointer-events-none opacity-40" : ""} ${
              dLock ? "text-black bg-orange-400" : "text-white"
            } cursor-pointer ml-4 border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <StraightenIcon />
          </div>

          <div
            className={`flex items-center gap-1  ${
              activeTool === HexpoTools.Paint
                ? "pointer-events-none opacity-40"
                : ""
            }`}
          >
            <div
              onClick={() => handleBrush(0)}
              className={`ml-[40px] ${
                selected == 0 ? "text-black bg-orange-400" : "text-white"
              } cursor-pointer ml-4 border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out w-10 h-10 flex items-center justify-center`}
            >
              <div className="bg-white w-1 h-1 rounded-full"></div>
            </div>

            <div
              onClick={() => handleBrush(1)}
              className={`${
                selected == 1 ? "text-black bg-orange-400" : "text-white"
              } cursor-pointer ml-4 border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out w-10 h-10 flex items-center justify-center`}
            >
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <div
              onClick={() => handleBrush(2)}
              className={`${
                selected == 2 ? "text-black bg-orange-400" : "text-white"
              } cursor-pointer ml-4 border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out w-10 h-10 flex items-center justify-center`}
            >
              <div className="bg-white w-3 h-3 rounded-full"></div>
            </div>
            <div
              onClick={() => handleBrush(3)}
              className={`${
                selected == 3 ? "text-black bg-orange-400" : "text-white"
              } cursor-pointer ml-4 border-[1px] border-white rounded-full 
            transition-all duration-200 ease-in-out w-10 h-10 flex items-center justify-center`}
            >
              <div className="bg-white w-4 h-4 rounded-full"></div>
            </div>
            <div
              onClick={() => handleBrush(4)}
              className={`${
                selected == 4 ? "text-black bg-orange-400" : "text-white"
              } cursor-pointer ml-4 border-[1px] border-white rounded-full  
            transition-all duration-200 ease-in-out w-10 h-10 flex items-center justify-center`}
            >
              <div className="bg-white w-5 h-5 rounded-full"></div>
            </div>
          </div>
        </div>

        {/* color */}
        <div className="relative flex gap-3 items-center bg-black rounded-lg  w-[75px] min-h-[50px] px-4 py-2 ml-[10px]">
          <div
            onClick={handleColorPicker}
            style={{
              backgroundColor: `rgba(${sessionColor.r},${sessionColor.g},${sessionColor.b},1.0)`,
            }}
            className={`$ text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-300 ease-in-out h-11 min-w-11`}
          ></div>
          {openColorPicker && (
            <div>
              <div
                className="flex gap-1"
                style={{ position: "absolute", top: -75, left: -100 }}
              >
                <div className="flex gap-2 items-center p-2 bg-black rounded-lg min-h-[50px] min-w-[250px]">
                  <ColorSwatch
                    color={Colors.WHITE}
                    callBack={handleDidPickColor}
                  />
                  <ColorSwatch
                    color={Colors.GRAY_100}
                    callBack={handleDidPickColor}
                  />
                  <ColorSwatch
                    color={Colors.GRAY_200}
                    callBack={handleDidPickColor}
                  />
                  <ColorSwatch
                    color={Colors.GRAY_300}
                    callBack={handleDidPickColor}
                  />
                  <ColorSwatch
                    color={Colors.BLACK}
                    callBack={handleDidPickColor}
                  />
                </div>
                <div className="text-white flex gap-2 items-center px-4 py-2 bg-black rounded-lg min-h-[50px] min-w-[300px]">
                  <Slider
                    value={opacity}
                    onChange={handleChange}
                    aria-labelledby="continuous-slider"
                    min={0}
                    max={100}
                  />
                  <div className="w-[150px] ml-2">Value: {opacity}</div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* brushes */}
        <div className="flex gap-3 items-center bg-black rounded-lg  min-w-[100px] min-h-[50px] px-4 py-2 ml-[10px]">
          <div
            onClick={undoLastAction}
            className={`text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <UndoIcon />
          </div>
          <div
            onClick={redoLastAction}
            className={`text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <RedoIcon />
          </div>
          <div
            onClick={() => clearCanvas(false)}
            className={`text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <CancelPresentationIcon />
          </div>
        </div>

        {/* view */}
        <div className="flex gap-3 items-center bg-black rounded-lg  min-w-[50px] min-h-[50px] px-4 py-2 ml-[10px]">
          <div
            onClick={handleZoomExtent}
            className={`text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <CropFreeIcon />
          </div>
        </div>

        {/* lines */}
        <div className="flex items-center bg-black rounded-lg ml-3 min-h-[60px] min-w-[180px]">
          <h2 className=" text-gray-400 font-bold text-4xl px-4">
            lines: {lines?.length}
          </h2>
        </div>

        {/* save pdf locally */}
        <div className="flex gap-3 items-center bg-black rounded-lg  min-w-[50px] min-h-[50px] px-4 py-2 ml-auto mr-2">
          <button
            onClick={() => createPDF(project, dText, false)}
            className={`text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <SaveAltIcon />
          </button>
        </div>

        {/* save pdf */}
        <div className="flex gap-3 items-center bg-blue-700 rounded-lg  min-w-[50px] min-h-[50px] px-4 py-2 mr-10">
          <div
            onClick={handleSavePdf}
            className={`text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out`}
          >
            <CloudUploadIcon />
          </div>
        </div>

        {/* Dialoug */}

        <BasicDialog
          title={"Add Image"}
          open={dialogOpen}
          handleAction={handleAction}
        >
          <div className="flex items-center">
            <div className="text-xl font-bold">rating:</div>
            <div className="text-xl font-thin ml-2">{rating}</div>
          </div>

          {/* input */}
          <div className="w-[500px]">
            <div className="mt-4">
              <label
                htmlFor="sessionDescription"
                className="block text-sm font-medium text-gray-700"
              >
                Add session description (optional)
              </label>
              <textarea
                id="sessionDescription"
                name="sessionDescription"
                placeholder={"description"}
                value={dText}
                className="mt-2 block w-full rounded-md border-gray-200 border-2 focus:border-gray-300 focus:ring-0 sm:text-sm h-24 pl-4 resize-none"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </BasicDialog>
      </div>
    </div>
  );
}

const ColorSwatch = ({ color = { r: 0, g: 0, b: 225 }, callBack }) => {
  return (
    <div
      onClick={() => callBack(color)}
      style={{
        backgroundColor: `rgba(${color.r},${color.g},${color.b},1.0)`,
      }}
      className={` text-white cursor-pointer border-[1px] border-white rounded-full p-2 
            transition-all duration-200 ease-in-out h-11 w-11`}
    ></div>
  );
};
