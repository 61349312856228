import { useState } from "react";
// firebase imports
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { usePermify } from "@permify/react-role";
import { setPersistence, browserLocalPersistence } from "firebase/auth";

import { db } from "../firebase/config";
import {
  getDoc,
  getDocs,
  doc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCollectionContext } from "./useCollectionContext";
import { useAppContext } from "./useAppContext";
import { data } from "autoprefixer";

export const useLogin = () => {
  const { dispatch, setActiveUser, setRole, setPermissions } = useAuthContext();
  const [error, setError] = useState(null);
  const { users, contractors } = useCollectionContext();

  const navigate = useNavigate();

  const login = (email, password) => {
    setError(null);

    setPersistence(auth, browserLocalPersistence) // This makes the session persistent
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password)
          .then((res) => {
            const user = res.user;

            // get user document
            const userDocument = users.filter((i) => i.uid == user.uid)[0];

            dispatch({ type: "LOGIN", payload: user });
            toast.success("login");

            // 1. set active user
            setActiveUser(userDocument);
            console.log(userDocument);

            // 2. set role
            setRole(userDocument.role);

            // 3. set permissions
            setPermissions(userDocument.permissions);

            localStorage.setItem("authToken", user.accessToken);
            // Proceed with your logic

            navigate("/");
          })
          .catch((err) => {
            toast.error("error");
            setError(err.message);
            console.log("hello: ", err.message);
          });
      });
  };

  return { error, login };
};
