import React from "react";

export default function CollectonButton({ title, index, selected, badge = 0 }) {
  return (
    <div
      className={`w-[100px] h-[50px] ${
        selected == index ? "bg-orange-300" : "bg-orange-200"
      } transition-colors duration-100 ease-in-out rounded-sm cursor-pointer flex items-center justify-center text-center`}
    >
      {title}{" "}
      {badge > 0 && (
        <div className="flex items-center justify-center ml-2 bg-red-400 w-[28px] h-[28px] rounded-full">
          <div>{badge}</div>
        </div>
      )}
    </div>
  );
}
