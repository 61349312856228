import A from "./a.mp3";
import B from "./b.mp3";
import C from "./c.mp3";

import E from "./e.mp3";
import G from "./g.mp3";
import H from "./h.mp3";
import L from "./l.mp3";
import M from "./m.mp3";
import N from "./n.mp3";
import O from "./o.mp3";
import P from "./p.mp3";
import R from "./r.mp3";
import Y from "./y.mp3";

export const letterAudio = {
  a: A,
  b: B,
  c: C,
  e: E,
  g: G,
  h: H,
  l: L,
  m: M,
  n: N,
  o: O,
  p: P,
  r: R,
  y: Y,
};
