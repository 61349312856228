import React, { useState } from "react";
import { useStudioContext } from "../../hooks/useStudioContext";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";

export default function CanvasRating() {
  const { rating: drawingRating, setRating } = useStudioContext();
  const [showRatings, setShowRatings] = useState(false);
  const ratingOptions = ["excellent", "good", "fair", "weak", "unset"]; // Example ratings

  const handleContainerClick = () => {
    setShowRatings(!showRatings);
  };

  const resolveRating = (rating) => {
    setRating(rating);
  };

  return (
    // <div onClick={handleContainerClick} className="ralative">
    //   <span className="font-bold">rating</span>
    //   <span className="ml-2">unset</span>
    // </div>
    <div
      className="relative"
      onClick={handleContainerClick}
      style={{ cursor: "pointer" }}
    >
      <div className="flex items-center">
        <AnimatePresence>
          {showRatings && (
            <div className="flex">
              {ratingOptions.map((rating, index) => (
                <motion.span
                  key={rating}
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -100, opacity: 0 }}
                  transition={{ delay: index * 0.1 }} // Stagger the animations
                  className="ml-5"
                >
                  <div
                    onClick={() => resolveRating(rating)}
                    className="opacity-50 hover:opacity-100"
                  >
                    {rating}
                  </div>
                </motion.span>
              ))}
            </div>
          )}
        </AnimatePresence>
        <span className="font-bold ml-10">rating</span>
        <span className="ml-2 w-[50px]">{drawingRating}</span>
      </div>
    </div>
  );
}
