import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";

class HexUser {
  uid = ""; // generated by firebase when registering the user
  id = ""; // generated by firebase on adding object to firestore
  entityId = "";
  studioId = "";
  isInstructor = false;
  created = null;
  email = "";
  displayname = "";
  role = "";
  permissions = [];
  inbox = { items: [] };
  pinned = [];

  constructor(_id = uuid()) {
    this.id = _id;
  }
  static fromData = (data) => {
    const u = new HexUser(data["id"]);
    u.uid = data["uid"];
    u.id = data["id"];
    u.entityId = data["entityId"];
    u.studioId = data["studioId"];
    u.created = data["created"];
    u.email = data["email"];
    u.displayname = data["displayname"];
    u.role = data["role"];
    u.permissions = data["permissions"];
    u.inbox = data["inbox"];
    u.pinned = data["pinned"];
    u.isInstructor = data["isInstructor"];
    return u;
  };

  data = () => {
    return {
      uid: this.uid,
      id: this.id,
      entityId: this.entityId,
      studioId: this.studioId,
      created: this.created,
      displayname: this.displayname,
      email: this.email,
      role: this.role,
      permissions: this.permissions,
      pinned: this.pinned,
      inbox: this.inbox,
      isInstructor: this.isInstructor,
    };
  };

  getInboxCount = () => {
    return this.inbox.items.length || "x";
  };

  removeFromInbox = (drawingId) => {
    const newItems = this.inbox.items.filter(
      (item) => item.drawingId != drawingId
    );
    this.inbox = { ...this.inbox, items: [...newItems] };
  };

  markItemAsRead = (itemId) => {
    const i = this.inbox.items.find((item) => item.id == itemId);
    i.isRead = true;
  };

  markItemAsUnread = (itemId) => {
    const i = this.inbox.items.find((item) => item.id == itemId);
    i.isRead = false;
  };

  numUnread = () => {
    return this.inbox.items.filter((item) => item.isRead == false).length;
  };

  commit = () => {
    const d = this.data();

    const docRef = doc(db, "users", this.id);
    return setDoc(docRef, d, { merge: true });
  };
}
export default HexUser;
