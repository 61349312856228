import Switch from "react-switch";

export default function SwitchField({
  label = "isDocument",
  id,
  data,
  setData,
  labelWidth = 300,
  isActive = false,
  valKey = "isDocument",
  required = true,
  production = false,
  paddingLeft = 0,
}) {
  return (
    <div
      className={`flex items-center h-11 ${
        production ? "bg-white rounded" : "bg-yellow-400"
      } `}
    >
      <p style={{ width: labelWidth, paddingLeft: paddingLeft }} className="">
        {label}
      </p>

      {isActive && (
        <Switch
          onChange={() => setData(!data)}
          checked={data}
          className="react-switch ml-auto bg-red-400 mr-1"
        />
      )}
      {!isActive && (
        <p className="w-[300px] max-w-[300px] ml-auto px-2">{String(data)}</p>
      )}
    </div>
  );
}
