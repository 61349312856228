import {
  collection,
  deleteField,
  doc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase/config";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import Enums from "../../models/Enums";
import { STAGES } from "../../models/Enums";
import Project from "../../models/Project";

export default function DrawingsPage() {
  const { projects, projectsLoading } = useCollectionContext();
  const [drawings, setDrawings] = useState(null);

  useEffect(() => {
    let result = [];
    if (projects) {
      projects.forEach((project) => {
        project.drawings?.forEach((drawing) => {
          result.push(drawing);
        });
      });
    }
    setDrawings(result);
    toast.success(result.length);
  }, [projects]);

  const handleAddValue = () => {
    if (window.confirm("are you sure you want to override value")) {
      batchUpdateField("projects", "serial", "0.0.0");
    }
  };

  const handleRemoveField = () => {
    batchRemoveField("projects", "stage");
  };

  const batchUpdateField = async (collectionName, fieldName, fieldValue) => {
    try {
      const batch = writeBatch(db); // Initialize batch operation
      const collectionRef = collection(db, collectionName);
      const snapshot = await getDocs(collectionRef); // Fetch all documents

      snapshot.forEach((doc) => {
        const p = Project.fromData(doc.data());
        const drawings = p.drawings || [];
        drawings.forEach((drawing) => {
          if (!drawing[fieldName] || drawing[fieldName] === "") {
            drawing[fieldName] = fieldValue;
          }
        });

        // turn drawings from HexDrawing to data
        const d = drawings.map((d) => d.data());

        // doc ref
        const docRef = doc.ref; // Reference to the document
        // spread
        batch.update(docRef, { drawings: [...d] }); // Batch update field
      });

      await batch.commit(); // Commit the batch operation
      console.log("Batch update successful!");
    } catch (error) {
      console.error("Error in batch update:", error);
    }
  };

  const batchRemoveField = async (collectionName, fieldName) => {
    try {
      const batch = writeBatch(db); // Initialize batch operation
      const collectionRef = collection(db, collectionName);
      const snapshot = await getDocs(collectionRef); // Fetch all documents

      snapshot.forEach((doc) => {
        const docRef = doc.ref; // Reference to the document
        batch.update(docRef, { [fieldName]: deleteField() }); // Batch remove field
      });

      await batch.commit(); // Commit the batch operation
      console.log("Batch field removal successful!");
    } catch (error) {
      console.error("Error in batch field removal:", error);
    }
  };

  return (
    <div className="flex w-[1000px] pt-4 bg-red-400 mx-auto">
      {!projectsLoading && (
        <div className="w-full">
          <div className="font-bold">number of projects: {projects.length}</div>

          <div className="flex py-4 gap-2">
            <div
              onClick={handleAddValue}
              className="bg-blue-600 rounded p-2 inline-block cursor-pointer"
            >
              [assign value]
            </div>
            <div
              onClick={handleRemoveField}
              className="bg-blue-600 rounded p-2 inline-block cursor-pointer"
            >
              [remove field]
            </div>
          </div>

          <div className="flex flex-col gap-1 w-full">
            {drawings &&
              drawings.map((drawing, index) => (
                <div key={index} className="bg-gray-300 rounded p-2 flex-grow">
                  <div className="w-full flex">
                    <div className="w-[20px]">{index + 1}</div>
                    <div className="ml-4 w-[350px] font-bold">
                      {drawing.name}
                    </div>
                    <div className="ml-4">{drawing.entityId}</div>
                    <div className="ml-10">stage: {drawing.stage}</div>
                    {/* <div className="ml-10">ref: {drawing.ref}</div> */}
                    <div>serial: {drawing.serial}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
