import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../firebase/config";
import { DrawingType } from "./Enums";
import HexDrawing from "./HexDrawing";
import Revision from "./Revision";

class Project {
  id = ""; // generated by firebase on adding object to firestore
  name = "";
  number = "";
  images = [];
  color = "";
  isReady = false;
  date = null;
  url = "";
  cover = "";
  revisions = [];
  tags = [];
  drawings = [];

  constructor(_id = uuid()) {
    this.id = _id;
  }

  static fromData = (data) => {
    const p = new Project(data["id"]);
    p.number = data["number"];
    p.name = data["name"];
    p.images = data["images"];
    p.color = data["color"];
    p.isReady = data["isReady"];
    p.date = data["date"];
    p.url = data["url"];
    p.cover = data["cover"];
    p.tags = data["tags"];

    p.drawings = data["drawings"]?.map((drawing) =>
      HexDrawing.fromData(drawing)
    );

    p.revisions = data["revisions"]?.map((revision) =>
      Revision.fromData(revision)
    );

    return p;
  };

  data = () => {
    return {
      id: this.id,
      number: this.number,
      name: this.name,
      images: this.images,
      color: this.color,
      isReady: this.isReady,
      date: this.date,
      url: this.url,
      cover: this.cover,
      tags: this.tags,
      drawings: this.drawings.map((drawing) => drawing.data()),
      revisions: this.revisions.map((revision) => revision.data()),
    };
  };

  dateString = () => {
    return new Date(this.date.seconds * 1000).toDateString();
  };

  addNewRevision = () => {
    var r = new Revision();
    this.revisions.push(r);
  };

  removeRevision = (rev) => {
    this.revisions = this.revisions.filter((revision) => revision.id != rev.id);
  };

  addImgToRevisionId = (url, id) => {
    const revision = this.revisions.filter((rev) => rev.id == id)[0];
    revision.images.push(url);
  };

  updateRevisionImages = (imgUrlList, id) => {
    const revision = this.revisions.filter((rev) => rev.id == id)[0];
    revision.images = [...imgUrlList];
  };

  removeImageFromRevision = (url, id) => {
    const revision = this.revisions.filter((rev) => rev.id == id)[0];
    revision.removeImage(url);
  };

  latestRevision = () => {
    return this.revisions[this.revisions.length - 1];
  };

  stageImages = () => {
    const r = this.latestRevision();
    return r.images;
  };

  removeDrawing = (drawingId) => {
    this.drawings = this.drawings.filter((drawing) => drawing.id != drawingId);
  };

  addDrawing = (drawing) => {
    console.log(this.drawings);
    this.drawings.push(drawing);
  };

  drawingsWithId = (drawingId) => {
    // toast.success(drawingId);
    const url =
      this.drawings.filter((drawing) => drawing.id == drawingId)[0] || "";
    return url;
  };

  getHeader = () => {
    if (this.drawings.length == 0) return "";
    return this.drawings[this.drawings.length - 1].serial;
  };

  /***
   * get pending feedback
   * a pending feedback is a header drawing of type feedback
   */
  getPendingFeedback = () => {
    if (!this.drawings) {
      return null;
    }
    const headerDrawing = this.drawings[this.drawings.length - 1];
    console.log("headerDrawing: ", headerDrawing);
    if (!headerDrawing) {
      return null;
    }
    return headerDrawing.drawingType.value == DrawingType.Feedback.value
      ? headerDrawing
      : null;
  };

  commit = () => {
    const d = this.data();

    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    console.log(d);

    const docRef = doc(db, "projects", this.id);
    return setDoc(docRef, d, { merge: true });
  };
}

export default Project;
