import React, { useEffect, useRef, useState } from "react";
import Project from "../../../models/Project";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { toast } from "react-toastify";
import UploadImageButton from "../../../components/UploadImage";
import BasicDialog from "../../../components/dialogs/BasicDialog";
import { useUploadDocument } from "../../../hooks/useUploadDocument";
import ImageTile from "./RevisionImageTile";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import HexRevList from "../HexRevList";
import CircularProgressWithLabel from "../../../components/progress/CircularProgressWithLabel";

export default function HexRevisionRow({
  project,
  revision,
  activeRevId,
  resolveActiveRev,
}) {
  const [open, setOpen] = useState(false);
  const { uploadImage } = useUploadDocument();

  const handleDelete = async () => {
    const p = Project.fromData(project);

    // check
    if (p.revisions.length < 2) {
      toast.error("must keep at least 1 revision");
      return;
    }

    p.removeRevision(revision);

    const d = p.data();
    const docRef = doc(db, "projects", project.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      toast.success("revision deleted");
    });
  };

  // handle upload
  const [selected, setSelected] = useState({});
  const inputfileRef = useRef();
  const handleAddClick = () => {
    inputfileRef.current.click();
  };

  const [selFiles, setSelFiles] = useState([]);

  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    const files = Array.from(e.target.files);
    setSelFiles(files);

    const d = { file: file };
    setSelected(d);
    setOpen(true);
  };

  // confirm upload
  const handleAction = (accept) => {
    if (accept) {
      uploadImageWrapper();
    } else {
      toast.error("disagree");
    }
    setOpen(false);
  };

  const uploadImageWrapper = async () => {
    const file = selected.file;
    setShowSpinner(true);
    await uploadFiles(selFiles, "hexpo/revisions");
  };

  const insertFirestoreDoc = async (url) => {
    // adjust the url to imageKit endpoint
    // -----------------------------------------------------------
    const imageUrl = url.replace(
      "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
      "ik.imagekit.io/habiatuq/hexpo"
    );

    // once the image is uploaded to storage
    // add the url to the revision on the project
    const p = Project.fromData(project);
    const revId = revision.id;

    console.log(revId);
    console.log(imageUrl);

    p.addImgToRevisionId(imageUrl, revId);

    // get project data
    const d = p.data();

    console.log(d);

    // update the project
    // update doc
    const docRef = doc(db, "projects", project.id);
    await setDoc(docRef, d, { merge: true }).then((docRef) => {
      // dismiss();
      setShowPayload(false);
    });
  };

  const [showSpinner, setShowSpinner] = useState(false);

  const handleRowClick = () => {
    //
    resolveActiveRev(revision);
  };

  const [imgUrlList, setImgUrlList] = useState([]);
  useEffect(() => {
    if (revision) {
      setImgUrlList([...revision.images]);
    }
  }, [revision]);

  const resolveListOrder = (reorderedList) => {
    setImgUrlList(reorderedList);
  };

  const handleUpdate = async () => {
    const xpro = Project.fromData(project);
    xpro.updateRevisionImages(imgUrlList, revision.id);

    // commit updates
    await xpro.commit().then(() => {
      toast.success("revision images updated");
    });
  };

  const mb = 1000000;
  const fileLimit = 7 * mb;

  const [payloadsize, setPayloadSize] = useState(0);
  const [progress, setProgress] = useState(0);
  const [payloadComplete, setPayloadComplete] = useState(0);
  const [payloadStats, setPayloadStats] = useState([]);
  const [showPayload, setShowPayload] = useState(false);
  const flushPayload = () => {
    setPayloadSize(0);
    setProgress(0);
    setPayloadComplete(0);
    setShowPayload(false);
  };

  // useEffect(() => {
  //   // update progress
  //   var total = 0;
  //   var transfered = 0;

  //   console.log("payloadStats: ###############################");
  //   console.log(payloadStats);

  //   // payloadStats.forEach((stat) => {
  //   //   total = total + stat.total;
  //   //   transfered = transfered + stat.transfered;
  //   // });

  //   for (var i = 0; i < payloadStats.length; i++) {
  //     const stat = payloadStats[i];
  //     total = total + stat.total;
  //     transfered = transfered + stat.transfered;
  //   }

  //   const prog = transfered / total;
  //   console.log("prog: $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
  //   console.log(prog, transfered, total);

  //   setProgress(prog);
  // }, [payloadStats]);

  const updateStates = (stats) => {
    // update progress
    var total = 0;
    var transfered = 0;

    for (const key in stats) {
      const stat = stats[key];
      const t = stat.total;
      const r = stat.transfered;
      total = total + stat.total;
      transfered = transfered + stat.transfered;
    }

    const prog = (transfered / total) * 100;
    setProgress(prog);
  };

  const uploadFiles = (files, path) => {
    // validate
    for (var i = 0; i < files.length; i++) {
      if (files[i].size > fileLimit) {
        toast.error(`${files[i].name} size is too large`);
        return "";
      }
    }

    setShowPayload(true);
    for (var i = 0; i < files.length; i++) {
      setPayloadSize((prev) => prev + 1);
    }

    const storage = getStorage();

    return new Promise((resolve, reject) => {
      const urls = [];
      const stats = [];
      for (let i = 0; i < files.length; i++) {
        // Loop through each file and upload

        const file = files[i];
        const storageRef = ref(storage, `${path}/${file.name}`);

        // Create the upload task
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Monitor the upload progress
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get progress as a percentage
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`${file.name} Upload is ${progress}% done`);
            stats[file.name] = {
              total: snapshot.totalBytes,
              transfered: snapshot.bytesTransferred,
            };

            updateStates(stats);

            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error(`Failed to upload ${file.name}: `, error);
          },
          async () => {
            // Handle successful uploads on complete
            await getDownloadURL(uploadTask.snapshot.ref).then(
              (downloadedUrl) => {
                console.log(`Download URL for ${file.name}: ${downloadedUrl}`);
                urls.push(downloadedUrl);
                insertFirestoreDoc(downloadedUrl);
              }
            );
            resolve(urls); // this not working for me
          }
        );
      }
    });
  };

  // Upload the file
  // uploadBytes(storageRef, file)
  //   .then((snapshot) => {
  //     console.log(`Uploaded ${file.name} successfully!`);
  //     // Get the download URL
  //     getDownloadURL(storageRef)
  //       .then((url) => {
  //         console.log(`Download URL for ${file.name}: ${url}`);
  //         // You can also store or use the URL as needed
  //         insertFirestoreDoc(url);
  //       })
  //       .catch((error) => {
  //         console.error(
  //           `Failed to get download URL for ${file.name}: `,
  //           error
  //         );
  //       });
  //   })
  //   .catch((error) => {
  //     console.error(`Failed to upload ${file.name}: `, error);
  //   });

  return (
    <div
      onMouseUp={handleRowClick}
      className={`${
        activeRevId == revision.id ? "bg-orange-400" : "bg-gray-400"
      }`}
    >
      <div className="flex items-center py-2">
        <div className="ml-2">Hex Revision</div>
        <BasicDialog
          title={"Add Image"}
          open={open}
          handleAction={handleAction}
        >
          <span className="pr-[150px]">Are you ready to upload image ?</span>
        </BasicDialog>

        {showPayload && (
          <div className="bg-black/50 w-[200px] px-4 py-1 ml-2 rounded">
            <div className="flex items-center">
              <div>uploading {payloadsize} files</div>
              <div className="ml-2">
                <CircularProgressWithLabel value={progress} />
              </div>
            </div>
          </div>
        )}

        <div onClick={handleAddClick} className="ml-auto cursor-pointer">
          <input
            ref={inputfileRef}
            type="file"
            id="file"
            onChange={handleSelectFile}
            className="hidden"
            multiple={true}
          />
          [add]
        </div>
        <div onClick={handleUpdate} className="ml-2 cursor-pointer">
          [update]
        </div>
        <div className="ml-2 cursor-pointer">[edit]</div>
        <div onClick={handleDelete} className="ml-2 mr-2 cursor-pointer">
          [delete]
        </div>
      </div>

      <div className="ml-2">
        <div className="mt-4 scroll-smooth flex flex-1 gap-4 w-full pb-5 snap-x overflow-x-auto">
          <HexRevList
            project={project}
            revision={revision}
            imageList={imgUrlList}
            resolveListOrder={resolveListOrder}
          />
        </div>
      </div>
    </div>
  );
}

// ====================================================================
