import React, { useRef, useEffect } from "react";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import opentype from "opentype.js";

export default function NigCanvas() {
  const { width, height } = useWindowDimensions();
  const canvasRef = useRef(null);
  const svgPathRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Adjust canvas size for high resolution
    canvas.width = width;
    canvas.height = height;

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Path for the letter "A"
    const path = new Path2D();
    const fontSize = 400;
    const x = canvas.width / 2; // Center of the canvas
    const y = canvas.height / 2;

    // Approximate the shape of "A" with lines
    const leftLegStart = { x: x - fontSize / 4, y: y + fontSize / 4 };
    const leftLegEnd = { x: x, y: y - fontSize / 2 };
    const rightLegStart = { x: x + fontSize / 4, y: y + fontSize / 4 };
    const crossbarStart = { x: x - fontSize / 6, y: y };

    // Animation variables
    const duration = 1000; // Duration per stage (ms)
    let startTime = null;

    const drawLeftLeg = () => {
      const legPath = new Path2D();
      legPath.moveTo(leftLegStart.x, leftLegStart.y);
      legPath.lineTo(leftLegEnd.x, leftLegEnd.y);
      return legPath;
    };

    const drawRightLeg = () => {
      const legPath = new Path2D();
      legPath.moveTo(leftLegEnd.x, leftLegEnd.y);
      legPath.lineTo(rightLegStart.x, rightLegStart.y);
      return legPath;
    };

    const drawCrossBar = () => {
      const barPath = new Path2D();
      barPath.moveTo(crossbarStart.x, crossbarStart.y);
      barPath.lineTo(x + fontSize / 6, crossbarStart.y);
      return barPath;
    };

    const animateStroke = (time) => {
      if (!startTime) startTime = time;
      const elapsed = time - startTime;

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Set stroke style
      ctx.lineWidth = 30;
      ctx.lineCap = "round"; // Rounded line ends
      ctx.lineJoin = "round"; // Rounded joints
      ctx.strokeStyle = "black";

      // Stage 1: Animate the left leg
      if (elapsed < duration) {
        const progress = elapsed / duration;
        const legPath = drawLeftLeg();
        const totalLength = 400; // Approximation of left leg length
        const dashLength = totalLength * progress;
        ctx.setLineDash([dashLength, totalLength]);
        ctx.stroke(legPath);
      }
      // Stage 2: Animate the right leg
      else if (elapsed < 2 * duration) {
        const progress = (elapsed - duration) / duration;
        const legPath = drawRightLeg();
        const totalLength = 400; // Approximation of right leg length
        const dashLength = totalLength * progress;

        // Draw left leg fully
        const leftLegPath = drawLeftLeg();
        ctx.setLineDash([]); // No dashes for completed path
        ctx.stroke(leftLegPath);

        // Animate the right leg
        ctx.setLineDash([dashLength, totalLength]);
        ctx.stroke(legPath);
      }
      // Stage 3: Animate the crossbar
      else if (elapsed < 3 * duration) {
        const progress = (elapsed - 2 * duration) / duration;
        const barPath = drawCrossBar();
        const totalLength = 300; // Approximation of crossbar length
        const dashLength = totalLength * progress;

        // Draw left and right legs fully
        const leftLegPath = drawLeftLeg();
        const rightLegPath = drawRightLeg();
        ctx.setLineDash([]); // No dashes for completed paths
        ctx.stroke(leftLegPath);
        ctx.stroke(rightLegPath);

        // Animate the crossbar
        ctx.setLineDash([dashLength, totalLength]);
        ctx.stroke(barPath);
      }
      // Final Stage: Draw all parts completely
      else {
        const leftLegPath = drawLeftLeg();
        const rightLegPath = drawRightLeg();
        const barPath = drawCrossBar();

        ctx.setLineDash([]);
        ctx.stroke(leftLegPath);
        ctx.stroke(rightLegPath);
        ctx.stroke(barPath);
        return; // Stop the animation
      }

      requestAnimationFrame(animateStroke);
    };

    // Start the animation
    requestAnimationFrame(animateStroke);
  }, [width, height]);

  //   useEffect(() => {
  //     const canvas = canvasRef.current;
  //     const ctx = canvas.getContext("2d");

  //     // Adjust canvas size for high resolution
  //     canvas.width = width;
  //     canvas.height = height;

  //     // Clear the canvas
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     ctx.fillStyle = "black";
  //     ctx.font = "400px Arial";
  //     ctx.textAlign = "center";
  //     ctx.textBaseline = "middle";
  //     ctx.fillText("A", canvas.width / 2, canvas.height / 2);
  //   }, [width, height]);

  const handlePointerDown = (e) => {};
  const handlePointerMove = (e) => {};
  const handlePointerUp = (e) => {};

  return (
    <div className="bg-red-400 w-[1000px]">
      <canvas
        className="bg-blue-500"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: width,
          height: height,
        }}
        ref={canvasRef}
        // context menu
        // onContextMenu={handleContextMenu}
        // pointer
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
      />
    </div>
  );
}
