export const a_sm = `
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg">   
    <g id="Layer_1-2" data-name="Layer 1">      
    <circle cx="81.26" cy="81.28" r="61.26" transform="rotate(0, 81.26, 81.28)" offset="150"/>  
    <line x1="148.03" y1="20" x2="148.03" y2="141.9"/>      
    </g>
  </svg>`;

export const a_cp = `
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.92 256.83">
    <line x2="139.87" y2="20" x1="20" y1="236.83"/>
    <line x1="139.87" y1="20" x2="260.92" y2="236.83"/>    
    <line x1="65.33" y1="154.83" x2="213.84" y2="154.83"/>
  </svg>
`;

export const b_cp = `
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.2 257.04">    
  <path d="M20,237.04h91.61c30.7,0,55.59-24.89,55.59-55.59s-24.89-55.59-55.59-55.59H20"/>          
  <path d="M106.81,125.03c29,0,52.51-23.51,52.51-52.51s-23.51-52.51-52.51-52.51H24.92"/>    
  <line x1="20" y1="20.47" x2="20" y2="237.04"/>    
</svg>
`;

export const b_sm = `
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.28 260.9">    
    <circle class="cls-1" cx="82.02" cy="179.64" r="61.26"/>
    <line class="cls-1" x1="20" y1="20" x2="20" y2="240.89"/>  
</svg>
`;

export const c_sm = `
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.49 162.53">    
    <path class="cls-1" d="M117.39,130.75c-10.13,7.41-22.62,11.78-36.12,11.78-33.84,0-61.26-27.43-61.26-61.26s27.43-61.26,61.26-61.26c14,0,26.9,4.7,37.22,12.6"/>  
</svg>
`;

export const c_cp = `
<svg
  id="Layer_2"
  data-name="Layer 2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 211.86 253.28"
>
  <path
    class="cls-1"
    d="M190.88,211.77c-17.87,13.5-40.12,21.51-64.24,21.51-58.9,0-106.64-47.74-106.64-106.64S67.74,20,126.64,20c24.56,0,47.19,8.31,65.22,22.26"
  />
</svg>;
`;

export const letters = [
  { label: "A", audio: "a", svg: a_cp },
  { label: "a", audio: "a", svg: a_sm },
  { label: "B", audio: "b", svg: b_cp },
  { label: "b", audio: "b", svg: b_sm },
  { label: "C", audio: "c", svg: c_cp },
  { label: "c", audio: "c", svg: c_sm },
];
