import React from "react";
import LassoCanvas from "./LassoCanvas";

export default function Lassoplay() {
  return (
    <div>
      <LassoCanvas />
    </div>
  );
}
