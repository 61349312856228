import { uuidv4 as uuid } from "@firebase/util";

class Revision {
  id = ""; // generated by firebase on adding object to firestore
  number = "";
  name = "";
  images = [];
  date = null;
  isActive = false;

  constructor(_id = uuid()) {
    this.id = _id;
  }

  static fromData = (data) => {
    const p = new Revision(data["id"]);
    p.number = data["number"];
    p.name = data["name"];
    p.images = data["images"];
    p.date = data["date"];
    p.isActive = data["isActive"];
    return p;
  };

  data = () => {
    return {
      id: this.id,
      number: this.number,
      name: this.name,
      images: this.images,
      date: this.date,
      isActive: this.isActive,
    };
  };

  deactivate = () => {
    this.isActive = false;
  };

  activate = () => {
    this.isActive = true;
  };

  removeImage = (url) => {
    this.images = this.images.filter((imageUrl) => imageUrl != url);
  };

  dateString = () => {
    return new Date(this.date.seconds * 1000).toDateString();
  };
}

export default Revision;
