import React, { useState, useRef } from "react";

import UploadImage from "../../../components/UploadImage";
import TextField from "../../../fields/TextField";
import SelectField from "../../../fields/SelectField";
import SwitchFiled from "../../../fields/SwitchField";
import DateField from "../../../fields/DateField";
import UploadImageButton from "../../../components/UploadImage";
import Revision from "../../../models/Revision";
// firebase
import { db } from "../../../firebase/config";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";

import { toast } from "react-toastify";
import { useUploadDocument } from "../../../hooks/useUploadDocument";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
// import { v4 as uuid } from "uuid";
// import { timestamp } from "../firebase/config";

import AcUnitIcon from "@mui/icons-material/AcUnit";
import BasicDialog from "../../../components/dialogs/BasicDialog";

export default function AddCollectionForm({ dismiss }) {
  const [data, setData] = useState({
    name: "",
    date: new Date(),
    file: null,
  });

  const { uploadImage } = useUploadDocument();
  const { projects } = useCollectionContext();
  const [optionData, setOptionData] = useState({
    value: "blue",
    label: "blue",
  });
  const [isReady, setIsReady] = useState(false);
  const resolveIsReady = (option) => {
    setIsReady(option);
  };

  // const addName = async () => {
  //   // add to collection
  //   const d = {
  //     ...data,
  //     images: [],
  //     color: optionData,
  //     isReady: isReady,
  //   };
  //   const projectRef = collection(db, "projects");
  //   await addDoc(projectRef, d).then((docRef) => {
  //     console.log("project added");
  //   });
  // };

  const options = [
    { value: "blue", label: "blue" },
    { value: "orange", label: "orange" },
    { value: "pink", label: "pink" },
  ];
  const resolveOptions = (option) => {
    setOptionData(option);
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setData({ ...data, file: file });
  };

  const handleFormSubmit = async () => {
    // validate image
    if (data.file == undefined || data.file == "") {
      toast.error("please select an image");
      return;
    }

    setInProgress(true);

    const file = data.file;
    await uploadImage(file, "collections").then((url) => {
      // d
      const d = {
        number: data.number,
        name: data.name,
        date: data.date,
        color: optionData,
        isReady: isReady,
        cover: "",
        url: url,
        images: [],
        projects: [],
      };

      // add doc
      const collectionRef = collection(db, "collections");
      addDoc(collectionRef, d).then((docRef) => {
        dismiss();
        setInProgress(false);
        toast.success("done");
      });
    });
  };
  const inputfileRef = useRef();
  const handleAddClick = () => {
    inputfileRef.current.click();
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selFiles, setSelFiles] = useState([]);
  const [selected, setSelected] = useState({});

  const handleSelectFile = (e) => {
    // 1. this is the old method
    const file = e.target.files[0];
    setData({ ...data, file: file });

    // 2. this is for the multi files
    const files = Array.from(e.target.files);
    setSelFiles(files);

    const d = { file: file };
    setSelected(d);

    // call the dialoug for concent
    // setDialogOpen(true);
  };

  // this (the dialog action) is not called in this component
  const handleAction = (accept) => {
    if (accept) {
      toast.success("agree");
      // fire action here
    } else {
      toast.error("disagree");
    }
    setDialogOpen(false);
  };

  const [inProgress, setInProgress] = useState(false);

  const resolveDismiss = () => {
    setInProgress(false);
    dismiss();
  };

  return (
    <div>
      <BasicDialog
        title={"Add Image"}
        open={dialogOpen}
        handleAction={handleAction}
      >
        <p className="pr-[150px]">Are you ready to upload image ?</p>
      </BasicDialog>

      <div className="w-[600px] p-4">
        <TextField
          isActive={true}
          inputWidth={600}
          label="collection number"
          id="number"
          valKey="number"
          data={data}
          setData={setData}
          production={true}
          showLabel={false}
        />
        <TextField
          inputWidth={600}
          isActive={true}
          label="collection name"
          id="name"
          valKey="name"
          data={data}
          setData={setData}
          production={true}
          showLabel={false}
        />
        <SelectField
          inputWidth={600}
          fullWidth={true}
          options={options}
          data={optionData}
          setData={resolveOptions}
          isActive={true}
          production={true}
          showLabel={false}
        />
        <SwitchFiled
          id="isReady"
          data={isReady}
          setData={resolveIsReady}
          isActive={true}
          production={true}
          showLabel={false}
          paddingLeft={12}
        />
        <DateField
          inputWidth={600}
          label="color date"
          id="date"
          isActive={true}
          valKey="date"
          data={data}
          setData={setData}
          production={true}
          showLabel={false}
        />

        <div
          onClick={handleAddClick}
          className="bg-blue-600 text-white rounded mt-2 py-2 hover:bg-blue-700 cursor-pointer"
        >
          <div className="ml-2 font pointer-events-none">
            <input
              ref={inputfileRef}
              type="file"
              id="file"
              onChange={handleSelectFile}
              className="hidden"
              // multiple={true}
            />
            select image{" "}
            {<span className="ml-2">{selected ? data.file?.name : ""}</span>}
          </div>
        </div>

        {/* <div className="mt-1 w-[600px]">
          <UploadImageButton callBack={handleUpload} />
        </div> */}
      </div>
      <div className="flex gap-1 ml-3">
        {/* <button
          onClick={handleFormSubmit}
          className="bg-slate-600 hover:bg-slate-500 w-[140px] h-[40px] px-2 rounded text-white"
        >
          add collection
        </button> */}

        <button
          disabled={inProgress}
          onClick={handleFormSubmit}
          className="bg-slate-600 hover:bg-slate-500 w-[140px] h-[40px] px-2 rounded text-white"
        >
          {!inProgress && <span>add collection</span>}
          {inProgress && (
            <div className="mt-1">
              <CircularProgress size={20} />
            </div>
          )}
        </button>

        <button
          className="bg-slate-600 hover:bg-slate-500 w-[140px] h-[40px] px-2 rounded text-white"
          onClick={resolveDismiss}
        >
          cancel
        </button>
      </div>
    </div>
  );
}
