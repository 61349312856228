import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useMiswadahContext } from "../../hooks/useMiswadahContext";
import MisClient from "../../models/MisClient";
import MisLawsuit from "../../models/MisLawsuit";
import HexDialog from "../studio/forms/HexDialog";

export default function ClientPage() {
  const { id } = useParams();
  const { misClients, misClientLoading } = useCollectionContext();
  const { client, setClient } = useMiswadahContext();

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [data, setData] = useState({ name: "", serial: "" });

  /***
   * set client
   */
  useEffect(() => {
    if (misClients) {
      const c = misClients.find((client) => client.id == id);
      setClient(c);
    }
  }, [id]);

  /***
   * add lawsuite
   */
  const addLawsuite = () => {
    const misClient = MisClient.fromData(client);

    console.log(misClient);

    // lawsuite
    const misLawsuit = new MisLawsuit();

    misClient.addLawsuit(misLawsuit);
    misClient.commit().then((ref) => {
      setClient({ ...misClient });
    });
  };

  const navigate = useNavigate();
  const goToLawsuit = (e, clientId, lawsuitId) => {
    navigate(`/lawsuit/${clientId}/${lawsuitId}`);
  };

  const handleShowEditForm = () => {
    setShowEditDialog(true);
    toast.success("show dialog");
  };

  /***
   * handle set name
   */
  const handleSetName = (agree) => {
    if (agree) {
      const misClient = MisClient.fromData(client);
      misClient.name = data.name;
      misClient.commit().then((ref) => {
        setClient({ ...misClient.data() });
        toast.success("name updated");
        setShowEditDialog(false);
      });
    } else {
      setShowEditDialog(false);
    }
  };

  if (!client) {
    <div>loading</div>;
  }

  return (
    <div className="flex w-[1000px] mx-auto bg-red-400">
      {/* dialog */}
      <HexDialog
        open={showEditDialog}
        data={data}
        setData={setData}
        handleDialogButton={handleSetName}
      />

      <div className="w-full">
        <div className="flex items-center">
          <h2 className="pt-2">Client</h2>
          <div
            onClick={handleShowEditForm}
            className="ml-auto mr-2 mt-2 cursor-pointer opacity-50 hover:opacity-100"
          >
            [edit]
          </div>
        </div>

        <div>{client?.name}</div>

        <div className="flex items-center w-full h-[40px]">
          <h2 className="h-full flex items-center">Lawsuits</h2>
          <div
            onClick={addLawsuite}
            className="flex items-center ml-auto mr-2 cursor-pointer h-full"
          >
            <div>[add lawsuite]</div>
          </div>
        </div>

        {/* horizontal break */}
        <hr className="border-gray-800 border-[1px] my-1" />

        <div className="flex flex-col gap-1">
          {client?.lawsuits.map((lawsuit) => (
            <div key={lawsuit.id}>
              <div
                onClick={(e) => goToLawsuit(e, client.id, lawsuit.id)}
                className="bg-gray-400 min-h-[40px] items-center p-2 cursor-pointer"
              >
                <div>name: {lawsuit.name}</div>
                <div>id: {lawsuit.id}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
