import PlanA from "../../assets/plans/planA.jpg";
import PlanD from "../../assets/plans/PlanD.jpg";
import Swoosh from "../../assets/sounds/swoosh-sound-effect.mp3";
import Click from "../../assets/sounds/click-234708.mp3";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import React, { useRef, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigationType, useParams } from "react-router-dom";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import { motion, useDragControls } from "framer-motion";
import { toast } from "react-toastify";
import { ReactSketchCanvas } from "react-sketch-canvas";
import Hammer from "hammerjs";
import { TouchAppSharp } from "@mui/icons-material";
import SwitchButton from "../../components/switchButton/SwitchButton";
import SwitchButtonItem from "../../components/switchButton/SwitchButtonItem";
import { HexpoTools, Colors } from "../../models/Enums";
import { throttle } from "lodash";
import CircularProgressWithLabel from "../../components/progress/CircularProgressWithLabel";
import { Box, CircularProgress, Typography } from "@mui/material";
import CanvasSwitcher from "./CanvasSwitcher";

import CanvasPages from "./CanvasPages";

// icons
import StraightenIcon from "@mui/icons-material/Straighten";
import CreateIcon from "@mui/icons-material/Create";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CropFreeIcon from "@mui/icons-material/CropFree";

import { useNavigate } from "react-router-dom";

import { useCanvasContext } from "../../hooks/useCanvasContext";
import CanvasControls from "./CanvasControls";
import { useDocument } from "../../hooks/useDocument";

import useLongPress from "../../hooks/useLongPress"; // Adjust the path if needed

// pdf
import * as pdfjs from "pdfjs-dist/webpack";
import Project from "../../models/Project";
import HexDebugStack from "./HexDebugStack";
import CanvasQuickBrush from "./CanvasQuickBrush";
import VisibilityButton from "./VisibilityButton";
import HexStudioProject from "../../models/HexStudioProject";
import { useStudioContext } from "../../hooks/useStudioContext";
import CanvasRating from "./CanvasRating";

const HexCanvas = () => {
  const { id, proId, studio } = useParams();
  const isStudio = () => {
    return studio == 1;
  };

  const { document: project, error } = useDocument(
    isStudio() ? "studioProjects" : "projects",
    proId
  );
  const { currentStudentId, resoveNameForId } = useStudioContext();

  useEffect(() => {
    toast.error(error);
  }, [error]);

  // convas context
  const {
    isSavingPDF,
    setIsSavingPDF,
    pdfUploadProgress,
    setPDFUploadProgress,
    isCreatingPDF,
    setIsCreatingPDF,
    audioRef,
    audioClickRef,
    showCanvasPages,
    showQuickBrush,
    closeColorPicker,
    opacity,
    setOpacity,
    showNav,
    setShowNav,
    pdfDocument,
    setPdfDocument,
    loading,
    setLoading,
    loadingDocument,
    setLoadingDocument,
    // getImageForPage,
    getImageForPage_tiling,
    clearCanvas,
    canvasSize,
    setCanvasSize,
    baseLineWidth,
    canvasScaleFactor,
    lines,
    setLines,
    pdf,
    activeTool,
    setActiveTool,
    isPinching,
    setIsPinching,
    startDistance,
    setStartDistance,
    brushSize,
    setBrushSize,
    touches,
    setTouches,
    lastTapTime,
    setLastTapTime,
    isDragging,
    setIsDragging,
    isErasing,
    setIsErasing,
    isPainting,
    setIsPainting,
    canvasRef,
    imageCanvasRef,
    mouseOffset,
    setMouseOffset,
    canvasPosition,
    setCanvasPosition,
    canvasOffset,
    setCanvasOffset,
    lastCanvasOffset,
    setLastCanvasOffset,
    lastPos,
    setLastPos,
    dragStartPos,
    setDragStartPos,
    scale,
    setScale,
    lastScale,
    setLastScale,
    dragStartOffset,
    setDragStartOffset,
    selected,
    setSelected,
    scaleFactor,
    handleBrush,
    handleToolClick,
    dLock,
    setDLock,
    openColorPicker,
    setOpenColorPicker,
    showTools,
    setShowTools,
    restCanvasPosition,
    handleZoomExtent,
    sessionColor,
    setSessionColor,
    undoLastAction,
    redoLastAction,
    setCurrentPage,
    currentPage,
    imagesArray,
    setImagesArray,
    linesArray,
    setLinesArray,
    image,
    isHighRes,
    setIsHighRes,
    viewModeIsActive,
    setViewModeIsActive,
    lineRef,
    currentDrawing,
    setCurrentDrawing,
    pagesCompleted,
    setPagesCompleted,
  } = useCanvasContext();

  // state
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { width, height } = useWindowDimensions();
  const dragControls = useDragControls();

  /***
   * prevent body from scrolling
   *
   */
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto"; // Re-enable scrolling when the component unmounts
    };
  }, []);

  /***
   * listen to refresh or departure
   */
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const [navEntry] = performance.getEntriesByType("navigation");
      if (navEntry && navEntry.type === "reload") {
        // User clicked reload
        console.log("Page reload detected!");

        const confirmationMessage = "Are you sure you want to leave?";
        e.returnValue = confirmationMessage; // Legacy method
        return confirmationMessage;
      }
    };

    const handlePageHide = (e) => {
      // Clear the canvas only when the page is actually being unloaded
      setShowNav(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pagehide", handlePageHide);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, []);

  const location = useLocation(); // This gives access to the current route
  const navigationType = useNavigationType(); // Determines the type of navigation
  const isFirstLoad = useRef(true); // Track if this is the first render

  /***
   * 1. get pdf url from project
   */
  const [pdfUrl, setPdfUrl] = useState("");
  useEffect(() => {
    if (project) {
      // const length = project.drawings.length || 0;
      let p;
      if (isStudio()) {
        p = HexStudioProject.fromData(project);
      } else {
        p = Project.fromData(project);
      }

      const drawing = p.drawingsWithId(id);

      // toast.success(`${drawing.id}`);

      setCurrentDrawing(drawing);
      // set pdf url
      if (pdfUrl == "") {
        setPdfUrl(drawing.url);
      }
    }
  }, [project]);

  useEffect(() => {
    const loadPdfAndExtractImages = async () => {
      try {
        // Load PDF document from URL
        setLoadingDocument(true);
        const pdf = await pdfjs.getDocument(pdfUrl).promise;
        setPdfDocument(pdf);
        setLoadingDocument(false);
        // Array to hold images of each page
        const tempImagesArray = Array(pdf.numPages).fill(null);

        setCurrentPage(1);
        const img = await getImageForPage_tiling(pdf, 1);
        tempImagesArray[0] = img;
        // Once all pages are processed, update state with the images array
        setImagesArray(tempImagesArray);

        // lines array
        const _ar = Array(pdf.numPages).fill([]);
        // linesArray
        setLinesArray([..._ar]);
        // set active lines
      } catch (error) {
        console.error("Error loading or rendering PDF:", error);
      }
    };

    if (pdfUrl) {
      loadPdfAndExtractImages();
    }
  }, [pdfUrl]);

  /***
   * move to page
   */
  const moveToPage = async (pageNum) => {
    if (!imagesArray[pageNum - 1]) {
      // toast.success(`getting page ${pageNum}`);
      const img = await getImageForPage_tiling(pdfDocument, pageNum);
      imagesArray[pageNum - 1] = img;
    }

    // setup lines for page
    linesArray[currentPage - 1] = lines;
    // setLinesArray(prevArray => {
    //   const newArray = [...prevArray]; // Create a copy to maintain immutability
    //   newArray[currentPage - 1] = lines;
    //   return newArray;
    // });
    const _lines = linesArray[pageNum - 1];
    setLines(_lines?.length > 0 ? linesArray[pageNum - 1] : []);

    // set current page
    setImagesArray([...imagesArray]);
    setCurrentPage(pageNum);
    setTimeout(() => ping(), 30); // this is required to fix the lines clearnace problem
  };

  const ping = () => {
    handleZoomExtent();
  };

  /***
   * call page
   */
  useEffect(() => {
    const img = imagesArray[currentPage - 1];
    image.current.src = img;
    // toast.success("Did assign image");
  }, [imagesArray]);

  // ------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------

  const [imageUrls, setImageUrls] = useState([]); // Store image URLs
  const [imageloading, setImageLoading] = useState(false); // Manage loading state

  // ----------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);

  /***
   * setting up the canvas
   */
  useEffect(() => {
    // selected brush
    setShowNav(false);
    setSelected(0);
    setBrushSize(2);
    setActiveTool(HexpoTools.Draw);
    setSessionColor(Colors.BLACK);
    // image canvas
    const imageCanvas = imageCanvasRef.current;
    const imgContext = imageCanvas.getContext("2d");

    // drawing canvas
    const drawingCanvas = canvasRef.current;
    const drawingContext = drawingCanvas.getContext("2d");

    drawingCanvas.style.width = width;
    drawingCanvas.style.height = height;

    image.current.src = ""; //PlanD;

    image.current.onload = () => {
      // set image is loaded
      setImageLoaded(true);
      // draw canvases
      drawImageCanvas(imgContext); // Draw background image
      drawCanvas(drawingContext); // Draw stored lines
    };

    // Cleanup
    return () => {};

    image.current.onerror = () => {
      console.error("Image failed to load.");
    };
  }, [canvasSize]);

  // ------------------------------------------------------------------------------------

  /***
   * Draw the lines on the canvas
   */
  const drawCanvas = (context) => {
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    context.save();
    context.setTransform(scale, 0, 0, scale, canvasOffset.x, canvasOffset.y);
    // draw lines on context
    drawLines(context); // draw lines
    // requestRedraw(context);
    context.restore();
  };

  const requestRedraw = (context) => {
    requestAnimationFrame(() => drawLines(context));
  };

  // -------------------------------------------------------------------------------------

  const drawLines = (context) => {
    if (!lines) return; // Check for null/undefined lines

    context.lineCap = "round";
    context.lineJoin = "round"; // Set these properties once

    // Draw all lines
    lines.forEach((line) => {
      context.beginPath();

      const { r, g, b } = line.sessionColor ?? { r: 0, g: 0, b: 0 };
      const opacity = line.opacityVal ? line.opacityVal / 100 : 1;
      const color = `rgba(${r},${g},${b},${opacity})`;

      if (line.isErasing) {
        context.globalCompositeOperation = "destination-out";
      } else {
        context.globalCompositeOperation = "source-over";
        context.strokeStyle = color;
        context.fillStyle = color;
        // context.strokeStyle = `rgba(${line.sessionColor?.r},${
        //   line.sessionColor?.g
        // },${line.sessionColor?.b},${line.opacityVal / 100})`;
        // context.fillStyle = context.strokeStyle;
      }

      // Draw the points for the current line
      line.points.forEach((point, index) => {
        if (index === 0) {
          context.moveTo(point.x, point.y);
        } else {
          context.lineTo(point.x, point.y);
        }
      });

      context.lineWidth = line.points[0]?.brushSize || 5; // Use first point's brush size

      // Commit the drawing to the canvas
      if (line.isPainting) {
        context.closePath();
        context.fill();
      } else {
        context.stroke();
      }
    });

    // Draw in-progress line from `lineRef.current`
    const currentLine = lineRef.current;
    const { r, g, b } = currentLine.sessionColor ?? { r: 0, g: 0, b: 0 };
    const opacity = currentLine.opacityVal ? currentLine.opacityVal / 100 : 1;
    const color = `rgba(${r},${g},${b},${opacity})`;

    if (currentLine?.points?.length > 0) {
      context.beginPath();

      currentLine.points.forEach((point, index) => {
        if (index === 0) context.moveTo(point.x, point.y);
        else context.lineTo(point.x, point.y);
      });

      context.lineWidth = currentLine.points[0]?.brushSize || 5;
      context.strokeStyle = color;
      context.fillStyle = color;

      if (currentLine.isPainting) {
        context.closePath();
        context.fill();
      } else {
        context.stroke();
      }
    }
  };

  /***
   * draw image canvas
   */
  const drawImageCanvas = (context) => {
    context.clearRect(
      0,
      0,
      imageCanvasRef.current.width,
      imageCanvasRef.current.height
    );
    context.save();
    context.setTransform(scale, 0, 0, scale, canvasOffset.x, canvasOffset.y);
    drawImageWithObjectFitCover(context);
    context.restore();
  };

  // -------------------------------------------------------------------------------------

  /***
   * draw image with object fit
   */
  const drawImageWithObjectFitCover = (context) => {
    const canvas = canvasRef.current;
    const img = image.current;

    const imgAspectRatio = img.width / img.height;
    const canvasAspectRatio = canvas.width / canvas.height;

    let drawWidth, drawHeight, offsetX, offsetY;

    if (imgAspectRatio > canvasAspectRatio) {
      // Image is wider than canvas
      drawHeight = canvas.height;
      drawWidth = img.width * (canvas.height / img.height);
      offsetX = -(drawWidth - canvas.width) / 2; // Center the image
      offsetY = 0;
    } else {
      // Image is taller than canvas
      drawWidth = canvas.width;
      drawHeight = img.height * (canvas.width / img.width);
      offsetX = 0;
      offsetY = -(drawHeight - canvas.height) / 2; // Center the image
    }

    context.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
  };

  // -------------------------------------------------------------------------------------

  useEffect(() => {
    const render = () => {
      if (!canvasRef.current || !imageCanvasRef.current) return;

      const context = canvasRef.current.getContext("2d");
      const imageContext = imageCanvasRef.current.getContext("2d");

      drawCanvas(context);
      drawImageCanvas(imageContext);
    };

    // Call render whenever the relevant dependencies change
    render();
  }, [scale, canvasOffset, canvasPosition, isFullScreen, lines, currentPage]);

  // -------------------------------------------------------------------------------------

  // const [lastTouches, setLastTouches] = useState(null);
  // const [currentTouches, setCurrentTouches] = useState([]);
  // const [shift, setShift] = useState(0);
  const [initialTouch, setInitialTouch] = useState(null);
  const [startPos, setStartPos] = useState(null);
  const [lockedDirection, setLockedDirection] = useState(null); // New state to lock the direction
  const [diagonalSign, setDiagonalSign] = useState({ dx: 1, dy: 1 }); // Track diagonal direction

  let initialPointerType = useRef("pen");

  /***
   * handle PointerDown
   */
  const handlePointerDown = (event) => {
    // hide colors
    closeColorPicker();

    const now = Date.now();
    setLastTapTime(now);

    // set initialPointerType
    initialPointerType.current = event.pointerType;

    // check pointer type
    // toast.success(event.pointerType, { position: "top-center" });
    // toast.success(`initial pointer typet: ${initialPointerType.current}`, {
    //   position: "top-right",
    // });

    if (viewModeIsActive) return;

    // set start
    setStartPos({ x: event.clientX, y: event.clientY });
    // if touch
    if (event.type === "touchstart") {
      const startX = event.touches[0].clientX;
      const startY = event.touches[0].clientY;
      setStartPos({ x: startX, y: startY });
    }

    setLockedDirection(null);

    // touches
    if (event.type === "touchstart") {
      const t = [...event.touches];
      setTouches(t);
    }

    // pinching
    if (event.type === "touchstart" && event.touches.length === 2) {
      setIsPinching(true);
      setInitialTouch(event.touches[0]);
      const distance = getDistance(event.touches[0], event.touches[1]);
      setStartDistance(distance);
      setLastScale(scale); // Remember the current scale
    }

    if (
      event.pointerType === "pen" ||
      event.pointerType === "mouse" ||
      event.pointerType === "touch"
    ) {
      if (isDragging) {
        startDrag(event);
        return;
      }

      // start drawing
      if (!imageLoaded) return;
      setIsDrawing(true);
      const { x, y } = getPointerPosition(event);
      // start a new line
      // toast.success(event.touches?.length);
      startLine(x, y, brushSize, sessionColor, isErasing, isPainting);
    }
  };

  // const [offsetXState, setOffsetXState] = useState(0);
  // const [offsetYState, setOffsetYState] = useState(0);

  /***
   * handle PointerMove
   */
  const handlePointerMove = (event) => {
    // if dragging with two fingers
    if (event.type === "touchmove") {
      if (event.touches.length === 2) {
        const firstTouchX = event.touches[0].clientX;
        const firstTouchY = event.touches[0].clientY;

        const shiftX = firstTouchX - initialTouch.clientX; // Difference in X axis
        const shiftY = firstTouchY - initialTouch.clientY; // Difference in Y axis

        const { offsetX, offsetY, newScale } = resolveCanvasOffset(event);

        // shift from initial touch
        const shiftDistance = getDistance(initialTouch, event.touches[0]);

        // distance between two fingers
        const distance = getDistance(touches[0], event.touches[0]);

        // scale
        setScale(newScale);

        // clientX, clientY
        // setClientX(event.touches[0].clientX);
        // setClientX(event.touches[0].clientY);

        // offsetX, offsetY
        // setOffsetXState(offsetX);
        // setOffsetYState(offsetY);

        // canvasOffset
        setCanvasOffset({
          x: offsetX + shiftX,
          y: offsetY + shiftY,
        });

        // update initial
        setInitialTouch(event.touches[0]);

        return;
      }
    }

    // escape
    if (
      viewModeIsActive ||
      !isDrawing ||
      !imageLoaded ||
      isDragging ||
      isPinching
    )
      return;

    if (
      event.pointerType === "pen" ||
      event.pointerType === "mouse" ||
      event.pointerType === "touch"
    ) {
      const { x, y } = getPointerPosition(event);

      // get currentX, currentY
      const rect = canvasRef.current.getBoundingClientRect();
      const currentX = event.clientX - rect.left;
      const currentY = event.clientY - rect.top;

      const dx = currentX - startPos.x;
      const dy = currentY - startPos.y;

      // Lock the direction on the first significant movement
      if (!lockedDirection && (Math.abs(dx) > 5 || Math.abs(dy) > 5)) {
        // Determine if the line is closer to horizontal, vertical, or diagonal
        if (Math.abs(dx) > Math.abs(dy)) {
          // Horizontal or diagonal
          if (Math.abs(dy / dx) <= Math.tan(Math.PI / 8)) {
            setLockedDirection("horizontal");
          } else {
            // Lock diagonal and store the initial diagonal direction
            setLockedDirection("diagonal");
            setDiagonalSign({ dx: Math.sign(dx), dy: Math.sign(dy) });
          }
        } else {
          // Vertical or diagonal
          if (Math.abs(dx / dy) <= Math.tan(Math.PI / 8)) {
            setLockedDirection("vertical");
          } else {
            // Lock diagonal and store the initial diagonal direction
            setLockedDirection("diagonal");
            setDiagonalSign({ dx: Math.sign(dx), dy: Math.sign(dy) });
          }
        }
      }

      const snappedPos = getSnappedPosition(
        startPos.x,
        startPos.y,
        currentX,
        currentY,
        lockedDirection,
        diagonalSign
      );

      // (localX - rect.left - canvasOffset.x) / scale;

      const snappedPosX = adjustedX(snappedPos.x);
      const snappedPosY = adjustedY(snappedPos.y);

      // echo
      // const echo = `X: ${x.toFixed(2)}, Y ${y.toFixed(2)}, pointer type:  ${
      //   event.pointerType
      // }, pressure: ${event.pressure}`;

      if (dLock) {
        addToLine(snappedPosX, snappedPosY, brushSize, sessionColor, isErasing);
      } else {
        addToLine(x, y, brushSize, sessionColor, isErasing, isPainting);
      }
    }
  };

  /***
   * adjusted coordinate x
   */
  const adjustedX = (localX) => {
    const rect = canvasRef.current.getBoundingClientRect();
    return (localX - rect.left - canvasOffset.x) / scale;
  };

  /***
   * adjusted coordinate y
   */
  const adjustedY = (localY) => {
    const rect = canvasRef.current.getBoundingClientRect();
    return (localY - rect.top - canvasOffset.y) / scale;
  };

  /***
   * start line
   */
  const startLine = (
    x,
    y,
    brushSize,
    sessionColor,
    isErasing = false,
    isPainting = false
  ) => {
    // start a new line
    const line = {
      points: [{ x, y, brushSize }],
      brushSize: brushSize || 1,
      sessionColor: sessionColor,
      opacityVal: opacity,
      isErasing: isErasing,
      isPainting: isPainting,
    };

    // line ref
    // remember we are only interested in the points
    lineRef.current = line;

    // lines state
    // if (!lines) {
    //   setLines([]);
    // } else {
    //   setLines([...lines, line]);
    // }
  };

  /***
   * addToLine
   */
  const addToLine = (x, y, brushSize, sessionColor, isErasing = false) => {
    // get lines
    const newLines = [...lines];
    // get last line
    const lastLine = newLines[newLines.length - 1];

    // point
    const point = { x, y, brushSize };

    // add point to lines ref
    lineRef.current.points.push(point);

    // the new optimization calls for drawing the canvas on mouse move
    // ------------------------------------------------------------------

    // 1. Render immediately for real-time feedback
    const context = canvasRef.current.getContext("2d");
    drawCanvas(context);

    // ------------------------------------------------------------------

    // 2. old implementation
    // it add lines to the lines state, which triggers a re-render
    // the problem with this approach is that its heavey with state chages as point count grows

    // add a point to lines state
    // if (lastLine) {
    //   lastLine.points = [...lastLine.points, point];
    //   setLines([...newLines]);
    // }
  };

  const [tapDuration, setTapDuration] = useState(0);

  /***
   * handle PointerUp
   */
  const handlePointerUp = (event) => {
    event.preventDefault();

    if (viewModeIsActive) return;

    setIsPinching(false);

    // for double tapping
    const now = Date.now();
    // Check if there were two fingers at start and end of the tap
    if (
      event.type === "touchend" &&
      touches.length === 2 &&
      event.touches?.length === 0
    ) {
      const _tapDuration = now - lastTapTime;
      setTapDuration(_tapDuration);
      // Define a tap duration threshold (e.g., 150ms)
      if (_tapDuration < 150) {
        setTimeout(handleTwoFingerTap, 50); // 50ms
      }
    }

    if (
      event.type === "touchend" &&
      touches.length === 3 &&
      event.touches.length === 0
    ) {
      const _tapDuration = now - lastTapTime;
      setTapDuration(_tapDuration);
      // Define a tap duration threshold (e.g., 150ms)
      if (_tapDuration < 150) {
        setTimeout(redoLastAction, 50); // delay 50ms
      }
    }

    if (
      event.pointerType === "pen" ||
      event.pointerType === "mouse" ||
      event.pointerType === "touch"
    ) {
      // localStorage.setItem("canvas-drawings", JSON.stringify(lines));
      setIsDrawing(false);
      setIsDragging(false);
    }

    // toast.success(`mouse pointer prior to lines commit: ${event.pointerType}`, {
    //   position: "top-right",
    // });
    // toast.success(
    //   `mouse initialPointerType prior to lines commit: ${initialPointerType.current}`,
    //   {
    //     position: "top-right",
    //   }
    // );

    if (
      event.pointerType == initialPointerType.current ||
      event.pointerType == "mouse"
    ) {
      // update lines
      const newLines = [...lines, lineRef.current];

      // empty lineRef
      lineRef.current = { points: [] };

      // Save the current line to state
      setLines([...newLines]); // Save the current line

      // Save to lines array for the current page
      linesArray[currentPage - 1] = newLines;
    }

    pergeLines(); // Call purge
  };

  /***
   * perge lines
   */
  const pergeLines = () => {
    if (lines?.length === 0) return;
    const newLines = [...lines];
    const lastLine = newLines.pop();
    if (lastLine.points.length === 1) {
      setLines([...newLines]);
    }
  };

  // useEffect(() => {
  //   const render = () => {
  //     const canvas = canvasRef.current;
  //     const ctx = canvas.getContext("2d");
  //     ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

  //     // Draw all completed lines from state
  //     lines.forEach((line) => {
  //       ctx.beginPath();
  //       line.forEach((point, index) => {
  //         if (index === 0) ctx.moveTo(point.x, point.y);
  //         else ctx.lineTo(point.x, point.y);
  //       });
  //       ctx.stroke();
  //     });

  //     // Draw the in-progress line (while the user is drawing)
  //     if (currentLine.current.length > 0) {
  //       ctx.beginPath();
  //       currentLine.current.forEach((point, index) => {
  //         if (index === 0) ctx.moveTo(point.x, point.y);
  //         else ctx.lineTo(point.x, point.y);
  //       });
  //       ctx.stroke();
  //     }

  //     requestAnimationFrame(render); // Schedule the next frame
  //   };

  //   render(); // Start the rendering loop on mount
  // }, [lines]); // Rerun render loop if lines change

  /***
   * handle wheel
   */
  const handleWheel = (event) => {
    // event.preventDefault();

    // const rect = canvasRef.current.getBoundingClientRect();
    // const mouseX = event.clientX - rect.left;
    // const mouseY = event.clientY - rect.top;

    // const offsetX = mouseX - (mouseX - canvasOffset.x) * (newScale / scale);
    // const offsetY = mouseY - (mouseY - canvasOffset.y) * (newScale / scale);

    // get canvas offsets
    const { offsetX, offsetY, newScale } = resolveCanvasOffset(event);

    setScale(newScale);
    setCanvasOffset({ x: offsetX, y: offsetY });
  };

  /***
   * resolve canvas offset
   */
  const resolveCanvasOffset = (event) => {
    // use the local param instead of state
    // once done with calculation use state
    let _clientX = event.clientX;
    let _clientY = event.clientY;

    let newScale = scale + (event.deltaY < 0 ? scaleFactor : -scaleFactor);
    newScale = Math.max(0.5, Math.min(newScale, 20));

    if (event.type === "touchmove") {
      const distance = getDistance(event.touches[0], event.touches[1]);
      newScale = (distance / startDistance) * lastScale;

      _clientX = event.touches[0].clientX;
      _clientY = event.touches[0].clientY;

      const rect = canvasRef.current.getBoundingClientRect();

      // Get the current mouse position relative to the canvas
      const mouseX = _clientX - rect.left;
      const mouseY = _clientY - rect.top;

      // Calculate the offset by adjusting the scale factor
      // We're keeping the mouse (touch) position the same after scaling
      const offsetX = mouseX - (mouseX - canvasOffset.x) * (newScale / scale);
      const offsetY = mouseY - (mouseY - canvasOffset.y) * (newScale / scale);

      // Apply the new calculated offset and scale to the canvas transformation
      return { offsetX, offsetY, newScale };
    }

    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = _clientX - rect.left;
    const mouseY = _clientY - rect.top;

    const offsetX = mouseX - (mouseX - canvasOffset.x) * (newScale / scale);
    const offsetY = mouseY - (mouseY - canvasOffset.y) * (newScale / scale);
    return { offsetX, offsetY, newScale };
  };

  // ------------------------------------------------------------

  const getPointerPosition = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left - canvasOffset.x) / scale;
    const y = (event.clientY - rect.top - canvasOffset.y) / scale;
    return { x, y };
  };

  const handleDragStart = (event, info) => {
    // Calculate the mouse offset relative to the element's current position
    setMouseOffset({
      x: info.point.x - canvasPosition.x,
      y: info.point.y - canvasPosition.y,
    });
    // start pos, startOffset
    setDragStartPos({ x: event.clientX, y: event.clientY });
    setDragStartOffset({ ...canvasOffset });
    // set is dragging
    setIsDragging(true);
  };

  // const handleDrag = (event) => {
  //   if (!isDragging) return;

  //   const deltaX = event.clientX - dragStartPos.x;
  //   const deltaY = event.clientY - dragStartPos.y;

  //   setCanvasOffset({
  //     x: dragStartOffset.x + deltaX,
  //     y: dragStartOffset.y + deltaY,
  //   });
  // };

  // const handleDragEnd = () => {
  //   setIsDragging(false);
  // };

  // Function to calculate the distance moved by the fingers
  const calculateDistance = (startPositions, endPositions) => {
    const dx1 = endPositions[0].x - startPositions[0].x;
    const dy1 = endPositions[0].y - startPositions[0].y;
    const dx2 = endPositions[1].x - startPositions[1].x;
    const dy2 = endPositions[1].y - startPositions[1].y;
    const distance1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
    const distance2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
    return (distance1 + distance2) / 2;
  };

  // Function to handle two-finger tap
  const handleTwoFingerTap = () => {
    undoLastAction();
  };

  // ---------------------------------------------------------------
  // ---------------------------------------------------------------

  /***
   * handle keydown
   */
  const handleKeyDown = (event) => {
    if (event.keyCode == "88") {
      // toast.success(showTools ? "showTools" : "hide tools");
      setShowTools(!showTools);
      setViewModeIsActive(!viewModeIsActive);
    }

    if (event.keyCode == "32") {
      setIsDragging(true);
    }
    if (event.keyCode == "70") {
      setIsFullScreen(true);
    }
    if (event.keyCode == "71") {
      restCanvasPosition();
    }
  };

  /***
   * handle keyup
   */
  const handleKeyUp = (event) => {
    if (event.keyCode == "32") {
      setIsDragging(false);
    }
  };

  /***
   * resolve canvas size
   */
  // const resolveCanvasSize = () => {
  //   setCanvasSize({
  //     width: width * canvasScaleFactor,
  //     height: height * canvasScaleFactor,
  //   });
  // };

  const onFullScreenChange = (isFull) => {
    setIsFullScreen(isFull); // this will trigger a canvas redraw effect
  };

  const startDrag = (event) => {
    dragControls.start(event);
  };

  // -------------------------------------------------------------------------------------

  const saveCanvas = () => {
    const canvas = canvasRef.current;
    const img = image.current;

    if (canvas && img) {
      // Create an offscreen canvas with the same resolution as the natural image
      const offCanvas = document.createElement("canvas");
      offCanvas.width = canvas.width * 4; // img.naturalWidth; // Use the high-res image width
      offCanvas.height = canvas.height * 4; // img.naturalHeight; // Use the high-res image height
      const offContext = offCanvas.getContext("2d");

      // Calculate aspect ratios for both the image and the canvas
      const imgAspectRatio = img.naturalWidth / img.naturalHeight;
      const canvasAspectRatio = canvas.width / canvas.height;

      let drawWidth, drawHeight, offsetX, offsetY;

      // Object-fit: cover logic: scale and crop the image correctly
      if (imgAspectRatio > canvasAspectRatio) {
        // Image is wider than canvas: scale by height and crop horizontally
        drawHeight = img.naturalHeight;
        drawWidth = drawHeight * canvasAspectRatio; // Calculate the width based on the canvas aspect ratio
        offsetX = (img.naturalWidth - drawWidth) / 2; // Center horizontally by cropping sides
        offsetY = 0; // No vertical offset
      } else {
        // Image is taller than canvas: scale by width and crop vertically
        drawWidth = img.naturalWidth;
        drawHeight = drawWidth / canvasAspectRatio; // Calculate the height based on the canvas aspect ratio
        offsetX = 0; // No horizontal offset
        offsetY = (img.naturalHeight - drawHeight) / 2; // Center vertically by cropping top/bottom
      }

      // Draw the image on the offscreen canvas, cropping excess parts
      offContext.drawImage(
        img,
        offsetX, // Crop and center horizontally
        offsetY, // Crop and center vertically
        drawWidth, // Scaled width of the image
        drawHeight, // Scaled height of the image
        0, // Draw at top-left corner of the offscreen canvas
        0,
        offCanvas.width, // Draw to the full size of the offscreen canvas
        offCanvas.height
      );

      // Calculate scaling factors to map the display canvas to the high-res canvas
      const scaleFactorX = offCanvas.width / canvas.width;
      const scaleFactorY = offCanvas.height / canvas.height;

      // Draw the lines on the offscreen canvas, scaling the points to match the high-res canvas
      offContext.strokeStyle = "red"; // Set line color
      offContext.lineWidth = 10 * scaleFactorX; // Adjust line width for high resolution
      offContext.lineCap = "round";

      lines.forEach((line) => {
        offContext.beginPath();
        line.points.forEach((point, index) => {
          // Scale the line points to match the high-resolution image
          const scaledX = point.x * scaleFactorX;
          const scaledY = point.y * scaleFactorY;
          if (index === 0) {
            offContext.moveTo(scaledX, scaledY);
          } else {
            offContext.lineTo(scaledX, scaledY);
          }
        });
        offContext.stroke();
      });

      // Save the offscreen canvas as a high-resolution PNG
      const dataURL = offCanvas.toDataURL("image/jpeg", 0.8);
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "high_res-image";
      link.click(); // Trigger the download
    }
  };

  // Save lines to localStorage whenever they are updated
  useEffect(() => {
    if (lines) {
      if (lines.length > 0) {
        localStorage.setItem("canvas-drawings", JSON.stringify(lines));
      }
    }
  }, [lines, currentPage]);

  // -------------------------------------------------------------------------------------

  /**
   * read the comments inside.
   * there is a great tip on how to trick react to rerender
   * even if you are sending the save state values
   */
  const resolveContainerPostion = (event, info) => {
    setCanvasPosition({
      x: info.point.x - mouseOffset.x,
      y: info.point.y - mouseOffset.y,
    });
    // I have to trick react to update the convas position since
    // the values are alywas x=0, y=0
    // setCanvasPosition((prev) => ({ x: prev.x + 1, y: prev.y + 1 }));
    // Then immediately move it back to {x: 200, y: 200}
    // setTimeout(() => {
    //   setCanvasPosition({ x: 0, y: 0 });
    // }, 0); // Use a short delay to ensure the state gets updated
  };

  const handleErasingButton = () => {
    toast.success("erasing button");
    setIsErasing(!isErasing);
  };

  // Helper function to calculate distance between two touch points
  const getDistance = (touch1, touch2) => {
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  // Start dragging when two fingers are detected
  // const startDragging = (e) => {
  //   const rect = canvasRef.current.getBoundingClientRect();
  //   const x = (e.touches[0].clientX + e.touches[1].clientX) / 2 - rect.left;
  //   const y = (e.touches[0].clientY + e.touches[1].clientY) / 2 - rect.top;
  //   setLastPos({ x, y });
  //   setIsDragging(true);
  // };

  // Handle dragging with two fingers
  // const handleDragging = (e) => {
  //   const rect = canvasRef.current.getBoundingClientRect();
  //   const x = (e.touches[0].clientX + e.touches[1].clientX) / 2 - rect.left;
  //   const y = (e.touches[0].clientY + e.touches[1].clientY) / 2 - rect.top;

  //   const dx = x - lastPos.x;
  //   const dy = y - lastPos.y;

  //   // Update canvas offset for dragging
  //   setCanvasOffset({
  //     x: lastCanvasOffset.x + dx,
  //     y: lastCanvasOffset.y + dy,
  //   });
  // };

  // const applyTransform = (offsetX, offsetY, newScale) => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   // Clear and apply the scale and translate transformation
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   ctx.save();
  //   ctx.translate(offsetX, offsetY);
  //   ctx.scale(newScale, newScale);

  //   // Redraw your content (e.g., a rectangle or image)
  //   ctx.fillStyle = "blue";
  //   ctx.fillRect(50, 50, 100, 100); // Example rectangle

  //   ctx.restore();
  // };

  const handleOnListChnage = (value) => {
    switch (value) {
      case 0:
        setBrushSize(5);
        break;
      case 1:
        setBrushSize(10);
        break;
      case 2:
        setBrushSize(20);
        break;
    }
    // update ui
    setSelected(value);
  };

  // Lock initial direction based on movement
  const lockDirection = (dx, dy) => {
    if (Math.abs(dx) > Math.abs(dy)) {
      if (Math.abs(dy / dx) <= Math.tan(Math.PI / 8)) {
        return "horizontal";
      }
    }

    if (Math.abs(dy) > Math.abs(dx)) {
      if (Math.abs(dx / dy) <= Math.tan(Math.PI / 8)) {
        return "vertical";
      }
    }

    // Lock diagonal with its initial sign (positive or negative slope)
    setDiagonalSign({ dx: Math.sign(dx), dy: Math.sign(dy) });
    return "diagonal";
  };

  const getSnappedPosition = (
    startX,
    startY,
    endX,
    endY,
    direction,
    diagonalSign
  ) => {
    const dx = endX - startX;
    const dy = endY - startY;

    // const _x = (event.clientX - rect.left - canvasOffset.x) / scale;

    switch (direction) {
      case "horizontal":
        // Lock to horizontal: X changes, Y stays constant
        return { x: endX, y: startY };

      case "vertical":
        // Lock to vertical: Y changes, X stays constant
        return { x: startX, y: endY };

      case "diagonal": {
        // Get diagonal distance (movement along both X and Y) to allow movement in both directions
        const diagonalDist = Math.max(Math.abs(dx), Math.abs(dy)); // Use max to maintain diagonal angle

        // Extend line in both directions based on diagonalSign (initial direction) and current mouse position
        return {
          x: startX + diagonalSign.dx * diagonalDist,
          y: startY + diagonalSign.dy * diagonalDist,
        };
      }

      default:
        return { x: endX, y: endY }; // Default case (shouldn't occur in ruler mode)
    }
  };

  const handleDLock = () => {
    setDLock(!dLock);
  };

  const handleTool = () => {
    setIsErasing(!isErasing);
  };

  const handlePaint = () => {
    toast.success("will be implemented");
  };

  const handErase = () => {};

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  // dImageHeight,
  //   setDImageHeight,
  //   dA3Height,
  //   setDA3Height,

  const handleDisableViewModel = () => {
    if (viewModeIsActive) {
      setViewModeIsActive(false);
      setShowTools(true);
    } else {
      setViewModeIsActive(true);
      setShowTools(false);
    }
  };

  /**
   * Lazy load a specific page.
   */
  const loadImageForPage = useCallback(
    async (pageNumber) => {
      try {
        const img = await getImageForPage_tiling(pdfDocument, pageNumber);
        setImagesArray((prevArray) => {
          const newArray = [...prevArray];
          newArray[pageNumber - 1] = img; // Store image at correct index
          return newArray;
        });
      } catch (error) {
        console.error(`Error loading page ${pageNumber}:`, error);
      }
    },
    [pdfDocument, setImagesArray]
  );

  /***
   * lazy loading
   */
  useEffect(() => {
    if (!pdfDocument) return; // Exit early if pdfDocument is not available

    // Load remaining pages in the background
    const lazyLoadAllPages = async () => {
      for (let i = 2; i <= pdfDocument.numPages; i++) {
        if (!imagesArray[i - 1]) {
          await loadImageForPage(i); // Ensure one page loads at a time
        }
      }
    };

    lazyLoadAllPages();
  }, [pdfDocument, loadImageForPage]);

  const progress = 0;

  return (
    <div
      // wheel
      style={{
        overflow: "hidden",
        position: "relative",
        width: width,
        height: height,
      }}
      onWheel={handleWheel}
    >
      <audio ref={audioRef} src={Swoosh} />
      <audio ref={audioClickRef} src={Click} />

      <div>
        <FullScreen
          isFullScreen={isFullScreen}
          onChange={(isFull) => onFullScreenChange(isFull)}
        >
          <motion.div
            tabIndex={0}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            // dragging
            drag={isDragging ? true : false}
            dragControls={dragControls} // Add drag controls
            dragListener={true}
            onDragStart={handleDragStart} // Capture mouse offset on drag start
            onDragEnd={(e, info) => {
              // Update position state during drag
              resolveContainerPostion(e, info);
            }}
            animate={{ x: canvasPosition.x, y: canvasPosition.y }}
            style={{
              x: canvasPosition.x,
              y: canvasPosition.y,
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              touchAction: "none",
            }}
          >
            {/* Background Image Canvas */}
            <canvas
              ref={imageCanvasRef}
              width={width}
              height={height}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: width,
                height: height,
                touchAction: "none",
                zIndex: 0,
              }}
            />

            {/* drawing canvas */}
            <canvas
              //   className="bg-blue-400"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: width,
                height: height,
                touchAction: "none",
                zIndex: 2,
              }}
              ref={canvasRef}
              width={width}
              height={height}
              // context menu
              onContextMenu={handleContextMenu}
              // touch
              onTouchStart={handlePointerDown}
              onTouchMove={handlePointerMove}
              onTouchEnd={handlePointerUp}
              // pointer
              onPointerDown={handlePointerDown}
              onPointerMove={handlePointerMove}
              onPointerUp={handlePointerUp}
            />
          </motion.div>
          {isFullScreen && (
            <div style={{ position: "absolute", top: 25, left: 25 }}>
              <button
                onClick={clearCanvas}
                className="bg-blue-600 px-4 py-2 rounded"
              >
                Clear
              </button>

              <button
                onClick={handleErasingButton}
                className={`${
                  isErasing ? "bg-red-400" : "bg-blue-600"
                } px-4 py-2 rounded ml-2`}
              >
                {isErasing ? "draw" : "erase"}
              </button>

              <button
                onClick={saveCanvas}
                className="bg-green-600 px-4 py-2 rounded ml-2"
              >
                Save
              </button>
              <button
                onClick={undoLastAction}
                className="bg-blue-600 px-4 py-2 rounded ml-2"
              >
                Undo
              </button>
              <button
                onClick={redoLastAction}
                className="bg-yellow-600 px-4 py-2 rounded ml-2"
              >
                Redo
              </button>
            </div>
          )}
        </FullScreen>
        {/* title */}
        <div style={{ position: "absolute", top: 25, left: 25 }} className="">
          {/* <h1 className="text-8xl">Hexpo</h1> */}
        </div>
        {/* debug */}
        {/* <HexDebugStack /> */}

        {/* Controls */}
        <CanvasControls project={project} studio={studio} />

        <CanvasQuickBrush project={project} />

        {/* Switcher */}
        {/* <CanvasSwitcher /> */}

        {pdfDocument && (
          <div>
            <CanvasPages
              loading={loading}
              loadingDocument={loadingDocument}
              imagesArray={imagesArray}
              linesArray={linesArray}
              moveToPage={moveToPage}
            />
          </div>
        )}

        <div>
          <VisibilityButton handleDisableViewModel={handleDisableViewModel} />
        </div>
      </div>

      {/* pdf upload progress */}
      {isSavingPDF && (
        <div className="flex items-center gap-1 absolute top-1/2 left-1/2 bg-black/75 min-w-[250px] min-h-[120px] p-10 rounded-lg text-white transform -translate-x-1/2 -translate-y-1/2">
          {isCreatingPDF && (
            <div>
              <div className="flex items-center">
                <div>Creating document</div>
                <div className="ml-2">
                  <CircularProgress size={30} sx={{ color: "white" }} />
                </div>
              </div>
              <div>completed: {pagesCompleted}</div>
            </div>
          )}
          {!isCreatingPDF && pdfUploadProgress == 0 && (
            <div>choose where to save the pdf</div>
          )}
          {pdfUploadProgress > 0 && (
            <div className="flex items-center gap-2">
              <div>uploading drawing</div>
              <div className="ml-2 text-white mt-2">
                <XCircularProgressWithLabel
                  value={pdfUploadProgress}
                  size={60}
                  sx={{ color: "white" }}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* is studio session */}
      {isStudio() && currentDrawing && currentDrawing.studentId && (
        <div className="absolute top-0 left-0 bg-red-500/85 text-white font-thin w-full rounded-sm p-2 z-10">
          <div className="flex items-center">
            <div className="">
              <span>studio session with</span>
              <span className="font-bold mx-2">
                {resoveNameForId(currentDrawing.studentId)}:{" "}
              </span>
              {currentDrawing.studentId}
            </div>
            <div className="ml-auto mr-4 cursor-pointer">
              <CanvasRating />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HexCanvas;

function XCircularProgressWithLabel({
  value,
  size = 60,
  color = "white",
  ...props
}) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={4} // Optional: Adjust thickness for aesthetics
        {...props}
      />

      <Box
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color, fontSize: size * 0.25 }} // Dynamically shrink text size
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
