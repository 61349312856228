import React, { useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import rough from "roughjs/bin/rough"; // Import Rough.js
import simplify from "simplify-js"; // Import the simplify library
import { FullScreen } from "@chiragrupani/fullscreen-react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import ImageA from "../../../assets/black/image_a.jpg";

import {
  motion,
  animate,
  useMotionValue,
  useDragControls,
} from "framer-motion";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { toast } from "react-toastify";
export default function Canvas() {
  const dragControls = useDragControls();
  // dimensiosn
  const { height, width } = useWindowDimensions();
  // refs
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const imageRef = useRef(null);
  const motionRef = useRef(null);
  // drawing state
  const [isDrawing, setIsDrawing] = useState(false);
  const [isErasing, setIsErasing] = useState(false); // Track erasing mode
  const eraserSize = 20; // Define the size of the eraser

  // setup canvas
  useEffect(() => {
    if (canvasRef.current != null) {
      const canvas = canvasRef.current;
      // size
      canvas.width = window.innerWidth * 2;
      canvas.height = window.innerHeight * 2;
      //style
      canvas.style.width = `${window.innerWidth}`;
      canvas.style.height = `${window.innerHeight}`;
      //context
      const context = canvas.getContext("2d");
      context.scale(2, 2);
      context.lineCap = "round";
      context.strokeStyle = "black";
      context.lineWidth = 15;
      contextRef.current = context;

      // Create a Rough.js instance on the canvas
      const rc = rough.canvas(canvas);

      // Example of drawing a sketchy rectangle
      rc.rectangle(150, 150, 200, 200, {
        roughness: 2,
        stroke: "black",
        fill: "black",
        hachureAngle: 45,
        strokeWidth: 3,
        hachureGap: 5,
      });

      const lineProps = {
        stroke: "black", // Line color
        strokeWidth: 3, // Line thickness
        roughness: 5, // Roughness of the line (higher = rougher)
      };

      // Drawing a line from (50, 50) to (300, 200)
      rc.line(500, 500, 500, 200, lineProps);
      rc.line(500, 500, 1000, 500, lineProps);
    }
  }, [canvasRef]);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   context.lineCap = "round";
  //   context.lineJoin = "round";
  //   context.lineWidth = 2; // Adjust line width
  // }, []);

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  const [lastPos, setLastPos] = useState({ x: 0, y: 0 });

  const getMousePos = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  };

  const handlePointerDown = (e) => {
    const pos = getMousePos(e);
    setLastPos(pos);
    setIsDrawing(true);
  };

  const handlePointerMove = (e) => {
    if (!isDrawing) return;

    const context = canvasRef.current.getContext("2d");
    const pos = getMousePos(e);

    // Drawing logic
    context.beginPath();
    context.moveTo(lastPos.x, lastPos.y);
    context.lineTo(pos.x, pos.y);
    context.stroke();

    setLastPos(pos);
  };

  const handlePointerUp = () => {
    setIsDrawing(false);
  };

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  // window.addEventListener("beforeunload", function (e) {
  //   // This condition could check if the user has unsaved changes
  //   var confirmationMessage =
  //     "Are you sure you want to leave? You might have unsaved changes.";

  //   // Some browsers will display the return value as the confirmation message
  //   e.returnValue = confirmationMessage; // Legacy method for older browsers

  //   // For modern browsers, this confirmation message will not be shown, but the dialog will appear
  //   return confirmationMessage;
  // });

  // ------------------------------------------------------------------------
  const [value, setValue] = useState(0.5);
  const saveImage = async () => {
    const canvas = canvasRef.current;
    const image = imageRef.current;

    // Export the sketch as a data URL
    const sketchDataUrl = await canvas.toDataURL("image/png");

    // Create an off-screen canvas to combine the background and the sketch
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = image.naturalWidth;
    offscreenCanvas.height = image.naturalHeight;
    const ctx = offscreenCanvas.getContext("2d");

    const rationWidth = image.width / image.naturalWidth;
    const rationHeight = image.naturalHeight / image.height;
    const delta = image.width - image.naturalWidth;

    // step 1: Draw the background image on the off-screen canvas
    ctx.drawImage(image, 0, 0, offscreenCanvas.width, offscreenCanvas.height);

    // Step 2: Draw the semi-transparent layer
    // ctx.fillStyle = `rgba(255, 255, 0, ${value / 100})`; // 50% opacity yellow layer
    // ctx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

    const goldenWidth = canvasRef.current?.getBoundingClientRect().width;
    const silverWidth = imageRef.current?.getBoundingClientRect().width;
    const adjustmentRation = goldenWidth / silverWidth;

    console.log("from save image @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    console.log(goldenWidth, silverWidth);
    console.log(adjustmentRation);

    // step 3: Draw the sketch on top of the background image
    const sketchImage = new Image();
    sketchImage.src = sketchDataUrl;
    sketchImage.onload = () => {
      ctx.drawImage(
        sketchImage,
        0,
        0,
        offscreenCanvas.width * adjustmentRation,
        offscreenCanvas.height
      );

      const localDis = true;
      if (localDis) {
        // Convert the combined canvas to a data URL and trigger download
        const combinedImageDataUrl = offscreenCanvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = combinedImageDataUrl;
        link.download = "sketch-over-image.png";
        link.click();
      } else {
        // Convert the combined canvas to Blob
        const storage = getStorage();
        offscreenCanvas.toBlob(async (blob) => {
          // Create a reference to the Firebase storage location
          const storageRef = ref(storage, `sketches/testing-${Date.now()}.png`);

          // Upload the Blob to Firebase Storage
          await uploadBytes(storageRef, blob).then((snapshot) => {
            console.log("Uploaded the sketch over the image successfully!");

            // Optionally, get the download URL
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              alert(downloadURL);
              console.log("Download URL:", downloadURL);
            });
          });
        }, "image/png");
      }
    };
  };

  const [lines, setLines] = useState([]); // Stack of lines for undo/redo
  const [undoLines, setUndoLines] = useState([]); // Stack for redo

  // Undo functionality
  const undo = () => {
    if (lines.length > 0) {
      const newLines = [...lines];
      const undoneLine = newLines.pop();
      setUndoLines([...undoLines, undoneLine]);
      setLines(newLines);
    }
  };

  // Redo functionality
  const redo = () => {
    if (undoLines.length > 0) {
      const newUndoLines = [...undoLines];
      const restoredLine = newUndoLines.pop();
      setLines([...lines, restoredLine]);
      setUndoLines(newUndoLines);
    }
  };

  // -----------------------------------------------------------------------

  const handleKeydown = (event) => {
    if (event.keyCode == "32") {
      setIsPanning(true);
    }
    if (event.keyCode == "70") {
      restMotion();
    }
    if (event.keyCode == "71") {
      setIsFullScreen(true);
    }
  };

  const restMotion = () => {
    toast.success("reset");
    motionScale.set(1);
    motionX.set(0.5);
    motionY.set(0.5);
    imageX.set(0);
    imageY.set(0);
    setPosition({ x: 0, y: 0 });
  };

  const handleKeyup = (event) => {
    if (event.keyCode == "32") {
      setIsPanning(false);
    }
  };

  const [isPanning, setIsPanning] = useState(false);
  const startDrawing = ({ keyCode, target, nativeEvent }) => {
    if (isPanning || !isFullScreen) {
      return;
    }
    setIsDrawing(true);

    const offsetX = nativeEvent.offsetX;
    const offsetY = nativeEvent.offsetY * resolveScallingY();

    if (isErasing) {
      setLines([...lines, { points: [{ offsetX, offsetY }], isErasing: true }]);
    } else {
      setLines([
        ...lines,
        { points: [{ offsetX, offsetY }], isErasing: false },
      ]);
    }
  };

  const finishDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
    setIsPanning(false);
  };

  // drawing or ereasing
  const [dRatio, setDRatio] = useState(0);
  const [prop, setProp] = useState(0);
  const draw = ({ preventDefault, nativeEvent }) => {
    if (!isDrawing || !isFullScreen) {
      return;
    }

    nativeEvent.preventDefault();

    const imgHeight = imageRef.current?.getBoundingClientRect().height;
    const imgNatHeight = imageRef.current?.naturalHeight;
    const ratio =
      imageRef.current?.naturalHeight /
      imageRef.current?.getBoundingClientRect().height;

    const delta = window.screen.height + 10 - imageRef.current?.naturalHeight;
    const adding = nativeEvent.offsetY / delta;
    const r = delta / imageRef.current?.naturalHeight;

    const offsetX = nativeEvent.offsetX;
    let offsetY = nativeEvent.offsetY * resolveScallingY();

    setDRatio(resolveScallingY());
    setProp(resolveScallingY());

    const newLines = [...lines];
    if (isErasing) {
      const lastLine = newLines[newLines.length - 1];
      lastLine.points = [...lastLine.points, { offsetX, offsetY }];
      setLines(newLines);

      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(
        offsetX - eraserSize / 2,
        offsetY - eraserSize / 2,
        eraserSize,
        eraserSize
      );
    } else {
      const lastLine = newLines[newLines.length - 1];
      lastLine.points = [...lastLine.points, { offsetX, offsetY }];
      setLines(newLines);
    }
  };

  const resolveScallingY = () => {
    const ratio = imageRef.current?.naturalHeight / height;
    const adjustedRatio = ratio - 0.05;
    const overridRatio = 0.5;
    return isFullScreen ? adjustedRatio : 1;
  };

  // Helper function to handle drawing logic
  const updateDrawing = (x, y) => {
    const newLines = [...lines];
    const lastLine = newLines[newLines.length - 1];
    lastLine.points = [...lastLine.points, { offsetX: x, offsetY: y }];
    setLines(newLines);
  };

  const clearCanvas = () => {
    if (window.confirm("clear canvas")) {
      const context = canvasRef.current.getContext("2d");
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      setLines([]);
      setUndoLines([]);
    }
  };

  // Repaint all the lines
  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

      lines.forEach((line) => {
        if (line.isErasing) {
          // Erase by clearing part of the canvas where the eraser went
          line.points.forEach((point) => {
            context.clearRect(
              point.offsetX - eraserSize / 2,
              point.offsetY - eraserSize / 2,
              eraserSize,
              eraserSize
            );
          });
        } else {
          // Drawing
          context.beginPath();
          for (let i = 0; i < line.points.length - 1; i++) {
            context.moveTo(line.points[i].offsetX, line.points[i].offsetY);
            context.lineTo(
              line.points[i + 1].offsetX,
              line.points[i + 1].offsetY
            );
          }
          context.strokeStyle = "black"; // Drawing in black
          context.lineWidth = 15; // Standard line width
          context.stroke();
        }
      });
    }
  }, [lines]);

  // Function to simplify points using simplify.js
  const simplifyLine = (points) => {
    // Convert your points to a format simplify.js accepts
    const simplified = simplify(
      points.map((point) => ({ x: point.offsetX, y: point.offsetY })),
      0.01, // Tolerance level for simplification
      true // High-quality simplification
    );
    // Convert back to original format
    const p = simplified.map((point) => ({
      offsetX: point.x,
      offsetY: point.y,
    }));
    console.log(p);
    return p;
  };

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   const roughCanvas = rough.canvas(canvas); // Rough.js context

  //   context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

  //   lines.forEach((line) => {
  //     if (line.isErasing) {
  //       // Erasing logic
  //       line.points.forEach((point) => {
  //         context.clearRect(
  //           point.offsetX - eraserSize / 2,
  //           point.offsetY - eraserSize / 2,
  //           eraserSize,
  //           eraserSize
  //         );
  //       });
  //     } else {
  //       console.log(line.points);
  //       // Simplify the points of the line before drawing
  //       const simplifiedPoints = simplifyLine(line.points);

  //       if (simplifiedPoints.length > 1) {
  //         for (let i = 0; i < simplifiedPoints.length - 1; i++) {
  //           const { offsetX: x1, offsetY: y1 } = simplifiedPoints[i];
  //           const { offsetX: x2, offsetY: y2 } = simplifiedPoints[i + 1];

  //           // Draw rough lines using Rough.js
  //           roughCanvas.line(x1, y1, x2, y2, {
  //             stroke: "black",
  //             strokeWidth: 15,
  //             roughness: 1.5, // Adjust roughness as needed
  //           });
  //         }
  //       }
  //     }
  //   });
  // }, [lines]);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   const roughCanvas = rough.canvas(canvas); // Create a rough.js canvas

  //   context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

  //   lines.forEach((line) => {
  //     if (line.isErasing) {
  //       // Erase by clearing part of the canvas where the eraser went
  //       line.points.forEach((point) => {
  //         context.clearRect(
  //           point.offsetX - eraserSize / 2,
  //           point.offsetY - eraserSize / 2,
  //           eraserSize,
  //           eraserSize
  //         );
  //       });
  //     } else {
  //       // Drawing with Rough.js
  //       const points = line.points.map((point) => [
  //         point.offsetX,
  //         point.offsetY,
  //       ]);
  //       if (points.length > 1) {
  //         for (let i = 0; i < points.length - 1; i++) {
  //           const [x1, y1] = points[i];
  //           const [x2, y2] = points[i + 1];
  //           // Use rough.js to draw rough lines
  //           roughCanvas.line(x1, y1, x2, y2, {
  //             stroke: "black",
  //             strokeWidth: 10,
  //             roughness: 1, // Adjust roughness here
  //             bowing: 2,
  //           });
  //         }
  //       }
  //     }
  //   });
  // }, [lines]);

  const resolveErease = () => {
    setIsErasing(!isErasing);
  };

  const [scale, setScale] = useState(1);
  const [originX, setOriginX] = useState(0.5); // Default origin (center)
  const [originY, setOriginY] = useState(0.5);

  const motionScale = useMotionValue(1);
  const imageX = useMotionValue(0);
  const imageY = useMotionValue(0);
  const motionX = useMotionValue(0.5);
  const motionY = useMotionValue(0.5);

  // origin ref
  const originXRef = useRef(0.5);
  const originYRef = useRef(0.5);

  // ------------------------------------------------------------

  const handleWheel = (event) => {
    // event.preventDefault();
    console.log("on wheel");

    if (event.deltaY > 0 && motionScale.get() <= 0.5) {
      return;
    }

    // Get the div's bounding box to compute the pointer position relative to the div
    const divBoundingBox = event.target.getBoundingClientRect();

    // Calculate the pointer position relative to the bounding box
    const pointerX =
      (event.clientX - divBoundingBox.left) / divBoundingBox.width;
    const pointerY =
      (event.clientY - divBoundingBox.top) / divBoundingBox.height;

    // Set the scale origin to the pointer's position
    // setOriginX((prevOriginX) => pointerX);
    // setOriginY((prevOriginY) => pointerY);

    // useMotionValue
    motionX.set(pointerX);
    motionY.set(pointerY);

    // useRef
    originXRef.current = pointerX;
    originYRef.current = pointerY;

    // use state
    flushSync(() => {
      setOriginX((prevOriginX) => pointerX);
      setOriginY((prevOriginY) => pointerY);
    });

    // ------------------------------------------------------------

    // Adjust the scale factor smoothly
    const scaleChange = event.deltaY > 0 ? -0.1 : 0.1;
    const s = Math.max(0.5, motionScale.get() + scaleChange);
    motionScale.set(s);

    // Update the scale based on the wheel direction
    setScale((prevScale) => {
      const newScale = prevScale + event.deltaY * -0.001; // Adjust scaling sensitivity
      return Math.max(0.5, Math.min(newScale, 3)); // Restrict scaling between 0.5 and 3
    });
  };

  const handleMouseMove = (event) => {};
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialDrag, setInitialDrag] = useState(true);

  const handleDragEnd = (event, info) => {
    setPosition({
      x: info.point.x - event.offsetX,
      y: info.point.y - event.offsetY,
    });
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleFullScreenChnage = (isFull) => {
    // handleResize();
    setIsFullScreen(isFull);
  };

  return (
    <div
      onMouseLeave={() => setIsPanning(false)}
      onWheel={handleWheel}
      className="bg-red-400"
      style={{
        top: 0,
        left: 0,
        display: "relative",
        width: width,
        height: height,
        overflow: "hidden",
      }}
    >
      <FullScreen isFullScreen={isFullScreen} onChange={handleFullScreenChnage}>
        <motion.div
          tabIndex={0}
          onKeyDown={handleKeydown}
          onKeyUp={handleKeyup}
          onDragEnd={handleDragEnd}
          drag={isPanning ? true : false}
          ref={motionRef}
          style={{
            psition: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            originX: motionX.get(), // originXRef.current,
            originY: motionY.get(), // </div>originYRef.current,
          }}
          initial={{ scale: 1 }} // Initial scale
          // animate={{ scale }}
          animate={{
            scale: motionScale.get(),
            // originX: motionX.get(),
            // originY: motionX.get(),
            x: position.x,
            y: position.y,
          }}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 200,
            damping: 20,
          }} // Add a smooth transition
        >
          <img
            className="bg-green-500"
            ref={imageRef}
            draggable="false"
            src={ImageA}
            style={{
              display: "flex",
              position: "absolute",
              top: 0,
              left: 0,
              width: isFullScreen ? imageRef.current?.innerWidth : width, // keep it this way so it would not be sented with fit contain
              height: height,
              zIndex: 1,
              pointerEvents: "none",
              objectFit: isFullScreen ? "contain" : "cover",
              overflow: "hidden",
            }}
          />

          <canvas
            onMouseDown={startDrawing}
            onMouseUp={finishDrawing}
            onMouseMove={draw}
            onPointerDown={startDrawing}
            onPointerMove={draw}
            onPointerUp={finishDrawing}
            className={`${
              isFullScreen ? "bg-blue-500/50" : ""
            } overflow-hidden`}
            style={{
              opacity: isFullScreen ? 1 : 0,
              position: "absolute",
              top: 0,
              left: 0,
              width: width,
              height: height,
              zIndex: 2, // isFullScreen ? 2 : -2,
            }}
            ref={canvasRef}
          />
        </motion.div>

        {isFullScreen && (
          <div
            style={{ zIndex: 4 }}
            className="flex gap-1 fixed top-[25px] left-[25px]"
          >
            <button
              onClick={saveImage}
              className="bg-blue-600 px-4 py-2 rounded"
            >
              Save Image
            </button>
            <button
              onClick={resolveErease}
              className={`${
                isErasing ? "bg-green-600" : "bg-blue-400"
              } px-4 py-2 rounded`}
            >
              erease
            </button>
            <button onClick={undo} className="bg-blue-400 px-4 py-2 rounded">
              undo
            </button>
            <button onClick={redo} className="bg-blue-400 px-4 py-2 rounded">
              redo
            </button>

            <button
              onClick={clearCanvas}
              className="bg-blue-600 px-4 py-2 rounded"
            >
              clear
            </button>
            <h2>{lines.length}</h2>
            <h2>{isPanning.toString()}</h2>
            <h2 className="ml-2">{motionScale.get().toFixed(2)}</h2>
            <h2 className="ml-4">{motionX.get().toFixed(2)}</h2>
            <h2 className="ml-4">{motionY.get().toFixed(2)}</h2>
            <h2 className="ml-4">
              x:{motionRef.current?.getBoundingClientRect().left}
            </h2>
            <div>
              <div>
                <h3>
                  canvas W:
                  {canvasRef.current?.getBoundingClientRect().width.toFixed(2)}
                </h3>
                <h3>
                  canvas H:
                  {canvasRef.current?.getBoundingClientRect().height.toFixed(2)}
                </h3>
              </div>
              <div>
                <h3>
                  image W:
                  {imageRef.current?.getBoundingClientRect().width.toFixed(2)}
                </h3>
                <h3>
                  image H:
                  {imageRef.current?.getBoundingClientRect().height.toFixed(2)}
                </h3>
                <h3>
                  image h:
                  {imageRef.current?.naturalHeight.toFixed(2)}
                </h3>
                <h3>
                  image w:
                  {imageRef.current?.naturalWidth.toFixed(2)}
                </h3>
                <h3>
                  ration:
                  {dRatio.toFixed(2)}
                </h3>
                <h3>
                  prop:
                  {prop.toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
        )}
      </FullScreen>
    </div>
  );
}
