import React, { type ReactNode, useState } from "react";
import logo from "../../assets/playground/logo.png";
import GoalsHeader from "./GoalsHeader";
import { toast } from "react-toastify";
import CourseGoalList from "./CourseGoalList";
import NewGoal from "./NewGoal";
import InfoBox from "./InfoBox";

export type CourseGoal = {
  title: string;
  description: string;
  id: number;
};

export default function Coal({}) {
  const [goals, setGoals] = useState<CourseGoal[]>([]);

  const handleAddGoal = (goal: string, summery: string) => {
    setGoals((prevGoals) => {
      // new goal
      const newGoal: CourseGoal = {
        title: goal,
        description: summery,
        id: Math.random(),
      };
      return [...prevGoals, newGoal];
    });
  };

  const handleDeleteCoal = (id: number) => {
    setGoals((prevGoals) => prevGoals.filter((goal) => goal.id != id));
  };

  return (
    <main>
      <div className="mx-auto my-4 rounded-lg flex flex-col items-center justify-center w-[700px] bg-gray-600">
        <div className="mt-[50px]">
          <GoalsHeader image={{ src: logo, alt: "some text" }}>
            <p className="cursor-default text-center mt-2 text-white">
              some child prop goes here
            </p>
          </GoalsHeader>
        </div>
        <div className="my-5">
          <NewGoal onAddGoal={handleAddGoal} />
          <div className="mt-7">
            <CourseGoalList goals={goals} onDelete={handleDeleteCoal} />
          </div>
        </div>
      </div>
    </main>
  );
}
