import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import Collection from "../../models/Collection";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { toast } from "react-toastify";
import { db } from "../../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import Enums from "../../models/Enums";
import SelectField from "../../fields/SelectField";
import ColProList from "./ColProList";
import HexCrumb from "./HexCrumb";
import AppsIcon from "@mui/icons-material/Apps";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const iconStyles = {
  largeIcon: {
    width: 160,
    height: 160,
  },
};

export default function CollectionHex() {
  let [isFullScreen, setFullScreen] = useState(false);
  const { height, width } = useWindowDimensions();
  const { id } = useParams();
  const { document: collection, error } = useDocument("collections", id);
  const { projects, collections } = useCollectionContext();

  const [mopen, setmopen] = useState(false);
  const [copen, setcopen] = useState(false);

  const handleAddToCollection = async (project) => {
    if (window.confirm("add to collection")) {
      const d = {
        ...collection,
        projects: [...collection.projects, project.id],
      };
      const docRef = doc(db, "collections", collection.id);

      await setDoc(docRef, d, { merge: true }).then((ref) => {
        toast.success("add to collection");
      });
    }
  };

  // pull images
  const [images, setImages] = useState([]);
  const [sentinals, setSentinals] = useState([]);
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    if (collection) {
      const xCol = Collection.fromData(collection);
      const {
        imgs,
        sents,
        titles: t,
      } = xCol.stageImages(projectsWithId(xCol.projects));

      setImages(imgs);
      setSentinals(sents);
      setTitles(t);
      setIndex(0);
      setCurrentHexImage(imgs[0]);
    }
  }, [collection]);

  const projectsWithId = (projIds) => {
    const r = [];
    projIds?.forEach((projId) => {
      const p = projects.filter((proj) => proj.id == projId)[0];
      r.push(p);
    });
    return r;
  };

  const dismiss = () => {
    // setShowEditHex(false);
    // setShowAddImageForm(false);
    // setShowMenu(true);
  };

  /// active index
  const [index, setIndex] = useState(0);
  // active image
  const [currentHexImage, setCurrentHexImage] = useState("");

  const handleTreverse = (e) => {
    const xval = e.nativeEvent.clientX;

    const screenWidth = width;
    const screenMid = width / 2;

    if (xval > screenMid) {
      goForward();
    } else {
      goBack();
    }

    if (!isFullScreen) {
      dismiss();
    }
  };

  const goForward = () => {
    var i = index + 1;
    if (i > images.length - 1) {
      i = 0;
    }
    setIndex(i);
    setCurrentHexImage(images[i]);
  };

  const goBack = () => {
    var i = index - 1;
    if (i < 0) {
      i = images.length - 1;
    }
    setIndex(i);
    setCurrentHexImage(images[i]);
  };

  const handleKeyDown = (e) => {
    // event

    if (e.keyCode == "70") {
      setFullScreen(true);
    }

    // listen for right arrow
    if (e.keyCode == "39") {
      goForward();
    }

    // listen for left arrow
    if (e.keyCode == "37") {
      goBack();
    }
  };

  const blackContainer = useRef(null);

  const resolveCurrentIndex = (i) => {
    setIndex(i);
    setCurrentHexImage(images[i]);
  };

  const collectionIsEmpty = () => {
    return images.length < 1;
  };

  return (
    <div
      style={{ height: height - 50 }}
      className="relative overflow-hidden bg-green-200"
    >
      <FullScreen
        isFullScreen={isFullScreen}
        onChange={(isFull) => {
          setFullScreen(isFull);
        }}
      >
        {collectionIsEmpty() && (
          <h1 className="absolute top-[50%] text-black">
            <div className="w-screen text-center">select projects</div>
            <div className="w-screen text-center" fontSize={"large"}>
              <div className="mt-4 opacity-75 hover:scale-150 transition duration-150 ease-in-out cursor-pointer">
                <AddCircleOutlineIcon
                  onClick={() => setmopen(true)}
                  style={{ fontSize: 40, fontWeight: "lighter" }}
                />
              </div>
            </div>
          </h1>
        )}

        <CollectionMenu
          copen={copen}
          setcopen={setcopen}
          collection={collection}
        />

        <ProjectsMenu
          mopen={mopen}
          setmopen={setmopen}
          handleAddToCollection={handleAddToCollection}
        />

        <div
          tabIndex="-1"
          ref={blackContainer}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          {/* menu controlls */}
          {!isFullScreen && (
            <Controlls setmopen={setmopen} setcopen={setcopen} />
          )}

          {/* image panel */}
          <img
            onClick={(e) => handleTreverse(e)}
            className="w-screen h-screen object-cover"
            src={currentHexImage}
          />

          {/* bread crumbs */}
          {!collectionIsEmpty() && (
            <div className="absolute left-[50px] bottom-[50px] z-10">
              <HexCrumb
                size={images.length}
                sentinals={sentinals}
                titles={titles}
                resolveCurrentIndex={resolveCurrentIndex}
                currentIndex={index}
              />
            </div>
          )}
        </div>
      </FullScreen>
    </div>
  );
}

// ==========================================================================
// project menu
const ProjectsMenu = ({ mopen, setmopen, handleAddToCollection }) => {
  const { projects, collections } = useCollectionContext();
  const [state, setstate] = useState({
    query: "",
    list: [],
  });

  const [requiredTags, setRequiredTags] = useState([]);
  const [activeList, setActiveList] = useState([]);
  useEffect(() => {
    const mapTags = (itemTags) => {
      return itemTags.map((tag) => tag.value);
    };

    const tagedProjects = projects?.filter((item) =>
      requiredTags.every((tag) => mapTags(item.tags).includes(tag))
    );

    // set the list
    if (requiredTags.length < 1) {
      setActiveList(projects);
      setstate({ query: "", list: projects });
    } else {
      setActiveList(tagedProjects);
      setstate({ query: "", list: tagedProjects });
    }
  }, [requiredTags, projects]);

  // update the filter
  const handleChange = (e) => {
    // use query to filer
    const results = activeList.filter((project) => {
      if (e.target.value === "") return projects;
      return (
        project.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        project.number.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setstate({
      query: e.target.value,
      list: results,
    });
  };

  const [tagOption, setTagOption] = useState([]);
  const resolveTagOption = (option) => {
    setTagOption(option);
    const tags = option.map((p) => p.value);
    setRequiredTags(tags);
  };

  return (
    <div>
      <div
        onMouseLeave={() => setmopen(false)}
        style={{ width: mopen ? "400px" : "0px" }}
        className={`flex flex-col overflow-hidden duration-300 items-center absolute top-0 left-0 bg-gray-400 w-[300px] h-full z-10`}
      >
        <div onClick={() => setmopen(false)} className="flex w-full mr-4">
          <div className="ml-auto cursor-pointer">close</div>
        </div>

        <div className="flex w-[400px] justify-center mt-4">
          <input
            className="w-[300px] h-[40px] px-4 bg-gray-300 rounded-lg"
            placeholder="search for scope"
            onChange={handleChange}
            value={state.query}
            type="search"
          />
        </div>
        <div className="mt-1">
          <SelectField
            label={"tags"}
            production={true}
            showLabel={false}
            options={Enums.TagOptions}
            data={tagOption}
            setData={resolveTagOption}
            isActive={true}
            isMulti={true}
          />
        </div>

        <div className="w-[300px] ml-4 font-normal cursor-default text-xs">
          {state.list?.length} projects
        </div>

        <div className="mt-4 relative flex flex-col gap-1">
          {state.list?.map((project, index) => (
            <div
              key={index}
              onClick={() => handleAddToCollection(project)}
              className="flex items-center h-[40px] w-[300px] transition-colors duration-100 ease-in-out hover:bg-gray-300 rounded cursor-pointer"
            >
              <div className="ml-4">{project.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// collection menu
const CollectionMenu = ({ copen, setcopen, collection }) => {
  return (
    <div>
      <div
        onMouseLeave={() => setcopen(false)}
        style={{ width: copen ? "400px" : "0px" }}
        className={`flex flex-col overflow-hidden duration-300 items-center absolute top-0 left-0 bg-orange-600 w-[300px] h-full z-10`}
      >
        <div
          onClick={() => setcopen(false)}
          className="cfursor-pointer mt-2 h-[40px]"
        >
          <h1 className="">collection projects</h1>
        </div>
        <div className="flex flex-col gap-2 mt-4">
          <ColProList collection={collection} projects={collection?.projects} />
        </div>
      </div>
    </div>
  );
};

// project & collection menues
const Controlls = ({ setmopen, setcopen }) => {
  return (
    <div>
      <div className="flex gap-2 absolute">
        <h1
          onClick={() => setmopen(true)}
          className="cursor-pointer text-white"
        >
          [projects]
        </h1>
        <h1
          onClick={() => setcopen(true)}
          className="cursor-pointer text-white"
        >
          [collection]
        </h1>
      </div>
    </div>
  );
};
