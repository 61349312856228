import { useState, useEffect } from "react";

function useDeviceDetection() {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iPadOS 13+ reports itself as a Mac, so we detect that separately
    const isIPad =
      navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;

    // Detect mobile devices
    const isMobileDevice =
      /android|iPhone|iPod/.test(userAgent.toLowerCase()) &&
      window.innerWidth <= 768;

    // Refined Android tablet detection (including Galaxy Tab)
    const isAndroidTablet =
      /android/.test(userAgent.toLowerCase()) &&
      window.innerWidth > 768 &&
      window.innerWidth <= 1366;

    // iPads and Android tablets (including Galaxy Tab)
    const isTabletDevice =
      isIPad ||
      isAndroidTablet ||
      (/iPad/.test(userAgent) && window.innerWidth > 768);

    if (isMobileDevice) {
      setDeviceType("mobile");
    } else if (isTabletDevice) {
      setDeviceType("tablet");
    } else {
      setDeviceType("desktop");
    }
  }, []);

  return deviceType;
}

export default useDeviceDetection;
