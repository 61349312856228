import React from "react";
import CourseGoal from "./CourseGoal";
import { type CourseGoal as xCourseGoal } from "./Goals";
import InfoBox from "./InfoBox";
import { type ReactNode } from "react";

type CourseGoalsListProps = {
  goals: xCourseGoal[];
  onDelete: (id: number) => void;
};

export default function CourseGoalList({
  goals,
  onDelete,
}: CourseGoalsListProps) {
  if (goals.length == 0) {
    return (
      <InfoBox severity="medium" mode="hint">
        You have not course goals yet
      </InfoBox>
    );
  }

  let warningBox: ReactNode;

  if (goals.length >= 4) {
    warningBox = (
      <InfoBox severity="medium" mode="warning">
        You have too many goals
      </InfoBox>
    );
  }

  return (
    <>
      {warningBox}
      <div>
        <ul>
          {goals.map((goal) => (
            <li key={goal.id}>
              <CourseGoal id={goal.id} title={goal.title} onDelete={onDelete}>
                <p>{goal.description}</p>
              </CourseGoal>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
