import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { HexpoTools, Colors } from "../../models/Enums";
import { useCanvasContext } from "../../hooks/useCanvasContext";

// icons
import StraightenIcon from "@mui/icons-material/Straighten";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import CreateIcon from "@mui/icons-material/Create";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AppsIcon from "@mui/icons-material/Apps";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function CanvasQuickBrush({ project }) {
  const {
    setShowTools,
    showQuickBrush,
    setShowQuickBrush,
    setActiveQuickBrush,
    setShowCanvasPages,
    setSessionColor,
  } = useCanvasContext();

  // listen to component mounting
  useEffect(() => {
    if (showQuickBrush) {
      const timer = setTimeout(() => {
        onMount();
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [showQuickBrush]);

  const onMount = () => {
    // set active brush
    // setActiveQuickBrush(1);
    // setup the tool
  };

  const dismissQuickBrush = () => {
    setShowCanvasPages(true);
    setActiveQuickBrush(0);
    setShowTools(true);
    setShowQuickBrush(false);
  };

  return (
    <div
      className={`flex items-center absolute bottom-6 left-1/2 transform transition-all duration-300 ease-in-out w-full h-[56px] rounded-lg ${
        showQuickBrush
          ? "translate-y-0 opacity-100"
          : "translate-y-20 opacity-0"
      } -translate-x-1/2`}
    >
      <div className="flex flex-grow justify-center w-full">
        <div className="flex items-center gap-1">
          <div
            onClick={dismissQuickBrush}
            className="flex items-center justify-center text-white bg-black rounded-lg w-[56px] h-[56px] cursor-pointer"
          >
            <MoreHorizIcon fontSize="large" />
          </div>

          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={1}
              signiture={{
                tool: HexpoTools.Draw,
                brush: -1,
                color: Colors.BLACK,
                opacity: 100,
              }}
            />
          </div>

          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={2}
              signiture={{
                tool: HexpoTools.Draw,
                brush: 0,
                color: Colors.BLACK,
                opacity: 100,
              }}
            />
          </div>

          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={3}
              signiture={{
                tool: HexpoTools.Draw,
                brush: 2,
                color: Colors.BLACK,
                opacity: 100,
              }}
            />
          </div>
          <Spacer />
          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={4}
              signiture={{
                tool: HexpoTools.Draw,
                brush: 0,
                color: Colors.WHITE,
                opacity: 100,
              }}
            />
          </div>
          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={55}
              signiture={{
                tool: HexpoTools.Draw,
                brush: 0,
                color: Colors.RED_300,
                opacity: 100,
              }}
            />
          </div>
          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={5}
              signiture={{
                tool: HexpoTools.Draw,
                brush: 1,
                color: Colors.RED_300,
                opacity: 100,
              }}
            />
          </div>
          <Spacer />
          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={6}
              signiture={{
                tool: HexpoTools.Paint,
                brush: 1,
                color: Colors.BLACK,
                opacity: 50,
              }}
            />
          </div>
          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={7}
              signiture={{
                tool: HexpoTools.Paint,
                brush: 1,
                color: Colors.ORANGE_300,
                opacity: 65,
              }}
            />
          </div>
          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={9}
              signiture={{
                tool: HexpoTools.Paint,
                brush: 1,
                color: Colors.GREEN_400,
                opacity: 65,
              }}
            />
          </div>
          <div className="bg-black rounded-lg w-[56px] h-[56px]">
            <ToolDisk
              id={10}
              signiture={{
                tool: HexpoTools.Paint,
                brush: 1,
                color: Colors.WHITE,
                opacity: 100,
              }}
            />
          </div>
          <Spacer />
          <div className="bg-gray-600 rounded-lg w-[56px] h-[56px]">
            <EreaserTool
              id={8}
              signiture={{
                tool: HexpoTools.Erase,
                brush: 4,
                color: Colors.WHITE,
                opacity: 100,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Spacer = ({ size = 50 }) => {
  return <div style={{ width: size }}></div>;
};

const EreaserTool = ({ id = 0, signiture, diskSize = 40 }) => {
  const {
    activeQuickBrush,
    setActiveQuickBrush,
    handleBrush,
    setOpacity,
    setSessionColor,
    handleToolClick,
  } = useCanvasContext();

  const handleSetTool = () => {
    setActiveQuickBrush(id);
    // setup tool
    handleToolClick(signiture.tool);
    handleBrush(signiture.brush);
    setOpacity(signiture.opacity || 100.0);
    setSessionColor(signiture.color || Colors.GREEN_400);
  };

  return (
    <div className="flex items-center justify-center w-fll h-full">
      <div
        onClick={handleSetTool}
        style={{
          width: diskSize,
          height: diskSize,
        }}
        className={`flex items-center justify-center rounded-full ${
          activeQuickBrush == id ? "bg-red-500" : "bg-red-500/40"
        }  border border-white/50 cursor-pointer text-white/50`}
      >
        <CheckBoxOutlineBlankIcon />
      </div>
    </div>
  );
};

const ToolDisk = ({ id = 0, signiture, diskSize = 40 }) => {
  const {
    activeQuickBrush,
    setActiveQuickBrush,
    handleBrush,
    setOpacity,
    setSessionColor,
    handleToolClick,
  } = useCanvasContext();

  const handleSetTool = () => {
    setActiveQuickBrush(id);
    // setup tool
    handleToolClick(signiture.tool);
    handleBrush(signiture.brush);
    setOpacity(signiture.opacity || 100.0);
    setSessionColor(signiture.color || Colors.GREEN_400);
  };

  return (
    <div className="flex items-center justify-center w-fll h-full">
      <div
        onClick={handleSetTool}
        style={{
          width: diskSize,
          height: diskSize,
        }}
        className={`flex items-center justify-center rounded-full ${
          activeQuickBrush == id ? "bg-red-500" : "bg-red-500/40"
        }  border border-white/50 cursor-pointer `}
      >
        {signiture.tool == HexpoTools.Draw && <CreateIcon />}
        {signiture.tool == HexpoTools.Paint && <ImagesearchRollerIcon />}
      </div>
    </div>
  );
};
