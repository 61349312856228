import { useState, useEffect, useRef } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config"; // Assuming you have this file for Firebase initialization

const useGetMembersForEntity = (collectionName, entityIds) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const unsubscribeRef = useRef(null); // To keep track of unsubscribe function

  useEffect(() => {
    if (!entityIds || entityIds.length === 0) {
      setDocuments([]);
      setLoading(false);
      return;
    }

    // Function to unsubscribe from the previous Firestore listener (if exists)
    const unsubscribe = unsubscribeRef.current;
    if (unsubscribe) {
      unsubscribe(); // Clean up previous listener
    }

    // Set up new Firestore listener for members with matching entityIds
    const q = query(
      collection(db, collectionName),
      where("entityId", "in", entityIds) // Query members where entityId is in the entityIds array
    );

    const newUnsubscribe = onSnapshot(
      q,
      (snapshot) => {
        let docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDocuments(docs);
        setLoading(false);
      },
      (err) => {
        setError(err.message);
        setLoading(false);
      }
    );

    // Store the new unsubscribe function
    unsubscribeRef.current = newUnsubscribe;

    // Cleanup function to unsubscribe when component unmounts or entityIds change
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [collectionName, entityIds]);

  return { documents, loading, error };
};

export default useGetMembersForEntity;
