export default function StackSegmentedButton({
  currentStack,
  setCurrentStack,
}) {
  /***
   * handle click
   */
  const handleClick = (lebel) => {
    setCurrentStack(lebel);
  };

  return (
    <div className="flex  justify-center gap-1 mb-2">
      <StackButton
        label={"client"}
        buttonLabel={"present"}
        currentStack={currentStack}
        handleClick={handleClick}
      />
      <StackButton
        label={"principle"}
        buttonLabel={"review"}
        currentStack={currentStack}
        handleClick={handleClick}
      />
      <StackButton
        label={"studio"}
        buttonLabel={"design"}
        currentStack={currentStack}
        handleClick={handleClick}
      />
    </div>
  );
}

/***
 * stack button
 */
const StackButton = ({
  label,
  buttonLabel = "none",
  currentStack,
  handleClick,
}) => {
  return (
    <div
      onClick={() => handleClick(label)}
      className={`w-[140px] ${
        currentStack == label ? "bg-gray-400" : "bg-gray-400/50"
      }  rounded p-2 text-center cursor-pointer`}
    >
      {buttonLabel}
    </div>
  );
};
