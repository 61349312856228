import { useState, useEffect, useCallback } from "react";

function useLongPress(callback = () => {}, ms = 300, onClick = () => {}) {
  const [startLongPress, setStartLongPress] = useState(false);
  const [isLongPress, setIsLongPress] = useState(false);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(() => {
        callback();
        setIsLongPress(true);
      }, ms);
    } else {
      clearTimeout(timerId);
      setIsLongPress(false);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [startLongPress, callback, ms]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);

  const stop = useCallback(
    (e, ...args) => {
      if (startLongPress && !isLongPress) {
        onClick(e, ...args); // Pass the event and any additional arguments to onClick
      }
      setStartLongPress(false);
    },
    [startLongPress, isLongPress, onClick]
  );

  return {
    onMouseDown: start,
    onTouchStart: start,
    onMouseUp: (e, ...args) => stop(e, ...args),
    onMouseLeave: (e, ...args) => stop(e, ...args),
    onTouchEnd: (e, ...args) => stop(e, ...args),
  };
}

export default useLongPress;
