import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, deleteDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { toast } from "react-toastify";
import Enums, { ListItemType } from "../../models/Enums";

// import
import PushPinIcon from "@mui/icons-material/PushPin";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";

export default function ProjectListRow({ hexpoData, type }) {
  const { activeUser } = useAuthContext();
  const { document: user } = useDocument("users", activeUser.id);
  const navigate = useNavigate();

  /***
   * handle click
   */
  const handleClick = async (id) => {
    if (type == Enums.ListItemType.project) {
      navigate(`/project/${id}`);
    } else {
      navigate(`/collection/${id}`);
    }
  };

  /***
   * handle delete
   * currently disconnected
   */
  const handleDelete = async () => {
    await deleteDoc(doc(db, "projects", hexpoData.id)).then((ref) => {
      toast("hi");
    });
  };

  /***
   * handle pin
   */
  const handlePin = async (e) => {
    e.stopPropagation();
    const d = { pinned: [...user.pinned, hexpoData.id] };
    const docRef = doc(db, "users", user.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {});
  };

  /***
   * resolve is pinned
   */
  const resolveIsPinned = (id) => {
    if (!user) return false;
    return user.pinned.includes(id);
  };

  if (!user) {
    return <div>loading</div>;
  }

  return (
    <div
      onClick={() => handleClick(hexpoData.id)}
      className=" bg-gray-300 cursor-pointer w-full h-[80px] overflow-hidden relative"
    >
      {/* <div>{project.isReady.toString()}</div> */}
      <div className="flex items-center h-full w-full px-4">
        <h2 className="flex gap-1 items-center w-full font-bold">
          <div className="w-[80px]">{hexpoData.number}</div>
          <div className="">{hexpoData.name}</div>
          <div className="font-thin">
            revisions {hexpoData.revisions?.length ?? "x"}
          </div>
          {type == Enums.ListItemType.project &&
            !resolveIsPinned(hexpoData.id) && (
              <div
                onClick={(e) => handlePin(e)}
                className="ml-auto opacity-50 w-11 h-11 flex items-center justify-center rounded-full hover:bg-gray-400 transition duration-300 ease-in-out"
              >
                <PushPinIcon />
              </div>
            )}
        </h2>
      </div>
    </div>
  );
}
