import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDocument } from "../../hooks/useDocument";
import { COLLECTION } from "../../models/Enums";
import MisClient from "../../models/MisClient";
import MisDraft from "../../models/MisDraft";

export default function LawsuitDocumentPage() {
  const { clientId, lawsuitId, docId } = useParams();
  const { document: client } = useDocument(COLLECTION.MisClients, clientId);
  const [document, setDocument] = useState(null);

  useEffect(() => {
    if (client) {
      const misClient = MisClient.fromData(client);
      const d = misClient.getDocumentInLawsuit(docId, lawsuitId);

      setDocument(d);
    }
  }, [client]);

  const handleAddDraft = () => {
    const misClient = MisClient.fromData(client);
    const draft = misClient.addDraftToDocument(lawsuitId, docId);
    draft.name = "blue draft";
    misClient.commit().then((ref) => {
      toast.success("draft added");
    });
  };

  const handleDeleteDraft = (e, draftId) => {
    e.stopPropagation();
    const misClient = MisClient.fromData(client);
    misClient.removeDraftToDocument(lawsuitId, docId, draftId);
    // commit
    misClient.commit().then((ref) => {
      toast.success("draft removed");
    });
  };

  const handleEditName = () => {};
  return (
    <div className="flex flex-col gap-1 w-[1000px] mx-auto">
      <div className="flex w-full items-center p-2 bg-red-400">
        <div>
          <h2 className="cursor-default">Document</h2>
          <div>document id: {docId}</div>
          <div>client name: {client?.name}</div>
        </div>
        <div
          onClick={handleAddDraft}
          className="ml-auto opacity-40 hover:opacity-100 cursor-pointer"
        >
          [add draft]
        </div>
        <div className="ml-2 mr-2 cursor-pointer opacity-40 hover:opacity-100">
          [edit]
        </div>
      </div>
      <div className="flex flex-col gap-1">
        {document &&
          document.drafts.map((draft) => (
            <div key={draft.id}>
              <MisDraftRow
                draft={draft}
                handleDeleteDraft={handleDeleteDraft}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

const MisDraftRow = ({ draft, handleDeleteDraft }) => {
  const pushToCnvas = () => {
    toast.success("push to canvas");
  };

  return (
    <div
      onClick={pushToCnvas}
      className="p-2 bg-gray-400 h-[60px] cursor-pointer"
    >
      <div className="flex">
        <div className="flex items-center">
          <span className="font-bold">draft:</span>{" "}
          <span className="ml-2">{draft.id}</span>
        </div>
        <div
          onClick={(e) => handleDeleteDraft(e, draft.id)}
          className="ml-auto mr-2 opacity-40 hover:opacity-100"
        >
          [deelet]
        </div>
      </div>
      <div>name: {draft.name}</div>
    </div>
  );
};
