import { createContext, useReducer, useEffect, useState } from "react";
import { auth } from "../firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const value = {};

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
