import React, { useRef, type FormEvent } from "react";

type xNewGoalProps = {
  onAddGoal: (goal: string, summery: string) => void;
};

export default function NewGoal({ onAddGoal }: xNewGoalProps) {
  // refs
  const goal = useRef<HTMLInputElement>(null);
  const summery = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const goalValue = goal.current!.value;
    const summeryValue = summery.current!.value;

    // add goal
    onAddGoal(goalValue, summeryValue);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="flex flex-col">
        <label className="font-bold text-white" htmlFor="goal">
          Your goal
        </label>
        <input
          ref={goal}
          className="pl-2 w-[300px] h-[40px] bg-gray-300 rounded-lg outline-1 
          outline-gray-500"
          id="goal"
          type="text"
        />
      </p>
      <p className="flex flex-col mt-1">
        <label className="font-bold text-white" htmlFor="summery">
          Short summery
        </label>
        <input
          ref={summery}
          className="pl-2 w-[300px] h-[40px] bg-gray-300 rounded-lg outline-1 
          outline-gray-500"
          id="summery"
          type="text"
        />
      </p>
      <p>
        <button
          className="w-[300px] h-[40px] bg-yellow-400 rounded-lg mt-10 my-2
        outline-gray-500 hover:bg-yellow-500 transition-colors ease-in-out"
        >
          add goal button
        </button>
      </p>
    </form>
  );
}
