import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";

class MisDraft {
  id = ""; // generated by firebase on adding object to firestore
  name = "";
  clientId = "";
  lawsuitId = "";
  documentId = "";
  number = "";
  date = new Date();
  url = "";

  constructor(_id = uuid()) {
    this.id = _id;
  }

  blankId() {
    delete this.id; // remove the id when first initiating so we can allow firebase to install its document id
    return this;
  }

  static fromData = (data) => {
    const p = new MisDraft(data["id"]);
    p.clientId = data["clientId"];
    p.lawsuitId = data["lawsuitId"];
    p.documentId = data["documentId"];
    p.name = data["name"];
    p.number = data["number"];
    p.date = data["date"];
    p.url = data["url"];

    return p;
  };

  data = () => {
    return {
      ...(this.id !== undefined && { id: this.id }),
      number: this.number,
      clientId: this.clientId,
      lawsuitId: this.lawsuitId,
      documentId: this.documentId,
      name: this.name,
      date: this.date,
      url: this.url,
    };
  };
}
export default MisDraft;
