import React from "react";
import { useNavigate } from "react-router-dom";

export default function CoursesPage() {
  const navigate = useNavigate();

  const handleGoToNigta = (e) => {
    // go to nigta
    navigate("/nigta");
  };

  return (
    <div
      style={{ fontSize: "120px" }}
      className="flex flex-col items-center justify-center w-screen h-[calc(100vh-80px)] mt-[-40px] font-bold text-gray-600"
    >
      <div>courses</div>
      <div onClick={handleGoToNigta} className="cursor-pointer">
        nigta<span className="text-orange-400">.</span>
      </div>
    </div>
  );
}
