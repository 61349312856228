import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Beep from "../../assets/sounds/beep.mp3";
import Cheer from "../../assets/sounds/cheer.mp3";
import Step from "../../assets/sounds/level-up.mp3";
import BGTunes from "../../assets/sounds/game-music.mp3";
import Blip from "../../assets/sounds/sound-bip-alert.mp3";
import ReplayIcon from "@mui/icons-material/Replay";
import { toast } from "react-toastify";
import { letterAudio } from "../../assets/sounds/letter_audio/letter-audio";
import { wordAudio } from "../../assets/sounds/word_audio/word-audio";

// images
import NI_Apple from "../../assets/nigta/Apple.png";
import NI_Orange from "../../assets/nigta/Orange.png";
import NI_Banana from "../../assets/nigta/Banana2.png";
import NI_Pare from "../../assets/nigta/Pare.png";
import NI_Cherry from "../../assets/nigta/Cherry.png";
import NI_Mango from "../../assets/nigta/Mango.png";

// letters
const Apple_letters = ["a", "p", "p", "l", "e"];
const Orange_letters = ["o", "r", "a", "n", "g", "e"];
const Banana_letters = ["b", "a", "n", "a", "n", "a"];
const Mango_letters = ["m", "a", "n", "g", "o"];
const Pare_letters = ["p", "a", "r", "e"];
const Cherry_letters = ["c", "h", "e", "r", "r", "y"];

export default function BuildTheWordPage() {
  const [letters, setLetters] = useState(Orange_letters);
  const [imgSrc, setImgSrc] = useState(NI_Orange);

  const containerRef = useRef(null); // Reference to the container
  const tileBaseRefs = useRef([]);
  const [completeConter, setCompleteConter] = useState(0);
  const completeCount = letters.length;
  const [matchedTiles, setMatchedTiles] = useState(
    Array(letters.length).fill(false) // Track if each tile is matched
  );

  /***
   * prevent body from scrolling
   *
   */
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto"; // Re-enable scrolling when the component unmounts
    };

    document.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  }, []);

  // sound
  const beepRef = useRef(null);
  const cheerRef = useRef(null);
  const stepRef = useRef(null);
  const bgTuneRef = useRef(null);
  const blipRef = useRef(null);
  const letterAudioRef = useRef(null);
  const selectedLetterRef = useRef(null);

  const generatePositions = (numTiles) => {
    const positions = [];
    const spacing = 100; // Minimum spacing between tiles

    for (let i = 0; i < numTiles; i++) {
      let x = i * spacing - (numTiles - 1) * (spacing / 2); // Spread out horizontally
      let y = -(Math.random() * 300 + 50); // Random upward y position

      if (x < 100) {
        x = -(Math.random() * 300 + 50);
      }
      if (y < 100) {
        y = -(Math.random() * 300 + 50);
      }
      positions.push({ x, y });
    }

    return positions;
  };

  // Persist the initial positions for reference
  let initialPositions = generatePositions(letters.length);

  // -----------------------------------------------------------------

  // rest
  const reset = () => {
    playBGTune();
    playBlip();
    const newPositions = generatePositions(letters.length); // Generate new random positions
    initialPositions = newPositions; // Update initial positions
    setTilePositions([...newPositions]); // Reset tile positions to push them out
    setCompleteConter(0); // Reset complete counter
    setMatchedTiles(Array(letters.length).fill(false)); // Mark all tiles as unmatched
  };

  // -----------------------------------------------------------------

  const playLetter = (letter = "a") => {
    // audio
    const audio = letterAudioRef.current;

    // Stop any currently playing audio
    audio.pause();
    audio.currentTime = 0; // Reset playback position

    audio.src = letterAudio[letter];
    audio.load(); // Load the new audio source
    // Delay playing the audio by 300ms
    setTimeout(() => {
      audio.play(); // Play the new audio
    }, 500);
  };

  const playWord = () => {
    // audio
    const audio = letterAudioRef.current;

    // Stop any currently playing audio
    audio.pause();
    audio.currentTime = 0; // Reset playback position
    const word = letters.join("");
    audio.src = wordAudio[word];
    audio.load(); // Load the new audio source
    // Delay playing the audio by 300ms
    setTimeout(() => {
      audio.play(); // Play the new audio
    }, 3000);
  };

  const playBGTune = () => {
    const bgAudio = bgTuneRef.current;
    bgAudio.volume = 0.05;
    bgAudio.loop = true; // Enable looping
    bgAudio.play();
  };

  const playBlip = () => {
    const blipAudio = blipRef.current;
    blipAudio.play();
  };

  const playStep = () => {
    stopStep();
    stepRef.current.play();
  };

  const stopStep = () => {
    if (!stepRef.current.paused) {
      // Stop the audio if it's playing
      stepRef.current.pause();
      stepRef.current.currentTime = 0; // Reset to the beginning
    }
  };

  const playCheer = () => {
    stopBeep();
    cheerRef.current.play();
  };

  const stopCheer = () => {
    if (!cheerRef.current.paused) {
      // Stop the audio if it's playing
      cheerRef.current.pause();
      cheerRef.current.currentTime = 0; // Reset to the beginning
    }
  };

  const playBeep = () => {
    stopBeep();
    beepRef.current.play();
  };

  const stopBeep = () => {
    if (!beepRef.current.paused) {
      // Stop the audio if it's playing
      beepRef.current.pause();
      beepRef.current.currentTime = 0; // Reset to the beginning
    }
  };

  const [tilePositions, setTilePositions] = useState(
    generatePositions(letters.length)
  );

  const [isPulsing, setIsPulsing] = useState(false);
  const handleDragEnd = (event, info, index) => {
    const pointerX = info.point.x;
    const pointerY = info.point.y;

    let matched = false;

    // Iterate over all TileBase elements to check for a hit
    for (
      let baseIndex = 0;
      baseIndex < tileBaseRefs.current.length;
      baseIndex++
    ) {
      const baseRef = tileBaseRefs.current[baseIndex];
      if (baseRef) {
        const rect = baseRef.getBoundingClientRect();
        // check if there is a hit
        if (
          pointerX >= rect.left &&
          pointerX <= rect.right &&
          pointerY >= rect.top &&
          pointerY <= rect.bottom
        ) {
          // Check if letters match
          if (letters[index] === letters[baseIndex]) {
            matched = true;
            resolveMatch(event.target, index, matched);

            break;
          }
        }
      }
    }

    // If no match, reset the tile to its original position
    if (!matched) {
      playBeep();
      setTilePositions((prev) =>
        prev.map((pos, i) =>
          i === index
            ? initialPositions[index] // Reset to the initial position
            : pos
        )
      );
    }
  };

  // -------------------------------------------------------------

  const resolveMatch = (tile, index, matched) => {
    playStep();
    playLetter(letters[index]);
    // Update complete counter
    setCompleteConter((prev) => {
      const n = prev + 1;
      return n;
    });
    // Animate Tile to the TileBase position
    setTilePositions((prev) =>
      prev.map((pos, i) =>
        i === index
          ? { x: "0", y: "0" } // Match TileBase position
          : pos
      )
    );

    // if complete
    if (completeConter >= completeCount - 1) {
      playCheer();
      playWord();
    }

    // Mark the tile as matched
    setMatchedTiles((prev) =>
      prev.map((matched, i) => (i === index ? true : matched))
    );

    // Add the Tailwind class
    tile.classList.add("animate-pulse");

    // Remove the animation after 500ms
    setTimeout(() => {
      tile.classList.remove("animate-pulse");
    }, 500);
  };

  // -------------------------------------------------------------

  // No match or hit, reset the Tile to its original position
  const [renderCounter, setRenderCounter] = useState(0);

  const handleTryAgain = () => {
    reset();
  };

  const [canStart, setCanStart] = useState(false);
  const handleCanStart = () => {
    playBGTune();
    setCanStart(true);
  };

  const loadSession = (value) => {
    if (value == "apple") {
      setImgSrc(NI_Apple);
      setLetters(Apple_letters);
    }

    if (value == "orange") {
      setImgSrc(NI_Orange);
      setLetters(Orange_letters);
    }

    if (value == "banana") {
      setImgSrc(NI_Banana);
      setLetters(Banana_letters);
    }

    if (value == "mango") {
      setImgSrc(NI_Mango);
      setLetters(Mango_letters);
    }
    if (value == "pare") {
      setImgSrc(NI_Pare);
      setLetters(Pare_letters);
    }

    if (value == "cherry") {
      setImgSrc(NI_Cherry);
      setLetters(Cherry_letters);
    }
    reset();
  };

  return (
    <div
      style={{ overflow: "hidden", touchAction: "none" }}
      ref={containerRef}
      className="relative flex flex-col gap-10 items-center justify-center mx-auto w-screen h-[calc(100vh-50px)]"
    >
      <div
        onClick={handleTryAgain}
        className="cursor-pointer flex items-center justify-center absolute bottom-10 right-10 size-[60px] rounded-full border-2 border-black bg-blue-400"
      >
        <ReplayIcon />
      </div>
      <div className="absolute flex flex-col gap-4 top-10 right-10">
        <div
          onClick={() => loadSession("orange")}
          className=" rounded-full size-[80px] bg-white border-slate-400 border-2 cursor-pointer"
        >
          <img src={NI_Orange} alt="orange" width={100} height={100} />
        </div>
        <div
          onClick={() => loadSession("apple")}
          className=" rounded-full size-[80px] bg-white border-slate-400 border-2 cursor-pointer"
        >
          <img src={NI_Apple} alt="apple" width={100} height={100} />
        </div>
        <div
          onClick={() => loadSession("banana")}
          className=" rounded-full size-[80px] bg-white border-slate-400 border-2 cursor-pointer"
        >
          <img src={NI_Banana} alt="banana" width={100} height={100} />
        </div>
        <div
          onClick={() => loadSession("mango")}
          className=" rounded-full size-[80px] bg-white border-slate-400 border-2 cursor-pointer"
        >
          <img src={NI_Mango} alt="banana" width={100} height={100} />
        </div>
        <div
          onClick={() => loadSession("pare")}
          className=" rounded-full size-[80px] bg-white border-slate-400 border-2 cursor-pointer"
        >
          <img src={NI_Pare} alt="banana" width={100} height={100} />
        </div>
        <div
          onClick={() => loadSession("cherry")}
          className=" rounded-full size-[80px] bg-white border-slate-400 border-2 cursor-pointer"
        >
          <img src={NI_Cherry} alt="banana" width={100} height={100} />
        </div>
      </div>
      <audio ref={beepRef} src={Beep} />
      <audio ref={cheerRef} src={Cheer} />
      <audio ref={stepRef} src={Step} />
      <audio ref={bgTuneRef} src={BGTunes} />
      <audio ref={blipRef} src={Blip} />
      <audio ref={letterAudioRef} src={selectedLetterRef.current} />

      {/* complete counter */}
      {/* <div className="text-4xl font-bold">{completeConter}</div> */}

      {/* image */}
      <img
        src={imgSrc}
        alt=""
        className="select-none"
        width={500}
        height={500}
        draggable={false}
      />
      {!canStart && (
        <div>
          <div
            onClick={handleCanStart}
            className="flex items-center justify-center h-[80px] cursor-pointer text-4xl font-bold border-2 p-2 
            hover:bg-slate-200 hover:border-slate-400 rounded-full w-[300px] text-slate-500"
          >
            Lets Start
          </div>
        </div>
      )}
      {canStart && (
        <div className="relative mt-20 w-[760px] h-[180px]">
          {/* tile base */}
          <div className="absolute inset-0 flex items-center justify-center gap-2 z-0">
            {letters.map((letter, index) => (
              <div key={index} ref={(el) => (tileBaseRefs.current[index] = el)}>
                <TileBase letter={letter} />
              </div>
            ))}
          </div>
          {/* track */}
          <div className="absolute bottom-0 h-[20px] w-full">
            <Indicator />
          </div>
          {/* tile */}
          <div className="absolute inset-0 flex items-center justify-center gap-2 z-10">
            {letters.map((letter, index) => (
              <motion.div
                key={index}
                initial={{ x: 0, y: 0 }}
                animate={tilePositions[index]}
                transition={{
                  duration: 1.5, // Animation duration
                  ease: "easeOut", // Smooth easing function
                }}
                drag={!matchedTiles[index]} // Disable drag if the tile is matched
                dragConstraints={containerRef} // Constrain drag to the container
                dragElastic={0.2} // Elasticity for bounce-back effect
                dragTransition={{
                  bounceStiffness: 800, // Controls stiffness of bounce
                  bounceDamping: 100, // Controls damping of bounce
                }}
                onDragEnd={(event, info) => handleDragEnd(event, info, index)}
              >
                <Tile letter={letter} />
              </motion.div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const Indicator = () => {
  return (
    <div>
      <div
        className={`animate-blink w-[${tileSize}px] h-[20px] bg-gray-400 rounded-md`}
      >
        .
      </div>
    </div>
  );
};

const tileSize = 120;
const TileBase = React.forwardRef(({ letter }, ref) => {
  return (
    <div>
      <div
        ref={ref}
        style={{ width: tileSize, height: tileSize }}
        className="flex items-center justify-center rounded-xl text-6xl font-bold bg-gray-300 text-gray-400 select-none"
      >
        {letter}
      </div>
    </div>
  );
});

const Tile = ({ letter }) => {
  return (
    <div>
      <div
        style={{ width: tileSize, height: tileSize }}
        className="bg-orange-400 flex items-center justify-center rounded-xl opacity-50 text-6xl font-bold select-none"
      >
        {letter}
      </div>
    </div>
  );
};
