export const parseSvgPaths = (svgString) => {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

  const paths = [];

  // Handle <path> elements
  const pathElements = svgDoc.querySelectorAll("path");
  pathElements.forEach((path) => {
    paths.push(path.getAttribute("d"));
  });

  // Handle <line> elements
  const lineElements = svgDoc.querySelectorAll("line");
  lineElements.forEach((line) => {
    const x1 = line.getAttribute("x1");
    const y1 = line.getAttribute("y1");
    const x2 = line.getAttribute("x2");
    const y2 = line.getAttribute("y2");
    const offset = line.getAttribute("offset") || 0;
    paths.push(`M${x1},${y1} L${x2},${y2},OFFSET:${offset}`);
  });

  // Handle <circle> elements
  const circleElements = svgDoc.querySelectorAll("circle");
  circleElements.forEach((circle) => {
    const cx = parseFloat(circle.getAttribute("cx"));
    const cy = parseFloat(circle.getAttribute("cy"));
    const r = parseFloat(circle.getAttribute("r"));
    const offset = parseFloat(circle.getAttribute("offset")) || 0;
    // Create a path using elliptical arc commands to approximate the circle
    paths.push(
      `M${cx - r},${cy} A${r},${r} 0 1,0 ${cx + r},${cy} A${r},${r} 0 1,0 ${
        cx - r
      },${cy},OFFSET:${offset}`
    );
  });

  return paths;
};

export const calculateOffsets = (canvas, pathDataArray) => {
  const ctx = canvas.getContext("2d");

  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;

  pathDataArray.forEach((pathData) => {
    const path = new Path2D(pathData);

    // Get the bounding box using `isPointInPath`
    for (let x = 0; x < canvas.width; x++) {
      for (let y = 0; y < canvas.height; y++) {
        if (ctx.isPointInPath(path, x, y)) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }
  });

  const letterWidth = maxX - minX;
  const letterHeight = maxY - minY;

  const xOffset = (canvas.width - letterWidth) / 2 - minX;
  const yOffset = (canvas.height - letterHeight) / 2 - minY;

  return { xOffset, yOffset };
};

// Example usage
// const { xOffset, yOffset } = calculateOffsets(canvas, paths);
// drawPathsOnCanvas(canvas, paths, xOffset, yOffset);
