import React, { useEffect, useState } from "react";
import { Reorder } from "framer-motion";
import { toast } from "react-toastify";
import Project from "../../models/Project";

import img_a from "../../assets/black/image_a.jpg";
import img_b from "../../assets/black/image_b.jpg";
import img_c from "../../assets/black/image_c.jpg";
import img_d from "../../assets/black/image_d.jpg";
import img_e from "../../assets/black/image_e.jpg";
import img_f from "../../assets/black/image_f.jpg";

export default function HexRevList({
  project,
  revision,
  imageList,
  resolveListOrder,
}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (imageList) {
      setItems([...imageList]);
    }
  }, [imageList]);

  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // on reorder
  const resolveReorder = (newItems) => {
    setItems(newItems);
  };

  // on start drag
  const handleDragStart = (e) => {
    setIsDragging(true);
    toast.success("dragging started");
  };

  // on end drag
  const handleDragEnd = (e) => {
    setIsDragging(false);
    resolveListOrder(items);
    toast.success("dragging ended");
  };

  // on mouse up
  const handleMouseUp = (e, url) => {
    // do not fire if image is dragging

    e.stopPropagation();

    if (isDragging) {
      // image is dragging don't fire the mouse up actions
      return;
    }

    if (window.confirm("delete image")) {
      handleDelete(url);
    }
  };

  const handleDelete = async (url) => {
    const xpro = Project.fromData(project);
    xpro.removeImageFromRevision(url, revision.id);

    await xpro.commit().then((ref) => {
      toast.success("image deleted");
    });
  };

  return (
    <>
      <Reorder.Group axis="x" values={items} onReorder={resolveReorder}>
        <div className="flex gap-1">
          {items.map((item) => (
            <Reorder.Item
              onDragEnd={(e) => handleDragEnd(e)}
              onDragStart={(e) => handleDragStart(e)}
              onMouseUpCapture={(e) => handleMouseUp(e, item)}
              key={item}
              value={item}
            >
              <div
                className="flex overflow-clip items-center justify-center bg-gray-300 w-[100px] h-[60px]
                rounded-md border-[1px] border-sky-900"
              >
                {<img draggable="false" src={item} />}
              </div>
            </Reorder.Item>
          ))}
        </div>
      </Reorder.Group>
    </>
  );
}
