/***
 * Dialog Buttons
 */
export default function HexDialogButtons({ callBack }) {
  return (
    <div>
      {/* buttons */}
      <div className="flex gap-1 mx-auto mt-4">
        <button
          onClick={() => callBack(false)}
          className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
        >
          Close
        </button>
        <button
          onClick={() => callBack(true)}
          className="p-2 w-[150px] hover:bg-gray-500 bg-gray-400 rounded"
        >
          add
        </button>
      </div>
    </div>
  );
}
