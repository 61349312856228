import getBounds from "svg-path-bounding-box";

export const drawPathsOnCanvas = (
  canvas,
  pathDataArray,
  xOffset = 0,
  yOffset = 0,
  lineWidth = 24,
  animated = false,
  duration = 4000
) => {
  const ctx = canvas.getContext("2d");

  // clear canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Set up styles
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = "black";
  ctx.lineCap = "round";
  ctx.lineJoin = "round";

  // Save the context state
  ctx.save();
  ctx.translate(xOffset, yOffset);

  const totalPaths = pathDataArray.length;
  const pathDuration = duration / totalPaths; // Duration per path
  let currentPathIndex = 0;
  let startTime = null;

  if (!animated) {
    // Draw all paths instantly if animation is disabled
    pathDataArray.forEach((pathData) => {
      const path = new Path2D(pathData);
      ctx.stroke(path);
    });
    ctx.restore(); // Restore context after drawing
    return;
  }

  // Animation logic
  const animatePath = (timestamp) => {
    if (!startTime) startTime = timestamp;
    const elapsed = timestamp - startTime;

    // Calculate progress for the current segment
    const progress = Math.min(elapsed / pathDuration, 1); // Progress (0 to 1)

    // Draw already completed paths
    for (let i = 0; i < currentPathIndex; i++) {
      const path = new Path2D(pathDataArray[i]);
      ctx.stroke(path); // Fully draw completed paths
    }

    // Animate the current path
    if (currentPathIndex < totalPaths) {
      const p = pathDataArray[currentPathIndex];

      const offset = extractOffsetValue(p);

      const path = new Path2D(pathDataArray[currentPathIndex]);
      const pathLength = 350; // Approximation of path length
      const visibleLength = pathLength * progress;

      // Set up the line dash for the current path
      ctx.setLineDash([visibleLength, pathLength]);
      ctx.lineDashOffset = offset; // startOffset; // Adjust the starting point of the dash
      ctx.stroke(path);

      // If the current path is fully drawn, immediately move to the next path
      if (progress === 1) {
        currentPathIndex++;
        startTime = timestamp; // Reset startTime for the next segment
      }
    }

    // Continue animation if there are more paths
    if (currentPathIndex < totalPaths) {
      requestAnimationFrame(animatePath);
    } else {
      // Reset after animation is complete
      ctx.setLineDash([]);
      ctx.restore();
    }
  };

  requestAnimationFrame(animatePath);
};

export const extractOffsetValue = (inputString) => {
  const match = inputString.match(/OFFSET:\s*(\d+)/);
  if (match) {
    return parseInt(match[1], 10); // Convert the extracted value to a number
  }
  return null; // Return null if no match is found
};

// export const drawPathsOnCanvas = (
//   canvas,
//   pathDataArray,
//   xOffset = 0,
//   yOffset = 0,
//   lineWidth = 24,
//   animated = false,
//   startOffset = 0,
//   duration = 4000
// ) => {
//   const ctx = canvas.getContext("2d");

//   // Set up styles
//   ctx.lineWidth = lineWidth;
//   ctx.strokeStyle = "black";
//   ctx.lineCap = "round";
//   ctx.lineJoin = "round";

//   // Save the context state
//   ctx.save();
//   ctx.translate(xOffset, yOffset);

//   const totalPaths = pathDataArray.length;
//   const pathDuration = duration / totalPaths; // Duration per path
//   let currentPathIndex = 0;
//   let startTime = null;

//   const animatePath = (timestamp) => {
//     if (!startTime) startTime = timestamp;
//     const elapsed = timestamp - startTime;

//     // Calculate progress for the current segment
//     const progress = Math.min(elapsed / pathDuration, 1); // Progress (0 to 1)

//     // Draw already completed paths
//     for (let i = 0; i < currentPathIndex; i++) {
//       const path = new Path2D(pathDataArray[i]);
//       ctx.stroke(path); // Fully draw completed paths
//     }

//     // Animate the current path
//     if (currentPathIndex < totalPaths) {
//       const path = new Path2D(pathDataArray[currentPathIndex]);
//       const pathLength = 350; // Approximation of path length
//       const visibleLength = pathLength * progress;

//       // Set up the line dash for the current path
//       ctx.setLineDash([visibleLength, pathLength]);
//       ctx.lineDashOffset = startOffset; // Adjust the starting point of the dash
//       ctx.stroke(path);

//       // If the current path is fully drawn, immediately move to the next path
//       if (progress === 1) {
//         currentPathIndex++;
//         startTime = timestamp; // Reset startTime for the next segment
//       }
//     }

//     // Continue animation if there are more paths
//     if (currentPathIndex < totalPaths) {
//       requestAnimationFrame(animatePath);
//     } else {
//       // Reset after animation is complete
//       ctx.setLineDash([]);
//       ctx.restore();
//     }
//   };

//   requestAnimationFrame(animatePath);
// };

export const centerLetterOnCanvas = (canvas, pathDataArray) => {
  return { xOffset: 50, yOffset: 50 };
};

export const calculateBoundingBox = (pathData) => {
  const bounds = getBounds(pathData); // Returns { x1, y1, x2, y2 }
  return {
    minX: bounds.x1,
    minY: bounds.y1,
    maxX: bounds.x2,
    maxY: bounds.y2,
  };
};

export const calculateGroupBoundingBox = (pathDataArray) => {
  if (!Array.isArray(pathDataArray)) {
    throw new Error("Input must be an array of SVG path strings.");
  }

  // Initialize bounding box variables
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;

  // Iterate through each path to calculate its bounding box
  pathDataArray.forEach((pathData) => {
    const bounds = getBounds(pathData); // Get bounds for the current path
    minX = Math.min(minX, bounds.x1);
    minY = Math.min(minY, bounds.y1);
    maxX = Math.max(maxX, bounds.x2);
    maxY = Math.max(maxY, bounds.y2);
  });

  // Return the combined bounding box
  return {
    minX,
    minY,
    maxX,
    maxY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

// -------------------------------------------------------------------------

// export const drawPathsOnCanvas = (
//   canvas,
//   pathDataArray,
//   xOffset = 0,
//   yOffset = 0,
//   lineWidth = 24,
//   duration = 10000
// ) => {
//   const ctx = canvas.getContext("2d");

//   // Set up styles
//   ctx.lineWidth = lineWidth;
//   ctx.strokeStyle = "black";
//   ctx.lineCap = "round";
//   ctx.lineJoin = "round";

//   // Save the context state
//   ctx.save();
//   ctx.translate(xOffset, yOffset);

//   const totalPaths = pathDataArray.length;
//   const pathDuration = duration / totalPaths; // Duration per path
//   let currentPathIndex = 0;
//   let startTime = null;

//   const animatePath = (timestamp) => {
//     if (!startTime) startTime = timestamp;
//     const elapsed = timestamp - startTime;

//     // Draw already completed paths
//     for (let i = 0; i < currentPathIndex; i++) {
//       const path = new Path2D(pathDataArray[i]);
//       ctx.stroke(path);
//     }

//     // Animate the current path
//     if (currentPathIndex < totalPaths) {
//       const progress = Math.min(elapsed / pathDuration, 1); // Progress (0 to 1)

//       const path = new Path2D(pathDataArray[currentPathIndex]);
//       const pathLength = 2000; // Approximation of path length
//       const visibleLength = pathLength * progress;

//       // Set up the line dash for the current path
//       ctx.setLineDash([visibleLength, pathLength]);
//       ctx.stroke(path);

//       // If the current path is fully drawn, move to the next path
//       if (progress === 1) {
//         currentPathIndex++;
//         startTime = null; // Reset startTime for the next path
//       }
//     }

//     // Continue animation if there are more paths
//     if (currentPathIndex < totalPaths) {
//       requestAnimationFrame(animatePath);
//     } else {
//       // Reset after animation is complete
//       ctx.setLineDash([]);
//       ctx.restore();
//     }
//   };

//   requestAnimationFrame(animatePath);
// };

// ------------------------------------------------------------------------------

// export const drawPathsOnCanvas = (
//   canvas,
//   pathDataArray,
//   xOffset = 0,
//   yOffset = 0,
//   lineWidth = 24,
//   duration = 4000
// ) => {
//   const ctx = canvas.getContext("2d");

//   // Set up styles
//   ctx.lineWidth = 24;
//   ctx.strokeStyle = "black";
//   ctx.lineCap = "round";
//   ctx.lineJoin = "round";

//   // Save the context state
//   ctx.save();
//   ctx.translate(xOffset, yOffset);

//   const totalPaths = pathDataArray.length;
//   const pathDuration = duration / totalPaths; // Duration per path
//   let currentPathIndex = 0;
//   let startTime = null;

//   const animatePath = (timestamp) => {
//     if (!startTime) startTime = timestamp;
//     const elapsed = timestamp - startTime;

//     // Clear the canvas
//     ctx.clearRect(-xOffset, -yOffset, canvas.width, canvas.height);

//     // Draw already completed paths
//     for (let i = 0; i < currentPathIndex; i++) {
//       const path = new Path2D(pathDataArray[i]);
//       ctx.stroke(path);
//     }

//     // Animate the current path
//     if (currentPathIndex < totalPaths) {
//       const progress = Math.min(elapsed / pathDuration, 1); // 0 to 1

//       const path = new Path2D(pathDataArray[currentPathIndex]);
//       const pathLength = 2000; // Approximation of path length
//       const visibleLength = pathLength * progress;

//       ctx.setLineDash([visibleLength, pathLength]); // Dash length for animation
//       ctx.stroke(path);

//       if (progress === 1) {
//         currentPathIndex++;
//         startTime = null; // Reset startTime for the next path
//       }
//     }

//     // Continue animation if paths remain
//     if (currentPathIndex < totalPaths) {
//       requestAnimationFrame(animatePath);
//     } else {
//       // Reset after animation
//       ctx.setLineDash([]);
//       ctx.restore();
//     }
//   };

//   requestAnimationFrame(animatePath);
// };

// export const drawPathsOnCanvas = (
//   canvas,
//   pathDataArray,
//   xOffset = 0,
//   yOffset = 0,
//   lineWidth = 24
// ) => {
//   const ctx = canvas.getContext("2d");

//   // Clear the canvas
//   ctx.clearRect(0, 0, canvas.width, canvas.height);

//   // Apply line styles
//   ctx.lineWidth = lineWidth;
//   ctx.strokeStyle = "black";
//   ctx.lineCap = "round";
//   ctx.lineJoin = "round";

//   // Save the current context state
//   ctx.save();

//   // Translate the canvas origin to include offsets
//   ctx.translate(xOffset, yOffset);

//   // Draw each path with the offset
//   pathDataArray.forEach((pathData) => {
//     const path = new Path2D(pathData); // Create a Path2D object
//     ctx.stroke(path); // Draw the path
//   });

//   // Restore the original context state
//   ctx.restore();
// };

// -------------------------------------------------------------

export const drawCanvasOnMove = (
  event,
  isDrawing,
  canvas,
  coverCanvas,
  hitTestBlack
) => {
  if (event.pointerType === "touch" || event.pointerType === "") {
    return;
  }

  if (!isDrawing.current) return;

  const ctx = canvas.getContext("2d");
  const coverCtx = coverCanvas.getContext("2d");

  // drawing
  const rect = canvas.getBoundingClientRect();
  // x, y
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;

  ctx.lineTo(event.clientX - rect.left, event.clientY - rect.top); // Create a line to the current position
  ctx.strokeStyle = "orange"; // Set stroke color
  ctx.lineWidth = 32; // Set stroke width
  ctx.lineCap = "round"; // Optional: smooth the ends of the line
  ctx.lineJoin = "round"; // Set these properties once
  ctx.stroke(); // Draw the stroke
  // cover
  const coverRect = coverCanvas.getBoundingClientRect();
  coverCtx.lineTo(event.clientX - rect.left, event.clientY - rect.top); // Create a line to the current position
  coverCtx.strokeStyle = "orange"; // Set stroke color
  coverCtx.lineWidth = 50; // Set stroke width
  coverCtx.lineCap = "round"; // Optional: smooth the ends of the line
  coverCtx.lineJoin = "round"; // Set these properties once
  coverCtx.stroke(); // Draw the stroke

  // on course
  hitTestBlack(x, y);
};
