import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { usePermify } from "@permify/react-role";
import { toast } from "react-toastify";
import { useAppContext } from "./useAppContext";

export const useSignout = () => {
  const { dispatch, flushActiveUser, setActiveUser, setRole, setPermissions } =
    useAuthContext();
  const { setUser } = usePermify();

  const signout = () => {
    signOut(auth)
      .then(() => {
        flushActiveUser();
        toast.success("signed out");
        // flushActiveUser
        flushActiveUser();

        // dispatch
        dispatch({ type: "LOGOUT" });
        window.location.reload(false);

        // flush active user
        // setActiveUser(null);
        // setRole(null);
        // setPermissions(null);

        // flush user
        setUser({
          id: "",
          roles: [],
          permissions: [],
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return { signout };
};
