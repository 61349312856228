import FORMATER from "../abstract/FORMATER";

export default function DateField({
  label = "date",
  id,
  data,
  setData,
  valKey = "date",
  labelWidth = 300,
  isActive = false,
  production = false,
  component = "date",
  showLabel = true,
  inputWidth = 300,
}) {
  // ----------------------------------------------------

  const formatedDate = (date) => {
    return setInputDate(date);
  };

  const setInputDate = (_date) => {
    if (_date) {
      let d = _date.getDay();
      let m = _date.getMonth() + 1;
      let y = _date.getFullYear();

      if (d < 10) {
        d = "0" + d;
      }
      if (m < 10) {
        m = "0" + m;
      }

      const date = y + "-" + m + "-" + d;
      console.log(date);
      return date;
    }
    return "";
  };

  // ----------------------------------------------------------------------------------------------

  const resolveValue = (valKey) => {
    let date;
    try {
      if (component == "month") {
        let d = data[valKey];
        let m = d.getMonth() + 1;
        if (m < 10) {
          m = "0" + m;
        }
        let y = d.getFullYear();
        const FormatedMonth = y + "-" + m;
        console.log(FormatedMonth);
        return FormatedMonth;
      } else {
        return FORMATER.formatedDate(data[valKey]);
      }
    } catch (err) {
      return new Date();
      console.log(err);
    }
  };

  return (
    <div
      className={`flex items-center h-11 ${production ? "" : "bg-yellow-400"} `}
    >
      {showLabel && <p className="w-[${labelWidth}px]">{label}</p>}

      {isActive && (
        <input
          className={`w-[${inputWidth}px] ${
            showLabel == false ? "" : "max-w-[300px] ml-auto"
          } h-11 px-2 border border-gray-500 rounded`}
          type={component}
          id={id}
          value={resolveValue(valKey)}
          onChange={(e) => {
            setData({
              ...data,
              [valKey]: new Date(e.target.value),
            });
          }}
        />
      )}
      {!isActive && (
        <p className="w-[300px] max-w-[300px] ml-auto px-2">
          {data[valKey] && new Date(data[valKey]).toDateString()}
        </p>
      )}
    </div>
  );
}
