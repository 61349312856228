import React from "react";
import Tag from "../../components/Tag";

export default function HexMeta({ project, goFullScreen }) {
  return (
    <div>
      <div className="absolute top-3 left-3">
        {/* <h3 className="">{project.color.value}</h3> */}
        <h1
          className="cursor-pointer text-6xl text-white drop-shadow-lg shadow-black"
          onClick={goFullScreen}
        >
          {project.name}
        </h1>
        {/* <h3>{project.isReady.toString()}</h3>
        <h3>hex images: {project.images.length}</h3>
        <div className="flex gap-1 mt-2">
          {project?.tags.map((tag, index) => (
            <div key={index}>
              <Tag name={tag.label} />
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}
