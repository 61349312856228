import { db } from "../firebase/config";
import { toast } from "react-toastify";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import HexInboxItem from "../models/HexInboxItem";

/***
 * handle send drawing
 */
// const handleSendDrwaing = async (e, drawing, targetUserId) => {
//   e.stopPropagation();

//   const user = users.find((u) => u.id == targetUserId);
//   if (user) {
//     console.log(drawing);

//     // const newItem = { drawingId: drawing.id, projectId: drawing.projectId };
//     const hexItem = new HexInboxItem();
//     hexItem.drawingId = drawing.id;
//     hexItem.projectId = drawing.projectId;
//     const newItem = hexItem.data();

//     const d = { inbox: { items: [...user.inbox.items, newItem] } };

//     // update user
//     const docRef = doc(db, "users", targetUserId);
//     await setDoc(docRef, d, { merge: true }).then((ref) => {
//       toast.success("item added to inbox");
//     });
//   } else {
//     toast.error("no such user");
//   }
// };

export const handleSendDrawing_Group = async (
  e,
  users,
  drawing,
  senderId,
  targetUserIds,
  setOpen = null
) => {
  e.stopPropagation();

  const unsuccessfulUpdates = [];

  // Loop through each targetUserId
  for (const targetUserId of targetUserIds) {
    const user = users.find((u) => u.id === targetUserId);

    if (user) {
      console.log(`Adding drawing to inbox of user: ${targetUserId}`);

      const hexItem = new HexInboxItem();
      hexItem.drawingId = drawing.id;
      hexItem.projectId = drawing.projectId;
      hexItem.senderId = senderId;
      const newItem = hexItem.data();

      try {
        // Update each user’s inbox.items
        const docRef = doc(db, "users", targetUserId);
        await updateDoc(docRef, {
          "inbox.items": [...user.inbox.items, newItem],
        });

        toast.success(`Item added to inbox of user ${targetUserId}`);
      } catch (error) {
        console.error(`Error updating inbox for user ${targetUserId}:`, error);
        unsuccessfulUpdates.push(targetUserId);
      }
    } else {
      console.warn(`No such user with ID: ${targetUserId}`);
      unsuccessfulUpdates.push(targetUserId);
    }
  }

  // Final toast message to summarize
  if (unsuccessfulUpdates.length > 0) {
    toast.error(
      `Failed to update inbox for users: ${unsuccessfulUpdates.join(", ")}`
    );
  }
  // close the dialog
  if (setOpen) {
    setOpen(false);
  }
};
