import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import Project from "../../../models/Project";
import TextField from "../../../fields/TextField";
import SwitchFiled from "../../../fields/SwitchField";
import SelectField from "../../../fields/SelectField";
import HexRevisionRow from "./HexRevisionRow";
import Enums from "../../../models/Enums";
import { useUploadDocument } from "../../../hooks/useUploadDocument";

export default function HexEditForm({ project, dismiss }) {
  const [data, setData] = useState({});
  const { uploadImage } = useUploadDocument();
  const inputfileRef = useRef();
  const navigate = useNavigate();
  // read project and update ui
  useEffect(() => {
    setData({ ...project });
    // update ui
    setIsReady(project.isReady);
    setOptionData(project.color);
    setTagOption(project.tags);
  }, []);

  // switch field
  const [isReady, setIsReady] = useState(false);
  const resolveIsReady = (option) => {
    setIsReady(option);
  };

  // save
  const handleSave = async () => {
    const d = { ...data, isReady: isReady, color: optionData, tags: tagOption };
    const docRef = doc(db, "projects", project.id);
    await setDoc(docRef, d, { merge: true }).then((ref) => {
      dismiss();
      toast.success("save");
    });
  };

  const handleDelete = async () => {
    if (window.confirm("delete project")) {
      const docRef = doc(db, "projects", project.id);
      await deleteDoc(docRef).then((ref) => {
        toast.success("project deleted");
        navigate("/projects");
      });
    }
  };

  // color option ----------------------------------------------

  const [optionData, setOptionData] = useState({
    value: "blue",
    label: "blue",
  });
  const options = [
    { value: "blue", label: "blue" },
    { value: "orange", label: "orange" },
    { value: "pink", label: "pink" },
  ];
  const resolveOptions = (option) => {
    setOptionData(option);
  };
  const [tagOption, setTagOption] = useState([]);
  const resolveTagOption = (option) => {
    setTagOption(option);
  };

  const handlePickFile = () => {
    inputfileRef.current.click();
  };

  const handleUploadPdf = async (e) => {
    const file = e.target.files[0];

    const fileName = file.name || "hamadah drawings";

    toast.success(fileName);

    setData({ ...data, file: file });
    if (!project) return;

    // Get the file size in bytes
    const sizeInBytes = file.size;

    // // 2. upload img
    await uploadImage(file, "hexpo").then(async (imgUrl) => {
      const url = imgUrl.replace(
        "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
        "ik.imagekit.io/habiatuq/hexpo"
      );

      // add the url to project
      const p = Project.fromData(project);
      p.url = url;
      p.commit().then((ref) => {
        setData({ ...p }); // this will update the ui
        toast.success("cover image changed");
      });
    });
  };

  return (
    <div>
      <div>{data.name}</div>

      <div className="w-[600px] bg-orange-600 p-4 rounded-lg">
        <TextField
          isActive={true}
          label="project number"
          id="number"
          valKey="number"
          data={data}
          setData={setData}
        />
        <TextField
          isActive={true}
          label="project name"
          id="name"
          data={data}
          setData={setData}
        />
        <SwitchFiled
          id="isReady"
          data={isReady}
          setData={resolveIsReady}
          isActive={true}
        />
        <SelectField
          options={options}
          data={optionData}
          setData={resolveOptions}
          isActive={true}
        />
        <SelectField
          label={"tags"}
          options={Enums.TagOptions}
          data={tagOption}
          setData={resolveTagOption}
          isActive={true}
          isMulti={true}
        />
      </div>

      <div>change cover page</div>

      <div onClick={handlePickFile}>
        <img
          style={{ marginTop: 10, marginBottom: 10 }}
          className="cursor-pointer"
          src={data.url}
          width={200}
        />
        <div className="ml-2 pointer-events-none">
          <input
            ref={inputfileRef}
            type="file"
            accept="image/jpeg, image/png" // This limits the file type to PDF
            id="file"
            onChange={handleUploadPdf}
            className="hidden"
          />
        </div>
      </div>

      <div onClick={handleSave} className="cursor-pointer bg-orange-400 p-2">
        save
      </div>
      <div onClick={handleDelete} className="cursor-pointer bg-yellow-400 p-2">
        delete
      </div>
      <div onClick={dismiss} className="cursor-pointer bg-gray-400 p-2 rounded">
        close
      </div>
    </div>
  );
}
