import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import { motion } from "framer-motion";
import Spinaci from "../../assets/spinaci.jpg";
import { useCollection } from "../../hooks/useCollection";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import UploadImageButton from "../../components/UploadImage";

import { toast } from "react-toastify";
import AddProject from "../projects/form/AddProject";

// import { v4 as uuid } from "uuid";
// import { timestamp } from "../firebase/config";

const TextVariants = {
  hidden: { x: 75, opacity: 0 },
  visible: (custom = 0.5) => ({
    x: 0,
    opacity: 1,
    x: custom,
    transition: { duration: 0.5, delay: custom },
  }),
};

export default function Services() {
  const { height, width } = useWindowDimensions();
  return (
    <div>
      <div
        style={{ height: height - 50 }}
        className="bg-orange-400 flex items-center justify-center"
      >
        <div className="">
          <div className="mx-auto cursor-default text-6xl font-bold">
            services
          </div>
        </div>
      </div>

      <div className="w-[1200px] mx-auto mb-4">
        <div className="opacity-75 rounded-lg h-[400px] mt-[50px] p-4 flex">
          <ImageBlock />
          <div className="ml-4">
            <motion.h1
              variants={TextVariants}
              viewport={{ once: true }}
              initial="hidden"
              whileInView="visible"
              className="font-bold text-5xl"
            >
              Design
            </motion.h1>
            <motion.div
              variants={TextVariants}
              viewport={{ once: true }}
              initial="hidden"
              whileInView="visible"
              custom={0.75}
            >
              <p className="text-2xl mt-2">Aimed at aspiring clients </p>
              <p className="text-2xl mt-2">
                At Hamadah Architecture,responsible and creative design forms
                the cornerstone of our practice. We are deeply committed to
                understanding and meeting our clients' needs, ensuring that
                every project not only fulfills their requirements but also
                pushes the boundaries of innovation and aesthetics. Our design
                team brings a blend of creativity and practicality to each
                project, translating client visions and aspirations into
                functional and inspiring spaces. Whether it’s a residential,
                commercial, or public project, we prioritize design excellence
                and client satisfaction in all our endeavors.
              </p>
            </motion.div>
          </div>
        </div>

        <div className="opacity-75 rounded-lg h-[400px] mt-[50px] p-4 flex">
          <div>
            <motion.h1
              initial={{ x: 75, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              viewport={{ once: true }}
              className="font-bold text-5xl"
            >
              Coordination
            </motion.h1>
            <motion.div
              initial={{ x: -75, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.75 }}
              viewport={{ once: true }}
            >
              <p className="text-2xl mt-2">
                Aimed to design and construction partners
              </p>
              <p className="text-2xl mt-2">
                BBuilding Information Modeling (BIM) is essential to advance
                design and engineering practice. At Hamadah Architecture, our
                team of professionals leverages BIM to help our partners excel
                in delivering successful projects. By creating detailed models
                and ensuring seamless coordination, we enhance efficiency,
                reduce risks, and improve overall project outcomes. Our tailored
                solutions support better decision-making and collaboration,
                ensuring your projects meet the highest standards of quality and
                performance.
              </p>
            </motion.div>
          </div>
          {/* <motion.div
            initial={{ x: 75, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.75 }}
            viewport={{ once: true }}
            className="min-w-[350px] h-[350px] bg-gray-500"
          >
            box
          </motion.div> */}
          <ImageBlock />
        </div>
        <div className="opacity-75 rounded-lg min-h-[400px] mt-[50px] p-4 flex">
          <ImageBlock />

          <div className="ml-4">
            <motion.h1
              initial={{ x: 75, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              viewport={{ once: true }}
              className="font-bold text-5xl"
            >
              Management
            </motion.h1>
            <motion.div
              initial={{ x: 75, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.75 }}
              viewport={{ once: true }}
            >
              <p className="text-2xl mt-2">Aimed at contractors</p>
              <p className="text-2xl mt-2">
                At Hamadah Architecture, we recognize the transformative impact
                of 4D modeling on the construction industry. By integrating
                time-related information with 3D models, 4D modeling allows for
                enhanced project planning, scheduling, and visualization. This
                cutting-edge approach not only facilitates better management of
                construction timelines but also helps in identifying potential
                issues before they arise, thereby reducing delays and costs. As
                leaders in the field, Hamadah Architecture employs
                state-of-the-art 4D modeling techniques to ensure that our
                construction partners have the most accurate and efficient
                project management tools at their disposal. Our commitment to
                innovation and excellence makes us a trusted partner in
                delivering successful, high-quality projects.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ImageBlock() {
  return (
    <motion.div
      initial={{ x: -75, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      viewport={{ once: true }}
      className="min-w-[350px] h-[350px] bg-gray-600 overflow-hidden"
    >
      <motion.img
        src={Spinaci}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.25, ease: "easeInOut" }}
      />
    </motion.div>
  );
}
