import React, { useEffect, useState, useRef } from "react";
import * as pdfjs from "pdfjs-dist/webpack";
import { toast } from "react-toastify";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useDocument } from "../../hooks/useDocument";
import { useUploadDocument } from "../../hooks/useUploadDocument";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// icons
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { async } from "@firebase/util";
import { useCanvasContext } from "../../hooks/useCanvasContext";
import RevStack from "./RevStack";
import PDFCanvas from "./PDFCanvas";
import PDFToImages from "./TurnPdfToImages";

const PDF_URL_2 = ""; //"/ABDULAZIZ.pdf";

/***
 * PDF Canvas
 */
export default function PDFCanvasplay() {
  const { document: project } = useDocument("reviews", "hamadah");

  /***
   * 1. load pdf document
   */
  const [pdfurl, setPdfUrl] = useState("");
  useEffect(() => {
    if (project) {
      const length = project.urls.length;
      if (pdfurl == "") {
        setPdfUrl(project.urls[length - 1]);
      }
    }
  }, [project]);

  return (
    <div>
      <RevStack />
      {/* <PDFCanvas pdfUrl={PDF_URL_2} /> */}
      <PDFToImages pdfUrl={pdfurl} />
    </div>
  );
}
