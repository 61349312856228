import React from "react";
import { toast } from "react-toastify";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HexStudioProject from "../../../models/HexStudioProject";

export default function StudioProjectMemberStack({ project, users }) {
  /***
   * resolve member delete
   */
  const resolveMemberDelete = (e, memberId) => {
    e.stopPropagation();
    if (window.confirm("delete member")) {
      const hexSTProject = HexStudioProject.fromData(project);
      hexSTProject.removeMemberWithId(memberId);
      hexSTProject.commit().then((ref) => {
        toast.success("delete member");
      });
    }
  };

  const resolveMemberName = (memberId) => {
    return users.find((u) => u.id == memberId).displayname || "no name";
  };

  return (
    <div className="flex flex-col gap-1">
      {project.members.map((memberId) => (
        <div key={memberId}>
          <div className="flex items-center p-2 bg-gray-400 min-h-[80px] hover:bg-gray-500/45">
            <div>
              <div className="font-bold text-xl">
                {resolveMemberName(memberId)}
              </div>
              <div className="">{memberId}</div>
            </div>

            <div
              onClick={(e) => resolveMemberDelete(e, memberId)}
              className="ml-auto mr-2 opacity-50 hover:opacity-100 cursor-pointer"
            >
              <DeleteOutlineIcon />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
