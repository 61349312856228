import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function Contacts() {
  const { height, width } = useWindowDimensions();
  return (
    <div>
      <div
        style={{ height: height - 50 }}
        className="bg-yellow-400 flex items-center justify-center"
      >
        <div className="">
          <div className="mx-auto cursor-default text-6xl font-bold">
            Contacts
          </div>
        </div>
      </div>
    </div>
  );
}
