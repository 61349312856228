import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getAuth } from "firebase/auth";
import { useAuthContext } from "../../hooks/useAuthContext";
import { motion } from "framer-motion";

import { usePermify } from "@permify/react-role";
import Enums from "../../models/Enums";
import { SELECT_ROLES } from "../../models/Enums";
import { useCanvasContext } from "../../hooks/useCanvasContext";
import { toast } from "react-toastify";
import { useDocument } from "../../hooks/useDocument";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import Project from "../../models/Project";
import { Link, useNavigate } from "react-router-dom";
import sketch from "../../assets/white/sketch.png";

export default function Home() {
  // useWindowDimensions
  const { can, is, activeUser, userIs } = useAuthContext(); // another way of getting this is getAuth().currentUser
  const { height, width } = useWindowDimensions();

  const { showNav, setShowNav } = useCanvasContext();

  useEffect(() => {
    setShowNav(true);
  }, []);

  const [showOverview, setShowOverview] = useState(false);
  const handleLearnMore = () => {
    setShowOverview(true);
  };

  return (
    <div>
      <div
        style={{ height: height - 50 }}
        className="relative bg-coral flex items-center justify-center"
      >
        {/* overview */}
        {showOverview && (
          <motion.div
            initial={{ y: 20, opacity: 0 }} // Start slightly below and invisible
            animate={{ y: 0, opacity: 1 }} // Move to original position and become visible
            transition={{ duration: 0.3, ease: "easeOut" }} // Customize animation timing
            className="absolute top-[150px] right-[100px] w-[450px]"
          >
            <h1 className="ml-[76px] mb-[20px]">Overview</h1>
            <div className="flex flex-col gap-2">
              <FeatureBlock />
              <FeatureBlock />
              <FeatureBlock />
              <FeatureBlock />
            </div>
            <div
              onClick={() => setShowOverview(false)}
              className="text-2xl ml-[76px] mt-[10px] inline-block opacity-50 hover:opacity-100 cursor-pointer"
            >
              Got it
            </div>
          </motion.div>
        )}

        {/* landing */}
        {!activeUser && (
          <div className="flex flex-col items-center justify-center">
            <img className="" src={sketch} width={500} height={500} />

            <h1 className="text-5xl">Design • Review • Present</h1>
            <div className="font-thin my-6 text-xl cursor-default">
              hexpo helps you focus on creativity ...{" "}
              {/* <span
                onClick={handleLearnMore}
                className="opacity-40 hover:opacity-100 cursor-pointer"
              >
                learn more
              </span> */}
            </div>
            <Link to="/login">
              <h3 className="font-light w-[150px] px-4 rounded-lg py-2 border border-black cursor-pointer hover:bg-black/10">
                <div className="text-center">sign in</div>
              </h3>
            </Link>
          </div>
        )}

        {/* student welcome */}
        {activeUser && activeUser.entityId == "" && (
          <div>
            <h1 className="cursor-default">
              {userIs(SELECT_ROLES.STUDENT) ? (
                <div>
                  <span className="">Welcome to</span>
                  <span className="text-gray-00 ml-2 text-gray-300 border-b-[8px] border-black">
                    Hex
                  </span>
                  <span className="">Academy</span>
                </div>
              ) : (
                "Welcome to Hexpo"
              )}
            </h1>
          </div>
        )}

        {/* entity dash */}
        {activeUser && activeUser.entityId != "" && (
          <div className="flex gap-1 items-center w-[1000px] mx-auto">
            <div className="flex-1 bg-white/50 h-[300px]">
              <PinnedProjectPanel userId={activeUser.id} />
            </div>
            <div className="flex-1 bg-white/50 h-[300px]">
              <InboxProjectPanel userId={activeUser.id} />
            </div>
            <div className="flex-1 bg-white/50 min-h-[300px] max-h-[600px] overflow-hidden overflow-y-auto">
              <ProjectFeedbackPanel userId={activeUser.id} />
            </div>
          </div>
        )}

        {/* <div className="">
          <div className="mx-auto cursor-default text-6xl font-bold">
            home{activeUser && <div>signed in</div>}
            {can("read") && <div>user can read</div>}
            {is(SELECT_ROLES.MASTER) && <div>this is a Master account</div>}
          </div>
        </div> */}
      </div>
      {/* <div className="w-[1200px] mx-auto mb-4">
        <div className=" bg-blue-500 opacity-75 rounded-lg h-[400px] mt-4">
          panel 1
        </div>
        <div className=" bg-blue-400 opacity-75 rounded-lg h-[400px] mt-4">
          panel 2
        </div>
        <div className=" bg-blue-300 opacity-75 rounded-lg h-[400px] mt-4">
          panel 3
        </div>
      </div> */}
    </div>
  );
}

const FeatureBlock = () => {
  return (
    <div>
      <div className="flex p-2 items-start">
        <div className="w-[60px] h-[60px] mt-1 bg-black/50 rounded"></div>
        <div className="w-[350px] ml-2">
          break a way of from your computer and get into a more fluid creative
          flow to develop your design concepts. Hexpo provides a greate set of
          tools to help you focus on concept development.
        </div>
      </div>
    </div>
  );
};

const PinnedProjectPanel = ({ userId }) => {
  const navigate = useNavigate();
  const { document: user } = useDocument("users", userId);
  const { projects } = useCollectionContext();

  const resolveProjectName = (projectId) => {
    return (
      projects.find((project) => project.id == projectId).name || "no name"
    );
  };

  const goToProject = (projectId) => {
    // project id
    navigate(`/project/${projectId}`);
  };

  const goToProjects = (e) => {
    e.stopPropagation();
    const selected = 0;
    localStorage.setItem("selected", JSON.stringify(selected));
    navigate(`/projects`);
  };

  const listLimit = 5;
  return (
    <div className="p-2">
      <h3 onClick={(e) => goToProjects(e)} className="mb-2 cursor-pointer">
        pinned projects
      </h3>
      <div className="flex flex-col gap-1">
        {user?.pinned.slice(0, listLimit).map((item) => (
          <div
            key={item}
            onClick={() => goToProject(item)}
            className="flex items-center bg-gray-400 h-[40px] p-1 cursor-pointer hover:bg-gray-500 transition-colors duration-100 ease-in-out"
          >
            <div>{resolveProjectName(item)}</div>
          </div>
        ))}
        {user?.pinned.length > listLimit && (
          <div>
            <div
              onClick={(e) => goToProjects(e)}
              className="cursor-default ml-[72px]"
            >{`and ${user?.pinned.length - listLimit} more`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

/***
 * inbox panel
 */
const InboxProjectPanel = ({ userId }) => {
  const { document: user } = useDocument("users", userId);
  const { projects } = useCollectionContext();
  const { resetCanavs } = useCanvasContext();
  const navigate = useNavigate();

  const resolveProjectName = (projectId) => {
    return (
      projects.find((project) => project.id == projectId).name || "no name"
    );
  };
  const resolveDrawingName = (projectId, drawingId) => {
    const project = projects.find((project) => project.id == projectId);
    const drawing = project.drawings.find((drawing) => drawing.id == drawingId);
    return drawing?.serial || "no serial";
  };

  const handleGoToDrawing = (projectId, drwingId) => {
    toast.success("ready to go to drawing");
    resetCanavs();
    navigate(`/canvas/${drwingId}/${projectId}/${0}`);
  };

  const goToInbox = (e) => {
    e.stopPropagation();
    const selected = 2;
    localStorage.setItem("selected", JSON.stringify(selected));
    navigate(`/projects`);
  };

  const listLimit = 5;
  return (
    <div className="p-2">
      <h3 onClick={(e) => goToInbox(e)} className="mb-2 cursor-pointer">
        Inbox<span className="font-thin ml-2 opacity-50">(unread)</span>
      </h3>
      <div className="flex flex-col gap-1">
        {user?.inbox.items
          .filter((item) => item.isRead == false)
          .slice(0, listLimit)
          .map((item) => (
            <div
              key={item.id}
              onClick={() => handleGoToDrawing(item.projectId, item.drawingId)}
              className="flex p-1 items-center bg-gray-400 h-[40px] hover:bg-gray-500 cursor-pointer transition-colors duration-100 ease-in-out"
            >
              <div>{resolveDrawingName(item.projectId, item.drawingId)}</div>
              <div className="ml-4 truncate max-w-xs">
                {resolveProjectName(item.projectId)}
              </div>
            </div>
          ))}

        {/* more */}
        {user?.inbox.items.length > listLimit && (
          <div>
            <div
              onClick={(e) => goToInbox(e)}
              className="ml-[72px] cursor-pointer"
            >{`and ${user?.inbox.items.length - listLimit} more`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

/***
 * feedback panel
 */
const ProjectFeedbackPanel = ({ userId }) => {
  const { projects } = useCollectionContext();
  const [pendingFB, setPendingFB] = useState([]);

  const { resetCanavs } = useCanvasContext();
  const navigate = useNavigate();

  const resolveProjectName = (projectId) => {
    return (
      projects.find((project) => project.id == projectId).name || "no name"
    );
  };

  const resolveDrawingName = (projectId, drawingId) => {
    const project = projects.find((project) => project.id == projectId);
    const drawing = project.drawings.find((drawing) => drawing.id == drawingId);
    return drawing?.serial || "no serial";
  };

  const handleGoToDrawing = (projectId, drwingId) => {
    resetCanavs();
    navigate(`/canvas/${drwingId}/${projectId}/${0}`);
  };

  useEffect(() => {
    let results = [];
    if (projects) {
      projects.forEach((p) => {
        const hexProj = Project.fromData(p);
        const drawing = hexProj.getPendingFeedback();
        if (drawing != null) {
          results.push(drawing);
        }
      });

      setPendingFB(results);
    }
  }, [projects]);

  const goToFeedback = (e) => {
    e.stopPropagation();
    // this is a hack. Instead of moving selected to a context.
    // I will leave it where it is and override its value in the local storage
    const selected = 3;
    localStorage.setItem("selected", JSON.stringify(selected));
    navigate(`/projects`);
  };

  const listLimit = 5;
  return (
    <div className="p-2">
      <h3 onClick={(e) => goToFeedback(e)} className="mb-2 cursor-pointer">
        Pending Feedback
      </h3>
      <div className="flex flex-col gap-1">
        {pendingFB.slice(0, listLimit).map((item, index) => (
          <div
            onClick={() => handleGoToDrawing(item.projectId, item.id)}
            key={index}
            className="bg-gray-400 h-[40px] p-1 hover:bg-gray-500 cursor-pointer transition-colors duration-100 ease-in-out"
          >
            <div className="flex h-full items-center gap-2">
              <div className="min-w-[60px]">
                {resolveDrawingName(item.projectId, item.id)}
              </div>
              <div className="truncate max-w-xs">
                {resolveProjectName(item.projectId)}
              </div>
            </div>
          </div>
        ))}
        {pendingFB.length > listLimit && (
          <div>
            <div
              onClick={(e) => goToFeedback(e)}
              className="cursor-pointer ml-[72px]"
            >{`and ${pendingFB.length - listLimit} more`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

/** 
feature 1
Creative
break a way of from your computer and get into a more fluid creative flow to develop your design concepts. 
Hexpo provides a greate set of tools to help you focus on concept development. 

feature 2
collaboration
Hexpo makes it easy and seamless to collaborate on design with your teams.

feature 3
delightful presentations
Hexpo offers exception presentation features to help explain your design to the client. 

feature 4
document control

feature 5
effecient:
It saves time

feature 6
sustainable
It is paperless, and saves printing costs including machine cost, maintenence. 
*/
