import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function NigtaPage() {
  const navigate = useNavigate();
  const goToCanavs = () => {
    // go to canvas
    const id = "0";
    navigate(`/nigCanvas/${id}`);
  };

  return (
    <div className="flex w-[1000px] mx-auto mt-[20px]">
      <div>
        <div className="text-4xl text-gray-600 font-bold cursor-default">
          nigta
        </div>
        {/* <div onClick={goToCanavs} className="mt-4 cursor-pointer text-4xl">
          test sheet
        </div> */}

        <div className="flex items-center gap-1 mt-4">
          <Link to={"/tmpCanvas"} className="text-lg">
            <div className="cursor-pointer flex items-center justify-center bg-slate-600 rounded-lg size-[200px]">
              Letter Stack
            </div>
          </Link>

          <Link to={"/buildTheWord"} className="text-lg">
            <div className="cursor-pointer flex items-center justify-center bg-slate-600 rounded-lg size-[200px]">
              Build The Word
            </div>
          </Link>

          <Link to={"/memoblock"} className="text-lg">
            <div className="cursor-pointer flex items-center justify-center bg-slate-600 rounded-lg size-[200px]">
              Match The Fruite
            </div>
          </Link>

          <div className="cursor-pointer flex items-center justify-center bg-slate-600 rounded-lg size-[200px]">
            Count
          </div>
        </div>
      </div>
    </div>
  );
}
