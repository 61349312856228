import { CircularProgress } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BasicDialog from "../../components/dialogs/BasicDialog";
import { db } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useDocument } from "../../hooks/useDocument";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { SELECT_ROLES } from "../../models/Enums";
import EntityRow from "./components/EntityRow";

export default function MyAccountPage() {
  const { height, width } = useWindowDimensions();
  const { userIs } = useAuthContext();
  const { id } = useParams();
  const { users } = useCollectionContext();
  const { document: user, error } = useDocument("users", id);

  const [showAddUser, setShowAddUser] = useState(false);

  // data
  const [data, setData] = useState({ id: "" });
  // handle data change
  const handleDataChange = (e, key) => {
    setData({ ...data, [key]: e.target.value });
  };

  /***
   * handle action
   */
  const handleAction = async (agree) => {
    const u = users?.find((r) => r.id == data.id);

    if (agree) {
      if (u.id == user.id) {
        toast.error("you cannot add your account to your own entity");
        dismiss();
      }

      if (!u) {
        toast.error("no user found with this id");
        dismiss();
      }

      const d = { entityId: user.entityId };
      const docRef = doc(db, "users", u.id);
      await setDoc(docRef, d, { merge: true }).then((ref) => {
        toast.success(
          "user added to your entity. they can now see and work shared projects"
        );
        dismiss();
      });
    } else {
      dismiss();
    }
  };

  const dismiss = () => {
    setData({ id: "" });
    setShowAddUser(false);
  };

  const userHasEnity = () => {
    if (!user) return false;
    return user.entityId !== "";
  };

  return user ? (
    <div className="w-[1040px] mx-auto p-5">
      {/* Dialog */}
      <BasicDialog
        title={"Add User"}
        open={showAddUser}
        handleAction={handleAction}
      >
        {/* input */}
        <span style={{ display: "block" }} className="w-[500px]">
          <span style={{ display: "block" }} className="mt-4">
            <label
              htmlFor="userId"
              className="block text-sm font-medium text-gray-700"
            >
              Add user id to add this user to your entity
            </label>
            <input
              id="userId"
              name="user id"
              placeholder={"user id"}
              value={data["id"]}
              className="mt-2 block w-full rounded-md border-gray-200 border-2 focus:border-gray-300 focus:ring-0 sm:text-sm h-12 pl-4 resize-none"
              onChange={(e) => handleDataChange(e, "id")}
            />
          </span>
        </span>
      </BasicDialog>

      {/* page content */}
      <h1 className="cursor-default">{user.displayname}</h1>
      <hr className="border-t-2 border-gray-700 my-4" />
      {/* <div>request to join entity</div> */}

      <h2 className="mt-[200px]">entity</h2>
      <hr className="border-t-2 border-gray-700 my-4" />
      <EntityRow entityId={user.entityId} />

      {userHasEnity() && (
        <div className="flex mt-[6px]">
          <div className="w-1/4"></div>
          {userIs(SELECT_ROLES.MASTER) && (
            <div
              style={{ width: "calc(25% - 3px)" }}
              className="bg-orange-300 rounded px-6 py-2 hover:bg-orange-400 transition-colors duration-200"
            >
              <div
                onClick={() => setShowAddUser(true)}
                className="cursor-pointer"
              >
                add user to entity
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div
      style={{ width: width, height: height - 100 }}
      className="flex items-center justify-center h-screen"
    >
      <div className="flex flex-col gap-2 items-center justify-center bg-black/50 rounded-lg w-[100px] h-[100px]">
        <div className="text-white font-thin">loading</div>
        <CircularProgress size={20} sx={{ color: "white" }} />
      </div>
    </div>
  );
}
