import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Enums from "../../models/Enums";

export default function UsersDash() {
  const { height, width } = useWindowDimensions();
  const { users } = useCollectionContext();
  const navigate = useNavigate();

  const handleClick = (id) => {
    toast.success("going to user");
    navigate(`/user/${id}`);
  };

  return (
    <div
      style={{ height: height - 50 }}
      className="flex w-[1000px] bg-red-300 mx-auto mt-4"
    >
      <div className="w-full">
        <h1>users dash</h1>
        <div>users: {users?.length}</div>
        <div className="flex flex-col gap-[2px]">
          {users &&
            users.map((user, index) => (
              <div
                key={index}
                onClick={() => handleClick(user.id)}
                className="cursor-pointer"
              >
                <UserRow user={user} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export function UserRow({ user }) {
  /***
   * permisions
   */
  const permisionsForRole = (role) => {
    return Enums.RolePermissions[role];
  };

  return (
    <div className="bg-slate-600">
      <div className="flex py-1 min-h-[40px] items-center">
        <div className="flex-col">
          <div className="px-2 min-w-[200px] bg-green-500">
            user: {user.displayname}
          </div>
          {/* <div>{user.uid}</div> */}
        </div>
        <div className="min-w-[100px] bg-red-400">{user.role.value}</div>

        <div className="flex flex-wrap gap-1">
          {permisionsForRole(user.role.value)?.map((per, index) => (
            <div key={index}>
              <div className="min-w-[75px] bg-orange-400 py-1 px-2 rounded text-center cursor-default">
                {per}
              </div>
            </div>
          ))}

          {user.permissions.map((per, index) => (
            <div key={index}>
              <div className="min-w-[75px] bg-gray-400 py-1 px-2 rounded text-center cursor-default">
                {per.value}
              </div>
            </div>
          ))}
        </div>

        <div className="ml-auto min-w-[50px] mr-1">
          <div className="h-full text-center cursor-pointer">[edit]</div>
        </div>
      </div>
    </div>
  );
}
