import { uuidv4 as uuid } from "@firebase/util";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../firebase/config";
import MisDocument from "./MisDocument";
import MisDraft from "./MisDraft";
import MisLawsuit from "./MisLawsuit";

class MisClient {
  id = ""; // generated by firebase on adding object to firestore
  name = "";
  number = "";
  avatar = "";
  date = new Date();
  lawsuits = [];
  documents = [];
  inbox = { items: [] };

  constructor(_id = uuid()) {
    this.id = _id;
  }

  blankId() {
    delete this.id; // remove the id when first initiating so we can allow firebase to install its document id
    return this;
  }

  static fromData = (data) => {
    const p = new MisClient(data["id"]);
    p.name = data["name"];
    p.number = data["number"];
    p.avatar = data["avatar"];
    p.date = data["date"];
    p.lawsuits = data["lawsuits"]?.map((lawsuiteData) =>
      MisLawsuit.fromData(lawsuiteData)
    );

    p.documents = data["documents"];
    p.inbox = data["inbox"];

    return p;
  };

  data = () => {
    return {
      ...(this.id !== undefined && { id: this.id }),
      number: this.number,
      name: this.name,
      avatar: this.avatar,
      date: this.date,
      lawsuits: this.lawsuits.map((lawsuit) => lawsuit.data()),

      documents: this.documents,
      inbox: this.inbox,
    };
  };

  addLawsuit = (misLawsuit) => {
    this.lawsuits.push(misLawsuit);
  };

  getLawsuitWithId = (lawsuitId) => {
    return this.lawsuits.find((lawsuit) => lawsuit.id == lawsuitId) || null;
  };

  getDocumentInLawsuit = (docId, lawsuitId) => {
    const misLawsuit = MisLawsuit.fromData(this.getLawsuitWithId(lawsuitId));
    return misLawsuit.getDocumentWithId(docId);
  };

  addDocumentToLawsuit = (document, lawsuitId) => {
    const misLawsuit = MisLawsuit.fromData(this.getLawsuitWithId(lawsuitId));
    misLawsuit.addDocument(document);
  };

  // Add draft
  addDraftToDocument = (lawsuitId, docId) => {
    // draft
    const misDraft = new MisDraft();
    // set ids
    misDraft.clientId = this.id;
    misDraft.lawsuitId = lawsuitId;
    misDraft.documentId = docId;
    const draft = misDraft.data();

    const misDocument = this.getDocumentInLawsuit_test(lawsuitId, docId);
    misDocument.addDraft(draft);

    return draft;
  };

  getDocumentInLawsuit_test = (lawsuitId, docId) => {
    const misLawsuit = MisLawsuit.fromData(this.getLawsuitWithId(lawsuitId));
    const misDocument = MisDocument.fromData(
      misLawsuit.getDocumentWithId(docId)
    );
    return misDocument;
  };

  removeDraftToDocument = (lawsuitId, docId, draftId) => {
    const lawsuit = this.lawsuits.find((lawsuit) => lawsuit.id == lawsuitId);
    const document = lawsuit.documents.find((document) => document.id == docId);
    document.drafts = document.drafts.filter((draft) => draft.id != draftId);
  };

  echoDocument = (lawsuitId, docId) => {
    const lawsuit = this.lawsuits.find((lawsuit) => lawsuit.id == lawsuitId);
    const document = lawsuit.documents.find((document) => document.id == docId);

    toast.success(`number of drafts: ${document.drafts.length}`);
    console.log(document.drafts.length);
  };

  commit = () => {
    const d = this.data();

    console.log(d);

    const docRef = doc(db, "misClients", this.id);
    return setDoc(docRef, d, { merge: true });
  };
}
export default MisClient;
