import { db } from "../firebase/config";
import { useState, useEffect } from "react";

// firebase imports
import {
  collection,
  doc,
  onSnapshot,
  updateDoc,
  where,
} from "firebase/firestore";

export const useCollection = (c) => {
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state

  useEffect(() => {
    let ref = collection(db, c);

    const unsub = onSnapshot(
      ref,
      (snapshot) => {
        let results = [];

        snapshot.docs.forEach((snap) => {
          // insert the id from the snap
          results.push({ id: snap.id, ...snap.data() });
        });
        setDocuments(results);
        setLoading(false);
        setError(null);
      },
      (err) => {
        console.error("Firestore error:", err);
        setError("Could not fetch the data."); // Set error message
        setLoading(false); // Stop loading on error
      }
    );

    // cleanup function
    return () => unsub;
  }, [c]);

  return { documents, loading, error };
};
