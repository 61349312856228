import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Framer() {
  const [show, setShow] = useState(true);

  return (
    <div className="min-[1000px] mb-[50px]">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginTop: "30px" }}>
          Framer motion exit and enter animation
        </h1>

        <AnimatePresence>
          {show && (
            <motion.div
              style={{ width: "100px", height: "100px", background: "gray" }}
              initial={{ opacity: 0, x: -150 }}
              animate={{ opacity: 1, x: 100 }}
              exit={{ opacity: 0, x: -150 }}
              transition={{
                duration: "1",
              }}
            ></motion.div>
          )}
        </AnimatePresence>

        <button onClick={() => setShow(!show)} className="mt-4">
          {show ? "remove cube" : "show cube"}
        </button>
      </div>
      <div className="bg-gray-300 h-[600px] mt-4">
        <h1 className="text-center pt-[20px]">
          Draggable object with framer motion
        </h1>
        <div className="flex flex-row gap-2 justify-center mt-[80px]">
          <div className="flex gap-2">
            <div>
              <p>no const</p>
              <motion.div className="w-[150px] h-[150px] bg-gray-600" drag>
                black
              </motion.div>
            </div>
            <div>
              <p>drag Y const</p>
              <motion.div className="w-[150px] h-[150px] bg-gray-600" drag="y">
                black
              </motion.div>
            </div>
            <div>
              <p>drag const</p>
              <motion.div
                className="w-[150px] h-[150px] bg-gray-600"
                drag
                dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
              >
                black
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        className="mt-[100px]"
        initial={{ x: 300, opacity: 0 }}
        whileInView={{ x: 350, opacity: 1 }}
        transition={{ duration: 0.5, delay: 1.0 }}
        viewport={{ once: true }}
      >
        <h1 className="text-4xl font-bold">Slide In Text</h1>
      </motion.div>
      <motion.div
        className="mt-[20px]"
        initial={{ x: 275, opacity: 0 }}
        whileInView={{ x: 350, opacity: 1 }}
        transition={{ duration: 0.5, delay: 1.25 }}
        viewport={{ once: true }}
      >
        <p className="w-[300px]">
          This is a paragraph here. It is just text to see if we can delay its
          animation in view
        </p>
      </motion.div>
    </div>
  );
}
