/***
 * project tab
 */
export default function ProjectTab({
  index,
  label,
  selectedTab = 0,
  onClick,
  numberOfItems = 0,
}) {
  return (
    <div onClick={() => onClick(index)} className="flex items-center pr-3">
      <div
        className={`${
          selectedTab == index ? "opacity-100" : "opacity-40"
        } font-bold text-3xl cursor-pointer`}
      >
        {label}
      </div>
      <div className="text-3xl font-thin text-gray-400 cursor-default">
        ({numberOfItems})
      </div>
    </div>
  );
}
