import React, { useRef, useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { motion } from "framer-motion";

const CanvasComponent = () => {
  const canvasRef = useRef(null);
  const [isScaled, setIsScaled] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(1);

  const toggleScale = () => {
    const newScale = isScaled ? 1 : 1.5; // Toggle between normal and scaled up
    setScaleFactor(newScale);
    setIsScaled(!isScaled);
  };

  const handlePointerDown = (event) => {
    if (canvasRef.current) {
      const boundingRect = canvasRef.current.getBoundingClientRect();
      const x = (event.clientX - boundingRect.left) / scaleFactor; // Adjust for scale
      const y = (event.clientY - boundingRect.top) / scaleFactor; // Adjust for scale
      canvasRef.current.current.beginStroke({ x, y });
    }
  };

  const handlePointerMove = (event) => {
    if (canvasRef.current && event.buttons === 1) {
      // Only when mouse is pressed
      const boundingRect = canvasRef.current.getBoundingClientRect();
      const x = (event.clientX - boundingRect.left) / scaleFactor; // Adjust for scale
      const y = (event.clientY - boundingRect.top) / scaleFactor; // Adjust for scale
      canvasRef.current.current.addPoint({ x, y });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <motion.div
        style={{
          position: "relative",
          width: `${500 * scaleFactor}px`, // Dynamically set width/height based on scale
          height: `${500 * scaleFactor}px`,
        }}
      >
        {/* Background Image */}
        <img
          src="https://example.com/background.jpg"
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        />

        {/* Sketch Canvas */}
        <ReactSketchCanvas
          ref={canvasRef}
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid black",
            transform: `scale(${scaleFactor})`, // Apply the scaling here
            transformOrigin: "0 0", // Keep the scaling centered on the top-left
          }}
          strokeWidth={4 / scaleFactor} // Adjust stroke width according to scale
          strokeColor="black"
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
        />
      </motion.div>

      <button
        onClick={toggleScale}
        style={{ position: "absolute", bottom: 20 }}
      >
        {isScaled ? "Reset Scale" : "Scale Up"}
      </button>
    </div>
  );
};

export default CanvasComponent;
