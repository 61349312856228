import React, { useState, useRef } from "react";

import UploadImage from "../../../components/UploadImage";
import TextField from "../../../fields/TextField";
import SelectField from "../../../fields/SelectField";
import SwitchFiled from "../../../fields/SwitchField";
import DateField from "../../../fields/DateField";
import UploadImageButton from "../../../components/UploadImage";
import Revision from "../../../models/Revision";
// firebase
import { db } from "../../../firebase/config";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

import { toast } from "react-toastify";
import { useUploadDocument } from "../../../hooks/useUploadDocument";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import Enums from "../../../models/Enums";
import { CircularProgress } from "@mui/material";
import { useDocument } from "../../../hooks/useDocument";
import { useAuthContext } from "../../../hooks/useAuthContext";
// import { v4 as uuid } from "uuid";
// import { timestamp } from "../firebase/config";

export default function AddProject({ dismiss }) {
  const { activeUser } = useAuthContext();
  const { document: user } = useDocument("users", activeUser.id);

  const [data, setData] = useState({
    name: "qutaibah",
    date: new Date(),
    file: null,
  });

  const [inProgress, setInProgress] = useState(false);
  const [selFiles, setSelFiles] = useState([]);
  const [selected, setSelected] = useState({});
  const inputfileRef = useRef();
  const { uploadImage } = useUploadDocument();
  const { projects } = useCollectionContext();
  const [optionData, setOptionData] = useState({
    value: "blue",
    label: "blue",
  });
  const [isReady, setIsReady] = useState(false);
  const resolveIsReady = (option) => {
    setIsReady(option);
  };

  const [tagOption, setTagOption] = useState([]);
  const resolveTagOption = (otion) => {
    setTagOption(otion);
  };

  // const addName = async () => {
  //   const d = {
  //     ...data,
  //     entityId: user.entityId,
  //     images: [],
  //     color: optionData,
  //     isReady: isReady,
  //   };
  //   const projectRef = collection(db, "projects");
  //   await addDoc(projectRef, d).then((docRef) => {
  //     console.log("project added");
  //   });
  // };

  const options = [
    { value: "blue", label: "blue" },
    { value: "orange", label: "orange" },
    { value: "pink", label: "pink" },
  ];
  const resolveOptions = (option) => {
    setOptionData(option);
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setData({ ...data, file: file });
  };

  /***
   * handle form submit
   */
  const handleFormSubmit = async () => {
    // make sure there is an active user
    if (!user) {
      toast.error("loading active user. please try again");
      return;
    }

    // 1. validate image
    if (data.file == undefined || data.file == "") {
      toast.error("please select an image");
      return;
    }

    setInProgress(true);

    // 2. upload image
    const file = data.file;
    await uploadImage(file, "hexpo").then((imageUrl) => {
      const url = imageUrl.replace(
        "firebasestorage.googleapis.com/v0/b/hexpo-94ce2.appspot.com",
        "ik.imagekit.io/habiatuq/hexpo"
      );

      // 3. prepare document for firestore
      // revision
      const r = new Revision();
      // d
      const d = {
        number: data.number,
        name: data.name,
        date: data.date,
        entityId: user.entityId,
        color: optionData,
        isReady: isReady,
        drawings: [],
        cover: "",
        url: url,
        images: [],
        tags: tagOption,
        revisions: [r.data()],
      };

      // 4. add doc in firetore
      const collectionRef = collection(db, "projects");
      addDoc(collectionRef, d).then((docRef) => {
        setInProgress(false);
        dismiss();
        toast.success("done");
      });
    });
  };

  const handleAddClick = () => {
    inputfileRef.current.click();
  };

  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    setData({ ...data, file: file });
  };

  const resolveDismiss = () => {
    setInProgress(false);
    dismiss();
  };
  return (
    <div>
      <div className="w-[600px] p-4 rounded-lg">
        <TextField
          isActive={true}
          inputWidth={600}
          label="project number"
          id="number"
          valKey="number"
          data={data}
          setData={setData}
          production={true}
          showLabel={false}
        />

        <TextField
          inputWidth={600}
          isActive={true}
          label="project name"
          id="name"
          valKey="name"
          data={data}
          setData={setData}
          production={true}
          showLabel={false}
        />

        <SelectField
          inputWidth={600}
          fullWidth={true}
          options={options}
          data={optionData}
          setData={resolveOptions}
          isActive={true}
          production={true}
          showLabel={false}
        />

        <SelectField
          label={"tags"}
          inputWidth={600}
          fullWidth={true}
          options={Enums.TagOptions}
          data={tagOption}
          setData={resolveTagOption}
          isActive={true}
          isMulti={true}
          showLabel={false}
        />

        <SwitchFiled
          id="isReady"
          data={isReady}
          setData={resolveIsReady}
          isActive={true}
          production={true}
          showLabel={false}
          paddingLeft={12}
        />

        <DateField
          inputWidth={600}
          label="color date"
          id="date"
          isActive={true}
          valKey="date"
          data={data}
          setData={setData}
          production={true}
          showLabel={false}
        />

        <div
          onClick={handleAddClick}
          className="bg-blue-600 text-white rounded mt-2 py-2 hover:bg-blue-700 cursor-pointer"
        >
          <div className="ml-2 font pointer-events-none">
            <input
              ref={inputfileRef}
              type="file"
              id="file"
              onChange={handleSelectFile}
              className="hidden"
              // multiple={true}
            />
            select image
            {<span className="ml-2">{selected ? data.file?.name : ""}</span>}
          </div>
        </div>
      </div>

      <div className="flex gap-1 ml-3">
        <button
          disabled={inProgress}
          onClick={handleFormSubmit}
          className="bg-slate-600 hover:bg-slate-500 w-[140px] h-[40px] px-2 rounded text-white"
        >
          {!inProgress && <span>add project</span>}
          {inProgress && (
            <div className="mt-1">
              <CircularProgress size={20} />
            </div>
          )}
        </button>

        <button
          className="bg-slate-600 hover:bg-slate-500 w-[140px] h-[40px] px-2 rounded text-white"
          onClick={resolveDismiss}
        >
          cancel
        </button>
      </div>
    </div>
  );
}
