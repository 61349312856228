import { createContext, useReducer, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  initSerialForToday,
  headerIsCurrent,
  incrementSerial,
} from "../utilities/SerialManager";

export const StudioContext = createContext();

export const StudioContextProvider = ({ children }) => {
  const ratings = [
    { value: 0, lable: "unset" },
    { value: 1, lable: "excellent" },
    { value: 2, lable: "good" },
    { value: 3, lable: "fair" },
    { value: 4, lable: "weak" },
  ];
  const [rating, setRating] = useState("unset");

  //const [members, setMembers] = useState([]);

  // Load the initial value of `selectedTab` from localStorage or set to 0 if not present
  const [members, setMembers] = useState(() => {
    const savedMembers = localStorage.getItem("members");
    return savedMembers !== null ? JSON.parse(savedMembers) : [];
  });
  // Save `selectedTab` to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("members", JSON.stringify(members));
  }, [members]);

  // Load the initial value of `selectedTab` from localStorage or set to 0 if not present
  const [selectedTab, setSelectedTab] = useState(() => {
    const savedSelectedTab = localStorage.getItem("selectedTab");
    return savedSelectedTab !== null ? JSON.parse(savedSelectedTab) : 0;
  });
  // Save `selectedTab` to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selectedTab", JSON.stringify(selectedTab));
  }, [selectedTab]);

  // Load the initial value from localStorage or set to 0 if not present
  const [currentStudentId, setCurrentStudentId] = useState(() => {
    const savedCurrentStudentId = localStorage.getItem("currentStudentId");
    return savedCurrentStudentId !== null
      ? JSON.parse(savedCurrentStudentId)
      : "";
  });
  // Save `selected` to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("currentStudentId", JSON.stringify(currentStudentId));
  }, [currentStudentId]);

  /***
   * resolve serial
   */
  const resolveSerial = (project) => {
    const drawingsForId = resolveDrawingsForId(project, currentStudentId);

    // get the prefix
    const header = drawingsForId[0];
    let serial = "";

    // if no drawings
    if (drawingsForId.length == 0) {
      serial = initSerialForToday();
    } else {
      // if drawings exist
      // if head has todays prefix > increment
      if (headerIsCurrent(header.serial)) {
        serial = incrementSerial(header.serial);
      } else {
        // if header has a diffrent prefix, initial new prefix for today.
        serial = initSerialForToday();
      }
    }
    return serial;
  };

  /***
   * resolve drawings for id
   */
  const resolveDrawingsForId = (project, studentId) => {
    return project.drawings
      .filter(
        (drawing) =>
          drawing.studentId == currentStudentId &&
          drawing.projectId == project.id
      )
      .sort((a, b) => b.date.toDate() - a.date.toDate());
  };

  /***
   * resolve name
   */
  const resoveNameForId = (memberId) => {
    const member = members.find((m) => m.id == memberId);
    return member?.displayname || "no name";
  };

  const goToStudioCanvas = (drawingId, projectId) => {
    // navigate(`/canvas/${drawingId}/${projectId}/${1}`);
  };

  /***
   * drawings for this week
   */
  const drawingsForThisWeek = (drawings) => {
    const now = new Date();
    const startOfWeek = new Date(now);
    const endOfWeek = new Date(now);

    // Set start of the week (assuming week starts on Sunday)
    startOfWeek.setDate(now.getDate() - now.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    // Set end of the week (Saturday 23:59:59.999)
    endOfWeek.setDate(now.getDate() + (6 - now.getDay()));
    endOfWeek.setHours(23, 59, 59, 999);

    return drawings.filter((drawing) => {
      const drawingDate = drawing.date.toDate(); // Assuming timestamp is a Firestore Timestamp
      return drawingDate >= startOfWeek && drawingDate <= endOfWeek;
    });
  };

  /***
   * drawings for last week
   */
  const drawingsForLastWeek = (drawings) => {
    const now = new Date();
    const startOfLastWeek = new Date(now);
    const endOfLastWeek = new Date(now);

    // Set start of last week (assuming week starts on Sunday)
    startOfLastWeek.setDate(now.getDate() - now.getDay() - 7);
    startOfLastWeek.setHours(0, 0, 0, 0);

    // Set end of last week (Saturday 23:59:59.999)
    endOfLastWeek.setDate(now.getDate() - now.getDay() - 1);
    endOfLastWeek.setHours(23, 59, 59, 999);

    return drawings.filter((drawing) => {
      const drawingDate = drawing.date.toDate(); // Assuming timestamp is a Firestore Timestamp
      return drawingDate >= startOfLastWeek && drawingDate <= endOfLastWeek;
    });
  };

  const drawingsOlderThanLastWeek = (drawings) => {
    const now = new Date();
    const startOfLastWeek = new Date(now);

    // Set start of last week (assuming week starts on Sunday)
    startOfLastWeek.setDate(now.getDate() - now.getDay() - 7);
    startOfLastWeek.setHours(0, 0, 0, 0);

    return drawings.filter((drawing) => {
      const drawingDate = drawing.date.toDate(); // Assuming timestamp is a Firestore Timestamp
      return drawingDate < startOfLastWeek;
    });
  };

  const value = {
    currentStudentId,
    setCurrentStudentId,
    resolveSerial,
    members,
    setMembers,
    resoveNameForId,
    selectedTab,
    setSelectedTab,
    goToStudioCanvas,
    rating,
    setRating,
    ratings,
    drawingsForThisWeek,
    drawingsForLastWeek,
    drawingsOlderThanLastWeek,
  };

  return (
    <StudioContext.Provider value={value}>{children}</StudioContext.Provider>
  );
};
