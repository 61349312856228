import { doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BasicDialog from "../../components/dialogs/BasicDialog";
import { db } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import { useDocument } from "../../hooks/useDocument";
import useGetMembersForEntity from "../../hooks/useGetMembersForEntity";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function HexEntityPage() {
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();

  const { id } = useParams();

  // collections
  const { documents: entities } = useCollection("entities");
  const { documents: users } = useCollection("users");

  // state
  const [entity, setEntity] = useState(null);
  const [owner, setOwner] = useState(null);

  const {
    documents: members,
    loading,
    error,
  } = useGetMembersForEntity("users", [id]);

  // fields
  const [name, setName] = useState("");
  const [diaName, setDiaName] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAction = async (agree) => {
    if (agree) {
      // update name
      const val = diaName;
      setName(val);

      if (entity.name == val) {
        toast.error("no changes made");
        setDialogOpen(false);
        return;
      }

      const d = { name: val };
      const docRef = doc(db, "entities", id);

      await setDoc(docRef, d, { merge: true }).then((docRef) => {
        toast.success("name updated");
        setDiaName("");
        setDialogOpen(false);
      });
    } else {
      // dismiss
      setDialogOpen(false);
    }
  };

  const resolveOpenNameDialog = () => {
    setDiaName(name);
    setDialogOpen(true);
  };

  /***
   * this will update the text
   */

  const handleInputChange = (event) => {
    const inputValue = event.target.value; // Get the input string
    setDiaName(inputValue);
  };

  useEffect(() => {
    if (entity) {
      const owner = users.find((u) => u.id == entity.ownerId);
      setOwner(owner);
    }
  }, [entity, users]);

  useEffect(() => {
    if (entities) {
      const e = entities.filter((ent) => ent.id == id)[0];

      setEntity(e);
      setName(e.name);
    }
  }, [entities]);

  if (!entity) {
    return (
      <div style={{ width: width, height: height }} className="relative">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white flex justify-center items-center bg-black/50 rounded-lg w-[100px] h-[100px] p-2">
            loading
          </div>
        </div>
      </div>
    );
  }

  const resolveOwnerName = () => {
    return owner ? owner.displayname : "not found";
  };

  // const resolveNameForOwner = () => {}

  return (
    <div className="flex mx-auto w-[800px] mt-4">
      <div className="w-full">
        <div onClick={() => navigate(-1)} className="cursor-pointer">
          back
        </div>

        <h1
          onClick={resolveOpenNameDialog}
          className="cursor-pointer w-[300px]"
        >
          {name}
        </h1>
        <div className="text-xl cursor-default font-thin">{entity.id}</div>
        <hr className="border-t-2 border-gray-700 my-4" />
        <div className="text-3xl cursor-default">owner</div>
        <div>{resolveOwnerName()}</div>

        <hr className="border-t-2 border-gray-700 my-4 mt-10" />
        <div className="text-3xl cursor-default">members</div>
        {members && members.length == 0 && (
          <div>
            <div>no members in entity</div>
          </div>
        )}
        {members &&
          members.map((member) => (
            <div>
              <div>{member.displayname}</div>
            </div>
          ))}
      </div>

      {/* Dialoug */}
      <BasicDialog
        title={"Change Name"}
        open={dialogOpen}
        handleAction={handleAction}
      >
        {/* input */}
        <div className="w-[500px]">
          <div className="mt-4">
            <label
              htmlFor="imageName"
              className="block text-sm font-medium text-gray-700"
            >
              Entity name
            </label>
            <input
              type="text"
              id="imageName"
              name="imageName"
              placeholder={name}
              value={diaName}
              className="mt-2 block w-full rounded-md border-gray-200 border-2 focus:border-gray-300 focus:ring-0 sm:text-sm h-12 pl-4"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </BasicDialog>
    </div>
  );
}
