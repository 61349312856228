import { createContext, useReducer, useEffect, useState } from "react";
import { auth } from "../firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Enums, { Roles } from "../models/Enums";
import { useDocument } from "../hooks/useDocument";

export const MiswadahContext = createContext();

export const MiswadahContextProvider = ({ children }) => {
  // const [currentClient, setCurrentClient] = useState(null);

  // Load the initial value from localStorage or set to 0 if not present
  const [client, setClient] = useState(() => {
    const savedClient = localStorage.getItem("client");
    return savedClient !== null ? JSON.parse(savedClient) : null;
  });
  // Save `selected` to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("client", JSON.stringify(client));
  }, [client]);

  const value = {
    client,
    setClient,
  };

  return (
    <MiswadahContext.Provider value={value}>
      {children}
    </MiswadahContext.Provider>
  );
};
