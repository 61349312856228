class CrumbTitle {
  start = 0;
  end = 0;
  title = "";
  projId = "";

  constructor(start, end, title, projId) {
    this.start = start;
    this.end = end;
    this.title = title;
    this.projId = projId;
  }

  brackets = (ind) => {
    return this.start <= ind && ind <= this.end;
  };
}

export default CrumbTitle;
