import React from "react";
import PdfSketch from "../../components/pdf/PdfSketch";
import PDF_FILE from "../../assets/pdf/plan.pdf";
import SketchOverImage from "./SketchOverImage";

function App() {
  const pdfFile = PDF_FILE; // "your-pdf-file.pdf"; // Replace with the path to your PDF file

  return (
    <div className="App">
      {/* <h1>Sketch on Image</h1> */}
      {/* <PdfSketch pdfFile={pdfFile} /> */}
      <SketchOverImage />
    </div>
  );
}

export default App;
