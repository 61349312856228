import { useState, useEffect } from "react";
import TextField from "../../fields/TextField";
import Button from "@mui/material/Button";
import { useLogin } from "../../hooks/useLogin";
import { toast } from "react-toastify";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import sketch from "../../assets/white/white_sketch.png";
import { motion } from "framer-motion";

export default function Login() {
  const [data, setData] = useState({ email: "", password: "" });
  const { login } = useLogin();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    handleCancel();
  }, []); // Empty dependency array ensures it only runs once on mount

  const handleLogin = () => {
    // handle sign in
    login(data.email, data.password);
  };

  const handleCancel = () => {
    setData({ email: "", password: "" });
  };

  return (
    <div
      style={{ height: height - 50 }}
      className="flex justify-center h-[600px] items-center bg-gray-600"
    >
      <div>
        <img src={sketch} width={400} height={600} className="mt-[-50px]" />

        <motion.div
          initial={{ y: 50, opacity: 0 }} // Start slightly below and invisible
          animate={{ y: -10, opacity: 1 }} // Move to original position and become visible
          transition={{ duration: 0.6, ease: "easeOut" }} // Customize animation timing
          className="flex flex-col gap-1 justify-center p-2 rounded-md md:w-[400px] h-[200px]"
        >
          <div className="text-center text-6xl mb-4 font-bold">sign in</div>
          <div className="mx-auto">
            <TextField
              label="email"
              isActive={true}
              id="email"
              valKey="email"
              data={data}
              setData={setData}
              showLabel={false}
              fullWidth={true}
              production={true}
              drak={true}
            />
          </div>
          <div className="mx-auto">
            <TextField
              label="password"
              isActive={true}
              id="password"
              valKey="password"
              type="password"
              data={data}
              setData={setData}
              showLabel={false}
              fullWidth={true}
              production={true}
              drak={true}
            />
          </div>
          <div className="flex mt-4 mx-auto">
            <Button onClick={handleLogin} variant="contained">
              Sign in
            </Button>
            <div className="ml-2">
              <Button onClick={handleCancel} variant="contained">
                Cancel
              </Button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
