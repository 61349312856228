import React from "react";
import { type ReactNode } from "react";

interface CourseGoalProps {
  id: number;
  title: string;
  onDelete: (id: number) => void;
  children?: ReactNode;
}

export default function CourseGoal({
  id,
  title,
  onDelete,
  children,
}: CourseGoalProps) {
  return (
    <article>
      <div className="p-4 bg-gray-700 rounded-lg mb-2">
        <div>
          <h2 className="text-white cursor-default">{title}</h2>
          <p className="text-white cursor-default">{children}</p>
        </div>
        <button
          className="mb-1 bg-yellow-400 rounded-sm px-6 py-1 hover:bg-yellow-500"
          onClick={(e) => onDelete(id)}
        >
          delete
        </button>
      </div>
    </article>
  );
}
