import React from "react";

export default function StoriesPage() {
  return (
    <div
      style={{ fontSize: "120px" }}
      className="flex items-center justify-center w-screen h-[calc(100vh-80px)] mt-[-40px] font-bold text-gray-600"
    >
      <div>stories</div>
    </div>
  );
}
