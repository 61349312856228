import { useEffect, useState } from "react";

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|Macintosh/.test(userAgent) && navigator.maxTouchPoints > 1) {
      // Detects iPad even when requesting desktop sites
      setDeviceType("tablet");
    } else if (
      /android|iphone|ipod|blackberry|windows phone|webOS/i.test(userAgent)
    ) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }
  }, []);

  return deviceType;
};

export default useDeviceType;
