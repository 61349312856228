import Select, { components } from "react-select";

export default function SelectField({
  label = "category",
  options,
  data,
  setData,
  inputWidth = 300,
  labelWidth = 300,
  fullWidth = false,
  isActive = false,
  production = false,
  isMulti = false,
  showLabel = true,
  components = null,
}) {
  // https://stackoverflow.com/questions/54218351/changing-height-of-react-select-component

  const theme = (theme) => ({
    ...theme,
    colors: { ...theme.colors },
    spacing: {
      ...theme.spacing,
      controlHeight: 42,
    },
  });

  return (
    <div
      className={`flex items-center ${!isActive ? "h-11" : ""}  ${
        production ? "" : "bg-yellow-400"
      } `}
    >
      {showLabel && <p className="w-[${labelWidth}px]">{label}</p>}

      {isActive && (
        <Select
          styles={{
            menu: (provided) => ({
              ...provided,
              maxHeight: "none", // Removes the default max height
            }),
          }}
          theme={theme}
          // classNamePrefix="h-[55px]"
          className={`${fullWidth ? "w-screen" : "w-[300px]"} ${
            showLabel ? "ml-auto" : ""
          } bg-red-400 border border-gray-500 rounded`}
          onChange={(option) => setData(option)}
          value={data}
          options={options}
          components={components}
          isMulti={isMulti}
        />
      )}
      {!isActive && <p className="w-[300px] ml-auto px-2">{data.label}</p>}
    </div>
  );
}
