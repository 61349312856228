import { useStudioContext } from "../../../hooks/useStudioContext";
import StudioProjectDrawingRow from "./StudioProjectDrawingRow";

export default function StudioProjectDrawingStack({ project, users }) {
  const { currentStudentId } = useStudioContext();
  return (
    <div className="flex flex-col gap-1">
      {project.drawings
        .filter((drawing) => drawing.studentId == currentStudentId)
        .sort((a, b) => b.date.toMillis() - a.date.toMillis())
        .map((drawing) => (
          <div key={drawing.id}>
            <StudioProjectDrawingRow
              drawing={drawing}
              project={project}
              users={users}
            />
          </div>
        ))}
    </div>
  );
}
