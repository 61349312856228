import { CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import useWindowDimensions from "../hooks/useWindowDimensions";

function ProtectedRoute({ children, requiredRole, authIsReady = true }) {
  const { activeUser } = useAuthContext();
  const { width, height } = useWindowDimensions();

  if (!authIsReady)
    return (
      <div
        style={{ width: width, height: height - 100 }}
        className="flex items-center justify-center h-screen"
      >
        <div className="flex flex-col gap-2 items-center justify-center bg-black/50 rounded-lg w-[100px] h-[100px]">
          <div className="text-white font-thin">loading</div>
          <CircularProgress size={20} sx={{ color: "white" }} />
        </div>
      </div>
    );

  // If the user is not authenticated, redirect to the home page
  if (authIsReady && !activeUser) {
    return <Navigate to="/" />;
  }

  // If the user is authenticated but does not have the required role, redirect to home
  if (requiredRole && activeUser.role.value !== requiredRole) {
    return <Navigate to="/" />;
  }

  // If authenticated and has the correct role, render the protected component
  return children;
}

export default ProtectedRoute;
