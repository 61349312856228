import { Dialog } from "@mui/material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import InboxRow from "./InboxRow";
import { handleSendDrawing_Group } from "../../../utilities/InboxManager";
import RecipientSelector from "../../hex/components/RecipientSelector";
import Project from "../../../models/Project";
import { useNavigate } from "react-router-dom";
import { useCanvasContext } from "../../../hooks/useCanvasContext";

export default function PendingFeedback({ inboxHeight, drawings }) {
  const { activeUser } = useAuthContext();
  const { users, projects } = useCollectionContext();
  const [user, setUser] = useState(null);
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [currentDrawing, setCurrentDrawing] = useState(null);
  const { resetCanavs } = useCanvasContext();

  // drawing groups
  const [todayItems, setTodayItems] = useState([]);
  const [prevItems, setPrevItems] = useState([]);

  const [pendingFB, setPendingFB] = useState([]);

  useEffect(() => {
    let results = [];
    if (projects) {
      projects.forEach((p) => {
        const hexProj = Project.fromData(p);
        const drawing = hexProj.getPendingFeedback();
        if (drawing != null) {
          results.push(drawing);
        }
      });

      setPendingFB(results);
    }
  }, [projects]);

  useEffect(() => {
    if (users) {
      const u = users.find((u) => u.id == activeUser.id);
      setUser(u);
      const items = u.inbox.items;

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset to the start of the day

      const todayGroup = [];
      const otherGroup = [];

      items.forEach((item) => {
        const itemDate = item.date.toDate();

        // Check if the item's date is today
        if (itemDate >= today) {
          todayGroup.push(item);
        } else {
          otherGroup.push(item);
        }
      });

      setTodayItems(todayGroup);
      setPrevItems(otherGroup);
    }
  }, [users]);

  const handleOpenDialog = (drawing) => {
    setCurrentDrawing(drawing);
    setOpenSendDialog(true);
  };

  /***
   * handle send to inboxs
   */
  const handleSend = (e) => {
    e.stopPropagation();
    if (recipients.length < 1) {
      toast.error("must add recipients to send drawing");
      return;
    }
    const ids = recipients.map((r) => r.id);
    console.log(ids);

    handleSendDrawing_Group(
      e,
      users,
      currentDrawing,
      activeUser.id,
      ids,
      setOpenSendDialog
    );
  };

  const handleClose = () => {
    setCurrentDrawing(null);
    setOpenSendDialog(false);
  };

  const handleCapture = (e) => {
    e.stopPropagation();
  };

  const resolveProjectName = (projectId) => {
    return (
      projects.find((project) => project.id == projectId).name || "no name"
    );
  };
  const resolveDrawingName = (projectId, drawingId) => {
    const project = projects.find((project) => project.id == projectId);
    const drawing = project.drawings.find((drawing) => drawing.id == drawingId);
    return drawing.serial;
  };

  const navigate = useNavigate();
  const handleGoToDrawing = (projectId, drwingId) => {
    toast.success("ready to go to drawing");
    resetCanavs();
    navigate(`/canvas/${drwingId}/${projectId}/${0}`);
  };

  if (!user) return <div>loading</div>;

  return (
    <div
      style={{ minHeight: inboxHeight }}
      className="flex flex-col gap-1 w-full h-full"
    >
      <div className="text-4xl my-6">Feedback</div>

      <div className="flex flex-col gap-1">
        {pendingFB.map((item, index) => (
          <div
            onClick={() => handleGoToDrawing(item.projectId, item.id)}
            key={index}
            className="bg-gray-400 h-[40px] p-1 hover:bg-gray-500 cursor-pointer transition-colors duration-100 ease-in-out"
          >
            <div className="flex h-full items-center gap-2">
              <div className="w-[60px]">
                {resolveDrawingName(item.projectId, item.id)}
              </div>
              <div>{resolveProjectName(item.projectId)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
