import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSignup } from "../../hooks/useSignup";
import Button from "@mui/material/Button";
// import { TextField } from "@mui/material";
import TextField from "../../fields/TextField";

export default function Signup() {
  const { error, signup } = useSignup();
  const [data, setData] = useState({
    email: "",
    password: "",
    displayName: "",
    contact: "",
  });

  const handleSignup = async () => {
    await signup({
      email: data.email,
      password: data.password,
      displayname: data.displayName,
      contact: data.contact,
    }).then(() => {
      toast.success("user added");
    });
  };

  return (
    <div className="flex justify-center items-center min-h-[400px]">
      {/* <div className="cursor-pointer" onClick={handleSignup}>
        Signup
      </div> */}
      <div className="">
        <div className="bg-gray-400 rounded-lg w-[500px] p-4">
          <div>
            <div className="font-bold text-xl mb-2">Signup</div>
            <div className="">
              <TextField
                label={"email"}
                valKey={"email"}
                data={data}
                setData={setData}
                isActive={true}
              />
            </div>
            <div className="mt-1">
              <TextField
                label={"password"}
                valKey={"password"}
                type={"password"}
                data={data}
                setData={setData}
                isActive={true}
              />
            </div>
            <div className="mt-1">
              <TextField
                label={"display name"}
                valKey={"displayName"}
                data={data}
                setData={setData}
                isActive={true}
              />
            </div>
            <div className="mt-1">
              <TextField
                label={"contact"}
                valKey={"contact"}
                data={data}
                setData={setData}
                isActive={true}
              />
            </div>

            <Button variant="contained" sx={{ mt: 2 }} onClick={handleSignup}>
              signup
            </Button>
          </div>
        </div>
      </div>
      {error && <div>{error}</div>}
    </div>
  );
}
