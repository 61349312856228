import { async } from "@firebase/util";
import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { uuidv4 as uuid } from "@firebase/util";

// icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function HexSettings() {
  const navHeight = 50;
  const { height, width } = useWindowDimensions();
  const { documents: entities } = useCollection("entities");
  const navigator = useNavigate();
  const [newUuid, setNewUuid] = useState("");

  /***
   * add entity
   */
  const handleAddEntity = async () => {
    // data
    const d = { name: "new office" };
    const colRef = collection(db, "entities");
    await addDoc(colRef, d).then((docRef) => {
      toast.success("document added");
    });
  };

  const navToEntity = (id) => {
    console.log(id);
    navigator(`/entity/${id}`);
  };

  const createUuid = () => {
    const u = uuid();
    setNewUuid(u);
  };

  return (
    <div
      style={{
        width: width,
        height: height - navHeight,
      }}
      className="bg-gray-400"
    >
      <h1>hex settings</h1>
      <div className="bg-orange-100/50 mt-4 p-10">
        <div className="flex gap-2">
          <div className="bg-gray-400 flex-grow rounded p-2 text-3xl">
            entities
          </div>
          <div className="bg-gray-400 flex-grow-[2] rounded p-2">
            <div className="flex">
              <div className="text-3xl">entities</div>
              <div
                onClick={handleAddEntity}
                className="ml-2 cursor-pointer text-3xl"
              >
                <div className="transform transition-all duration-300 ease-in-out hover:scale-[1.4]">
                  <AddCircleOutlineIcon fontSize="small" />
                </div>
              </div>
            </div>
            <div className="mt-4">
              {entities &&
                entities.map((entity) => (
                  <div key={entity.id}>
                    <div
                      onClick={() => navToEntity(entity.id)}
                      className="flex gap-2 cursor-pointer"
                    >
                      <div className="pointer-events-none w-[100px] text-ellipsis">
                        {entity.name}
                      </div>
                      <div className="font-thin pointer-events-none">
                        {entity.id}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="bg-gray-400 flex-grow rounded p-2 text-3xl">
            entities
          </div>
        </div>
        <div className="mt-10">
          <h1 className="font-bold">UUID</h1>
          <div
            onClick={createUuid}
            className="border-gray-600/55 border-2 bg-gray-400 w-[400px] p-2 cursor-pointer hover:bg-gray-500 rounded mt-1"
          >
            create uuid
          </div>
          <div className="mt-1 p-2 w-[400px] rounded bg-gray-600">
            {newUuid}
          </div>
        </div>
      </div>
    </div>
  );
}
