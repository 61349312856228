import React, { useEffect, useState } from "react";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import useGetMembersForEntity from "../../../hooks/useGetMembersForEntity";

export default function EntityRow({ entityId }) {
  const [entity, setEntity] = useState(null);
  const { entities, projects, users } = useCollectionContext();
  // const {
  //   documents: members,
  //   loading,
  //   error,
  // } = useGetMembersForEntity("users", [entityId]);

  useEffect(() => {
    const ent = entities?.find((e) => e.id == entityId);
    if (ent) {
      setEntity(ent);
    }
  }, [entities]);

  const [members, setMembers] = useState([]);
  useEffect(() => {
    const m = users.filter((u) => u.entityId == entityId);
    setMembers(m);
  }, [users]);

  const resolveNumProjects = () => {
    let n = 0;
    if (projects) {
      n = projects.filter((proj) => proj.entityId == entityId).length;
    }
    return n;
  };

  const resolveNumberOfDrawing = () => {
    let count = 0;
    const projs = projects.filter((proj) => proj.entityId == entityId);

    projs.forEach((proj) => {
      count += proj.drawings?.length || 0;
    });
    return count;
  };

  return (
    <div className="flex items-center gap-1">
      <div className="bg-black/25 flex-1 p-2 rounded h-[100px] cursor-default">
        <div>name</div>
        <div className="font-bold">{entity?.name}</div>
      </div>
      <div className="bg-black/25 flex-1 p-2 rounded h-[100px] cursor-default">
        <div>members</div>
        <div className="font-bold">{members.length} members</div>
      </div>
      <div className="bg-black/25 flex-1 p-2 rounded h-[100px] cursor-default">
        <div>projects</div>
        <div className="font-bold">{resolveNumProjects()} projects</div>
      </div>
      <div className="bg-black/25 flex-1 p-2 rounded h-[100px] cursor-default">
        <div>drawings</div>
        <div className="font-bold">{resolveNumberOfDrawing()} drawings</div>
      </div>
    </div>
  );
}
