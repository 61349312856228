import React, { useEffect, useState } from "react";
import { useStudioContext } from "../../../hooks/useStudioContext";
import {
  BarChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { toast } from "react-toastify";

export default function StudioProjectDashStack({ project }) {
  const {
    drawingsForThisWeek,
    drawingsForLastWeek,
    drawingsOlderThanLastWeek,
    members,
  } = useStudioContext();

  // const data = [
  //   { name: "abdullah", pv: 400, uv: 2400 },
  //   { name: "dalia", pv: 400, uv: 2400 },
  //   { name: "deemah", pv: 300, uv: 1400 },
  //   { name: "dhalia", pv: 300, uv: 2210 },
  //   { name: "fatmah", pv: 300, uv: 2210 },
  //   { name: "ghala", pv: 300, uv: 1400 },
  //   { name: "hajar", pv: 189, uv: 2181 },
  //   { name: "haya", pv: 600, uv: 1800 },
  //   { name: "hend", pv: 200, uv: 2290 },
  //   { name: "mahmood", pv: 300, uv: 1400 },
  //   { name: "mshael", pv: 300, uv: 1400 },
  //   { name: "saja", pv: 278, uv: 2000 },
  //   { name: "sarah", pv: 278, uv: 2000 },
  // ];

  const charKey = "performance";
  const [data, setData] = useState([]);

  useEffect(() => {
    const d = members.map((member) => {
      return { name: member.displayname, [charKey]: resolveValue(member.id) };
    });
    setData(d);
  }, []);

  const chartStyle = {
    backgroundColor: "#333333", // Dark gray background
    borderRadius: "8px", // Optional rounded corners
  };

  const resolveValue = (memberId) => {
    return project.drawings.filter(
      (drawing) => drawing.type == "submission" && drawing.sender == memberId
    ).length;
  };

  const resolvePerformance = (memberId) => {
    const memberDrawings = project.drawings.filter(
      (drawing) => drawing.type == "submission" && drawing.sender == memberId
    );

    let numDrawings = memberDrawings.length;
    let ratingSum = 0;
    memberDrawings.forEach((drawing) => {
      ratingSum += scoreForRating(drawing.rating);
    });
    const ratingAvrage = ratingSum / numDrawings;
    const performance = numDrawings * ratingAvrage;
    return performance;
  };

  const scoreForRating = (ratingKey) => {
    switch (ratingKey) {
      case "excellent":
        return 5;
        break;
      case "very good":
        return 4;
        break;
      case "very good":
        return 3;
        break;
      case "fair":
        return 2;
        break;
      case "weak":
        return 1;
        break;
      default:
        return 0;
    }
  };

  return (
    <div className="cursor-default">
      <div className="flex gap-1 mt-4">
        <NumberBlock
          number={project?.drawings.length}
          label={"total drawings"}
        />
        <NumberBlock
          number={drawingsForThisWeek(project.drawings).length}
          label={"drawings this week"}
        />

        <NumberBlock
          number={drawingsForLastWeek(project.drawings).length}
          label={"drawings last week"}
        />
        <NumberBlock
          number={drawingsOlderThanLastWeek(project.drawings).length}
          label={"past drawings"}
        />
      </div>
      <div className="mt-4 flex items-center justify-center">
        <div
          className="bg-gray-400 rounded p-2 min-w-[150px] 
        border-[2px] border-gray-600 text-center cursor-pointer"
        >
          submission
        </div>
        <div
          className="ml-1 bg-gray-400 rounded p-2 min-w-[150px] 
        border-[2px] border-gray-600 text-center cursor-pointer"
        >
          performance
        </div>
      </div>
      <div
        className="flex items-center justify-center mt-4 py-10"
        style={chartStyle}
      >
        <BarChart width={950} height={600} data={data} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" width={80} />

          <Legend />
          {/* <Bar dataKey="pv" fill="#8884d8" /> */}
          <Bar
            dataKey={charKey}
            fill="#82ca9d"
            label={{ position: "insideRight" }}
          />
        </BarChart>
      </div>
    </div>
  );
}

const NumberBlock = ({ number, label }) => {
  return (
    <div
      className="flex flex-col items-center justify-center text-white 
    min-w-[150px] min-h-[100px] bg-black/50 rounded-lg flex-1
    border-[2px] border-gray-600"
    >
      <div className="text-4xl">{number}</div>
      <div>{label}</div>
    </div>
  );
};
