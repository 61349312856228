import React, { type ReactNode } from "react";

type xInfoBox = {
  mode: "hint" | "warning";
  severity?: "low" | "medium" | "high";
  children: ReactNode;
};

export default function InfoBox({ mode, severity, children }: xInfoBox) {
  if (mode === "hint") {
    return (
      <aside
        className="flex items-center justify-center 
        border-solid border-[1px] border-white rounded-md p-4"
      >
        <p className="text-white">{children}</p>
      </aside>
    );
  }
  return (
    <aside>
      <h2>{mode}</h2>
      <p>{children}</p>
    </aside>
  );
}
