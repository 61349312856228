import { useCanvasContext } from "../../hooks/useCanvasContext";

// icons
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

const VisibilityButton = ({ handleDisableViewModel }) => {
  const { viewModeIsActive, showCanvasPages } = useCanvasContext();

  const resolveBottom = () => {
    if (!showCanvasPages) return 40;
    return viewModeIsActive ? 40 : 100;
  };

  const resolveOpacity = () => {
    if (!showCanvasPages) return 0;
    return 1;
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: resolveBottom(),
        opacity: resolveOpacity(),
        right: 0,
        transition: "bottom 0.2s", // Add transition for smoothness
      }}
      onClick={handleDisableViewModel}
      className="flex items-center justify-center bg-black text-white w-[74px] h-[56px] rounded-lg ml-auto mr-4 cursor-pointer"
    >
      {viewModeIsActive ? (
        <ModeEditOutlineIcon width={70} heigth={56} />
      ) : (
        <VisibilityOutlinedIcon />
      )}
    </div>
  );
};

export default VisibilityButton;
