// zero

import { toast } from "react-toastify";
import { stageForStack } from "../models/Enums";

export const zeroRight = (version) => {
  const parts = version.split(".").map(Number); // Split and convert to numbers
  parts[parts.length - 1] = 0; // Set the rightmost digit to 0
  return parts.join("."); // Join the parts back into version format
};

export const zeroCenter = (version) => {
  const parts = version.split(".").map(Number); // Split the version string and convert to numbers

  if (parts.length < 3) {
    throw new Error(
      "Version must have at least three parts to zero the center digit."
    );
  }

  const middleIndex = Math.floor(parts.length / 2); // Calculate the middle index
  parts[middleIndex] = 0; // Set the middle part to 0

  return parts.join("."); // Join the parts back into a version string
};

// increments

export const incrementRight = (version) => {
  const parts = version.split(".").map(Number); // Split and convert to numbers
  parts[parts.length - 1]++; // Increment the rightmost part
  return parts.join("."); // Join back into version format
};

export const incrementCenter = (version) => {
  const parts = version.split(".").map(Number); // Split and convert to numbers

  if (parts.length < 3) {
    throw new Error(
      "Version must have at least three parts to increment the middle digit."
    );
  }

  const middleIndex = Math.floor(parts.length / 2); // Find the middle index
  parts[middleIndex]++; // Increment the middle part

  return parts.join("."); // Join back into version format
};

export const incrementLeft = (version) => {
  const parts = version.split(".").map(Number); // Split and convert to numbers

  parts[0]++; // Increment the leftmost part

  return parts.join("."); // Join back into version format
};

/***
 * color increment right
 */
export const colonIncrementRight = (version) => {
  const parts = version.split("."); // Split the version into parts

  if (parts.length < 3) {
    throw new Error(
      "Version must have at least three parts to modify the right digit."
    );
  }

  const lastIndex = parts.length - 1; // Find the index of the rightmost digit
  const rightPart = parts[lastIndex];

  // Check if the right part already contains a colon
  if (rightPart.includes(":")) {
    const [base, increment] = rightPart.split(":").map(Number);
    parts[lastIndex] = `${base}:${increment + 1}`; // Increment the right part
  } else {
    parts[lastIndex] = `${rightPart}:1`; // Start with ":1"
  }

  return parts.join("."); // Join the parts back into a version string
};

/***
 * color increment center
 */
export const colonIncrementCenter = (version) => {
  const parts = version.split("."); // Split the version into parts

  if (parts.length < 3) {
    throw new Error(
      "Version must have at least three parts to modify the center digit."
    );
  }

  const middleIndex = Math.floor(parts.length / 2); // Find the center digit index
  const centerPart = parts[middleIndex];

  // Check if the center part already contains a colon
  if (centerPart.includes(":")) {
    const [base, increment] = centerPart.split(":").map(Number);
    parts[middleIndex] = `${base}:${increment + 1}`; // Increment the right part
  } else {
    parts[middleIndex] = `${centerPart}:1`; // Start with ":1"
  }

  return parts.join("."); // Join the parts back into a version string
};

/***
 * colon increment left
 */
export const colonIncrementLeft = (version) => {
  const parts = version.split("."); // Split the version into parts

  if (parts.length < 3) {
    throw new Error(
      "Version must have at least three parts to modify the left digit."
    );
  }

  const leftIndex = 0; // The index of the leftmost digit
  const leftPart = parts[leftIndex];

  // Check if the left part already contains a colon
  if (leftPart.includes(":")) {
    const [base, increment] = leftPart.split(":").map(Number);
    parts[leftIndex] = `${base}:${increment + 1}`; // Increment the right part
  } else {
    parts[leftIndex] = `${leftPart}:1`; // Start with ":1"
  }

  return parts.join("."); // Join the parts back into a version string
};

/***
 * remove colonFeedback
 */
export const removeColonFeedback = (version) => {
  const parts = version.split("."); // Split the version into parts

  // Iterate over parts to remove the colon and the number after it
  const cleanedParts = parts.map((part) => {
    if (part.includes(":")) {
      return part.split(":")[0]; // Keep only the part before the colon
    }
    return part;
  });

  return cleanedParts.join("."); // Join the cleaned parts back into a version string
};

/***
 * incrementFeedbackSerial
 */
export const incrementFeedbackSerial = (parentSerial, parentStage) => {
  let serial = parentSerial;
  if (parentStage == "design") {
    serial = colonIncrementRight(serial);
  }
  if (parentStage == "review") {
    serial = colonIncrementCenter(serial);
  }
  if (parentStage == "presentation") {
    serial = colonIncrementLeft(serial);
  }
  return serial;
};

/***
 * increment submission serial
 */
export const incrementSubmissionSerial = (serial, stageKey) => {
  if (stageKey == "design") {
    let i = incrementRight(serial); // 0.0.X
    return i;
  }
  if (stageKey == "review") {
    let i = incrementCenter(serial); // 0.X.0
    i = zeroRight(i);
    return i;
  }
  if (stageKey == "presentation") {
    let i = incrementLeft(serial); // X.0.0
    i = zeroCenter(i);
    i = zeroRight(i);
    return i;
  }
};

/***
 * resolve serial
 */
export const resolveSubmissionSerial = (proj, currentStack) => {
  const drawings = proj.drawings;
  if (drawings.length < 1) {
    return currentStack == "studio" ? "0.0.1" : "0.1.0";
  }
  let header = drawings[drawings.length - 1].serial;
  header = removeColonFeedback(header);
  const stage = stageForStack(currentStack);
  return incrementSubmissionSerial(header, stage);
};

/***
 * resolve feedback serial
 */
export const resolveFeedBackSerial = (proj, parentStage) => {
  const drawings = proj.drawings;
  const header = drawings[drawings.length - 1].serial;
  return incrementFeedbackSerial(header, parentStage); // <---- change to parent stage in final code
};

export const resolveStudioserial = () => {
  const serial = ""; // Currently unused
  const d = new Date(); // Create a new date instance
  const day = d.getDate(); // Get the day of the month (1-31)
  const month = d.getMonth() + 1; // Get the month (0-11), adding 1 to make it (1-12)

  const count = 0; // Placeholder value for count
  return `${month}.${day}.${count}`;
};

export const incrementStudioSerial = (currentSerial) => {
  // Split the current serial by '.'
  const parts = currentSerial.split(".");

  // Convert the last part (count) to a number and increment it
  const incrementedCount = parseInt(parts[2], 10) + 1;

  // Construct the new serial
  const newSerial = `${parts[0]}.${parts[1]}.${incrementedCount}`;

  return newSerial;
};

// ---------------------------------------------------------

export const resolvePrefix = () => {
  const d = new Date(); // Create a new date instance
  const day = d.getDate(); // Get the day of the month (1-31)
  const month = d.getMonth() + 1; // Get the month (0-11), adding 1 to make it (1-12)
  return `${month}.${day}`;
};

export const initSerialForToday = () => {
  return `${resolvePrefix()}.1`;
};

export const incrementSerial = (currentSerial) => {
  // Split the current serial by '.'
  const parts = currentSerial.split(".");

  // Convert the last part (count) to a number and increment it
  const incrementedCount = parseInt(parts[2], 10) + 1;

  // Construct the new serial
  const newSerial = `${parts[0]}.${parts[1]}.${incrementedCount}`;

  return newSerial;
};

export const extractCurrentPrefix = (serial) => {
  // Split the serial by '.' and return the first two parts joined by '.'
  const parts = serial.split(".");
  return `${parts[0]}.${parts[1]}`;
};

export const headerIsCurrent = (serial) => {
  const prefix = resolvePrefix();
  const currentPrefix = extractCurrentPrefix(serial);
  return prefix === currentPrefix;
};
