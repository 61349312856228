import React from "react";
import "./memo-card.css";

const style = {
  width: "100%",
  display: "block",
  border: "2px solid #fff",
  borderRadius: "6px",
};

export default function MemoCard({ card, handleClick, flipped, disabled }) {
  const localHandleClick = () => {
    if (!disabled) {
      handleClick(card);
    }
  };

  return (
    <div className="relative card">
      <div className={flipped ? "flipped" : ""}>
        <img
          style={style}
          className="front bg-white rounded  w-[200px] h-[200px]"
          src={card.src}
          atl="card front"
        />
        <div
          onClick={localHandleClick}
          style={style}
          className="back bg-black rounded w-[200px] h-[200px]"
        ></div>
        {/* <img
          onClick={localHandleClick}
          style={style}
          className="back bg-black rounded w-[200px] h-[200px]"
          src={card.src}
          atl="card back"
        /> */}
      </div>
    </div>
  );
}
