import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import MicClient from "../../models/MisClient";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useNavigate } from "react-router-dom";

export default function MiswadahPage() {
  const [isHidden, setIsHidden] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const { misClients } = useCollectionContext();

  const handleSigninClick = () => {
    setIsHidden(true);
  };

  // Delay showing clients after isHidden is true
  useEffect(() => {
    if (isHidden) {
      const timer = setTimeout(() => {
        setShowClients(true);
      }, 500); // Adjust the delay (in milliseconds) as needed
      return () => clearTimeout(timer); // Cleanup timeout on component unmount or change
    } else {
      setShowClients(false);
    }
  }, [isHidden]);

  const handleAddClient = async () => {
    toast.success("adding client");
    const client = new MicClient().blankId();
    client.name = "blue";
    const d = client.data();

    const colRef = collection(db, "misClients");
    await addDoc(colRef, d).then((ref) => {
      toast.success("project added");
    });
  };

  return (
    <div className="relative">
      {/* landing */}
      <motion.div
        style={{ fontSize: "120px" }}
        className={`${
          showClients ? "pointer-events-none" : ""
        } absolute font-bold text-gray-600 flex flex-col items-center justify-center w-screen h-[calc(100vh-80px)] mt-[-40px]`}
        initial={{ opacity: 1, y: 0 }}
        animate={{
          opacity: isHidden ? 0 : 1,
          y: isHidden ? 80 : 0, // Adjust '80' as needed for slide-down distance
        }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <div>miswadah</div>
        <div
          className="text-4xl font-light cursor-pointer text-center"
          onClick={handleSigninClick}
        >
          signin
        </div>
      </motion.div>

      {/* client stack */}
      {showClients && (
        <div className="w-[1000px] mx-auto mt-4">
          {/* title */}
          <div className="flex items-center">
            <div className="text-3xl">clients</div>
            <div
              onClick={handleAddClient}
              className="ml-auto mr-2 cursor-pointer"
            >
              [add client]
            </div>
          </div>
          {/* horizontal break */}
          <hr className="border-gray-800 border-[1px] my-1" />
          <div className="flex flex-col gap-1">
            {misClients.map((client) => (
              <div key={client.id}>
                <ClientRow client={client} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const ClientRow = ({ client }) => {
  const navigate = useNavigate();

  /***
   * go to client
   */
  const goToClient = (e) => {
    // e.stopPropagation();
    navigate(`/client/${client.id}`);
  };
  return (
    <div
      onClick={(e) => goToClient(e)}
      className="flex items-center bg-gray-400 h-[60px] p-2 cursor-pointer"
    >
      <div>id: {client.id}</div>
    </div>
  );
};
