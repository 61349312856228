import React from "react";
import { type ComponentPropsWithoutRef } from "react";

type xButtonProps = {
  el: "button";
} & ComponentPropsWithoutRef<"button">;

type xAnchoProps = {
  el: "anchor";
} & ComponentPropsWithoutRef<"a">;

export default function ButtonComp(props: xButtonProps | xAnchoProps) {
  if (props.el === "anchor") {
    return <a className="button" {...props}></a>;
  }
  return <button className="button" {...props}></button>;
}
