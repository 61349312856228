import { useState } from "react";
import HexDialog from "../forms/HexDialog";
import { toast } from "react-toastify";
import HexStudioProject from "../../../models/HexStudioProject";
import { useAuthContext } from "../../../hooks/useAuthContext";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { displayDate } from "../../../utilities/DateManager";

export default function StudioProjectFileStack({ project }) {
  // state
  const [openSetName, setOpenSetName] = useState(false);
  const [data, setData] = useState({ name: "", serial: "" });
  const { activeUser } = useAuthContext();

  // current file
  const [currentFile, setCurrentFile] = useState(null);

  /***
   * handle open edit name
   */
  const handelOpenNameDialog = (e, file) => {
    setCurrentFile(file);
    e.stopPropagation();
    // toast
    setOpenSetName(true);
    setData({ ...data, name: "" });
  };

  /***
   * handle set name
   */
  const handleEdiFiletName = (agree) => {
    if (agree) {
      const hexProject = HexStudioProject.fromData(project);
      hexProject.setDescriptionForFileWithId(data.name, currentFile.id);
      hexProject.commit().then((ref) => {
        toast.success("name updated");
        setOpenSetName(false);
      });
    } else {
      setOpenSetName(false);
    }
  };

  return (
    <div className="flex flex-col gap-1">
      {/* dialog */}
      <HexDialog
        open={openSetName}
        data={data}
        setData={setData}
        handleDialogButton={handleEdiFiletName}
      />

      {project.files
        .sort((a, b) => b.date.toMillis() - a.date.toMillis())
        .map((file, index) => (
          <div key={index}>
            <StudioProjectFileRow
              file={file}
              project={project}
              handelOpenNameDialog={handelOpenNameDialog}
            />
          </div>
        ))}
    </div>
  );
}

const StudioProjectFileRow = ({ file, project, handelOpenNameDialog }) => {
  const { activeUser } = useAuthContext();

  /***
   * resolve file delete
   */
  const resolveFileDelete = (e, file) => {
    if (window.confirm("delete file")) {
      toast.success("delete");
      const hexProject = HexStudioProject.fromData(project);
      hexProject.removeFileWithId(file.id);
      hexProject.commit().then((ref) => {
        toast.success("file removed");
      });
    }
  };

  /***
   * handle file download
   */
  const handleFileDownload = (e, file) => {
    resolveDownload(e, file);
  };

  /***
   * resolve download
   */
  const resolveDownload = async (e, drawing) => {
    e.stopPropagation();

    // drawing url
    const url = drawing.url;
    const date = handleDisplayDate(drawing.date);

    // Fetch the file as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");

    // Create a URL for the Blob and set it as the href of the link
    const blobURL = window.URL.createObjectURL(blob);
    link.href = blobURL;

    // link.download = "your-filename.pdf";
    link.setAttribute("download", `${project.name}-${date}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /***
   * handle displaydate
   */
  const handleDisplayDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toDateString()} : ${date.toLocaleTimeString()}`; // Format date and time as a string
    }
    return ""; // Return an empty string or a default value if the timestamp is invalid
  };

  const resolveDate = (date) => {
    return displayDate(date);
  };

  return (
    <div className="flex items-center bg-gray-400 h-[80px] p-2">
      <div>
        <div className="font-bold">{resolveDate(file.date)}</div>
        <div className="cursor-default">{file.name}</div>
        <div className="cursor-default">{file.description}</div>
      </div>

      {activeUser.isInstructor && (
        <div
          onClick={(e) => handelOpenNameDialog(e, file)}
          className="ml-auto cursor-pointer"
        >
          [edit]
        </div>
      )}

      <div
        onClick={(e) => handleFileDownload(e, file)}
        className={`flex items-center opacity-50 hover:opacity-100 cursor-pointer ${
          activeUser.isInstructor ? "ml-2" : "ml-auto"
        }`}
      >
        <DownloadIcon />
      </div>

      {activeUser.isInstructor && (
        <div
          onClick={(e) => resolveFileDelete(e, file)}
          className="ml-2 mr-2 opacity-50 hover:opacity-100 cursor-pointer"
        >
          <DeleteOutlineIcon />
        </div>
      )}
    </div>
  );
};
