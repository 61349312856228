import React, { useEffect, useRef, useState } from "react";
import "./memo.css";

// images
import NI_Apple from "../../assets/nigta/Apple.png";
import NI_Orange from "../../assets/nigta/Orange.png";
import NI_Banana from "../../assets/nigta/Banana2.png";
import NI_Pare from "../../assets/nigta/Pare.png";
import NI_Cherry from "../../assets/nigta/Cherry.png";
import NI_Mango from "../../assets/nigta/Mango.png";
import MemoCard from "./components/memo/memo-card";
import { toast } from "react-toastify";

// sound effects
import Beep from "../../assets/sounds/beep.mp3";
import Step from "../../assets/sounds/level-up.mp3";
import ClickEffect from "../../assets/sounds/click-234708.mp3";
import Cheer from "../../assets/sounds/cheer.mp3";

const cardImages = [
  { src: NI_Apple, matched: false },
  { src: NI_Orange, matched: false },
  { src: NI_Banana, matched: false },
  { src: NI_Pare, matched: false },
  { src: NI_Cherry, matched: false },
  { src: NI_Mango, matched: false },
];

export default function MemoBlockPage() {
  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  // sound
  const beepRef = useRef(null);
  const clickRef = useRef(null);

  const shuffleCards = () => {
    const shuffledCards = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));
    setCards(shuffledCards);
    setTurns(0);
  };

  const handleClick = (card) => {
    // playClick();
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };

  /***
   * prevent body from scrolling
   *
   */
  useEffect(() => {
    document.body.style.overflow = "hidden";

    document.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );

    return () => {
      document.body.style.overflow = "auto"; // Re-enable scrolling when the component unmounts
    };
  }, []);

  useEffect(() => {
    shuffleCards();
  }, []);

  // compare two cards
  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setIsDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.src === choiceOne.src) {
              //atched
              playEffect("step");
              return { ...card, matched: true };
            } else {
              playEffect("step");
              return card;
            }
          });
        });
        resetTurn();
      } else {
        // no match
        playEffect("beep");
        setTimeout(() => resetTurn(), 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((prev) => prev + 1);
    setIsDisabled(false);
  };

  const playClick = () => {
    // audio
    const audio = clickRef.current;

    // Stop any currently playing audio
    audio.pause();
    audio.currentTime = 0; // Reset playback position
    // Set the volume (e.g., 50% volume)
    audio.volume = 0.1;

    //audio.load(); // Load the new audio source
    // Delay playing the audio by 300ms
    audio.play(); // Play the new audio
  };

  const playEffect = (effectKey) => {
    // audio
    const audio = beepRef.current;

    // Stop any currently playing audio
    audio.pause();
    audio.currentTime = 0; // Reset playback position

    if (effectKey === "beep") {
      audio.src = Beep;
    }

    if (effectKey === "step") {
      audio.src = Step;
    }

    audio.load(); // Load the new audio source
    // Delay playing the audio by 300ms
    setTimeout(() => {
      audio.play(); // Play the new audio
    }, 500);
  };

  return (
    <div
      style={{ overflow: "hidden", touchAction: "none" }}
      className="w-[1000px] mx-auto"
    >
      <audio ref={clickRef} src={ClickEffect} />
      <audio ref={beepRef} src={Beep} />

      <h1 className="text-center">Fruit Memo</h1>
      <div className="flex justify-center">
        <button onClick={shuffleCards} className="bg-gray-400">
          Fruit Memo
        </button>
      </div>

      <div className="card-grid flex flex-col gap-2">
        {cards.map((card) => (
          <MemoCard
            flipped={card === choiceOne || card === choiceTwo || card.matched}
            card={card}
            key={card.id}
            handleClick={handleClick}
            disabled={isDisabled}
          />
        ))}
      </div>
      <div className="text-4xl font-bold mx-auto text-center py-1">
        Turns: {turns}
      </div>
    </div>
  );
}
