import { useRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cheer from "../../../../assets/sounds/cheer.mp3";
import Beep from "../../../../assets/sounds/beep.mp3";
import TryAgain from "../../../../assets/sounds/tryAgain.mp3";
import { letterAudio } from "../../../../assets/sounds/letter_audio/letter-audio";
import { motion } from "framer-motion";
import { StarSVG } from "../../../../assets/SVG/svgs";
import { a_sm, letters } from "../../models/letters.js";
import {
  centerLetterOnCanvas,
  drawPathsOnCanvas,
  drawCanvasOnMove,
  calculateBoundingBox,
  calculateGroupBoundingBox,
} from "../../lib/DrawManager.js";
import { parseSvgPaths, calculateOffsets } from "../../lib/Utility.js";
import ReplayIcon from "@mui/icons-material/Replay";

const HitTestCanvas = () => {
  const coverCanvasRef = useRef(null);
  const canvasRef = useRef(null);
  const hitCanvasRef = useRef(null);
  const [alerts, setAlerts] = useState(0);
  // const [inAlert, setInAlert] = useState(false);
  const inAlert = useRef(false);
  const [mistakes, setMistakes] = useState(0);
  const [initBlackCount, setInitBlackCount] = useState(0);
  const [expand, setExpand] = useState(false);
  const cheerRef = useRef(null);
  const beepRef = useRef(null);
  const tryAgainRef = useRef(null);

  const didFinishRef = useRef(false);

  const hackMistakesRef = useRef(mistakes);
  const letterAudioRef = useRef(null);
  const selectedLetterRef = useRef(null);
  const needNewSessionRef = useRef(null);

  const handleSwitchLetter = (letter) => {
    resetMistakes();
    setCurrentLetter(letter.svg);

    // audio
    const audio = letterAudioRef.current;

    // Stop any currently playing audio
    audio.pause();
    audio.currentTime = 0; // Reset playback position

    audio.src = letterAudio[letter.audio];
    audio.load(); // Load the new audio source
    audio.play(); // Play the new audio
  };

  const [currentLetter, setCurrentLetter] = useState(a_sm);

  const handleShowCanvases = () => {
    setExpand(!expand);
  };

  // --------------------------------------------------------------------

  const playBeep = () => {
    stopBeep();
    beepRef.current.play();
  };

  const stopBeep = () => {
    if (!beepRef.current.paused) {
      // Stop the audio if it's playing
      beepRef.current.pause();
      beepRef.current.currentTime = 0; // Reset to the beginning
    }
  };

  useEffect(() => {
    hackMistakesRef.current = mistakes;
  }, [mistakes]);

  const playCheer = () => {
    stopCheer();

    if (hackMistakesRef.current > 2) {
      tryAgainRef.current.play();
    } else {
      cheerRef.current.play();
    }
    didFinishRef.current = true;
  };

  const stopCheer = () => {
    if (!cheerRef.current.paused) {
      // Stop the audio if it's playing
      cheerRef.current.pause();
      cheerRef.current.currentTime = 0; // Reset to the beginning
      // stop the tryAgain audio if playing
      tryAgainRef.current.pause();
      tryAgainRef.current.currentTime = 0; // Reset to the beginning
    }
  };

  const resetMistakes = () => {
    setMistakes(0);
    hackMistakesRef.current = 0;
  };

  // --------------------------------------------------------------------

  /***
   * prevent body from scrolling
   *
   */
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto"; // Re-enable scrolling when the component unmounts
    };

    document.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  }, []);

  // const [isDrawing, setIsDrawing] = useState(false);
  const isDrawing = useRef(false);

  useEffect(() => {
    // drawing canvas
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // coverage canvas
    const coverCanvas = coverCanvasRef.current;
    const coverCtx = coverCanvas.getContext("2d");

    // hit canvas

    const hitCanvas = hitCanvasRef.current;
    const hitCtx = hitCanvas.getContext("2d");

    // path array
    const pathDataArray = parseSvgPaths(currentLetter);
    console.log(pathDataArray);
    const { adjustedX, adjustedY } = resolveOffsets(pathDataArray);

    drawPathsOnCanvas(canvas, pathDataArray, adjustedX, adjustedY, 20);
    drawPathsOnCanvas(coverCanvas, pathDataArray, adjustedX, adjustedY, 20);
    drawPathsOnCanvas(hitCanvas, pathDataArray, adjustedX, adjustedY, 32);
    // -------------------------------------------------------

    const startDrawing = (event) => {
      if (didFinishRef.current == true) {
        resetMistakes();
        handleTryAgain();
        didFinishRef.current = false;
      }

      // drawing
      const rect = canvas.getBoundingClientRect();
      ctx.beginPath(); // Start a new path
      ctx.moveTo(event.clientX - rect.left, event.clientY - rect.top);
      // cover
      const coverRect = coverCanvas.getBoundingClientRect();
      coverCtx.beginPath(); // Start a new path
      coverCtx.moveTo(event.clientX - rect.left, event.clientY - rect.top);

      // is drawing
      isDrawing.current = true;
    };

    const draw = (event) => {
      drawCanvasOnMove(event, isDrawing, canvas, coverCanvas, hitTestBlack);
    };

    const stopDrawing = (e) => {
      inAlert.current = false;
      isDrawing.current = false;
      ctx.closePath(); // Close the current path (optional for smoother paths)

      if (hackMistakesRef.current > 3 && e.type === "pointerup") {
        playCheer();
      }

      // check black
      if (e.type === "pointerup") {
        checkBlackPixels();
      }
    };

    /** for complete */
    const checkBlackPixels = () => {
      console.log("checking black pixels");
      console.log(coverCtx.width, coverCtx.height);
      // Get all pixel data from the canvas
      const imageData = coverCtx.getImageData(
        0,
        0,
        coverCanvas.width,
        coverCanvas.height
      );

      const pixels = imageData.data;

      // Check for any black pixels
      const hasBlack = Array.from({ length: pixels.length / 4 }).some(
        (_, i) =>
          pixels[i * 4] === 0 && // Red
          pixels[i * 4 + 1] === 0 && // Green
          pixels[i * 4 + 2] === 0 && // Blue
          pixels[i * 4 + 3] === 255 // Alpha (fully opaque)
      );

      const currentCount = countBlackPixels(coverCanvas);

      if (currentCount <= 25) {
        playCheer();
        handleLetterComplete();
        // toast.success("you are done ... ", { position: "top-right" });
      }
    };

    // Add mouse event listeners
    canvas.addEventListener("pointerdown", startDrawing);
    canvas.addEventListener("pointermove", draw);
    canvas.addEventListener("pointerup", stopDrawing);
    canvas.addEventListener("pointerleave", stopDrawing);

    coverCanvas.addEventListener("pointerdown", startDrawing);
    coverCanvas.addEventListener("pointermove", draw);
    coverCanvas.addEventListener("pointerup", stopDrawing);
    coverCanvas.addEventListener("pointerleave", stopDrawing);

    return () => {
      // Cleanup event listeners on unmount
      canvas.removeEventListener("pointerdown", startDrawing);
      canvas.removeEventListener("pointermove", draw);
      canvas.removeEventListener("pointerup", stopDrawing);
      canvas.removeEventListener("pointerleave", stopDrawing);

      coverCanvas.removeEventListener("pointerdown", startDrawing);
      coverCanvas.removeEventListener("pointermove", draw);
      coverCanvas.removeEventListener("pointerup", stopDrawing);
      coverCanvas.removeEventListener("pointerleave", stopDrawing);
    };
  }, [currentLetter]);

  const resolveOffsets = (pathDataArray) => {
    const rect = calculateGroupBoundingBox(pathDataArray);
    const canvas = canvasRef.current;
    const w = canvas.width;
    const center = w / 2;
    let adjusted = center - rect.width / 2;
    adjusted = adjusted - 20;
    const h = canvas.height;
    const centerY = h / 2;
    let adjustedY = centerY - rect.height / 2;
    adjustedY = adjustedY - 10;

    return { adjustedX: adjusted, adjustedY: adjustedY };
  };

  const handleTryAgain = () => {
    drawTopCanvases();
    setMistakes(0);
  };

  const drawTopCanvases = (animated = false) => {
    stopCheer();

    // Clear the drawing canvas
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const coverCanvas = coverCanvasRef.current;
    const coverCtx = coverCanvas.getContext("2d");
    coverCtx.clearRect(0, 0, coverCanvas.width, coverCanvas.height);

    const hitCanvas = hitCanvasRef.current;
    const hitCtx = hitCanvas.getContext("2d");
    hitCtx.clearRect(0, 0, hitCanvas.width, hitCanvas.height);

    // paths
    const pathDataArray = parseSvgPaths(currentLetter);
    const { adjustedX, adjustedY } = resolveOffsets(pathDataArray);
    // draw paths on canvas
    drawPathsOnCanvas(
      canvas,
      pathDataArray,
      adjustedX,
      adjustedY,
      20,
      animated
    );
    drawPathsOnCanvas(coverCanvas, pathDataArray, adjustedX, adjustedY, 20);
    drawPathsOnCanvas(hitCanvas, pathDataArray, adjustedX, adjustedY, 32);
  };

  const countBlackPixels = (canvas) => {
    // console.log("Counting black pixels");
    // console.log(coverCtx.width, coverCtx.height);
    const coverCtx = canvas.getContext("2d");

    // Get all pixel data from the canvas
    const imageData = coverCtx.getImageData(0, 0, canvas.width, canvas.height);

    const pixels = imageData.data;
    let blackPixelCount = 0;

    // Count the number of black pixels
    for (let i = 0; i < pixels.length; i += 4) {
      const red = pixels[i];
      const green = pixels[i + 1];
      const blue = pixels[i + 2];
      const alpha = pixels[i + 3];

      if (red === 0 && green === 0 && blue === 0 && alpha === 255) {
        blackPixelCount++;
      }
    }

    console.log(`Number of black pixels: ${blackPixelCount}`);
    return blackPixelCount;
  };

  /** for alerts */
  const hitTestBlack = (x, y) => {
    const hitCnavs = hitCanvasRef.current;
    const hitCtx = hitCnavs.getContext("2d", { willReadFrequently: true });
    // Get pixel data
    const pixel = hitCtx.getImageData(x, y, 1, 1).data;

    // Check if the pixel is black
    if (
      pixel[0] === 0 &&
      pixel[1] === 0 &&
      pixel[2] === 0 &&
      pixel[3] === 255
    ) {
      if (inAlert.current) {
        inAlert.current = false;
        console.log("Mouse is over a black pixel!");
      }
    } else {
      // mouse over white
      if (!inAlert.current) {
        inAlert.current = true;
        setMistakes((prev) => {
          return prev + 1;
        });
        playBeep();
        // toast.success("off course", { position: "top-right" });
        console.log("Mouse is NOT over a black pixel.");
      }
    }
  };

  const handleAnimate = () => {
    drawTopCanvases(true);
  };

  // ------------------------------------------------------------

  const [showStars, setShowStars] = useState(false);
  const layoutRef = useRef(null);
  const handleLetterComplete = () => {
    layoutRef.current = 5;
    didFinishRef.current = true;
    setShowStars(true);

    // Hide stars after the animation duration
    const timeoutId = setTimeout(() => {
      setShowStars(false);
    }, 3000); // Adjust duration to match the animation time

    // Store the timeout ID in a ref for cleanup
    timeoutRef.current = timeoutId;
  };

  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup timeout on unmount to prevent memory leaks
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // ------------------------------------------------------------

  return (
    <div
      style={{ overflow: "hidden", position: "relative", touchAction: "none" }}
      className="flex flex-col items-center justify-center h-screen"
    >
      <audio ref={cheerRef} src={Cheer} />
      <audio ref={beepRef} src={Beep} />
      <audio ref={tryAgainRef} src={TryAgain} />
      <audio ref={letterAudioRef} src={selectedLetterRef.current} />

      <div className="relative flex">
        {/* draw canvas */}
        <canvas
          className="select-none border border-gray-400 rounded-3xl"
          ref={canvasRef}
          width={400}
          height={400}
          style={{ cursor: "crosshair", touchAction: "none" }}
        />
        {/* cover cnavas */}
        <canvas
          className={expand ? "" : "hidden"}
          ref={coverCanvasRef}
          width={400}
          height={400}
        />
        {/* hit canvas */}
        <canvas
          className={expand ? "" : "hidden"}
          ref={hitCanvasRef}
          width={400}
          height={400}
        />
      </div>
      {showStars && <Stars />}

      <div className="absolute bottom-[100px] right-[50px] flex items-center mt-[50px]">
        <div
          onClick={handleTryAgain}
          className="flex items-center justify-center cursor-pointer border border-gray-500 size-[60px] rounded-full"
        >
          <ReplayIcon />
        </div>
        {/* <div
          onClick={handleAnimate}
          className="flex items-center justify-center text-center text-4xl font-bold cursor-pointer rounded-full border-2 border-gray-700 w-[74px] h-[74px] ml-4"
        >
          An
        </div> */}
      </div>
      <div className="absolute top-1 left-2 text-4xl font-bold">
        mistakes: {mistakes}
      </div>
      <div
        onClick={handleShowCanvases}
        className="absolute top-1 right-2 text-4xl font-bold cursor-pointer"
      >
        aid
      </div>
      <div className="flex items-center mt-6 gap-4">
        {letters.map((letter, index) => (
          <div key={index}>
            <div
              onClick={() => handleSwitchLetter(letter)}
              className="flex items-center justify-center text-center text-2xl               
              text-gray-600 font-bold cursor-pointer border-2 border-gray-600 rounded-full size-[50px]
              hover:bg-gray-400 transition-colors duration-300 ease-in-out"
            >
              {letter.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HitTestCanvas;

// Stars Component
const Stars = ({ count = 20 }) => {
  const stars = Array.from({ length: count }, () => ({
    id: Math.random().toString(36).substr(2, 9), // Unique ID
    size: Math.random() * 30 + 20, // Random size between 20px and 50px
    y: Math.random() * 300 - 150, // Random horizontal offset
    x: Math.random() * 300 - 150, // Random horizontal offset
    duration: Math.random() * 1 + 2, // Random duration between 1s and 3s
  }));

  return (
    <div
      style={{
        position: "absolute",
        // background: "red",
        // opacity: 0.5,
        top: "50%",
        left: "50%",
        height: "500px",
        width: "500px",
        touchAction: "none",
        pointerEvents: "none", // Allow events to pass through
        zIndex: 0,
      }}
    >
      {stars.map((star) => (
        <motion.div
          key={star.id}
          initial={{
            y: 0,
            x: star.x,
            opacity: 1,
          }}
          animate={{
            y: star.y - 300, // Move upwards
            x: star.x,
            opacity: 0, // Fade out
          }}
          transition={{
            duration: star.duration,
            ease: "easeOut",
          }}
          style={{
            position: "absolute",
            // left: `50%`,
            transform: `translateX(${star.x}px)`,
          }}
        >
          <StarSVG size={star.size} />
        </motion.div>
      ))}
    </div>
  );
};
