import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import { toast } from "react-toastify";
import HexEditForm from "./forms/HexEditForm";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import HexMeta from "./HexMeta";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import HexCrumb from "./HexCrumb";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { DrawingType, Roles, SELECT_ROLES } from "../../models/Enums";

import DrawingRevisionSheet from "./components/DrawingRevisionSheet";

import HexManageRevisionForm from "./forms/HexManageRevisionForm";
import Project from "../../models/Project";
import { useUploadDocument } from "../../hooks/useUploadDocument";
import HexDrawing from "../../models/HexDrawing";
import { useNavigate } from "react-router-dom";
import { useCanvasContext } from "../../hooks/useCanvasContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { CircularProgress } from "@mui/material";

export default function ProjectHex() {
  const { userIs, activeUser } = useAuthContext();
  const {
    resetCanavs,
    setShowNav,
    clearLines,
    resetScale,
    resetCanvasOffset,
    setLowRes,
    setHighRes,
    activateViewMode,
  } = useCanvasContext();

  let [isFullScreen, setFullScreen] = useState(false);

  const { height, width } = useWindowDimensions();
  const { id } = useParams();
  const { document: project, error } = useDocument("projects", id);
  const [showEditHex, setShowEditHex] = useState(false);

  /***
   * setup
   */
  useEffect(() => {
    setLowRes();
    setShowNav(true);

    resetCanavs();
  }, []);

  const dismiss = () => {
    setShowEditHex(false);
    setShowAddImageForm(false);
    setShowMenu(true);
  };

  const handleEdit = () => {
    setShowEditHex(!showEditHex);
    setShowMenu(false);
  };

  const resolveCurrentRev = () => {};
  const [revLength, setRevLength] = useState(1);
  // list of urls
  const [hexList, setHexList] = useState([]);
  // active rev id
  const [activeRevId, setActiveRevId] = useState("");
  /// active index
  const [index, setIndex] = useState(0);
  // active image
  const [currentHexImage, setCurrentHexImage] = useState("");

  const handleTreverse = (e) => {
    const xval = e.nativeEvent.clientX;

    const screenWidth = width;
    const screenMid = width / 2;

    if (xval > screenMid) {
      goForward();
    } else {
      goBack();
    }

    if (!isFullScreen) {
      dismiss();
    }
  };

  const goForward = () => {
    var i = index + 1;
    if (i > hexList.length - 1) {
      i = 0;
    }
    setIndex(i);
    setCurrentHexImage(hexList[i]);
  };

  const goBack = () => {
    var i = index - 1;
    if (i < 0) {
      i = hexList.length - 1;
    }
    setIndex(i);
    setCurrentHexImage(hexList[i]);
  };

  const resolveActiveRev = (rev) => {
    // new length
    const newLength = rev.images.length;
    setRevLength(newLength);

    setActiveRevId(rev.id);
    setHexList([...rev.images]);
    setCurrentHexImage(rev.images[0]);
    // if no images yet
    if (rev.images.length == 0) {
      setHexList([project.url]);
      setCurrentHexImage(project.url);
    }
  };

  useEffect(() => {
    setRevLength(hexList.length);
  }, [hexList]);

  useEffect(() => {
    if (project) {
      const xpro = Project.fromData(project);
      const latestRev = xpro.latestRevision();
      resolveActiveRev(latestRev);
    }
  }, [project]);

  const [showAddImageForm, setShowAddImageForm] = useState(false);
  const handleAddImage = () => {
    setShowAddImageForm(!showAddImageForm);
    setShowMenu(false);
  };

  const handleKeyDown = (e) => {
    // event
    if (openStack) return;

    console.log(e.keyCode);

    if (e.keyCode == "70") {
      setFullScreen(true);
    }

    if (e.keyCode == "68") {
      if (objectType == "cover") {
        setObjectType("container");
      } else {
        setObjectType("cover");
      }
    }

    // listen for right arrow
    if (e.keyCode == "39") {
      goForward();
    }

    // listen for left arrow
    if (e.keyCode == "37") {
      goBack();
    }
    // listen for the "q" key to hide menu
    if (e.keyCode == "81") {
      toast.success("q key pressed");
      console.log("q key down ======== ");
      setShowMenu(!showMenu);
    }
  };

  const [showMenu, setShowMenu] = useState(true);
  const blackContainer = useRef(null);

  const resolveCurrentIndex = (ind) => {
    setIndex(ind);
    setCurrentHexImage(hexList[ind]);
  };

  const [objectType, setObjectType] = useState("cover");

  const [showDStack, setShowDStack] = useState(false);

  const handleShowDrawings = () => {
    setOpenStack(true);
  };

  // Load the initial value from localStorage or set to false if not present
  const [openStack, setOpenStack] = useState(() => {
    const savedOpenStack = localStorage.getItem("openStack");
    return savedOpenStack !== null ? JSON.parse(savedOpenStack) : false;
  });
  // Save `selected` to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("openStack", JSON.stringify(openStack));
  }, [openStack]);

  const stackDismiss = () => {
    setOpenStack(false);
  };

  return (
    <div style={{ height: height - 50 }} className="relative overflow-hidden">
      <FullScreen
        isFullScreen={isFullScreen}
        onChange={(isFull) => {
          setFullScreen(isFull);
        }}
      >
        {revLength > 1 && (
          <div className="absolute left-[50px] bottom-[50px] z-10">
            <HexCrumb
              size={revLength}
              currentIndex={index}
              resolveCurrentIndex={resolveCurrentIndex}
            />
          </div>
        )}
        <div
          tabIndex="-1"
          ref={blackContainer}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          {project && (
            <div className="relative">
              {showAddImageForm && (
                <HexManageRevisionForm
                  project={project}
                  activeRevId={activeRevId}
                  resolveActiveRev={resolveActiveRev}
                  dismiss={dismiss}
                />
              )}

              {showEditHex && (
                <HexEditForm project={project} dismiss={dismiss} />
              )}

              {showMenu && (
                <>
                  {!isFullScreen && (
                    <div className="absolute right-4 top-4 flex items-center gap-1">
                      <div
                        onClick={handleEdit}
                        className="cursor-pointer bg-blue-500 p-3 rounded-lg"
                      >
                        Edit
                      </div>

                      <div
                        onClick={handleAddImage}
                        className="cursor-pointer bg-blue-500 p-3 rounded-lg"
                      >
                        add image
                      </div>

                      {activeUser && (
                        <div
                          onClick={handleShowDrawings}
                          className="cursor-pointer hover:bg-blue-700 bg-blue-500 rounded-lg p-3 min-h-[40px]"
                        >
                          drawings
                        </div>
                      )}
                    </div>
                  )}

                  <HexMeta
                    project={project}
                    goFullScreen={(e) => setFullScreen(true)}
                  />
                </>
              )}

              <DrawingRevisionSheet
                project={project}
                openMenu={openStack}
                stackDismiss={stackDismiss}
              />
              <div onClick={(e) => handleTreverse(e)}>
                <img
                  className={`w-screen h-screen transition-all duration-100 ease-in-out bg-black ${
                    objectType == "cover" ? "object-cover" : "object-contain"
                  }`}
                  src={currentHexImage}
                />
              </div>
            </div>
          )}
        </div>
      </FullScreen>
    </div>
  );
}
