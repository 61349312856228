import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollection } from "../../../hooks/useCollection";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Project from "../../../models/Project";
import { motion, AnimatePresence } from "framer-motion";
import DrawingRevRow from "./DrawingRevRow";
import { useStudioContext } from "../../../hooks/useStudioContext";
import { useCanvasContext } from "../../../hooks/useCanvasContext";

export default function DrawingRevisionStack({
  project,
  currentStack,
  handlePromote,
}) {
  const navigate = useNavigate();
  const { width, height: widowHeight } = useWindowDimensions();
  const { activeUser, getEntityId } = useAuthContext();
  const { documents } = useCollection("users");
  const [users, setusers] = useState(null);
  const scrollBoxRef = useRef(null);
  const [height, setHeight] = useState(widowHeight - 50 - 100);

  const { setCurrentStudentId } = useStudioContext();
  /****
   * set the users
   */
  useEffect(() => {
    if (documents) {
      setusers(documents);
    }
  }, [documents]);

  /***
   * handle delete
   */
  const handleDelete = (drawingId) => {
    const p = Project.fromData(project);

    // remove drawing with id
    p.removeDrawing(drawingId);
    p.commit().then((ref) => {});
  };

  const userNameForId = (userId) => {
    return users?.filter((u) => u.id == userId)[0] || null;
  };

  const handleClickRow = (id) => {
    // toast.success("navigating to page");
    setCurrentStudentId("");
    navigate(`/canvas/${id}/${project.id}/${0}`);
  };

  /***
   * handle display date
   */
  const handleDisplayDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toDateString()} : ${date.toLocaleTimeString()}`; // Format date and time as a string
    }
    return ""; // Return an empty string or a default value if the timestamp is invalid
  };

  /***
   * handle download
   */
  const handleDownload = async (e, drawing) => {
    e.stopPropagation();

    // drawing url
    const url = drawing.url;
    const date = handleDisplayDate(drawing.date);

    // Fetch the file as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");

    // Create a URL for the Blob and set it as the href of the link
    const blobURL = window.URL.createObjectURL(blob);
    link.href = blobURL;

    // link.download = "your-filename.pdf";
    link.setAttribute("download", `${project.name}-${date}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [drawings, setDrawings] = useState(
    project?.drawings?.slice().reverse() || []
  );

  useEffect(() => {
    // toast.success("project update");
    setDrawings(project?.drawings?.slice().reverse() || []);
  }, [project]);

  const handleRemoveDrawing = (e, drwingId) => {
    e.stopPropagation();
    if (window.confirm("delete drawings")) {
      setDrawings((prev) => prev.filter((drawing) => drawing.id !== drwingId));
      handleDelete(drwingId); // Call your original delete handler
    }
  };

  const [filteredDrawings, setFilteredDrawings] = useState([]);
  useEffect(() => {
    // toast.success(currentStack);
    if (project) {
      const result = [];
      if (currentStack == "studio") {
        const d = project?.drawings?.slice().reverse() || [];
        setFilteredDrawings([...d]);
      }
      if (currentStack == "principle") {
        let d = project?.drawings?.slice().reverse() || [];
        d = d.filter(
          (drawing) =>
            drawing.stage == "review" || drawing.stage == "presentation"
        );
        setFilteredDrawings([...d]);
      }
      if (currentStack == "client") {
        let d = project?.drawings?.slice().reverse() || [];
        d = d.filter((drawing) => drawing.stage == "presentation");
        setFilteredDrawings([...d]);
      }
    }
  }, [currentStack, project]);

  return (
    <div>
      <div
        ref={scrollBoxRef}
        style={{ height: height - 50 }}
        className="flex flex-col gap-1 w-full overflow-y-scroll scroll-box bg-transparent p-4"
      >
        {project && (
          <div className="flex flex-col gap-1">
            <AnimatePresence>
              {filteredDrawings.map((drawing, index) => (
                <motion.div
                  key={drawing.id}
                  layout // Enables Framer Motion's layout animation
                  initial={{ opacity: 0, y: 20 }} // Start slightly below
                  animate={{ opacity: 1, y: 0 }} // Animate into place
                  exit={{ opacity: 0, height: 0, margin: 0, padding: 0 }} // Animate out smoothly
                  transition={{ duration: 0.4, ease: "easeInOut" }} // Smooth transition
                >
                  <DrawingRevRow
                    index={index}
                    users={users}
                    drawing={drawing}
                    handleClickRow={handleClickRow}
                    handleDownload={handleDownload}
                    handleRemoveDrawing={handleRemoveDrawing}
                    handleDisplayDate={handleDisplayDate}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        )}
      </div>
    </div>
  );
}
