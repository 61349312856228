import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import HexInboxItem from "../../../models/HexInboxItem";
import HexUser from "../../../models/HexUser";

export default function InboxRow({ user, item, drawings, handleOpenDialog }) {
  const [drawing, setDrawing] = useState(null);
  const [project, setProject] = useState(null);
  const [sender, setSender] = useState(null);
  const { projects, users } = useCollectionContext();

  /***
   * set project
   */
  useEffect(() => {
    if (projects && drawing) {
      const p = projects.find((project) => project.id == drawing.projectId);
      setProject(p);
    }
  }, [projects, drawing]);

  /***
   * set drwaing
   */
  useEffect(() => {
    const d = drawings.find((drawing) => drawing.id == item.drawingId);
    setDrawing(d);
  }, [drawings]);

  /***
   * sender
   */
  useEffect(() => {
    // if (users) {
    //   const s = users.find((user) => user.id == drawing?.pushedBy);
    //   setSender(s);
    // }
    // setSender(item.senderId);
  }, [users, drawing]);

  const resolveSenderName = () => {
    console.log("item.senderId: ", item);
    return users.find((user) => user.id == item.senderId)?.displayname;
  };

  /***
   * delete
   */
  const handleDelete = (e) => {
    e.stopPropagation();
    if (window.confirm("delete item ?")) {
      const hexUser = HexUser.fromData(user);
      hexUser.removeFromInbox(item.drawingId);
      hexUser.commit();
    }
  };

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();

    // mark as read
    const hexUser = HexUser.fromData(user);
    hexUser.markItemAsRead(item.id);

    let drawingId;
    let projectId;

    try {
      projectId = project.id;
    } catch (e) {
      console.log(e);
      toast.error("the project may have been deleted");
      return;
    }

    try {
      drawingId = drawing.id;
    } catch (e) {
      console.log(e);
      toast.error("the drawing may have been deleted");
      return;
    }

    // console.log(drawingId, projectId);
    navigate(`/canvas/${drawingId}/${projectId}/${0}`);

    // commit
    hexUser.commit();
  };

  const handleMarkAsUnread = (e) => {
    e.stopPropagation();
    // mark as read
    const hexUser = HexUser.fromData(user);
    hexUser.markItemAsUnread(item.id);

    // commit
    hexUser.commit();
  };

  const resolveDate = () => {
    const hexItem = HexInboxItem.fromData(item);
    return hexItem.dateTimeString();
  };

  const handleShowSendDialog = (e) => {
    e.stopPropagation();
    handleOpenDialog(drawing);
  };

  const handleMarkUnread = (e) => {
    // mark unread
    e.stopPropagation();
    if (!item.isRead) {
      toast.success("drawing already unread");
      return;
    }
    // mark as read
    const hexUser = HexUser.fromData(user);
    hexUser.markItemAsUnread(item.id);

    // commit
    hexUser.commit();
  };

  return (
    <div
      onClick={(e) => handleClick(e)}
      className={`p-3 flex items-center bg-gray-400 min-h-[60px] w-full cursor-pointer`}
    >
      <div
        className={`w-[20px] h-[20px] ${
          item.isRead
            ? "bg-gray-300 border-2 border-gray-700/50"
            : "bg-orange-500"
        } rounded-full`}
      ></div>
      <div className="px-3">
        <div className="flex gap-2">
          <div className="cursor-default text-2xl pointer-events-none">
            {drawing?.serial || "no drawing id"}
          </div>
          <div className="cursor-default text-2xl font-bold ml-4">
            {project?.name || "no project id"}
          </div>
        </div>
        <div className="cursor-default text-xl font-thin pointer-events-none">
          sent by: {resolveSenderName()}
        </div>
        <div className="font-thin">date: {resolveDate()}</div>
      </div>
      <div className="flex items-center gap-2 ml-auto mr-4">
        <div onClick={(e) => handleMarkUnread(e)} className="">
          [unread]
        </div>
        <div onClick={(e) => handleShowSendDialog(e)} className="">
          [forward]
        </div>
        <div onClick={(e) => handleDelete(e)} className="cursor-pointer">
          [delete]
        </div>
      </div>
    </div>
  );
}
